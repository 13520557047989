export const ERRORS_MATCHING = [
    {code: 'PAY_001', urlMatcher: '/business_webpayment/payment/rest/v1/transfer/currencies/list'},

    {code: 'CTR_0001', urlMatcher: '/business_webctrinfo/rest/v1/webProContract/list'},
    {code: 'CTR_0007', urlMatcher: '/business_webctrinfo/rest/v1/synthesis/families/get'},
    {code: 'CTR_0008', urlMatcher: '/business_webctrinfo/rest/v1/cards/movements/last'},
    {code: 'CTR_0009', urlMatcher: '/business_webctrinfo/rest/v1/primaryContract/list'},
    {code: 'CTR_0011', urlMatcher: '/business_webctrinfo/rest/v1/synthesis/details/([a-zA-Z]+)'},
    {code: 'CTR_0012', urlMatcher: '/business_webctrinfo/rest/v1/cards/periods/list'},
    {code: 'CTR_0013', urlMatcher: '/business_webctrinfo/rest/v1/cards/movements/list'},
    {code: 'CTR_0014', urlMatcher: '/business_webctrinfo/rest/v1/asset-histories/movements/listWithPagination'},
    {code: 'CTR_9999', urlMatcher: '/business_webctrinfo/rest/v1/pewrapper/active-features/get'},

    {code: 'DOC_0001', urlMatcher: '/business_webdocacc/rest/v1/edocuments/getArchives'},
    {code: 'DOC_0002', urlMatcher: '/business_webdocacc/rest/v1/edocuments/getLastUnreadDocuments'},
    {code: 'DOC_0003', urlMatcher: '/business_webdocacc/rest/v1/edocuments/"contractReference"/documents/'},
    {code: 'DOC_0004', urlMatcher: '/business_webdocacc/rest/v1/edocuments/"contractReference"/createArchive/'},
    {code: 'DOC_0005', urlMatcher: '/business_webdocacc/rest/v1/edocuments/documentFilter'},
    {code: 'DOC_0006', urlMatcher: '/business_webdocacc/rest/v1/edocuments/"contractReference"/nbUnreadDocuments'},
    {code: 'DOC_0007', urlMatcher: '/business_webdocacc/rest/v1/edocuments/sendEdocumentsAccessEvent'},

    {code: 'WEB_0001', urlMatcher: '/business_web/gcl-war/gcl/context/getContext'},
    {code: 'WEB_0002', urlMatcher: '/business_web/gcl-war/gcl/context/getSignatureText'},
    {code: 'WEB_0003', urlMatcher: '/business_web/gcl-war/gcl/context/requestCertificate'},

    {code: 'PROF_0001', urlMatcher: '/business_webprofile/rest/v1/customerinformation/get'},
    {code: 'PROF_0002', urlMatcher: '/business_webprofile/rest/v1/customerinformation/notifications/get'},
    {code: 'PROF_0003', urlMatcher: '/business_webprofile/rest/v1/customerinformation/notifications/put'},
    {code: 'PROF_0010', urlMatcher: '/business_webprofile/rest/v1/notificationPreferences/mbb/get'},
    {code: 'PROF_0011', urlMatcher: '/business_webprofile/rest/v1/notificationPreferences/mbb/save'},

    {code: 'MSG_0001', urlMatcher: '/business_webmsg/rest/v1/sendOpenOptiflexAccount'},
    {code: 'MSG_0002', urlMatcher: '/business_webmsg/rest/v1/sendFeedback'},
    {code: 'MSG_0003', urlMatcher: '/business_webmsg/rest/v1/get/mails'},
    {code: 'MSG_0004', urlMatcher: '/business_webmsg/rest/v1/bmd/messagerieConsultationEvent/send'},
    {code: 'MSG_0005', urlMatcher: '/business_webmsg/rest/v1/pewrapper/active-features/get'},
    {code: 'MSG_0006', urlMatcher: '/business_webmsg/rest/v1/contactMail'},
    {code: 'MSG_0007', urlMatcher: '/business_webmsg/rest/v1/xmails'},
    {code: 'MSG_0008', urlMatcher: '/business_webmsg/rest/v1/bmd/meetingEvent/send'},
    {code: 'MSG_0009', urlMatcher: '/business_webmsg/rest/v1/bmd/recallEvent/send'},
    {code: 'MSG_0010', urlMatcher: '/business_webmsg/rest/v1/bmd/personalLoanSimEvent/send'},
    {code: 'MSG_0101', urlMatcher: '/business_webmsg/rest/v1/mails'},

    {code: 'LOG_001', urlMatcher: '/logon/wlo-war/rpc/wlo/getSignInChallenge'},
    {code: 'LOG_002', urlMatcher: '/logon/wlo-war/rpc/wlo/getStepUpChallenge'},
    {code: 'LOG_003', urlMatcher: '/logon/wlo-war/rpc/wlo/validateStepUpChallenge.auth'},
    {code: 'LOG_004', urlMatcher: '/logon/wlo-war/rpc/wlo/validateCardCode.auth'},
    {code: 'LOG_005', urlMatcher: '/logon/wlo-war/rpc/wlo/spmlogin2.auth'},
    {code: 'LOG_006', urlMatcher: '/logon/wlo-war/rpc/wlo/signIn.auth'},
    {code: 'LOG_007', urlMatcher: '/logon/wlo-war/rpc/wlo/luxtrust/signin/challenge/get'},
    {code: 'LOG_008', urlMatcher: '/logon/wlo-war/rpc/wlo/luxtrust/signin/challenge/validate.auth'},
    {code: 'LOG_009', urlMatcher: '/logon/wlo-war/rpc/wlo/logout.out'},
    {code: 'LOG_010', urlMatcher: '/logon/wlo-war/rpc/wlo/ping'},

    {code: 'AUT_0001', urlMatcher: '/business_logon/public/rest/luxtrust/signin/challenge/get'},
    {code: 'AUT_0002', urlMatcher: '/business_logon/public/rest/luxtrust/signin/challenge/validate.auth'},
    {code: 'AUT_0003', urlMatcher: '/business_logon/public/rest/luxtrust/signin/success'},

    {code: 'LOM_9999', urlMatcher: '/business_weblom/public/rest/active-features/get'},

    {code: 'PAY_9999', urlMatcher: '/business_webpaymentpro/rest/v1/pewrapper/active-features/get'},

    {code: 'PAY_0001', urlMatcher: '/business_webpaymentpro/rest/context/rules/iban/get'},
    {code: 'PAY_0002', urlMatcher: '/business_webpaymentpro/rest/context/countries/list'},

    {code: 'PAY_0100', urlMatcher: '/business_webpaymentpro/rest/transfer/create'},
    {code: 'PAY_0101', urlMatcher: '/business_webpaymentpro/rest/transfer/accounts/debitable/get'},
    {code: 'PAY_0102', urlMatcher: '/business_webpaymentpro/rest/transfer/accounts/creditable/get'},
    {code: 'PAY_0103', urlMatcher: '/business_webpaymentpro/rest/transfer/characteristic/currencies/get'},
    {code: 'PAY_0104', urlMatcher: '/business_webpaymentpro/rest/transfer/characteristic/countries/reporting/get'},
    {code: 'PAY_0105', urlMatcher: '/business_webpaymentpro/rest/transfer/characteristic/fees/get'},
    {code: 'PAY_0106', urlMatcher: '/business_webpaymentpro/rest/transfer/characteristic/transfer/types/get'},
    {code: 'PAY_0107', urlMatcher: '/business_webpaymentpro/rest/transfer/pre-validation/get'},
    {code: 'PAY_0108', urlMatcher: '/business_webpaymentpro/rest/transfer-fees/estimate'},
    {code: 'PAY_0109', urlMatcher: '/business_webpaymentpro/rest/transfer/signature/is-required'},

    {code: 'PAY_0200', urlMatcher: '/business_webpaymentpro/rest/beneficiary/list'},
    {code: 'PAY_0201', urlMatcher: '/business_webpaymentpro/rest/beneficiary/create'},
    {code: 'PAY_0202', urlMatcher: '/business_webpaymentpro/rest/beneficiary/update'},
    {code: 'PAY_0203', urlMatcher: '/business_webpaymentpro/rest/beneficiary/delete'},

    {code: 'PAY_0300', urlMatcher: '/business_webpaymentpro/rest/transfer/history'},
    {code: 'PAY_0301', urlMatcher: '/business_webpaymentpro/rest/transfer/details/get'},

    {code: 'PAY_0400', urlMatcher: '/business_webpaymentpro/rest/transfer/pre-instruction/list'},
    {code: 'PAY_0401', urlMatcher: '/business_webpaymentpro/rest/transfer/pre-instruction/get'},
    {code: 'PAY_0402', urlMatcher: '/business_webpaymentpro/rest/transfer/pre-instruction/cancel'},
    {code: 'PAY_0403', urlMatcher: '/business_webpaymentpro/rest/transfer/pre-instruction/sign'}

];
