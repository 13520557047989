import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import _ from 'lodash';
import {Amount, FeesTypeEnum, StandingOrderPeriod} from '../../../@types/PaymentProTypes.ts';
import {AccountType, Beneficiary} from '../../../@types/BeneficiaryTypes.ts';
import {Concat} from '../../../../@types/utils';
import { TransferToType } from './storeSinglePayment.ts';

export enum StandingOrderToType {
    MY_ACCOUNTS = 'MY_ACCOUNTS',
    BENEFICIARY = 'BENEFICIARY'
}

export type StandingOrderState = {
    standingOrderData: CreateStandingOrder;
    step: number;
};

export type StandingOrderBeneficiary = Pick<Beneficiary, 'accountReference'>;

export type CreateStandingOrder = {
    firstExecutionDate: string | null,
    lastExecutionDate: string | null,
    periodicity: StandingOrderPeriod,

    contractReference: string | null,
    primaryContract: string | null,

    accountFrom: string,
    initiatorName?: string,
    beneficiary: StandingOrderBeneficiary | Beneficiary,

    communication: string | null,
    amount: Amount,
    accountCurrency?: string | null,
    regulatoryReportingReason: string | null,

    debitAdvice: boolean,
    saveBeneficiary: boolean,
    saveCommunication: boolean,
    transferToType: TransferToType,
    transferRequestFees: FeesTypeEnum | null,

    signatureRequest?: {
        challengeId: string,
        answer: string
    }
};

export type StandingOrderResult = {
    message: string;
    preInstruction?: boolean,
    operationReference: string;
    status: string;
    warnings: {code: string, reason: string}[];
    errors: {code: string, reason: string}[];
};

export type FeesRequest = {
    amount: number,
    currency: string,
    countryOfDestination: string
};

export const daysOfMonthEnabled = [1, 5, 10, 15, 20, 25, 30];

const initialState: StandingOrderState = {
    standingOrderData: {
        firstExecutionDate: null,
        lastExecutionDate: null,
        periodicity: StandingOrderPeriod.MENSUEL,
        contractReference: null,
        primaryContract: null,

        accountFrom: '',
        beneficiary: {
            accountReference: null,
            beneficiaryAccountType: AccountType.IBAN
        },

        communication: null,
        amount: {
            value: null,
            currency: 'EUR'
        },
        accountCurrency: null,
        regulatoryReportingReason: '',

        debitAdvice: true,
        saveBeneficiary: false,
        saveCommunication: false,
        transferToType: TransferToType.BGL_BGL,
        transferRequestFees: null
    },
    step: 1
};

export type CreateStandingOrderDataFieldType =
Omit<keyof CreateStandingOrder, 'amount' | 'beneficiary'>
| Concat<'amount.', keyof Amount>
| Concat<'beneficiary.', keyof Beneficiary>;

const standingOrderSlice = createSlice({
    initialState,
    name: 'payment/singlePayment',
    reducers: {
        setStandingOrderData(state, action: PayloadAction<{ field: CreateStandingOrderDataFieldType, value: any }>) {
            _.set(state.standingOrderData, action.payload.field as string, action.payload.value);
        },
        setStandingOrderBeneficiaryData(state, action: PayloadAction<{ value: Beneficiary }>) {
            _.set(state.standingOrderData, 'beneficiary', action.payload.value);
        },
        setStep(state, action: PayloadAction<number>) {
            state.step = action.payload;
        },
        setTransferToType(state, action: PayloadAction<TransferToType>) {
            _.set(state.standingOrderData, 'transferToType', action.payload);
        },
        resetBeneficiary(state) {
            _.set(state.standingOrderData, 'beneficiary', initialState.standingOrderData.beneficiary);
        },
        resetStandingOrderData(state) {
            state.standingOrderData = initialState.standingOrderData;
        }
    }
});

export const {
    setStandingOrderData,
    setStandingOrderBeneficiaryData,
    setStep,
    setTransferToType,
    resetBeneficiary,
    resetStandingOrderData
} = standingOrderSlice.actions;

export const reducer = standingOrderSlice.reducer;
