import {Checkbox, Stack, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ActionKind} from '../reducers/paymentConstraintStateReducer';
import {PaymentConstraint} from '../../../redux/@types/SelfAdminTypes';
import {useValidation} from '../../../hooks/useValidation';
import AmountTextField from '../../_commons/components/Form/AmountTextField';

const rules = {
    signatureProfile: ['numeric']
};

const StandardPaymentSigningRules = ({
                                         signaturesProfile,
                                         dispatch,
                                         paymentConstraint,
                                         hasSelfAdminPermission
                                     }: {
    signaturesProfile: {
        id: number;
        label: string;
        value: string[];
    };
    dispatch: any,
    paymentConstraint: PaymentConstraint,
    hasSelfAdminPermission: boolean
}) => {
    const {t} = useTranslation(['commons']);
    const {validate, hasError, setValidationErrors, validationErrors} = useValidation();
    const indexSignatureProfile = paymentConstraint.signatureProfile.findIndex((profile) => {
        return profile.requiredTransactionProfiles.length === signaturesProfile.value.length &&
            profile.requiredTransactionProfiles.every((el, index) => el === signaturesProfile.value[index]);
    });
    const maxAmountLabel = paymentConstraint?.maxAmount?.nb && paymentConstraint.maxAmount.nb > 0 ?
        paymentConstraint.maxAmount.nb + ' EUR' :
        t('commons:modules.webpro.self-admin.limit-payement-unlimited')
    ;

    const isChecked = indexSignatureProfile !== -1 && signaturesProfile.value.every(
        el => paymentConstraint.signatureProfile[indexSignatureProfile].requiredTransactionProfiles.includes(el)
    );

    const handleCheck = (isChecked: boolean | null, profileList: string) => {
        dispatch({
                type: isChecked ? ActionKind.ACTION_ADD_PROFILE : ActionKind.ACTION_REMOVE_PROFILE,
                payload: {
                    profileList: profileList
                }
            }
        );
    };

    const handleAmount = (amount: number | null, id: number) => {
        const validationData = {signatureProfile: amount};
        const validation = validate(validationData, rules);

        if (validation.fails()) {
            setValidationErrors(validation.errors.all());
        } else {
            setValidationErrors([]);

            dispatch({
                    type: ActionKind.ACTION_EDIT_AMOUNT,
                    payload: {
                        index: id,
                        amount: amount
                    }
                }
            );
        }
    };

    return (
        <Stack>
            <Stack
                key={signaturesProfile.id}
                direction={'row'}
                spacing={2}
                m={2}
                alignItems={'center'}
                justifyContent={'space-between'}
            >
                <Typography flexGrow={1}>{signaturesProfile.label}</Typography>

                <Checkbox
                    checked={isChecked}
                    onChange={(e) => handleCheck(e.target.checked, signaturesProfile.value.toString())}
                    disabled={!hasSelfAdminPermission}
                />

                <AmountTextField
                    label={t('commons:modules.webpro.self-admin.payments-maximum-treshold')}
                    variant={'outlined'}
                    name={'amount' + indexSignatureProfile}
                    value={paymentConstraint.signatureProfile[indexSignatureProfile]?.maxAmount?.nb ?? ''}
                    onChange={(e) => handleAmount(+e.target.value, indexSignatureProfile)}
                    disabled={!isChecked || !hasSelfAdminPermission}
                    error={hasError(`signaturesProfile.${signaturesProfile.id}.amount`)}
                    helperText={
                        hasError(`signaturesProfile.${signaturesProfile.id}.amount`)
                            ? validationErrors[
                                `signaturesProfile.${signaturesProfile.id}.amount`
                                ]
                            : ''
                    }
                    inputProps={{thousandSeparator: true, suffix: ' EUR', style: {textAlign: 'right'}}}
                />


            </Stack>
            {isChecked && !(paymentConstraint.signatureProfile[indexSignatureProfile]?.maxAmount?.nb as number > 0) &&
                <Typography textAlign={'right'} color={'orange'}>{maxAmountLabel}</Typography>
            }
        </Stack>
    );
};

export default StandardPaymentSigningRules;
