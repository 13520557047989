import React from 'react';
import {PaperProps} from '@mui/material/Paper/Paper';
import {Box, Paper, styled, SxProps} from '@mui/material';

const PaperStyled = styled(Paper)<PaperProps>(({theme}) => ({
    flexGrow: 0,
    boxShadow: '0px 5px 12px #A7C7B31C',
    border: '1px solid #E1F4EE',
    borderRadius: '10px',
    padding: theme.spacing(10)
}));

const GenericPaper = ({actions, children, sxPaper, sxBox}: React.PropsWithChildren<{
    actions?: React.ReactNode,
    sxPaper?: SxProps,
    sxBox?: SxProps
}>) => {

    return (
        <Box>
            <PaperStyled sx={sxPaper}>
                <Box sx={sxBox} pb={'20px'}>
                    {children}
                </Box>
            </PaperStyled>

            {actions &&
                <Box sx={{position: 'relative', top: '-20px', textAlign: 'center'}}>
                    {actions}
                </Box>
            }
        </Box>
    );
};

export default GenericPaper;
