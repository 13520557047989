import {useNavigate} from 'react-router';
import {createBrowserHistory} from '@remix-run/router/history';
import {AppPrivateState} from '../context/AppPrivateContext';

export const useRedirectRules = (state: AppPrivateState) => {
    const navigate = useNavigate();
    const history = createBrowserHistory();
    const vanityString = '#vanity/';

    if (state.currentWebProContract?.reference && state.currentPrimaryContract?.primaryContractId) {
        history.listen((listener) => {
            if(listener.location.hash.startsWith(vanityString)) {
                const pos = !vanityString ? -1 : listener.location.hash.lastIndexOf(vanityString);
                const path = ~ pos ? listener.location.hash.slice(pos + vanityString.length, listener.location.hash.length) : listener.location.hash;

                navigate(`/${state.currentWebProContract?.reference}/${state.currentPrimaryContract?.primaryContractId}/${path}`);
            }
        });
    }
};
