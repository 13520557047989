import React, {useMemo} from 'react';
import {Stack, Typography} from '@mui/material';
import {formatDateTime} from '../FormattedText/FormattedDateTime';

const ONE_THOUSAND = 1_000;

const GitInfos = () => {

    const commitDateTimeFromTs = useMemo(() =>
        formatDateTime(
            parseInt(VITE_GIT_COMMIT_TS, 10) * ONE_THOUSAND as unknown as string,
            'fr',
            true, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
            }), []);


    return (
        <Stack direction={'row'} spacing={4}>
            <Typography variant={'body1'} color={'textSecondary'}>version : {VITE_VERSION}</Typography>
            <Typography variant={'body1'} color={'textSecondary'}>git : [branch: {VITE_GIT_BRANCH_NAME},
                commit: {VITE_GIT_COMMIT_HASH}, {commitDateTimeFromTs}]</Typography>
        </Stack>
    );
};

export default GitInfos;
