import {BglWebAndMobileConfig} from '../../config';
import axios, {AxiosError, AxiosRequestConfig, AxiosRequestHeaders, RawAxiosRequestHeaders} from 'axios';
import {BaseQueryFn} from '@reduxjs/toolkit/query';
import {JSEncrypt} from 'jsencrypt';
import {BglMonitor, BglMonitorEvent, BglUserAgentHeaders} from '@bgl/lib-ts-integration';
import {GlobalToaster} from '../../ui/_commons/components/GlobalToaster/GlobalToaster';
import {BglError} from '../@types/ErrorTypes';

axios.interceptors.request.use((request) => {
    request.headers = {
        ...request.headers,
        ...BglMonitor.getInstance().setMonitoringDefaultContext().getHeaders()
    } as AxiosRequestHeaders;

    const url = request.url ?? '';
    const path = (request.headers as RawAxiosRequestHeaders)[BglMonitor.BGL_MONITORING_PATH_HEADER_KEY.toLowerCase()] as string;
    const propagationId = (request.headers as RawAxiosRequestHeaders)[BglMonitor.BGL_MONITORING_PROPAGATION_ID_HEADER_KEY.toLowerCase()] as string;
    BglMonitor.getInstance().registerEvent(BglMonitorEvent.createStartEvent(url, path, propagationId));

    return request;
});

axios.interceptors.response.use((response) => {
    const path = (response.config?.headers as RawAxiosRequestHeaders)[BglMonitor.BGL_MONITORING_PATH_HEADER_KEY.toLowerCase()] as string;
    const propagationId = (response.config?.headers as RawAxiosRequestHeaders)[BglMonitor.BGL_MONITORING_PROPAGATION_ID_HEADER_KEY.toLowerCase()] as string;
    BglMonitor.getInstance().registerEvent(BglMonitorEvent.createEndEvent(path, propagationId));

    return response;
}, (error: AxiosError) => {
    BglMonitor.getInstance().sendMonitorBufferRequest();
    const clientSessionId = (error.config?.headers as RawAxiosRequestHeaders)[BglMonitor.BGL_CLIENT_SESSION_ID.toLowerCase()] as string;

    if (error.code === 'ERR_NETWORK') {
        const url = error.config?.url ?? '';
        GlobalToaster.genericError(url, 'ERR_NETWORK', clientSessionId);
    } else {
        const url = error.config?.url ?? '';
        const bglError = error.response?.data as BglError;
        if (bglError?.errorReference) {
            const errorType = bglError.description;
            GlobalToaster.genericError(url, errorType, clientSessionId);
        }

    }

    return Promise.reject(error);
});


export const createBglHeaders = () => {
    const rfExtCtr = window.location.hash.match(/^#\/(\w{16})\/\w{6}\/(.*)$/)?.at(1);

    let httpHeaders = BglUserAgentHeaders.generateHeaders()
        .set('X-App-Ident', BglWebAndMobileConfig.get('appIdent'))
        .set('X-App-Name', BglWebAndMobileConfig.get('appName'))
        .set('X-Bgl-App-Name', BglWebAndMobileConfig.get('appName'))
        .set('X-Bgl-Agent-App-Name', BglWebAndMobileConfig.get('appName'))
        .set('X-Bgl-App-Language', BglWebAndMobileConfig.get('appLanguage'))
        .set('X-CSRF-Header', 'Secure');

    if (rfExtCtr && rfExtCtr !== '') {
        httpHeaders = httpHeaders.set('X-Bgl-Rf-ExtCtr', rfExtCtr);
    }

    return httpHeaders;
};

export const axiosBaseQuery = ({baseUrl}: { baseUrl: string } = {baseUrl: ''}): BaseQueryFn<{
    url: string,
    method: AxiosRequestConfig['method'],
    data?: AxiosRequestConfig['data'],
    additionalHeaders?: { [p: string]: string }
}, unknown, unknown> =>
    async ({url, method, data, additionalHeaders}) => {
        const fullUrl = BglWebAndMobileConfig.get('baseUrl', '') + baseUrl;
        try {
            let bglHeaders = createBglHeaders();
            for (const additionalHeader in additionalHeaders) {
                if (additionalHeaders.hasOwnProperty(additionalHeader)) {
                    bglHeaders = bglHeaders.set(additionalHeader, additionalHeaders[additionalHeader]);
                }
            }
            const result = await axios({url: fullUrl + url, method, data, headers: bglHeaders.getHeaders()});
            return {data: result.data};
        } catch (axiosError) {
            const err = axiosError as AxiosError;
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data,
                    url: baseUrl + url
                }
            };
        }
    };

export const encryptDataWithPublicKey = (data: any, pubKey: string, stringify = true) => {
    const crypt = new JSEncrypt();
    crypt.setKey(pubKey);
    return stringify ? crypt.encrypt(JSON.stringify(data)) : crypt.encrypt(data);
};
