import React, {useMemo, useState} from 'react';
import {Box, Link, List, ListItem, Stack, styled, Typography} from '@mui/material';
import Logo from '../../../../assets/images/logo/bgl-logo.svg';
import {useTranslation} from 'react-i18next';
import useCookieConsent from './hooks/useCookieConsent';
import GenericDialog from '../Dialog/GenericDialog';
import {useAnalytics} from '../../../../hooks/useAnalytics';
import GitInfos from '../Debug/GitInfos';

const ListStyled = styled(List)({
    'listStyle': 'none',
    'paddingLeft': 0
});

const ListItemStyled = styled(ListItem)(({theme}) => ({
    'width': 'auto',
    'display': 'inline-block',
    'position': 'relative',
    '&:not(:last-child):before': {
        'position': 'absolute',
        'content': '""',
        'height': '50%',
        'borderLeft': '1px solid rgba(0,0,0,.1)',
        'right': '0',
        'top': '25%'
    },
    [theme.breakpoints.up('xs')]: {
        paddingLeft: '5px',
        paddingRight: '5px'
    },
    [theme.breakpoints.up('xl')]: {
        paddingLeft: '10px',
        paddingRight: '10px',
    }
}));

const LinkStyled = styled(Link)({
    'textAlign': 'center',
    'verticalAlign': 'middle',
    'display': 'flex',
    'alignItems': 'center',
    'height': '100%',
    'color': '#464D54',
    'textDecoration': 'none',
    'cursor': 'pointer',
    '&:hover': {
        'textDecoration': 'underline'
    }
});

const ContactTypographyStyled = styled(Typography)({
    fontFamily: 'BNPPSans',
    fontWeight: '400',
    lineHeight: '1.33',
    color: '#00915a'
});

const Footer = ({width, marginLeft, isShowLogo = true}: {
    width: string,
    marginLeft: string,
    isShowLogo?: boolean
}) => {
    const {t} = useTranslation(['commons']);
    const year = (new Date()).getFullYear();
    const CCONSENT_MODAL = 'cconsent-modal';
    const [isOpenContactDialog, setIsOpenContactDialog] = useState(false);
    const {pushAnalytics} = useAnalytics();
    useCookieConsent();

    const isNonProd = useMemo(() => import.meta.env.DEV || window.location.hostname.endsWith('.lu.fortis.bank'), []);

    const _openCookieConsent = () => {
        pushAnalytics({
            rule: 'manage_cookies_tracking_rule',
            pageName: 'cookie banner consent  first screen',
            sequenceName: 'cookie banner consent',
            stepName: 'step1_click_manage_cookies_button',
            actionName: 'manage_cookies',
            buttonName: 'manage_cookies_button'
        });
        // Close open collapse
        const tabgroupsOpen = document.querySelectorAll('.ccm__tabgroup--open');

        for (const tabgroupOpen of tabgroupsOpen) {
            tabgroupOpen.classList.remove('ccm__tabgroup--open');
        }

        document.getElementById(CCONSENT_MODAL)?.classList.add('ccm--visible');
        document.getElementById(CCONSENT_MODAL)?.setAttribute('aria-hidden', 'false');
        document.getElementById(CCONSENT_MODAL)?.setAttribute('tabindex', '0');
        document.getElementById(CCONSENT_MODAL)?.querySelector<HTMLElement>('.ccm__content')?.focus();
    };

    return (
        <Box sx={{
            display: 'flex',
            borderTop: '5px solid #15925f',
            width: {sm: '100%', md: width},
            marginLeft: {sm: '0', md: marginLeft},
            backgroundColor: 'white',
            zIndex: 10,
            position: 'relative'
        }} justifyContent={isShowLogo ? 'space-between' : 'center'}>
            {isShowLogo &&
                <Stack sx={{display: {xs: 'none', md: 'flex'}}} direction={'row'} alignItems={'center'} spacing={12}
                       pl={{xs: '0', sm: '18px'}}>
                    <img alt={'logo'} src={Logo} style={{width: '120px'}}/>
                    <Typography fontWeight={400} fontSize={'1rem'} sx={{display: {xs: 'none', xl: 'inline-block'}}}>La
                        banque d'un monde qui change</Typography>
                </Stack>
            }
            <Stack direction={'column'} alignItems={'center'} pr={{xs: '0', xl: '18px'}} spacing={0}
                   justifyContent={'center'} margin={{xs: 'auto', md: '0'}}>
                <ListStyled sx={{
                    textAlign: isShowLogo ? {xs: 'center', sm: 'right'} : 'center',
                    padding: {xs: '10px 5px', md: '10px'}
                }}>
                    <ListItemStyled>
                        <LinkStyled onClick={() => setIsOpenContactDialog(true)}
                                    target="_blank">{t('commons:modules.webpro.footer.menu.contact')}</LinkStyled>
                    </ListItemStyled>
                    <ListItemStyled>
                        <LinkStyled href={t('commons:modules.webpro.footer.links.official-documents')}
                                    target="_blank">{t('commons:modules.webpro.footer.menu.official-documents')}</LinkStyled>
                    </ListItemStyled>
                    <ListItemStyled>
                        <LinkStyled href={t('commons:modules.webpro.footer.links.cookie-policy')}
                                    target="_blank">{t('commons:modules.webpro.footer.menu.cookie-policy')}</LinkStyled>
                    </ListItemStyled>
                    <ListItemStyled>
                        <LinkStyled href={t('commons:modules.webpro.footer.links.data-protection')}
                                    target="_blank">{t('commons:modules.webpro.footer.menu.data-protection')}</LinkStyled>
                    </ListItemStyled>
                    <ListItemStyled>
                        <LinkStyled
                            onClick={() => _openCookieConsent()}>{t('commons:modules.webpro.footer.menu.cookie-management')}</LinkStyled>
                    </ListItemStyled>
                    <ListItemStyled>
                        © BGL BNP PARIBAS - {year}
                    </ListItemStyled>
                </ListStyled>

                {isNonProd &&
                    <Box position={'absolute'} top={'35px'}>
                        <GitInfos/>
                    </Box>
                }
            </Stack>

            <GenericDialog
                isOpen={isOpenContactDialog}
                title={t('commons:modules.webpro.footer.contact-dialog.title')}
                handleClose={() => setIsOpenContactDialog(false)}>

                <Stack alignItems={'left'}>
                    <Typography variant={'h1'} align={'left'} marginBottom={'15px'} marginTop={'15px'}
                                fontWeight={'400'}>
                        {t('commons:modules.webpro.footer.contact-dialog.client-service')}
                    </Typography>
                    <Typography variant={'h5'} align={'left'} marginBottom={'15px'}>
                        {t('commons:modules.webpro.footer.contact-dialog.availability')}
                    </Typography>
                    <ContactTypographyStyled variant={'h1'} align={'left'}>
                        {t('commons:modules.webpro.footer.contact-dialog.phone-number')}
                    </ContactTypographyStyled>
                </Stack>

            </GenericDialog>
        </Box>
    );
};

export default Footer;
