import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from './api-utils';
import {BglLogger} from '@bgl/lib-ts-integration';
import {ActiveFeatures, LuxtrustOrderManagementFeature, PublicActiveFeatures} from '../@types/FeaturesTypes';

const URL_API_PATH = '/pewrapper/active-features/get';
const URL_API_PUBLIC_PATH = '/active-features/get';

const URL_CONTRACT_INFO = '/business_webctrinfo/rest/v1';
const URL_E_DOC = '/business_webdocacc/rest/v1';
const URL_MSG = '/business_webmsg/rest/v1';
const URL_CUST_PROFILES = '/business_webprofile/rest/v1';
const URL_SELF_ADMIN = '/business_webselfadm/rest/v1';
const URL_PAYMENT = '/business_webpaymentpro/rest/v1';
const URL_LUXTRUST_ORDER_MANAGEMENT = '/business_weblom/public/rest';

const addPublicFeatures = (features: PublicActiveFeatures, from: keyof PublicActiveFeatures, featuresToAdd: LuxtrustOrderManagementFeature[]) => {
    features[from] = [...features[from], ...featuresToAdd];
};
const addFeatures = (features: ActiveFeatures, from: keyof ActiveFeatures, featuresToAdd: string[]) => {
    features[from] = [...features[from], ...featuresToAdd];
};

export const featureApi = createApi({
    reducerPath: 'featureApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Feature', 'Feature_Payment'],
    endpoints: builder => ({
        getPublicFeatures: builder.query<PublicActiveFeatures, void>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                const logger = BglLogger.getInstance();

                const allFeatures = {
                    lom: [] as LuxtrustOrderManagementFeature[]
                } as PublicActiveFeatures;

                const features1 = await fetchWithBQ({
                    url: URL_LUXTRUST_ORDER_MANAGEMENT + URL_API_PUBLIC_PATH,
                    method: 'POST',
                });

                if (features1.error) {
                    logger.error('Unable to load luxtrust-order-management features from server');
                } else {
                    addPublicFeatures(allFeatures, 'lom', features1.data as LuxtrustOrderManagementFeature[]);
                }

                return {data: allFeatures};
            }
        }),

        getFeatures: builder.query<ActiveFeatures, { webproContractRef?: string, primaryContractRef?: string }>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                const logger = BglLogger.getInstance();
                const {webproContractRef, primaryContractRef} = _arg;

                const allFeatures = {
                    contractInfo: [],
                    msg: [],
                    documentAccess: [],
                    customerProfiles: [],
                    selfAdmin: [],
                    payment: []
                };

                const features1 = fetchWithBQ({
                    url: URL_CONTRACT_INFO + URL_API_PATH,
                    method: 'POST',
                    additionalHeaders: {
                        ...webproContractRef && {'X-Bgl-Rf-ExtCtr': webproContractRef},
                        ...primaryContractRef && {'X-Bgl-Rf-PmrCtr': primaryContractRef}
                    }
                });

                const features2 = fetchWithBQ({
                    url: URL_MSG + URL_API_PATH,
                    method: 'POST',
                    data: {},
                    additionalHeaders: {
                        ...webproContractRef && {'X-Bgl-Rf-ExtCtr': webproContractRef},
                        ...primaryContractRef && {'X-Bgl-Rf-PmrCtr': primaryContractRef}
                    }
                });

                const features3 = fetchWithBQ({
                    url: URL_E_DOC + URL_API_PATH,
                    method: 'POST',
                    data: {},
                    additionalHeaders: {
                        ...webproContractRef && {'X-Bgl-Rf-ExtCtr': webproContractRef},
                        ...primaryContractRef && {'X-Bgl-Rf-PmrCtr': primaryContractRef}
                    }
                });

                const features4 = fetchWithBQ({
                    url: URL_CUST_PROFILES + URL_API_PATH,
                    method: 'POST',
                    data: {},
                    additionalHeaders: {
                        ...webproContractRef && {'X-Bgl-Rf-ExtCtr': webproContractRef},
                        ...primaryContractRef && {'X-Bgl-Rf-PmrCtr': primaryContractRef}
                    }
                });

                const features5 = fetchWithBQ({
                    url: URL_SELF_ADMIN + URL_API_PATH,
                    method: 'POST',
                    data: {},
                    additionalHeaders: {
                        ...webproContractRef && {'X-Bgl-Rf-ExtCtr': webproContractRef},
                        ...primaryContractRef && {'X-Bgl-Rf-PmrCtr': primaryContractRef}
                    }
                });

                const features6 = fetchWithBQ({
                    url: URL_PAYMENT + URL_API_PATH,
                    method: 'POST',
                    data: {},
                    additionalHeaders: {
                        ...webproContractRef && {'X-Bgl-Rf-ExtCtr': webproContractRef},
                        ...primaryContractRef && {'X-Bgl-Rf-PmrCtr': primaryContractRef}
                    }
                });

                const allPromises = [features1, features2, features3, features4, features5, features6];

                try {
                    const allResults = await Promise.allSettled(allPromises);

                    // @ts-ignore
                    allResults.forEach(({status, value}, idx) => {
                        if (status === 'fulfilled' && value.data && Array.isArray(value?.data)) {
                            addFeatures(allFeatures, Object.keys(allFeatures)[idx] as keyof ActiveFeatures, value?.data as string[]);
                        } else {
                            logger.error(`Unable to load ${Object.keys(allFeatures)[idx]} features from server`);
                        }
                    });
                } catch (e) {
                    logger.error(e as string);
                }

                logger.info('Loaded features ' + JSON.stringify(allFeatures));

                return {data: allFeatures};
            },
            providesTags: [{type: 'Feature', id: 'LIST'}]
        })

    })
});

export const {
    useGetPublicFeaturesQuery,
    useGetFeaturesQuery,
    usePrefetch: usePrefetchFeatures
} = featureApi;
