import React, {Children, useState} from 'react';
import {Swiper as SwiperType} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Box} from '@mui/material';
import {SwiperOptions} from 'swiper/types';

import 'swiper/css/bundle';
import './Carousel.scss';

type CarouselType = {
    children: JSX.Element | Array<JSX.Element> | Array<React.ReactNode>,
    options: SwiperOptions,
    className: 'carousel-auth' | 'carousel-cards'
};

const Carousel = ({children, options, className}: CarouselType) => {

    const pagination = {
        el: '.swiper-pagination',
        clickable: true
    };

    const [swipe, setSwipe] = useState<SwiperType>();
    const [isBegin, setIsBegin] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    return (
        <Box className={`swiper-container ${className}`}>
            <span className={`swiper-prev ${!options.loop && isBegin ? 'disabled' : ''}`}
                  onClick={() => swipe?.slidePrev()}/>
            <Swiper {...options}
                    onBeforeInit={(swiper) => setSwipe(swiper)}
                    onSlideChange={(swiper) => {
                        setIsBegin(swiper.progress === 0);
                        setIsEnd(swiper.progress === 1);
                    }}
                    slidesPerView={1}
                    grabCursor
                    pagination={pagination}
            >
                {Children.toArray(children).map((slide, idx) => (
                    <SwiperSlide key={idx}>
                        {slide}
                    </SwiperSlide>
                ))}
            </Swiper>
            <span className={`swiper-next ${!options.loop && isEnd ? 'disabled' : ''}`}
                  onClick={() => swipe?.slideNext()}/>
            <span className="swiper-pagination"/>
        </Box>
    );
};

export default Carousel;
