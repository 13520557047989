import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './api-utils';
import {
    AuditLogRequest,
    AuditLogResponse,
    Contact,
    ContractUpdatedResult,
    GetPartyProfileRequest,
    UpdateContactRequest,
    UpdateContractLabelRequest,
    UpdatePartyProfileRequest,
    WebBankingBusinessContract,
    WebBankingBusinessPartyProfile,
    WebBankingBusinessProfilePermission,
    WebBankingBusinessPaymentConstraint,
    PaymentConstraintsQuery,
    UpdatePaymentConstraintRequest

} from '../@types/SelfAdminTypes';
import { webProContractApi } from './webProContractApiRtk';
import { featureApi } from './featureApiRtk';

const URL_API = '/business_webselfadm/rest';

export const selfAdminApi = createApi({
    reducerPath: 'selfAdminApi',
    baseQuery: axiosBaseQuery({
        baseUrl: URL_API
    }),
    tagTypes: ['Contract', 'AuditLog', 'PartyProfile', 'ContractUpdates', 'EligibleContact'],

    endpoints: (builder) => ({
        getContract: builder.query<WebBankingBusinessContract, void>({
            query: (request) => ({
                url: '/contract/get',
                method: 'POST',
                data: request
            }),
            providesTags: [{ type: 'Contract', id: 'SINGLE' }] /* Doesn't matter contract id because only one contract is loaded on cache */
        }),

        getContractUpdates: builder.query<ContractUpdatedResult, void>({
            query: (request) => ({
                url: '/contract/update/get',
                method: 'POST',
                data: {}
            }),
            providesTags: result => result ? [{ type: 'ContractUpdates', id: 'SINGLE' }] : [] /* Doesn't matter contract id because only one contract is loaded on cache */
        }),

        listPartyProfile: builder.query<WebBankingBusinessPartyProfile[], void>({
            query: (request) => ({
                url: '/party-profile/list',
                method: 'POST',
                data: request
            }),
            providesTags: [{ type: 'PartyProfile', id: 'LIST' }]
        }),

        getPartyProfile: builder.query<WebBankingBusinessPartyProfile, GetPartyProfileRequest>({
            query: (request) => ({
                url: '/party-profile/get',
                method: 'POST',
                data: request
            }),
            providesTags: result =>
                result ? [{ type: 'PartyProfile', id: result.id }] : []
        }),

        getPermissions: builder.query<WebBankingBusinessProfilePermission[], void>({
            query: (request) => ({
                url: '/context/permissions/get',
                method: 'POST',
                data: request
            })
        }),

        getEligibleContacts: builder.query<Contact[], void>({
            query: (request) => ({
                url: '/contract/eligible/contacts/get',
                method: 'POST',
                data: {}
            }),
            providesTags: [{ type: 'EligibleContact', id: 'LIST' }]
        }),

        updateContractLabel: builder.mutation<void, UpdateContractLabelRequest>({
            query: request => ({
                url: '/contract/update/label',
                method: 'POST',
                data: request
            }),
            invalidatesTags: (result, error, request) => [
                { type: 'Contract', id: 'SINGLE' },
                { type: 'ContractUpdates', id: 'SINGLE' }
            ]
        }),

        updateContact: builder.mutation<void, UpdateContactRequest>({
            query: request => ({
                url: '/contract/update/contact',
                method: 'POST',
                data: request
            }),
            invalidatesTags: (result, error, request) => [
                { type: 'Contract', id: 'SINGLE' },
                { type: 'EligibleContact', id: 'LIST' },
                { type: 'ContractUpdates', id: 'SINGLE' }
            ]
        }),

        updatePartyProfile: builder.mutation<WebBankingBusinessPartyProfile, UpdatePartyProfileRequest>({
            query: request => ({
                url: '/party-profile/update',
                method: 'POST',
                data: request
            }),
            invalidatesTags: (result, error, request) =>
                result ? [
                    { type: 'PartyProfile', id: result.id },
                    { type: 'ContractUpdates', id: 'SINGLE' },
                    { type: 'PartyProfile', id: 'LIST' },
                    { type: 'AuditLog', id: 'LIST' }
                ] : []
        }),

        enablePartyProfile: builder.mutation<WebBankingBusinessPartyProfile, GetPartyProfileRequest>({
            query: request => ({
                url: '/party-profile/enable',
                method: 'POST',
                data: request
            }),
            invalidatesTags: (result, error, request) => [
                { type: 'PartyProfile', id: 'LIST' },
                { type: 'AuditLog', id: 'LIST' }
            ]
        }),

        disablePartyProfile: builder.mutation<WebBankingBusinessPartyProfile, GetPartyProfileRequest>({
            query: request => ({
                url: '/party-profile/disable',
                method: 'POST',
                data: request
            }),
            invalidatesTags: (result, error, request) => [
                { type: 'PartyProfile', id: 'LIST' },
                { type: 'AuditLog', id: 'LIST' }
            ]
        }),

        deletePartyProfile: builder.mutation<WebBankingBusinessPartyProfile, GetPartyProfileRequest>({
            query: request => ({
                url: '/party-profile/delete',
                method: 'POST',
                data: request
            }),
            invalidatesTags: (result, error, request) => [
                { type: 'ContractUpdates', id: 'SINGLE' },
                { type: 'PartyProfile', id: 'LIST' },
                { type: 'AuditLog', id: 'LIST' }
            ]
        }),

        getAuditLog: builder.query<AuditLogResponse, AuditLogRequest>({
            query: (request) => ({
                url: '/contract/audit-log/get',
                method: 'POST',
                data: request
            }),
            providesTags: [{ type: 'AuditLog', id: 'LIST' }]
        }),

        activateContract: builder.mutation<void, { signatureRequest: { challengeId: string, answer: string } }>({
            query: request => ({
                url: '/contract/activate',
                method: 'POST',
                data: request
            }),
            invalidatesTags: (result, error, request) => [
                { type: 'Contract', id: 'SINGLE' },
                { type: 'PartyProfile', id: 'LIST' },
                { type: 'AuditLog', id: 'LIST' }
            ],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(webProContractApi.util.invalidateTags([{ type: 'WebProContracts', id: 'LIST' }]));
                dispatch(featureApi.util.invalidateTags([{ type: 'Feature', id: 'LIST' }]));
            },
        }),

        cancelUpdates: builder.mutation<void, void>({
            query: request => ({
                url: '/contract/cancel',
                method: 'POST'
            }),
            invalidatesTags: (result, error, request) => [
                { type: 'Contract', id: 'SINGLE' },
                { type: 'PartyProfile', id: 'LIST' },
                { type: 'AuditLog', id: 'LIST' }
            ]
        }),

        getAllPaymentConstraints: builder.query<WebBankingBusinessPaymentConstraint[], PaymentConstraintsQuery>({
            query: (request) => ({
                url: '/contract/payment-constraints/get',
                method: 'POST',
                data: request
            }),
        }),

        updatePaymentConstraints: builder.mutation<void, UpdatePaymentConstraintRequest>({
            query: request => ({
                url: '/contract/payment-constraints/update',
                method: 'POST',
                data: request
            }),
        }),



    })
});

export const {
    useGetContractQuery,
    useGetContractUpdatesQuery,
    useListPartyProfileQuery,
    useGetPartyProfileQuery,
    useGetPermissionsQuery,
    useGetEligibleContactsQuery,
    useUpdateContractLabelMutation,
    useUpdateContactMutation,
    useUpdatePartyProfileMutation,
    useEnablePartyProfileMutation,
    useDisablePartyProfileMutation,
    useDeletePartyProfileMutation,
    useGetAuditLogQuery,

    useActivateContractMutation,
    useCancelUpdatesMutation,

    useGetAllPaymentConstraintsQuery,
    useUpdatePaymentConstraintsMutation,

    usePrefetch
} = selfAdminApi;
