import React, {useCallback, useState} from 'react';
import {Box, Button, Stack, TextField, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import RingIcon from '../../../assets/images/commons/ring.svg?react';
import {
    useGetContractQuery,
    useGetContractUpdatesQuery,
    useUpdateContractLabelMutation
} from '../../../redux/api/selfAdminApiRtk';
import ScreenWrapper, {ScreenWrapperTitle} from '../components/ScreenWrapper';
import DashedDivider from '../../_commons/components/Divider/DashedDivider';
import {WebBankingBusinessContract} from '../../../redux/@types/SelfAdminTypes';
import SavedAsDraft from '../components/SavedAsDraft';
import {useValidation} from '../../../hooks/useValidation';
import RequirePermissions from '../../../components/RequirePermissions';
import {Permission} from '../../../redux/@types/WebProContractTypes';
import {useNavigate} from 'react-router';

const rules = {
    newContractLabel: ['required', 'max:70']
};

const ManageContract = () => {

    const {t} = useTranslation(['commons']);
    const navigate = useNavigate();

    const {validate, hasError, setValidationErrors, validationErrors} = useValidation();

    const {data: currentWebProContract} = useGetContractQuery();
    const {refetch} = useGetContractUpdatesQuery();

    const {displayName} = currentWebProContract ?? {} as WebBankingBusinessContract;

    const [isEditing, setIsEditing] = useState(false);
    const [newContractLabel, setNewContractLabel] = useState('');

    const [updateContractLabel, {isLoading: isUpdateContractLabelInProgress}] = useUpdateContractLabelMutation();
    const [draftSaved, setDraftSaved] = useState(false);

    const _handleEditClick = useCallback(() => {
        setIsEditing(true);
    }, []);

    const _handleCancelClick = useCallback(() => {
        setIsEditing(false);
        setNewContractLabel('');
        setValidationErrors([]);
    }, [setValidationErrors]);

    const _handleBackClick = useCallback(() => {
        setIsEditing(false);
        setNewContractLabel('');
        setValidationErrors([]);
        refetch();
        navigate('../');
    }, [setValidationErrors, navigate, refetch]);

    const _handleValidateClick = useCallback(async () => {
        const validation = validate({
            newContractLabel
        }, rules);

        if (validation.fails()) {
            setValidationErrors(validation.errors.all());
        } else {
            try {
                await updateContractLabel({
                    newLabel: newContractLabel
                });

                setDraftSaved(true);
            } catch (e) {
                /* todo */
            }
        }

    }, [newContractLabel, validate, setValidationErrors, updateContractLabel]);

    return (
        <ScreenWrapper
            header={<ScreenWrapperTitle title={t('commons:modules.webpro.self-admin.contract-label')}/>}
            maxWidth={750}
        >
            {draftSaved ?
                <>
                    <SavedAsDraft/>
                    <Box textAlign={'center'} position={'absolute'} width={'100%'} bottom={'-16px'}>
                        <Button type={'submit'} onClick={_handleBackClick} variant="contained"
                                color="primary" size={'medium'}>
                            {t('commons:buttons.back')}
                        </Button>
                    </Box>
                </>
                :
                <Stack direction={'column'} width={'100%'} spacing={12}>
                    <Stack direction={'row'} spacing={12} p={12} width={'100%'} mb={8}>
                        <RingIcon width={90}></RingIcon>
                        <Stack direction={'column'} width={'100%'} spacing={2}>
                            <Stack direction={'row'} spacing={4} alignItems={'center'} justifyContent={'space-between'}>
                                <Typography
                                    variant={'h4'}>{t('commons:modules.webpro.self-admin.contract-label')} :</Typography>
                            </Stack>
                            <Typography variant={'h4'} fontWeight={400}>{displayName}</Typography>

                            <RequirePermissions permissions={[Permission.SELF_ADMIN]}>
                                <Box py={4}>
                                    <DashedDivider variant={'fullWidth'}/>
                                </Box>

                                <TextField
                                    focused
                                    label={t('commons:modules.webpro.self-admin.update-contract-label')}
                                    size={'medium'}
                                    onChange={event => setNewContractLabel(event.target.value)}
                                    value={newContractLabel}
                                    disabled={!isEditing || isUpdateContractLabelInProgress}
                                    error={hasError('newContractLabel')}
                                    helperText={hasError('newContractLabel') ? validationErrors['newContractLabel'] : ''}
                                >

                                </TextField>
                            </RequirePermissions>
                        </Stack>
                    </Stack>

                    <RequirePermissions permissions={[Permission.SELF_ADMIN]}>
                        <Box textAlign={'center'} position={'absolute'} width={'100%'} bottom={'-16px'}>
                            <Stack direction={'row'} justifyContent={'center'} spacing={12}>

                                {!isEditing ?
                                    <Button type={'submit'} onClick={_handleEditClick} variant="contained"
                                            color="primary" size={'medium'}>
                                        {t('commons:buttons.edit')}
                                    </Button>
                                    :
                                    <>
                                        <Button type={'submit'} onClick={_handleCancelClick} variant="contained"
                                                color="secondary" size={'medium'}
                                                disabled={isUpdateContractLabelInProgress}>
                                            {t('commons:buttons.cancel')}
                                        </Button>
                                        <Button type={'submit'} onClick={_handleValidateClick}
                                                variant="contained"
                                                color="primary" size={'medium'}
                                                disabled={isUpdateContractLabelInProgress}>
                                            {t('commons:buttons.validate')}
                                        </Button>
                                    </>
                                }
                            </Stack>
                        </Box>
                    </RequirePermissions>
                </Stack>
            }
        </ScreenWrapper>
    );
};

export default ManageContract;
