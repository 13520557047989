import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from './api-utils';
import {BglWebAndMobileConfig} from '../../config';

import {
    CertificateAffectationRequest,
    CertificateAffectationResponse,
    LuxtrustCertificateOrderRequest,
    LuxtrustCertificateOrderResponse,
    LuxtrustChallengeResponse,
    ServiceResponse,
    SignatureTextRequest,
    SignatureTextResponse,
    UserContext
} from '../@types/GclTypes';
import {LuxtrustType, PhysicalTokenType} from '../@types/AuthentTypes';

const URL_API = '/business_weblom';

export const gclApi = createApi({
    reducerPath: 'gclApi',
    baseQuery: axiosBaseQuery({
        baseUrl: URL_API
    }),
    endpoints: builder => ({
        getContext: builder.query<UserContext, void>({
            query: () => ({
                url: '/rest/context/getContext',
                method: 'POST',
                data: {}
            })
        }),

        getCertificateDeliveryPhoneNumber: builder.query<ServiceResponse, void>({
            query: () => ({
                url: '/rest/context/getCertificateDeliveryPhoneNumber',
                method: 'POST',
                data: {}
            })
        }),

        getSignatureText: builder.query<SignatureTextResponse, SignatureTextRequest>({
            query: ({ssn, email, mobilePhoneNumber, productType}) => ({
                url: '/rest/order/v2/getSignatureText',
                method: 'POST',
                data: {
                    ssn,
                    email,
                    mobilePhoneNumber,
                    productType
                }
            })
        }),

        requestCertificate: builder.mutation<LuxtrustCertificateOrderResponse, LuxtrustCertificateOrderRequest>({
            query: ({productType, email, phoneNumber, activationCode, disclaimer, profileType}) => ({
                url: '/rest/order/v2/requestCertificate',
                method: 'POST',
                data: {
                    productType,
                    email,
                    phoneNumber,
                    activationCode,
                    disclaimer,
                    profileType
                }
            })
        }),

        createValidationChallenge: builder.query<LuxtrustChallengeResponse, {
            luxTrustType?: LuxtrustType,
            tokenTypeList?: PhysicalTokenType[]
        }>({
            query: ({luxTrustType, tokenTypeList}) => ({
                url: '/public/rest/certificate-affectation/challenge/validation/get',
                method: 'POST',
                data: {
                    applicationCaller: BglWebAndMobileConfig.get('applicationCaller'),
                    luxTrustType,
                    tokenTypeList
                }
            }),
            keepUnusedDataFor: 0
        }),

        affectCertificate: builder.mutation<CertificateAffectationResponse, CertificateAffectationRequest>({
            query: ({challengeId, answer}) => ({
                url: '/public/rest/certificate-affectation/affect',
                method: 'POST',
                data: {
                    challengeId,
                    answer
                }
            })
        })
    })
});

export const {
    useGetContextQuery,
    useGetCertificateDeliveryPhoneNumberQuery,
    useGetSignatureTextQuery,
    useRequestCertificateMutation,

    useCreateValidationChallengeQuery,
    useAffectCertificateMutation
} = gclApi;
