import {Box, Button, Stack, Typography} from '@mui/material';
import GenericPaper from '../../../_commons/components/Paper/GenericPaper';
import React from 'react';
import LeftAndRightValueTextField from '../../../_commons/components/Form/LeftAndRightValueTextField';
import {useTranslation} from 'react-i18next';
import {ChipProps} from '../../../_commons/components/Chip/Chip';
import {ChildrenUpdatedData} from '../../../../redux/@types/SelfAdminTypes';
import {useNavigate} from 'react-router';
import {Permission} from '../../../../redux/@types/WebProContractTypes';
import RequirePermissions from '../../../../components/RequirePermissions';
import UserStatusIcon from '../UserStatusIcon';

const SimpleLineValidation = (
    {
        boxKey,
        update,
        label,
        url,
        showPartyInfo
    }
        :
        {
            boxKey: number,
            update: ChildrenUpdatedData,
            label: string,
            url?: string,
            showPartyInfo?: boolean
        }
) => {
    const {t} = useTranslation(['commons']);
    const navigate = useNavigate();

    const transformValue = (value: string) => {
        return value.replaceAll('€', ' EUR')
            .replace('NOT_ALLOWED', 'Désactivé')
            .replace('ALLOWED', 'Activé')
            .replace('UNLIMITED', t(
                'commons:modules.webpro.self-admin.limit-payement-unlimited'
            ));
    };

    const oldValues = [
        {
            value: update.oldValue ? transformValue(update.oldValue) : '',
            label: label
        }
    ];
    const newValues = [
        {
            value: update.newValue ? transformValue(update.newValue) : '',
            label: label
        }
    ];

    return (
        <Box mt={12} key={boxKey}>
            <Box mt={12}>
                <GenericPaper sxPaper={{position: 'relative', padding: 0}} sxBox={{paddingBottom: 0}}>
                    <Typography variant={'h3'} color={'primary'} fontWeight={400} padding={10}>
                        {label}
                    </Typography>
                    {showPartyInfo &&
                        <Stack direction={'row'} alignItems={'center'} spacing={8} padding={6} pt={0} pb={12}>
                            <UserStatusIcon admin={false} disabled={false} width={50} height={50}/>
                            <Typography variant={'h4'} fontWeight={400}
                                        mb={4}>{update.infoUser.firstName}, {update.infoUser.lastName}</Typography>
                        </Stack>
                    }
                    <Stack direction={'row'} alignItems={'center'} width={'100%'}>
                        {(update.oldValue || update.newValue) &&
                            <LeftAndRightValueTextField
                                leftValues={oldValues}
                                rightValues={newValues}
                                chipLeft={{
                                    title: t('commons:modules.webpro.self-admin.old-value'),
                                    color: 'grey'
                                } as ChipProps}
                                chipRight={{
                                    title: t('commons:modules.webpro.self-admin.new-value'),
                                    color: 'green'
                                } as ChipProps}
                            />
                        }
                    </Stack>
                    {url && <RequirePermissions permissions={[Permission.SELF_ADMIN]}>
                        <Stack direction={'row'} alignItems={'center'} width={'100%'} justifyContent={'center'}
                               sx={{position: 'absolute', bottom: '-18px'}}>
                            <Button variant={'contained'} color={'primary'} size={'small'}
                                    onClick={() => navigate(url)}>
                                {t('commons:buttons.edit')}
                            </Button>
                        </Stack>
                    </RequirePermissions>}
                </GenericPaper>
            </Box>
        </Box>
    );
};

export default SimpleLineValidation;
