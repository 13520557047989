import React from 'react';
import {Box, Paper, PaperProps, Stack, styled} from '@mui/material';
import WarningSvgGreen from '../../../../assets/images/commons/warningRoundedGreen.svg?react';
import WarningSvgOrange from '../../../../assets/images/commons/warningRoundedOrange.svg?react';
import WarningSvgRed from '../../../../assets/images/commons/warningRoundedRed.svg?react';

const WarningPaperStyled = styled(Paper, {
    shouldForwardProp: (propName) => propName !== 'color'
})<{ color: 'orange' | 'green' | 'red' }>(({theme, color}) => ({

    ...color === 'orange' && {
        background: '#FFF6ED 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #65808629',
        border: '1px solid #FF7200',
        borderRadius: '5px',
        color: '#FF7200'
    },

    ...color === 'green' && {
        background: '#EEFFF0 0% 0% no-repeat padding-box',
        border: '1px solid #55D071',
        borderRadius: '5px',
        color: '#00915A'
    },

    ...color === 'red' && {
        background: 'linear-gradient(225deg, #FFF3F3 0%, #FFF3F3 100%) 0% 0% no-repeat padding-box',
        border: '1px solid #EB4848',
        borderRadius: '5px',
        color: '#FF4343'
    },

    padding: theme.spacing(4)
}));

const WarningPaper = ({color = 'orange', children, ...theRest}: React.PropsWithChildren<PaperProps & {
    color?: 'green' | 'orange' | 'red'
}>) => {
    return (
        <WarningPaperStyled color={color} {...theRest}>
            <Stack direction={'row'} alignItems={'center'} spacing={4} justifyItems={'center'}>
                <>
                    {color === 'green' && <WarningSvgGreen width={25}/>}
                    {color === 'orange' && <WarningSvgOrange width={25}/>}
                    {color === 'red' && <WarningSvgRed width={25}/>}
                </>

                <Box>
                    {children}
                </Box>
            </Stack>

        </WarningPaperStyled>
    );
};

export default WarningPaper;
