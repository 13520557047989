import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import _ from 'lodash';
import {Amount, FeesTypeEnum} from '../../../@types/PaymentProTypes.ts';
import {AccountType, Beneficiary} from '../../../@types/BeneficiaryTypes.ts';
import {Concat} from '../../../../@types/utils';
import {formatISO} from 'date-fns';

export enum SingleTransferToType {
    MY_ACCOUNTS = 'MY_ACCOUNTS',
    BENEFICIARY = 'BENEFICIARY'
};

export enum PaymentTypeType {
    RTGS = 'RTGS',
    SALARY = 'SALARY',
    URGENT = 'URGENT',
    CLASSIC = 'CLASSIC'
};

export type PaymentTypeRequest = {
    currency: string,
    debitedAccount: string,
    creditedAccount: string,
    creditedCountryCode: string
};

export type FeesRequest = {
    amount: number,
    currency: string,
    countryOfDestination: string
};

export type SinglePaymentState = {
    paymentData: CreateAdvancedTransferData;
    step: number;
};

export enum TransferToType {
    BGL_BGL = 'BGL_BGL',
    BENEFICIARY_SAVED = 'BENEFICIARY_SAVED',
    BENEFICIARY_OTHER = 'BENEFICIARY_OTHER'
};

export type PrivateTransferBeneficiary = Pick<Beneficiary, 'accountReference'>;

export type CreateAdvancedTransferData = {
    contractReference: string | null,
    primaryContract: string | null,

    accountFrom: string,
    beneficiary: PrivateTransferBeneficiary | Beneficiary,

    communication: string | null,
    amount: Amount,
    accountCurrency?: string | null,
    endToEndReference: string,
    executionDate: string | null,
    transferRequestFees: FeesTypeEnum | null,
    regulatoryReportingReason: string | null,

    salary: boolean,
    rtgs: boolean,
    urgent: boolean,
    classic: boolean,

    debitAdvice: boolean,
    saveBeneficiary: boolean,
    transferToType?: TransferToType

    signatureRequest?: {
        challengeId: string,
        answer: string
    }
};

export type TransferResultResponse = {
    referenceOperation: string;
    preInstruction: boolean;
};

const initialState: SinglePaymentState = {
    paymentData: {
        contractReference: null,
        primaryContract: null,

        accountFrom: '',
        beneficiary: {
            accountReference: null,
            beneficiaryAccountType: AccountType.IBAN
        },

        communication: null,
        amount: {
            value: null,
            currency: 'EUR'
        },
        accountCurrency: null,
        endToEndReference: '',
        executionDate: formatISO(new Date(), {representation: 'date'}),
        transferRequestFees: FeesTypeEnum.REGULAR,
        regulatoryReportingReason: '',

        salary: false,
        rtgs: false,
        urgent: false,
        classic: true,

        debitAdvice: true,
        saveBeneficiary: false,
        transferToType: TransferToType.BGL_BGL
    },
    step: 1
};


export type CreateAdvancedTransferDataFieldType = 
Omit<keyof CreateAdvancedTransferData, 'amount' | 'beneficiary'>
| Concat<'amount.', keyof Amount>
| Concat<'beneficiary.', keyof Beneficiary>;

const singlePaymentSlice = createSlice({
    initialState,
    name: 'payment/singlePayment',
    reducers: {
        setPaymentData(state, action: PayloadAction<{ field: CreateAdvancedTransferDataFieldType, value: any }>) {
            _.set(state.paymentData, action.payload.field as string, action.payload.value);
        },
        setPaymentBeneficiaryData(state, action: PayloadAction<{ value: Beneficiary }>) {
            _.set(state.paymentData, 'beneficiary', action.payload.value);
        },
        setStep(state, action: PayloadAction<number>) {
            state.step = action.payload;
        },
        setTransferToType(state, action: PayloadAction<TransferToType>) {
            _.set(state.paymentData, 'transferToType', action.payload);
        },
        resetBeneficiary(state) {
            _.set(state.paymentData, 'beneficiary', initialState.paymentData.beneficiary);
        },
        resetPaymentData(state) {
            state.paymentData = initialState.paymentData;
        }
    }
});

export const {
    setPaymentData,
    setPaymentBeneficiaryData,
    setStep,
    setTransferToType,
    resetBeneficiary,
    resetPaymentData
} = singlePaymentSlice.actions;

export const reducer = singlePaymentSlice.reducer;
