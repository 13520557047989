import {styled, Tabs} from '@mui/material';
import {tabsClasses} from '@mui/material/Tabs';
import {tabClasses} from '@mui/material/Tab';

export const StyledTabs = styled(Tabs)(({theme}) => ({
  position: 'sticky',
  top: '208px',
  zIndex: 4,
  [`& .${tabsClasses['indicator']}`]: {
    backgroundColor: '#fff',
    height: '5px'
  },

  [`& .${tabClasses['root']}`]: {
    fontFamily: 'BNPPSansCondensed',
    fontWeight: 100,
    color: '#fff',
    [theme.breakpoints.down('md')]: {
      backgroundColor: '#fff',
      color: '#00915a'
    },
    fontSize: '1.5rem',
    letterSpacing: '0.1rem',
    textShadow: '0px 2px 6px #161D195A',
    textTransform: 'uppercase'
  },

  [`& .${tabClasses['root']}.${tabClasses['selected']}`]: {
    borderRadius: '5px 5px 0 0',
    backgroundColor: 'rgba(0,0,0,0.15)',
    [theme.breakpoints.down('md')]: {
      backgroundColor: '#00915a',
    },
    transition: 'background-color 1000ms ease-in-out',
    fontWeight: 400,
    color: '#fff'
  }
}));