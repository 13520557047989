import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import {Button, Stack, styled, Typography} from '@mui/material';
import {useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ExitSvg from '../../../assets/images/commons/exit.svg?react';
import {RootContext} from '../../../context/root-context';
import {useAnalytics} from '../../../hooks/useAnalytics';
import GenericDialog from '../../_commons/components/Dialog/GenericDialog';
import {useLogoutMutation} from "../../../redux/api/customerAuthentApiRtk.ts";

const LogoutButton = styled(Button)({
    background: 'transparent linear-gradient(256deg, #FF6346 0, #D81811 100%) 0 0 no-repeat padding-box',
    boxShadow: '0 3px 6px #FA475B58',
    borderRadius: '23px',
    float: 'right',
    '&:hover': {
        background: 'transparent linear-gradient(256deg, #f36c52 0, #d9453f 100%) 0 0 no-repeat padding-box'
    },
    '&:disabled': {
        background: '#fffefe linear-gradient(78deg, #cececf 0%, #eaeaef 100%) 0% 0% no-repeat padding-box',
        boxShadow: '2px 3px 6px #0000001a !important'
    },
    padding: '4px 20px'
});
const LOGOUT_TEXT_CODE = 'commons:buttons.logout';

const LogoutUserButton = () => {
    const {t} = useTranslation(['commons']);

    const {isAuthenticated, setIsAuthenticated} = useContext(RootContext);
    const [logout] = useLogoutMutation();
    const [isOpenLogoutDialog, setIsOpenLogoutDialog] = useState(false);

    const {pushAnalytics} = useAnalytics();

    const _logoutUser = useCallback(async () => {
        await logout().unwrap();

        setIsAuthenticated(false);
        pushAnalytics({
            rule: 'confirm_logoff_tracking_rule',
            actionName: 'confirm_logoff',
            buttonName: 'confirm_logoff_button',
            stepName: 'step2_confirm_logoff',
            sequenceName: 'logoff'
        });

        setIsOpenLogoutDialog(false);
    }, [setIsAuthenticated, logout, pushAnalytics]);

    const _openLogoutDialog = () => {
        pushAnalytics({
            rule: 'start_logoff_tracking_rule',
            actionName: 'start_logoff',
            buttonName: 'start_logoff_button',
            stepName: 'step1_start_logoff',
            sequenceName: 'logoff'
        });
        setIsOpenLogoutDialog(true);
    };

    return (
        <>
            <LogoutButton color={'error'} variant="contained" size="small"
                          onClick={() => _openLogoutDialog()} disabled={!isAuthenticated}>
                <PowerSettingsNewIcon sx={{fontSize: 22}}/>
            </LogoutButton>
            <GenericDialog
                isOpen={isOpenLogoutDialog}
                title={t(LOGOUT_TEXT_CODE)}
                handleClose={() => setIsOpenLogoutDialog(false)}
                actions={
                    <Stack direction={'row'} spacing={16}>
                        <Button type={'submit'} variant="outlined" color="primary" size={'small'}
                                onClick={() => setIsOpenLogoutDialog(false)}>{t('commons:buttons.cancel')}</Button>

                        <Button type={'submit'} variant="contained" color="primary" size={'small'}
                                onClick={() => _logoutUser()}>{t(LOGOUT_TEXT_CODE)}</Button>
                    </Stack>
                }
            >
                <Stack alignItems={'center'} justifyContent={'center'} spacing={4} py={8} mt={8}>
                    <ExitSvg height={'auto'} width={'350px'}/>
                    <Typography variant={'h4'} align={'center'}>
                        {t('commons:buttons.logout-confirmation')}
                    </Typography>
                </Stack>
            </GenericDialog>
        </>
    );
};

export default LogoutUserButton;
