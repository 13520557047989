import React, { useCallback, useState } from 'react';
import { SubjectSerialNumber } from '../../../../redux/@types/SelfAdminTypes';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
    useDeletePartyProfileMutation,
    useDisablePartyProfileMutation,
    useEnablePartyProfileMutation
} from '../../../../redux/api/selfAdminApiRtk';
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import UserStatusIcon from '../UserStatusIcon';
import RequirePermissions from '../../../../components/RequirePermissions';
import { Permission } from '../../../../redux/@types/WebProContractTypes';
import GenericDialog from '../../../_commons/components/Dialog/GenericDialog';
import { InnerPageBglSvgLoader } from '../../../../assets/Images';
import { DetailsRadioBox } from '../../../_commons/components/Form/DetailsRadioBox';
import OrelyValidation from '../../../_commons/components/Orely/Signature/OrelyValidation.tsx';
import { grey } from '@mui/material/colors';

const UserBox = ({ partyId, label, ssn, admin, blocked, refetchPartyProfile }: React.PropsWithoutRef<{
    partyId: string,
    label: string,
    ssn: SubjectSerialNumber[],
    admin: boolean,
    blocked: boolean,
    refetchPartyProfile: Function
}>) => {
    const { t } = useTranslation(['commons']);
    const navigate = useNavigate();
    const [disablePartyProfile, { isLoading: isDisablePartyProfileLoading }] = useDisablePartyProfileMutation();
    const [enablePartyProfile, { isLoading: isEnablePartyProfileLoading }] = useEnablePartyProfileMutation();
    const [deletePartyProfile, { isLoading: isDeletePartyProfileLoading }] = useDeletePartyProfileMutation();

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [step, setStep] = React.useState(0);
    const [selection, setSelection] = useState<'suspend' | 'delete' | 'enable'>('suspend');

    const _handleBlockAccessBtnClick = useCallback(() => {
        setDialogOpen(true);
    }, []);

    const _handleUnblockAccessBtnClick = useCallback(() => {
        setSelection('enable');
        setStep(2);
        setDialogOpen(true);
    }, []);

    const _handleOrelyCallback = useCallback(async (challengeId: string, samlResponse: string) => {
        try {
            setSelection('suspend');
            switch (selection) {
                case 'suspend':
                    await disablePartyProfile({
                        partyId,
                        signatureRequest: { challengeId, answer: samlResponse }
                    });
                    setDialogOpen(false);
                    setStep(0);
                    break;
                case 'enable':
                    await enablePartyProfile({
                        partyId,
                        signatureRequest: { challengeId, answer: samlResponse }
                    });
                    setDialogOpen(false);
                    setStep(0);
                    break;
            }
            refetchPartyProfile();
        } catch (e) {
            /* display error */
        }
    }, [disablePartyProfile, enablePartyProfile, partyId, selection, refetchPartyProfile]);

    const _handleChangeSuspendDeleteBtnRadio = useCallback((chosen: 'suspend' | 'delete') => {
        setSelection(chosen);
    }, []);

    const _handleClickBtnNext = useCallback(async () => {
        if (step === 0) {
            if (selection === 'suspend') {
                setStep(1);
            } else {
                await deletePartyProfile({ partyId });
                setDialogOpen(false);
                navigate('../');
            }
        } else {
            setStep(step + 1);
        }
    }, [deletePartyProfile, navigate, partyId, selection, step]);

    const _handleClose = useCallback(() => {
        setDialogOpen(false);
        setStep(0);
        setSelection('suspend');
    }, []);

    return (
        <Stack direction={'column'} width={'100%'}>
            <Stack direction={'column'} alignItems={'center'} spacing={8} padding={8} justifyItems={'center'}>
                <UserStatusIcon disabled={blocked} admin={admin} />
                <Stack direction={'column'} alignItems={'center'}>
                    <Typography variant={'h4'} fontWeight={400} gutterBottom>{label}</Typography>
                    <Typography variant={'h6'} fontWeight={400} textAlign={'center'}>SSN LUXTRUST:</Typography>
                    {
                        ssn.map(s =>
                            <Typography key={s.code} variant={'h6'} fontWeight={100} textAlign={'center'}
                                color={grey[500]}>
                                {s.code}
                            </Typography>
                        )
                    }
                </Stack>
            </Stack>

            <RequirePermissions permissions={[Permission.SELF_ADMIN]}>
                {blocked ?
                    <Box sx={{
                        background: '#fff linear-gradient(90deg, #E9F6F0 0%, #F2FBF3 100%) 0% 0% no-repeat padding-box',
                        border: '1px solid #E1F4EE',
                        padding: 4,
                        textAlign: 'center',
                        borderBottomRightRadius: '10px',
                        borderBottomLeftRadius: '10px'
                    }}>
                        <Button type={'submit'} onClick={_handleUnblockAccessBtnClick} variant={'outlinedSecondary'}
                            color={'primary'}
                            size={'small'}>
                            {t('commons:modules.webpro.self-admin.buttons.unblock')}
                        </Button>
                    </Box>
                    :
                    <Box sx={{
                        background: 'transparent linear-gradient(254deg, #FF818117 0%, #FF818117 100%) 0% 0% no-repeat padding-box',
                        border: '1px solid #E1F4EE',
                        padding: 4,
                        textAlign: 'center',
                        borderBottomRightRadius: '10px',
                        borderBottomLeftRadius: '10px'
                    }}>
                        <Button type={'submit'} onClick={_handleBlockAccessBtnClick} variant={'outlinedSecondary'}
                            color={'error'}
                            size={'small'}>
                            {t('commons:modules.webpro.self-admin.buttons.suppress-delete')}
                        </Button>
                    </Box>
                }

                <GenericDialog maxWidth={'sm'}
                    title={t('commons:modules.webpro.self-admin.labels.luxtrust-validation')}
                    handleClose={_handleClose}
                    noPadding
                    actions={
                        step === 0 && !isDeletePartyProfileLoading &&
                        <Button type={'button'}
                            onClick={_handleClickBtnNext}
                            variant="contained"
                            color="primary" size={'medium'}>{t('commons:buttons.validation')}</Button>
                    }
                    isOpen={dialogOpen}>

                    {isDisablePartyProfileLoading || isEnablePartyProfileLoading || isDeletePartyProfileLoading ?
                        <InnerPageBglSvgLoader />
                        :
                        <>
                            {step === 0 &&
                                <Stack spacing={12} p={8}>
                                    <DetailsRadioBox
                                        name={'suspend'}
                                        title={t('commons:modules.webpro.self-admin.labels.suspend-user')}
                                        desc={t('commons:modules.webpro.self-admin.labels.desc-suspend-user-access')}
                                        selection={selection} updateSelection={_handleChangeSuspendDeleteBtnRadio} />

                                    <DetailsRadioBox
                                        name={'delete'}
                                        title={t('commons:modules.webpro.self-admin.labels.delete-user')}
                                        desc={t('commons:modules.webpro.self-admin.labels.desc-delete-user-access')}
                                        selection={selection} updateSelection={_handleChangeSuspendDeleteBtnRadio} />
                                </Stack>
                            }

                            {step === 1 &&
                                <OrelyValidation
                                    challengeContext={{ operation: t('commons:modules.webpro.self-admin.luxtrust.context.suspend-user') }}
                                    callback={_handleOrelyCallback}>
                                </OrelyValidation>
                            }

                            {step === 2 &&
                                <OrelyValidation
                                    challengeContext={{ operation: t('commons:modules.webpro.self-admin.luxtrust.context.reactivation-user') }}
                                    callback={_handleOrelyCallback}>
                                </OrelyValidation>
                            }
                        </>
                    }
                </GenericDialog>
            </RequirePermissions>
        </Stack>
    );
};

export const UserBoxSkeleton = () => {
    return (
        <Stack direction={'column'} width={'100%'}>
            <Stack direction={'column'} alignItems={'center'} spacing={8} padding={8} justifyItems={'center'}>
                <Skeleton variant={'circular'} width={75} height={75}></Skeleton>
                <Stack direction={'column'} alignItems={'center'} width={'100%'}>
                    <Typography variant={'h4'} fontWeight={400} gutterBottom width={'100%'}><Skeleton variant={'text'}
                        width={'100%'} /></Typography>
                    <Typography variant={'h6'} fontWeight={400} textAlign={'center'} width={'100%'}><Skeleton
                        variant={'text'} width={'100%'} /></Typography>
                    <Typography variant={'h6'} fontWeight={100} textAlign={'center'} width={'100%'}
                        color={grey[500]}><Skeleton variant={'text'}
                            width={'100%'} /></Typography>
                </Stack>
            </Stack>
        </Stack >
    );
};

export default UserBox;
