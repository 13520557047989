import React, {useMemo} from 'react';
import {useLocale} from '../../../../hooks/useLocale';
import {Typography, TypographyProps} from '@mui/material';
import {parseJSON} from 'date-fns';
import {Locale} from '../../../../@types/ui.types';

type FormattedDateTimeProps = {
    dateTime: string,
    pattern?: string,
    asTimestamp?: boolean
    options?: Intl.DateTimeFormatOptions,
    parts?: Intl.DateTimeFormatPartTypes[],
    forceLocale?: Locale,
    typographyProps?: TypographyProps,
    disableTypography?: boolean
};

const FormattedDateTime = ({
    dateTime,
    asTimestamp = false,
    options,
    forceLocale,
    parts = ['day', 'month', 'year', 'hour', 'minute', 'second'],
    typographyProps = {variant: 'body1'},
    disableTypography = false
}: FormattedDateTimeProps) => {

    let locale = useLocale();

    if (forceLocale) {
        locale = forceLocale;
    }

    const formattedDateTime = useMemo(() => {
        return formatDateTime(dateTime, locale, asTimestamp, options, parts);
    }, [dateTime, locale, asTimestamp, options, parts]);

    return (
        formattedDateTime ?
            disableTypography ?
                <>{formattedDateTime}</>
                :
                <Typography {...typographyProps}>
                    {formattedDateTime}
                </Typography>
            :
            <></>
    );
};

export const formatDateTime = (
    dateTime: string,
    locale: string,
    asTimestamp: boolean = false,
    options?: Intl.DateTimeFormatOptions,
    parts: Intl.DateTimeFormatPartTypes[] = ['day', 'month', 'year', 'hour', 'minute', 'second']
): string => {
    if (dateTime === null || dateTime === undefined) {
        return 'N/A'; /* N/A more visible than '' */
    } else {
        const dateTimeString = typeof dateTime === 'string' ? dateTime : String(dateTime);
        const dateAsDate = asTimestamp ? new Date(dateTime) : parseJSON(dateTimeString);

        const formattedDate = Intl.DateTimeFormat(locale, options).formatToParts(dateAsDate);

        return formattedDate
            .map((part, idx) =>
                ((part.type === 'literal' &&
                        parts.includes(formattedDate[idx - 1].type as unknown as Intl.DateTimeFormatPartTypes)
                        && parts.includes(formattedDate[idx + 1].type as unknown as Intl.DateTimeFormatPartTypes))
                    || parts.includes(part.type as unknown as Intl.DateTimeFormatPartTypes)) ? part.value : '')
            .reduce((previous, current) => `${previous}${current}`);
    }
};

export default FormattedDateTime;
