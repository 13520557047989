import React, {useCallback, useState} from 'react';
import {Box, Button, SelectChangeEvent, Stack, TextField, Typography} from '@mui/material';
import ScreenWrapper, {ScreenWrapperTitle} from '../components/ScreenWrapper';

import UserIcon from '../../../assets/images/commons/user.svg?react';
import {useTranslation} from 'react-i18next';
import {WebBankingBusinessContract} from '../../../redux/@types/SelfAdminTypes';
import {
    useGetContractQuery,
    useGetContractUpdatesQuery,
    useGetEligibleContactsQuery,
    useUpdateContactMutation
} from '../../../redux/api/selfAdminApiRtk';
import DashedDivider from '../../_commons/components/Divider/DashedDivider';
import SelectField, {MenuItemStyled} from '../../_commons/components/Form/SelectField/SelectField';
import WarningPaper from '../../_commons/components/Paper/WarningPaper';
import SavedAsDraft from '../components/SavedAsDraft';
import {useValidation} from '../../../hooks/useValidation';
import Validator from 'validatorjs';
import {Permission} from '../../../redux/@types/WebProContractTypes';
import RequirePermissions from '../../../components/RequirePermissions';
import {useNavigate} from 'react-router';

const rules = {
    newPersonFirstName: ['required', 'max:35'],
    newPersonLastName: ['required', 'max:35'],
};

const EligibleContactsList = ({isEditing, value, onChange, hasError, validationErrors, contactParty}: {
    isEditing: boolean,
    value: { thirdPartyId?: string, firstName?: string, lastName?: string, isNew?: boolean },
    onChange: (value: { thirdPartyId?: string, firstName?: string, lastName?: string, isNew?: boolean }) => void,
    hasError: Function,
    validationErrors: Validator.ValidationErrors,
    contactParty: string
}) => {
    const {t} = useTranslation(['commons']);

    const {data: contacts, isFetching} = useGetEligibleContactsQuery();

    const _handlePersonChange = useCallback((event: SelectChangeEvent<unknown>) => {
        const val = event.target?.value as string ?? '';
        if (val === 'newContactPerson') {
            onChange({isNew: true});
        } else {
            onChange({thirdPartyId: val});
        }
    }, [onChange]);

    return (
        <>
            {contacts && !isFetching &&
                <Stack sx={{flexDirection: 'column', width: '80%'}}>

                    <SelectField label={t('commons:modules.webpro.self-admin.menu.contact')}
                                 items={contacts.filter(contact => contactParty ? !contactParty.toLowerCase().includes(`${contact.lastName.toLowerCase()} ${contact.firstName.toLowerCase()}`) : true)}
                                 renderItem={item => (
                                     <MenuItemStyled key={item.thirdPartyId} value={item.thirdPartyId} divider>
                                         <Typography variant={'h5'}
                                                     textTransform={'capitalize'}>{item.lastName.toUpperCase()} {item.firstName.toLowerCase()} </Typography>
                                     </MenuItemStyled>
                                 )}
                                 extraItems={
                                     <MenuItemStyled value={'newContactPerson'} sx={{
                                         marginLeft: '15px',
                                         marginTop: '15px',
                                         padding: '0'
                                     }}
                                     >
                                         <Typography textTransform={'uppercase'} sx={{
                                             fontWeight: '400 !important',
                                             fontFamily: 'BNPPSansCondensed !important',
                                             letterSpacing: '.07rem',
                                             fontSize: '1.4rem'
                                         }}>{t('commons:modules.webpro.self-admin.select-new-contact-person')}</Typography>
                                     </MenuItemStyled>
                                 }
                                 onChange={_handlePersonChange}
                                 value={value.thirdPartyId}
                                 disabled={!isEditing}
                    >
                    </SelectField>

                    {isEditing && value.isNew &&
                        <>
                            <Stack display={'flex'} flexDirection={'row'} marginTop={'34px'}>
                                <TextField
                                    sx={{width: '100%'}}
                                    label={t('commons:modules.gcl.fields.firstName')}
                                    size={'medium'}
                                    onChange={event => onChange({
                                        ...value,
                                        firstName: event.target.value.toUpperCase(),
                                        isNew: true
                                    })}
                                    value={value.firstName ?? ''}
                                    error={hasError('newPersonFirstName')}
                                    helperText={hasError('newPersonFirstName') ? validationErrors['newPersonFirstName'] : ''}
                                />
                                <TextField
                                    sx={{marginLeft: '36px', width: '100%'}}
                                    label={t('commons:modules.gcl.fields.lastName')}
                                    size={'medium'}
                                    onChange={event => onChange({
                                        ...value,
                                        lastName: event.target.value.toUpperCase(),
                                        isNew: true
                                    })}
                                    value={value.lastName?.toUpperCase() ?? ''}
                                    error={hasError('newPersonLastName')}
                                    helperText={hasError('newPersonLastName') ? validationErrors['newPersonLastName'] : ''}
                                />
                            </Stack>

                            <WarningPaper color={'orange'} sx={{mt: 8}}>
                                {t('commons:modules.webpro.self-admin.warning-modify-contact-person')}
                            </WarningPaper>

                        </>
                    }

                </Stack>

            }
        </>
    );
};

const ContactPerson = () => {

    const {t} = useTranslation(['commons']);
    const navigate = useNavigate();
    const {refetch} = useGetContractUpdatesQuery();

    const {data: currentWebProContract} = useGetContractQuery();
    const {contactParty} = currentWebProContract ?? {} as WebBankingBusinessContract;

    const [updateContact, {isLoading: isUpdateContactInProgress}] = useUpdateContactMutation();

    const [isEditing, setIsEditing] = useState(false);
    const [selectedContactPerson, setSelectedContactPerson] = useState<{
        thirdPartyId?: string,
        firstName?: string,
        lastName?: string,
        isNew?: boolean
    }>({
        thirdPartyId: '',
        firstName: '',
        lastName: ''
    });

    const _handleEditClick = useCallback(() => {
        setIsEditing(true);
    }, []);

    const _handleCancelClick = useCallback(() => {
        setIsEditing(false);
    }, []);

    const _handleBackClick = useCallback(() => {
        setIsEditing(false);
        refetch();
        navigate('../');
    }, [navigate, refetch]);

    const [draftSaved, setDraftSaved] = useState(false);

    const {validate, hasError, setValidationErrors, validationErrors} = useValidation();


    const _handleValidateClick = useCallback(async () => {

        try {
            if (selectedContactPerson?.isNew) {
                const validation = validate({
                    newPersonFirstName: selectedContactPerson?.firstName,
                    newPersonLastName: selectedContactPerson?.lastName
                }, rules);

                if (validation.fails()) {
                    setValidationErrors(validation.errors.all());
                } else {
                    await updateContact({
                        newContactFirstName: selectedContactPerson.firstName ?? '',
                        newContactLastName: selectedContactPerson.lastName ?? '',
                    });
                    setDraftSaved(true);
                }
            } else if(selectedContactPerson?.thirdPartyId) {
                await updateContact({
                    newContactThirdPartyId: selectedContactPerson?.thirdPartyId ?? ''
                });
                setDraftSaved(true);
            }
        } catch (e) {

        }

    }, [validate, setValidationErrors, updateContact, selectedContactPerson?.isNew, selectedContactPerson?.firstName, selectedContactPerson?.lastName, selectedContactPerson?.thirdPartyId]);


    return (
        <ScreenWrapper
            header={<ScreenWrapperTitle title={t('commons:modules.webpro.self-admin.contact-person')}/>}
            maxWidth={892}
        >
            {draftSaved ?
                <>
                    <SavedAsDraft/>
                    <Box textAlign={'center'} position={'absolute'} width={'100%'} bottom={'-16px'}>
                        <Button type={'submit'} onClick={_handleBackClick} variant="contained"
                                color="primary" size={'medium'}>
                            {t('commons:buttons.back')}
                        </Button>
                    </Box>
                </>
                :
                <Stack direction={'column'} width={'100%'} spacing={12}>
                    <Stack direction={'row'} spacing={12} p={12} width={'100%'} mb={8}>
                        <UserIcon width={90} height={90}/>

                        <Stack direction={'column'} width={'100%'} spacing={2}>
                            <Stack direction={'row'} spacing={4} alignItems={'center'} justifyContent={'space-between'}>
                                <Typography
                                    variant={'h5'}> {t('commons:modules.webpro.self-admin.current-contact-person')}:</Typography>
                            </Stack>
                            <Typography variant={'h5'} fontWeight={400}>{contactParty?.label}</Typography>

                            <RequirePermissions permissions={[Permission.SELF_ADMIN]}>
                                <Box py={4}>
                                    <DashedDivider variant={'fullWidth'}/>
                                </Box>

                                <EligibleContactsList
                                    isEditing={isEditing}
                                    value={selectedContactPerson}
                                    onChange={(value) => setSelectedContactPerson(value)}
                                    hasError={hasError}
                                    validationErrors={validationErrors}
                                    contactParty={contactParty?.label}
                                />
                            </RequirePermissions>
                        </Stack>

                    </Stack>
                    <RequirePermissions permissions={[Permission.SELF_ADMIN]}>
                        <Box textAlign={'center'} position={'absolute'} width={'100%'} bottom={'-16px'}>
                            <Stack direction={'row'} justifyContent={'center'} spacing={12}>

                                {!isEditing ?
                                    <Button type={'submit'} onClick={_handleEditClick} variant="contained"
                                            color="primary" size={'medium'}>
                                        {t('commons:buttons.edit')}
                                    </Button>
                                    :
                                    <>
                                        <Button type={'submit'} onClick={_handleCancelClick} variant="contained"
                                                color="secondary" size={'medium'} disabled={isUpdateContactInProgress}>
                                            {t('commons:buttons.cancel')}
                                        </Button>
                                        <Button type={'submit'} onClick={_handleValidateClick}
                                                variant="contained"
                                                color="primary" size={'medium'} disabled={isUpdateContactInProgress || selectedContactPerson?.thirdPartyId === ''}>
                                            {t('commons:buttons.validate')}
                                        </Button>
                                    </>
                                }

                            </Stack>
                        </Box>
                    </RequirePermissions>
                </Stack>
            }
        </ScreenWrapper>
    );
};

export default ContactPerson;
