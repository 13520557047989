import React from 'react';
import {Stack} from '@mui/system';
import {TextField} from '@mui/material';
import {RangeFieldType} from './DateRangeField';


const TextRangeField = (
    {field, advancedKeys, checkbox, handleAdvancedKeys} :
    {field: RangeFieldType, advancedKeys: any, checkbox: React.ReactElement, handleAdvancedKeys: Function}
) => {

    const _handleFieldChange = (id: string, value: string) => {
        if (field.fieldFrom.type === 'number' && isNaN(Number(value))) {
            return;
        }
        const name = id.replace('From', '').replace('To', '');
        handleAdvancedKeys({
            ...advancedKeys,
            [name+'Checked']: value && value.length > 0,
            [id]: value,
        });
    };

    return (
        <Stack direction={'row'} alignItems={'center'} spacing={6} mb={4}>
            <TextField
                value={advancedKeys[field.fieldFrom.field]}
                key={field.fieldFrom.field}
                label={field.fieldFrom.label}
                fullWidth={true}
                onChange={(e: { target: { value: string; }; }) => {
                    _handleFieldChange(field.fieldFrom.field, e.target.value);
                }}
                variant={'outlined'}
            />

            <TextField
                value={advancedKeys[field.fieldTo.field]}
                key={field.fieldTo.field}
                label={field.fieldTo.label}
                fullWidth={true}
                onChange={(e: { target: { value: string; }; }) => {
                    _handleFieldChange(field.fieldTo.field, e.target.value);
                }}
                variant={'outlined'}
            />

            {checkbox}
        </Stack>
    );
};

export default TextRangeField;
