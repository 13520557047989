import React, {useCallback, useEffect} from 'react';
import {useGetWebProContractsQuery} from '../../../redux/api/webProContractApiRtk';
import {
    useCurrentPrimaryContract,
    useCurrentWebProContract,
    usePrincipalPrimaryContract
} from '../../../hooks/useCurrentPrimaryContract';
import {useNavigate} from 'react-router';
import {Button, Card, CardActions, CardContent, CardHeader, Grid, Stack, Typography} from '@mui/material';
import SectionProContracts from '../components/SectionProContracts';
import {Trans, useTranslation} from 'react-i18next';
import {PrimaryContract, WebProContract} from '../../../redux/@types/WebProContractTypes';
import {useAnalytics} from '../../../hooks/useAnalytics';
import {InnerPageBglSvgLoader} from '../../../assets/Images.tsx';

const ContractSelection = () => {
    const {t} = useTranslation(['commons', 'authentication']);

    const {data: webProContracts, isFetching} = useGetWebProContractsQuery();
    const [currentWebProContract, setCurrentWebProContract] = useCurrentWebProContract();
    const [currentPrimaryContract, setCurrentPrimaryContract] = useCurrentPrimaryContract();
    const principal = usePrincipalPrimaryContract();
    const navigate = useNavigate();
    const {pushAnalytics} = useAnalytics();

    useEffect(() => {
        if (currentWebProContract && !currentPrimaryContract && principal) {
            setCurrentPrimaryContract(principal);
        }
    }, [currentPrimaryContract, currentWebProContract, principal, setCurrentPrimaryContract]);

    const _handleValidateBtnClick = useCallback(() => {
        pushAnalytics({
            rule: 'validate_contract_choice_tracking_rule',
            actionName: 'validate_contract_choice',
            buttonName: 'validate_contract_choice_button',
            sequenceName: 'change contract'
        });

        if (currentWebProContract && currentPrimaryContract) {

            if (currentWebProContract.menuProfiles === 'EDOCUMENTS') {
                navigate(`/${currentWebProContract.reference}/${currentPrimaryContract.primaryContractId}/e-documents`);
            } else if (currentWebProContract.menuProfiles === 'SYNTHESIS') {
                navigate(`/${currentWebProContract.reference}/${currentPrimaryContract.primaryContractId}/synthesis`);
            } else {
                navigate('/redirect/dashboard');
            }
        }
    }, [currentPrimaryContract, currentWebProContract, navigate, pushAnalytics]);

    const _handleChangeWebProContract = useCallback((webProContract: WebProContract) => {
            setCurrentWebProContract(webProContract);
            setCurrentPrimaryContract(undefined);
        },
        [setCurrentPrimaryContract, setCurrentWebProContract]);

    const _handleChangePrimaryContract = useCallback((primaryContract?: PrimaryContract) => {
            setCurrentPrimaryContract(primaryContract);
        },
        [setCurrentPrimaryContract]);

    useEffect(() => {
        if (!currentWebProContract && webProContracts && webProContracts.length === 1) {
            setCurrentWebProContract(webProContracts[0]);

            if (!currentPrimaryContract) {
                /* find principal primaryContract, orElse the first from the list */
                const mainPrimaryContract = webProContracts[0].primaryContracts.find(primaryContract => primaryContract.mainPrimaryContract) ?? webProContracts[0].primaryContracts[0];
                setCurrentPrimaryContract(mainPrimaryContract);

                if (webProContracts[0].menuProfiles === 'EDOCUMENTS') {
                    navigate(`/${webProContracts[0].reference}/${webProContracts[0].primaryContracts[0].primaryContractId}/e-documents`);
                } else if (webProContracts[0].menuProfiles === 'SYNTHESIS') {
                    navigate(`/${webProContracts[0].reference}/${webProContracts[0].primaryContracts[0].primaryContractId}/synthesis`);
                } else {
                    navigate('/redirect/dashboard');
                }
            } else {
                /* Nothing to do in useEffect. Waiting for user selection */
            }
        }
    }, [currentPrimaryContract, currentWebProContract, navigate, setCurrentPrimaryContract, setCurrentWebProContract, webProContracts]);

    return (
        <Grid container spacing={2} justifyContent={'space-around'}>
            <Grid container item xs={12} md={10} justifyContent={'center'} sx={{marginX: {xs: 8}}}>

                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            sx={{borderBottom: '1px solid #E5E5E5'}}
                            title={t('commons:modules.webpro.contract-selection.title')}
                            titleTypographyProps={{variant: 'h3', textAlign: 'center'}}
                        >
                        </CardHeader>

                        <CardContent>
                            {isFetching ?
                                <InnerPageBglSvgLoader/>
                                :
                                webProContracts && webProContracts.length > 0 ?
                                    <SectionProContracts currentWebProContract={currentWebProContract}
                                                         currentPrimaryContract={currentPrimaryContract}
                                                         onChangeWebProContract={_handleChangeWebProContract}
                                                         onChangePrimaryContract={_handleChangePrimaryContract}
                                    />
                                    :
                                    <Stack justifyContent={'center'} alignContent={'center'} py={8}>
                                        <Trans>
                                            <Typography variant={'body2'}
                                                        textAlign={'center'}>{t('authentication:labels.no-webpro-contract-available')}</Typography>
                                        </Trans>
                                    </Stack>
                            }
                        </CardContent>

                        <CardActions sx={{justifyContent: 'center'}}>
                            <Button type={'submit'} onClick={_handleValidateBtnClick} variant="contained"
                                    color="primary" size={'medium'}
                                    disabled={!(currentWebProContract && currentPrimaryContract)}>
                                {t('commons:buttons.validate')}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ContractSelection;
