import {Box, Stack, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import SuccessSvg from '../../../assets/images/icons/commons/success.svg?react';

const SavedAsDraft = () => {

    const {t} = useTranslation(['commons']);

    return (
        <Stack direction={'column'} alignItems={'center'} spacing={4} pt={20} pb={25}>
            <Box>
                <SuccessSvg width={160} height={160}/>
            </Box>
            <Stack direction={'column'} justifyItems={'center'} alignItems={'center'}>
                <Typography variant={'h4'} fontWeight={400} color={'primary'}
                            gutterBottom>{t('commons:modules.webpro.self-admin.labels.draft')}</Typography>
                {/*<Typography variant={'h5'}
                            color={'primary'}>{t('commons:modules.webpro.self-admin.labels.do-not-forget-to-validate')}</Typography>*/}
            </Stack>
        </Stack>
    );
};

export default SavedAsDraft;
