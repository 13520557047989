import React, { useMemo } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { useLocale } from '../../../../hooks/useLocale';
import { Locale } from '../../../../@types/ui.types';

export type FormattedAmountProps = {
    value: number,
    maximumFractionDigits?: number,
    currency: string,
    currencyDisplay?: 'code' | 'symbol',
    signDisplay?: Intl.NumberFormatOptions['signDisplay']
    forceLocale?: Locale,
    disableTypography?: boolean,
    typographyProps?: TypographyProps
};

const FormattedAmount = ({
    value,
    maximumFractionDigits = 2,
    currency,
    currencyDisplay = 'code',
    signDisplay = 'auto',
    forceLocale,
    disableTypography = false,
    typographyProps = { variant: 'body1', variantMapping: { body1: 'span' } }
}: FormattedAmountProps) => {
    let locale = useLocale();

    if (forceLocale) {
        locale = forceLocale;
    }

    const formattedAmount = useMemo(() =>
        formatAmount(
            {
                number: value,
                currency
            },
            locale,
            maximumFractionDigits,
            currencyDisplay,
            signDisplay
        ), [locale, value, currency, maximumFractionDigits, currencyDisplay, signDisplay]
    );

    return (
        disableTypography ?
            <>{formattedAmount}</>
            :
            <Typography {...typographyProps}>
                {formattedAmount}
            </Typography>
    );
};

export const toAmount = (number: number, currency: string): { number: number, currency: string } => ({
    number,
    currency
});

export const formatAmount = (
    { number, currency }: { number: number, currency: string },
    locale: string,
    maximumFractionDigits = 2,
                             currencyDisplay: Intl.NumberFormatOptions['currencyDisplay'] = 'code',
                             signDisplay: Intl.NumberFormatOptions['signDisplay'] = 'auto'): string => {
    return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency,
            maximumFractionDigits,
            currencyDisplay,
            signDisplay
        }
    ).format(number);
};

export default FormattedAmount;
