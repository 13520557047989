import {useMediaQuery, useTheme} from '@mui/material';

export const useMenuWidth = (): number | string => {
    const {breakpoints} = useTheme();

    let width: number | string = 0;
    const isDesktop = useMediaQuery(breakpoints.up('lg'));
    const isTablet = useMediaQuery(breakpoints.between('xs', 'lg'));
    const isSmartphone = useMediaQuery(breakpoints.only('xs'));
    if (isDesktop) {
        width = 275;
    } else if (isTablet) {
        width = 200;
    } else if (isSmartphone) {
        width = '80vw';
    } else {
        width = 275; /* default */
    }

    return width;
};
