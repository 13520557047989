import React, { useEffect, useMemo, useState } from 'react';

import {
    Collapse,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    styled,
    SvgIcon,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
    useCurrentPrimaryContractReference,
    useCurrentWebProContract,
    useCurrentWebProContractReference
} from '../../hooks/useCurrentPrimaryContract';
import DashedDivider from '../_commons/components/Divider/DashedDivider';
import {
    AccountsIcon,
    AccountsOnIcon,
    DashboardIcon,
    DashboardOnIcon,
    EdocumentsIcon,
    EdocumentsOnIcon,
    MailsIcon,
    MailsOnIcon,
    PaymentIcon,
    PaymentOnIcon,
    SelfAdminIcon,
    SelfAdminOnIcon,
    SettingsIcon,
    SettingsOnIcon
} from '../../assets/images/icons/menu/MenuIcons';
import { Permission } from '../../redux/@types/WebProContractTypes';
import { useHasOneOfPermission } from '../../hooks/useWebProPermissions';
import { useAnalytics } from '../../hooks/useAnalytics';

type MenuEntryParams = {
    webproContract: string;
    primaryContract: string;
};

type MenuItem = {
    id: 'dashboard' | 'synthesis' | 'mailing' | 'e-documents' | 'settings' | 'ihub' | 'self-admin' | 'payment';
    icon: Array<React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>>;
    sectionRegexp: RegExp;
    route: string;
    childs?: MenuItem[];
};

const LinkMenuStyled = styled(Link)({
    fontSize: '1.6rem',
    fontWeight: 100,
    fontFamily: 'BNPPSansCondensed',
    color: '#465449',
    textTransform: 'capitalize',
    textDecoration: 'none'
});

const ENTRY_DASHBOARD = ({ webproContract, primaryContract }: MenuEntryParams): MenuItem => ({
    id: 'dashboard',
    icon: [DashboardIcon, DashboardOnIcon],
    sectionRegexp: /^\/\w{16}\/[0-9]{6}\/dashboard(.)*/,
    route: `/${webproContract}/${primaryContract}/dashboard`
});

const ENTRY_SYNTHESIS = ({ webproContract, primaryContract }: MenuEntryParams): MenuItem => ({
    id: 'synthesis',
    icon: [AccountsIcon, AccountsOnIcon],
    sectionRegexp: /^\/\w{16}\/[0-9]{6}\/synthesis(.)*/,
    route: `/${webproContract}/${primaryContract}/synthesis`
});

/*const ENTRY_PAYMENT = ({webproContract, primaryContract}: MenuEntryParams): MenuItem => ({
    id: 'payment',
    icon: PaymentIcon,
    sectionRegexp: /^\/[0-9]{6}\/[0-9]{6}\/payment(.)*!/,
    route: `/${webproContract}/${primaryContract}/payments`
});
*/
const ENTRY_MAILING = ({ webproContract, primaryContract }: MenuEntryParams): MenuItem => ({
    id: 'mailing',
    icon: [MailsIcon, MailsOnIcon],
    sectionRegexp: /^\/\w{16}\/[0-9]{6}\/secure-messaging(.)*/,
    route: `/${webproContract}/${primaryContract}/secure-messaging`
});

const ENTRY_EDOCUMENTS = ({ webproContract, primaryContract }: MenuEntryParams): MenuItem => ({
    id: 'e-documents',
    icon: [EdocumentsIcon, EdocumentsOnIcon],
    sectionRegexp: /^\/\w{16}\/[0-9]{6}\/e-documents(.)*/,
    route: `/${webproContract}/${primaryContract}/e-documents`
});

const ENTRY_IHUB = ({ webproContract, primaryContract }: MenuEntryParams): MenuItem => ({
    id: 'ihub',
    icon: [EdocumentsIcon, EdocumentsOnIcon],
    sectionRegexp: /^\/\w{16}\/[0-9]{6}\/ihub(.)*/,
    route: `/${webproContract}/${primaryContract}/ihub`
});
/*
const ENTRY_DOCUMENTS = ({webproContract, primaryContract}: MenuEntryParams): MenuItem => ({
    id: 'documents',
    icon: PortfolioIcon,
    sectionRegexp: /^\/[0-9]{6}\/[0-9]{6}\/portfolio(.)*!/,
    route: `/${webproContract}/${primaryContract}/portfolio`
});
*/

const ENTRY_SETTINGS = ({ webproContract, primaryContract }: MenuEntryParams): MenuItem => ({
    id: 'settings',
    icon: [SettingsIcon, SettingsOnIcon],
    sectionRegexp: /^\/\w{16}\/[0-9]{6}\/settings(.)*/,
    route: `/${webproContract}/${primaryContract}/settings`
});

const ENTRY_PAYMENT = ({ webproContract, primaryContract }: MenuEntryParams): MenuItem => ({
    id: 'payment',
    icon: [PaymentIcon, PaymentOnIcon],
    sectionRegexp: /^\/\w{16}\/[0-9]{6}\/payment(.)*/,
    route: `/${webproContract}/${primaryContract}/payment`
});

const ENTRY_SELF_ADMIN = ({ webproContract, primaryContract }: MenuEntryParams): MenuItem => ({
    id: 'self-admin',
    icon: [SelfAdminIcon, SelfAdminOnIcon],
    sectionRegexp: /^\/\w{16}\/[0-9]{6}\/self-admin(.)*/,
    route: `/${webproContract}/${primaryContract}/self-admin`
});

const ListButtonStyled = styled(ListItemButton)({
    '&.Mui-selected': {
        background: '#F4F9F7 !important',
        color: '#28A373',
        fontWeight: 400,
        borderTop: '1px solid #28A37326',
        borderBottom: '1px solid #28A37326'
    }
});

const Menu = () => {
    const enableSecureMessaging = import.meta.env.VITE_ENABLE_SECURE_MESSAGING === 'true';
    const enableEDocuments = import.meta.env.VITE_ENABLE_EDOCUMENTS === 'true';
    const enableSettings = import.meta.env.VITE_ENABLE_SETTINGS === 'true';
    const enableSelfAdmin = import.meta.env.VITE_ENABLE_SELF_ADMIN === 'true';
    const enablePaiement = import.meta.env.VITE_ENABLE_PAYMENT === 'true';

    const theme = useTheme();
    const isLargerScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const { t } = useTranslation(['commons']);

    const primaryContract = useCurrentPrimaryContractReference();
    const webproContract = useCurrentWebProContractReference();
    const { pushAnalytics } = useAnalytics();

    const { pathname } = useLocation();
    const [open, setOpen] = useState('');

    const Divider = useMemo(
        () => (
            !isLargerScreen ? <DashedDivider sx={{ margin: '0px 25px 0px 25px !important' }} variant={'middle'} /> : <></>
        ),
        [isLargerScreen]
    );

    const hasEDocPermission = useHasOneOfPermission([Permission.CONSULTATION_E_DOCUMENT]);
    const hasIhubPermission = useHasOneOfPermission([Permission.CONSULTATION_IHUB]);
    const hasDashboardPermission = useHasOneOfPermission([Permission.CONSULTATION_CREDIT, Permission.CONSULTATION_CARD, Permission.CONSULTATION_DEPOTERM, Permission.CONSULTATION_SAVING, Permission.CONSULTATION_VIEW_ACCOUNT]);
    const hasSelfAdminPermission = useHasOneOfPermission([Permission.SELF_ADMIN, Permission.SELF_ADMIN_CONSULTATION]);
    const hasPaymentPermission = useHasOneOfPermission([
        Permission.BENEFICIARY_MANAGEMENT,
        Permission.PAYMENT_CREATE_SINGLE,
        Permission.PAYMENT_DISPLAY_HISTORY,
        Permission.PAYMENT_PRE_INSTRUCTION_DISPLAY,
        Permission.PAYMENT_DIRECT_DEBIT_DISPLAY
    ]);

    // For checking PTDC (Porteur de Cartes) role using menuProfiles
    const currentWebProContract = useCurrentWebProContract();

    const menuItems = useMemo(() => {
        return [
            ...hasDashboardPermission || currentWebProContract[0]?.menuProfiles === 'SYNTHESIS' ? [ENTRY_DASHBOARD({
                webproContract,
                primaryContract
            })] : [],
            ...hasDashboardPermission || currentWebProContract[0]?.menuProfiles === 'SYNTHESIS' ? [ENTRY_SYNTHESIS({
                webproContract,
                primaryContract
            })] : [],
            ...enablePaiement && hasPaymentPermission ? [ENTRY_PAYMENT({ webproContract, primaryContract })] : [],
            ...enableEDocuments && hasEDocPermission ? [ENTRY_EDOCUMENTS({ webproContract, primaryContract })] : [],
            ...enableSecureMessaging ? [ENTRY_MAILING({ webproContract, primaryContract })] : [],
            ...hasIhubPermission ? [ENTRY_IHUB({ webproContract, primaryContract })] : [],
            ...enableSelfAdmin && hasSelfAdminPermission ? [ENTRY_SELF_ADMIN({ webproContract, primaryContract })] : [],
            ...enableSettings ? [ENTRY_SETTINGS({ webproContract, primaryContract })] : []
        ];
    }, [hasDashboardPermission, currentWebProContract, webproContract, primaryContract, enableEDocuments, hasEDocPermission, enableSecureMessaging, hasIhubPermission, enablePaiement, hasPaymentPermission, enableSelfAdmin, hasSelfAdminPermission, enableSettings]);

    useEffect(() => {
        menuItems?.forEach(item => {
            if (pathname.match(item.sectionRegexp)) {
                setOpen(item?.id);
            }
        });
    }, [menuItems, pathname]);

    const _handleOnClicItem = (itemId: string) => {
        setOpen(itemId);
        pushAnalytics({
            rule: 'click_menu_item_tracking_rule',
            actionName: 'click_menu_item',
            buttonName: 'menu_item',
            pageName: itemId
        });
    };

    return (
        <List disablePadding>
            {
                menuItems.map(item => (
                    <LinkMenuStyled key={item.id} to={item.route}>
                        <Stack>
                            <ListButtonStyled alignItems={'center'}
                                sx={{ py: { md: 0, lg: 2 } }}
                                selected={pathname.match(item.sectionRegexp) != null}
                                onClick={() => _handleOnClicItem(item.id)}>
                                <Stack direction={{ lg: 'row', xs: 'column' }} py={2}
                                    width={'100%'}
                                    alignItems={{ lg: 'left', xs: 'center' }}
                                    justifyContent={{ lg: 'left', xs: 'center' }}>
                                    <ListItemIcon
                                        sx={{ minWidth: 'auto', pr: { xs: 0, lg: 4 }, marginLeft: { lg: '10px', xs: 0 } }}>
                                        <SvgIcon
                                            viewBox={'0 0 36 36'}
                                            fontSize={'large'}
                                            component={item.id === open ? item.icon[1] : item.icon[0]} />
                                    </ListItemIcon>
                                    <ListItemText primary={t(`commons:modules.webpro.menu.labels.${item.id}`)}
                                        disableTypography />
                                </Stack>
                            </ListButtonStyled>
                        </Stack>
                        {Divider}
                        {item.childs &&
                            <Collapse in={open === item.id} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {item.childs.map(subItem => (
                                        <LinkMenuStyled key={subItem.id} to={subItem.route}>
                                            <ListButtonStyled className={'nested'}>
                                                <ListItemText
                                                    primary={t(`commons:modules.webpro.menu.labels.${subItem.id}`)}
                                                    disableTypography />
                                            </ListButtonStyled>
                                        </LinkMenuStyled>
                                    ))}
                                </List>
                            </Collapse>
                        }
                    </LinkMenuStyled>
                ))
            }
        </List>
    );
};

export default Menu;
