import React, {PropsWithoutRef} from 'react';
import {NumericFormat} from 'react-number-format';
import {TextField, TextFieldProps} from '@mui/material';

const NumericFormatCustom = ({name, onChange, thousandSeparator, suffix, ...other}: PropsWithoutRef<{ name: string, thousandSeparator: boolean | string, suffix: string, onChange: Function }>) => {
    return (
        <NumericFormat
            {...other}
            onValueChange={values => {
                if (onChange) {
                    onChange({
                        target: {
                            name,
                            value: '' + values.value
                        }
                    });
                }
            }}
            decimalScale={2}
            allowNegative={false}
            thousandSeparator={thousandSeparator ? ' ' : false}
            valueIsNumericString
            suffix={suffix}
        />
    );
};

const AmountTextField = ({name, label, value, onChange, ...other}: TextFieldProps) => {
    return (
        <TextField
            {...other}
            label={label}
            onChange={onChange}
            name={name || label as string}
            value={value}
            InputProps={{
                ...other.InputProps,
                inputComponent: NumericFormatCustom as any
            }}
        />
    );
};

export default AmountTextField;
