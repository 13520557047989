import {Box, Tab} from '@mui/material';
import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router';
import {useCurrentWebProContractReference} from '../../../hooks/useCurrentPrimaryContract.ts';
import {useGetAllPaymentConstraintsQuery} from '../../../redux/api/selfAdminApiRtk.ts';
import {InnerPageBglSvgLoader} from '../../../assets/Images';
import {PaymentTypeType} from '../../../redux/datastore/features/payment/storeSinglePayment.ts';
import {StyledTabs} from '../../_commons/styled/StyledTabs.tsx';
import PaymentConstraints from '../components/PaymentConstraints';
import {WebBankingBusinessPaymentConstraint} from '../../../redux/@types/SelfAdminTypes';
import {Permission} from '../../../redux/@types/WebProContractTypes';
import {useHasOneOfPermission} from '../../../hooks/useWebProPermissions';

const ManagePayments = () => {

    const {t} = useTranslation(['commons']);
    const {typeList = 'standard-payments'} = useParams();
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const contractReference = useCurrentWebProContractReference();

    // Retrieve paymentConstraints data from backend
    const {data: webBankingBusinessPaymentConstraint, isFetching, refetch} =
        useGetAllPaymentConstraintsQuery({
            contractReference: contractReference,
        }, {skip: !contractReference, refetchOnMountOrArgChange: true});

    const hasSelfAdminPaymentPermission = useHasOneOfPermission([
        Permission.SELF_ADMIN_PAYMENT_SALARY,
    ]);

    useMemo(() => {
        const typeListValues: { [key: string]: number } = {
            'standard-payments': 0,
            'salary-payments': 1,
        };

        if (typeList in typeListValues) {
            setValue(typeListValues[typeList]);
        }

    }, [typeList, setValue]);

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
        const path = newValue === 0 ? 'standard-payments' : 'salary-payments';
        navigate(`../payments/${path}`);
    }, [navigate]);

    return (
        <Box mt={-14}>
            {hasSelfAdminPaymentPermission ?
                <StyledTabs
                    variant={'fullWidth'}
                    scrollButtons={'auto'}
                    value={value}
                    onChange={handleChange}
                    textColor='secondary'
                    indicatorColor='secondary'
                    centered
                >
                    <Tab
                        label={t('commons:modules.webpro.self-admin.CLASSIC-payments-label')}
                        {...a11yProps(0)}
                    />
                    <Tab
                        label={t('commons:modules.webpro.self-admin.SALARY-payments-label')}
                        {...a11yProps(1)}
                    />
                </StyledTabs>
                :
                <StyledTabs
                    variant={'fullWidth'}
                    scrollButtons={'auto'}
                    value={value}
                    onChange={handleChange}
                    textColor='secondary'
                    indicatorColor='secondary'
                    centered
                >
                    <Tab
                        label={t('commons:modules.webpro.self-admin.CLASSIC-payments-label')}
                        {...a11yProps(0)}
                    />
                </StyledTabs>
            }
            {webBankingBusinessPaymentConstraint && webBankingBusinessPaymentConstraint[0] && !isFetching ? (
                <PaymentConstraints paymentType={value === 0 ? PaymentTypeType.CLASSIC : PaymentTypeType.SALARY}
                                    webBankingBusinessPaymentConstraint={webBankingBusinessPaymentConstraint[0] as WebBankingBusinessPaymentConstraint}
                                    refetchPaymentConstraint={refetch}

                />
            ) : (
                <InnerPageBglSvgLoader/>
            )}
        </Box>
    );
};

export default ManagePayments;
