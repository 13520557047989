import {styled, Typography} from '@mui/material';

type TitleProps = {
    title: string
};

const StyledTypography = styled(Typography)(() => ({
    textAlign: 'left',
    fontFamily: 'BNPPSansCondensed',
    fontSize: '1.5rem',
    letterSpacing: '0.06rem',
    color: '#48424E',

}));

export const SectionTitle = ({title}: TitleProps) => {
    return (
        <StyledTypography gutterBottom>{title}</StyledTypography>
    );
};

export default SectionTitle;
