import React, {useCallback} from 'react';
import {Box, Button, Stack, Typography} from '@mui/material';
import ModificationDetailedBanner from '../components/ModificationDetailedBanner';
import GenericPaper from '../../_commons/components/Paper/GenericPaper';
import {useTranslation} from 'react-i18next';
import {
    useActivateContractMutation,
    useCancelUpdatesMutation,
    useGetContractUpdatesQuery
} from '../../../redux/api/selfAdminApiRtk';
import {
    useCurrentPrimaryContract,
    useCurrentWebProContract,
    useCurrentWebProContractReference
} from '../../../hooks/useCurrentPrimaryContract';
import SimpleLineValidation from '../components/validation/SimpleLineValidation';
import MulitpleLineValidation from '../components/validation/MulitpleLineValidation';
import OrelyValidation from '../../_commons/components/Orely/Signature/OrelyValidation.tsx';
import GenericDialog from '../../_commons/components/Dialog/GenericDialog';
import useConfirmationDialog from '../../../hooks/ui/useConfirmationDialog';
import {ChildrenUpdatedData, ParentUpdatedData} from '../../../redux/@types/SelfAdminTypes';
import RequirePermissions from '../../../components/RequirePermissions';
import {Permission} from '../../../redux/@types/WebProContractTypes';


import SuccessSvg from '../../../assets/images/icons/commons/success.svg?react';
import {InnerPageBglSvgLoader} from '../../../assets/Images';
import {useNavigate} from 'react-router';
import {SELFADMIN_CODES} from '../constants/selfadminCodes.ts';
import SignatureRulesValidation from '../components/validation/SignatureRulesValidation';

const Validation = () => {
    const {t} = useTranslation(['commons']);

    const currentWebProContractRef = useCurrentWebProContractReference();
    const {data, refetch} = useGetContractUpdatesQuery(void (0), {skip: !currentWebProContractRef});

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [currentWebProContract] = useCurrentWebProContract();
    const [currentPrimaryContract] = useCurrentPrimaryContract();

    const [activateContract, {
        isLoading: isActivationLoading,
        isSuccess: isActivationSuccess
    }] = useActivateContractMutation();
    const [cancelUpdates, {
        isLoading: isCancelLoading,
        isSuccess: isCancelSuccess
    }] = useCancelUpdatesMutation();

    const {getConfirmation} = useConfirmationDialog();

    const _handleCancelBtnClick = useCallback(async () => {
        const confirm = await getConfirmation({
            title: t('commons:modules.webpro.self-admin.labels.cancel-updates'),
            dialogContent:
                <Typography
                    variant={'h4'}
                    component={'div'}
                    textAlign={'center'}>
                    {t('commons:modules.webpro.self-admin.labels.cancel-updates-text')}
                </Typography>
        });

        if (confirm) {
            await cancelUpdates();
            await refetch();
        }
    }, [refetch, cancelUpdates, getConfirmation, t]);

    const _handleValidateBtnClick = useCallback(() => {
        setDialogOpen(true);
    }, []);

    const getComponents = useCallback((update: ParentUpdatedData | ChildrenUpdatedData, index: number) => {
        switch (update.type) {
            case SELFADMIN_CODES.CT_PERS:
                return <SimpleLineValidation boxKey={index} update={update as ChildrenUpdatedData}
                                             label={t('commons:modules.webpro.self-admin.contact-person')}
                                             url={`/${currentWebProContract?.reference}/${currentPrimaryContract?.primaryContractId}/self-admin/contact`}/>;
            case SELFADMIN_CODES.CONTRACT_LB:
                return <SimpleLineValidation boxKey={index} update={update as ChildrenUpdatedData}
                                             label={t('commons:modules.webpro.self-admin.contract-label')}
                                             url={`/${currentWebProContract?.reference}/${currentPrimaryContract?.primaryContractId}/self-admin/contract`}/>;
            case SELFADMIN_CODES.RMV_USER:
                return <SimpleLineValidation boxKey={index} update={update as ChildrenUpdatedData}
                                             label={t('commons:modules.webpro.self-admin.remove-user')}
                                             showPartyInfo
                />;
            case SELFADMIN_CODES.LOG_USR:
                return <MulitpleLineValidation boxKey={index} update={update as ParentUpdatedData}
                                               label={t('commons:modules.webpro.self-admin.rights-and-permissions')}
                                               url={`/${currentWebProContract?.reference}/${currentPrimaryContract?.primaryContractId}/self-admin/users/user-detail/${(update as ParentUpdatedData).infoUser.id}`}/>;
            case SELFADMIN_CODES.LOG_CTR_MT_LIM_STD:
                return (
                  <SimpleLineValidation
                    boxKey={index}
                    update={update as ChildrenUpdatedData}
                    label={t(
                      'commons:modules.webpro.self-admin.payments-std-limit-label'
                    )}
                    url={`/${currentWebProContract?.reference}/${currentPrimaryContract?.primaryContractId}/self-admin/payments/standard-payment`}
                  />
                );
            case SELFADMIN_CODES.CONTRACT_LIM_STD:
                return (
                  <SimpleLineValidation
                    boxKey={index}
                    update={update as ChildrenUpdatedData}
                    label={t(
                      'commons:modules.webpro.self-admin.payments-slr-limit-label'
                    )}
                    url={`/${currentWebProContract?.reference}/${currentPrimaryContract?.primaryContractId}/self-admin/payments/standard-payment`}
                  />
                );
            case SELFADMIN_CODES.LOG_CTR_PFL_STD:
                return (
                    <SignatureRulesValidation
                        boxKey={index}
                        update={update as ParentUpdatedData}
                        label={t(
                            'commons:modules.webpro.self-admin.payments-std-signing-rules'
                        )}
                        url={`/${currentWebProContract?.reference}/${currentPrimaryContract?.primaryContractId}/self-admin/payments/standard-payment`}
                    />
                );
            case SELFADMIN_CODES.LOG_CTR_PAY_STD:
                return (
                    <SimpleLineValidation
                        boxKey={index}
                        update={update as ChildrenUpdatedData}
                        label={t(
                            'commons:modules.webpro.self-admin.payments-std-activated-label'
                        )}
                        url={`/${currentWebProContract?.reference}/${currentPrimaryContract?.primaryContractId}/self-admin/payments/standard-payment`}
                    />
                );
            case SELFADMIN_CODES.LOG_CTR_PAY_SLR:
                return (
                    <SimpleLineValidation
                        boxKey={index}
                        update={update as ChildrenUpdatedData}
                        label={t(
                            'commons:modules.webpro.self-admin.payments-slr-activated-label'
                        )}
                        url={`/${currentWebProContract?.reference}/${currentPrimaryContract?.primaryContractId}/self-admin/payments/standard-payment`}
                    />
                );
            case SELFADMIN_CODES.CONTRACT_PFL_STD:
                return (
                        <SimpleLineValidation
                            boxKey={index}
                            update={update as ChildrenUpdatedData}
                            label={t(
                                'commons:modules.webpro.self-admin.payments-std-signing-rules'
                            )}
                            url={`/${currentWebProContract?.reference}/${currentPrimaryContract?.primaryContractId}/self-admin/payments/standard-payment`}
                        />
                );
            case SELFADMIN_CODES.LOG_CTR_PAY_STD_1:
                return (
                  <SimpleLineValidation
                    boxKey={index}
                    update={update as ChildrenUpdatedData}
                    label={t(
                      'commons:modules.webpro.self-admin.payments-std-activation-label'
                    )}
                    url={`/${currentWebProContract?.reference}/${currentPrimaryContract?.primaryContractId}/self-admin/payments/standard-payment`}
                  />
                );
            case SELFADMIN_CODES.LOG_CTR_PAY_STD_0:
                return (
                  <SimpleLineValidation
                    boxKey={index}
                    update={update as ChildrenUpdatedData}
                    label={t(
                      'commons:modules.webpro.self-admin.payments-std-deactivation-label'
                    )}
                    url={`/${currentWebProContract?.reference}/${currentPrimaryContract?.primaryContractId}/self-admin/payments/standard-payment`}
                  />
                );
            case SELFADMIN_CODES.LOG_CTR_MT_LIM_SLR:
                return (
                  <SimpleLineValidation
                    boxKey={index}
                    update={update as ChildrenUpdatedData}
                    label={t(
                      'commons:modules.webpro.self-admin.payments-slr-limit-label'
                    )}
                    url={`/${currentWebProContract?.reference}/${currentPrimaryContract?.primaryContractId}/self-admin/payments/salary-payment`}
                  />
                );
            case SELFADMIN_CODES.CONTRACT_LIM_SLR:
                return (
                  <SimpleLineValidation
                    boxKey={index}
                    update={update as ChildrenUpdatedData}
                    label={t(
                      'commons:modules.webpro.self-admin.payments-slr-limit-label'
                    )}
                    url={`/${currentWebProContract?.reference}/${currentPrimaryContract?.primaryContractId}/self-admin/payments/salary-payment`}
                  />
                );
            case SELFADMIN_CODES.LOG_CTR_PFL_SLR:
                return (
                    <SignatureRulesValidation
                        boxKey={index}
                        update={update as ParentUpdatedData}
                        label={t(
                            'commons:modules.webpro.self-admin.payments-slr-signing-rules'
                        )}
                        url={`/${currentWebProContract?.reference}/${currentPrimaryContract?.primaryContractId}/self-admin/payments/salary-payment`}
                    />
                );
            case SELFADMIN_CODES.CONTRACT_PFL_SLR:
                return (
                  <SimpleLineValidation
                    boxKey={index}
                    update={update as ChildrenUpdatedData}
                    label={t(
                      'commons:modules.webpro.self-admin.payments-slr-signing-rules'
                    )}
                    url={`/${currentWebProContract?.reference}/${currentPrimaryContract?.primaryContractId}/self-admin/payments/salary-payment`}
                  />
                );
            case SELFADMIN_CODES.LOG_CTR_PAY_SLR_1:
                return (
                  <SimpleLineValidation
                    boxKey={index}
                    update={update as ChildrenUpdatedData}
                    label={t(
                      'commons:modules.webpro.self-admin.payments-slr-activation-label'
                    )}
                    url={`/${currentWebProContract?.reference}/${currentPrimaryContract?.primaryContractId}/self-admin/payments/salary-payment`}
                  />
                );
            case SELFADMIN_CODES.LOG_CTR_PAY_SLR_0:
                return (
                  <SimpleLineValidation
                    boxKey={index}
                    update={update as ChildrenUpdatedData}
                    label={t(
                      'commons:modules.webpro.self-admin.payments-slr-deactivation-label'
                    )}
                    url={`/${currentWebProContract?.reference}/${currentPrimaryContract?.primaryContractId}/self-admin/payments/salary-payment`}
                  />
                );

            default:
                return <></>;
        }
    }, [t, currentPrimaryContract, currentWebProContract]);

    const _handleOrelyCallback = useCallback(async (challengeId: string, samlResponse: string) => {
        try {
            setDialogOpen(false);

            await activateContract({
                signatureRequest: {
                    challengeId: challengeId,
                    answer: samlResponse
                }
            });

            await refetch();
        } catch (e) {
            /* display error */
        }
    }, [activateContract, refetch]);

    const isStep1Active = !isActivationLoading && !isActivationSuccess && !isCancelLoading && !isCancelSuccess && data;

    return (
        <Box paddingLeft={'10px'} paddingRight={'10px'}>

            {isActivationLoading &&
                <InnerPageBglSvgLoader message={t('commons:modules.webpro.self-admin.activation-in-progress')}/>
            }

            {isCancelLoading &&
                <InnerPageBglSvgLoader message={t('commons:modules.webpro.self-admin.reject-in-progress')}/>
            }

            {isActivationSuccess &&
                <ValidationSuccess/>
            }

            {isStep1Active && data?.updates?.length === 0 &&
                <ValidationSuccess nothing={true}/>
            }

            {isCancelSuccess &&
                <ValidationRejectSuccess/>
            }

            {isStep1Active && data?.updates?.length > 0 &&
                <>
                    <ModificationDetailedBanner updates={data}/>
                    {data?.updates?.map((update, index) => (
                        getComponents(update, index)
                    ))}
                    <RequirePermissions
                        permissions={[Permission.SELF_ADMIN_CANCELLATION, Permission.SELF_ADMIN_VALIDATION]} oneOf>
                        <>
                            <Box mt={12}>
                                <Stack>
                                    <GenericPaper
                                        actions={
                                            <Stack direction={'row'} spacing={8} justifyContent={'center'}
                                                   textAlign={'center'}
                                                   alignItems={'center'}>
                                                <RequirePermissions permissions={[Permission.SELF_ADMIN_CANCELLATION]}>
                                                    <Button variant={'contained'}
                                                            color={'error'}
                                                            size={'small'}
                                                            onClick={_handleCancelBtnClick}
                                                    >{t('commons:buttons.reject-updates')}</Button>
                                                </RequirePermissions>

                                                <RequirePermissions permissions={[Permission.SELF_ADMIN_VALIDATION]}>
                                                    <Button variant={'contained'} color={'primary'} size={'small'}
                                                            onClick={_handleValidateBtnClick}
                                                            disabled={data?.errors?.length > 0}>{t('commons:buttons.validate-updates')}</Button>
                                                </RequirePermissions>
                                            </Stack>
                                        }
                                    >
                                        <Typography variant={'h3'} color={'primary'} fontWeight={400} mb={8}>
                                            {t('commons:modules.webpro.self-admin.updates-validation')}
                                        </Typography>

                                        <Typography variant={'body1'} color={'textSecondary'} fontWeight={400}>
                                            {t('commons:modules.webpro.self-admin.update-validation-description')}
                                        </Typography>

                                    </GenericPaper>

                                </Stack>
                            </Box>

                            <GenericDialog maxWidth={'sm'}
                                           noPadding
                                           title={t('commons:modules.webpro.self-admin.labels.luxtrust-validation')}
                                           handleClose={() => setDialogOpen(false)}
                                           isOpen={dialogOpen}>
                                <OrelyValidation
                                    challengeContext={{operation: t('commons:modules.webpro.self-admin.luxtrust.context.activate-contract')}}
                                    callback={_handleOrelyCallback}/>
                            </GenericDialog>
                        </>
                    </RequirePermissions>
                </>
            }

        </Box>
    );
};

const ValidationSuccess = ({nothing}: React.PropsWithoutRef<{nothing?: boolean}>) => {
    const navigate = useNavigate();
    const {t} = useTranslation(['commons']);

    return (
        <Stack direction={'column'} alignItems={'center'} spacing={4} pt={20} pb={25}>
            <Box>
                <SuccessSvg width={160} height={160}/>
            </Box>
            <Stack direction={'column'} justifyItems={'center'} alignItems={'center'}>
                <Typography
                variant={'h4'}
                fontWeight={400}
                color={'primary'}
                gutterBottom>
                    {nothing ?
                        t('commons:modules.webpro.self-admin.no-updates-to-validate')
                        : t('commons:modules.webpro.self-admin.labels.activated')
                    }
                </Typography>
            </Stack>
            <Box pt={8}>
                <Button type={'submit'} onClick={() => navigate('../')} variant="contained"
                        color="primary" size={'medium'}>
                    {t('commons:buttons.back')}
                </Button>
            </Box>
        </Stack>

    );
};

const ValidationRejectSuccess = () => {
    const navigate = useNavigate();
    const {t} = useTranslation(['commons']);

    return (
        <Stack direction={'column'} alignItems={'center'} spacing={4} pt={20} pb={25}>
            <Box>
                <SuccessSvg width={160} height={160}/>
            </Box>
            <Stack direction={'column'} justifyItems={'center'} alignItems={'center'}>
                <Typography variant={'h4'} fontWeight={400} color={'primary'}
                            gutterBottom>{t('commons:modules.webpro.self-admin.labels.rejected')}</Typography>
            </Stack>
            <Box pt={8}>
                <Button type={'submit'} onClick={() => navigate('../')} variant="contained"
                        color="primary" size={'medium'}>
                    {t('commons:buttons.back')}
                </Button>
            </Box>
        </Stack>

    );
};

export default Validation;
