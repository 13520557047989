import {Subject, Subscription} from 'rxjs';


export enum EventName {
    DISCONNECTED = 'DISCONNECTED'
}

class EventBus {
    private subjects: { [key: string]: Subject<any> } = {};

    on<T>(eventName: EventName, callback: (data: T) => void): Subscription {
        if (!this.subjects[eventName]) {
            this.subjects[eventName] = new Subject<T>();
        }

        return this.subjects[eventName].subscribe(callback);
    }

    emit<T>(eventName: EventName, data: T): void {
        if (this.subjects[eventName]) {
            this.subjects[eventName].next(data);
        }
    }
}

const eventBus = new EventBus();

export default eventBus;
