import _ from 'lodash';
import {PewClientConfig} from '@bgl/lib-ts-integration';

export const init = () => {

    PewClientConfig.config.host = '/business_logon';
    PewClientConfig.config.appName = VITE_NAME;
    PewClientConfig.config.appVersion = VITE_VERSION;
    PewClientConfig.config.componentId = VITE_COMPONENT_ID;

    PewClientConfig.config.bglLogger['local-enable'] = true;
    PewClientConfig.config.bglLogger['remote-enable'] = false;
    PewClientConfig.config.bglLogger['log-level'] = 'INFO';
    PewClientConfig.config.bglLogger['client-logging-configuration-url'] = '/public/rest/client-logging/get';


    PewClientConfig.config.bglMonitor['monitor-url'] = '/rest/v1/monitoring/client-event/post';
    PewClientConfig.config.bglMonitor['monitor-pool-url'] = '/rest/v1/monitoring/client-event-list/post';
    PewClientConfig.config.bglMonitor['monitor-pool-activated'] = true;
    PewClientConfig.config.bglMonitor['pool-size'] = 10;

    PewClientConfig.config.bglMonitor['monitor-activated'] = true;

};

export type Config = {
    baseUrl?: string,

    appIdent?: string,
    appName?: string,
    appVersion?: string,
    appLanguage?: string,

    deviceId?: string,
    deviceName?: string,
    deviceType?: string,

    osName?: string,
    osVersion?: string,
    agentSender?: string,
    applicationCaller?: string
};

const _config: Config = {
    baseUrl: '', /* Only needed for RN (Mobile app). For web, relative path is used */

    appIdent: '',
    appName: '', /* ui-webbanking (WBK-001) / ui-mobilebanking (MBK-001) */
    appVersion: '',
    appLanguage: '', /* fr, en, de */

    deviceId: '',
    deviceName: '',
    deviceType: '', /* COMPUTER / SMARTPHONE / TABLET */

    osName: '', /* WINDOWS / IOS / ANDROID */
    osVersion: '', /* 7, 8 ...*/
    agentSender: '', /* Chrome 89.0 / Restkit / Edge / Retrofit */
    applicationCaller: ''
};

export const BglWebAndMobileConfig = {
    load: async (config: Config) => {
        return new Promise(resolve => {
            _.assign(_config, config);
            resolve(_config);
        });
    },
    config: () => _config,
    get: (key: string, defaultValue = 'DEFAULT_VALUE') => _.get(_config, key, defaultValue)
};

Object.freeze(BglWebAndMobileConfig);

