import React, {PropsWithChildren} from 'react';
import {Box} from "@mui/material";

const BodyToaster = ({children, title}: PropsWithChildren<{ title: string }>) => {
    return <React.Fragment>
        <Box className="Toastify__toast-body-title" component={'span'}>{title}</Box>
        <Box className="Toastify__toast-body-text" py={4}>{children}</Box>
    </React.Fragment>;
};

export default BodyToaster;
