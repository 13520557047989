import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from './api-utils';

const URL_API = '/business_webprofile/rest/v1/notificationPreferences';

export type NotificationsPreferences = {
    notificationConditionsAccepted: boolean;
    secureEmailManagement: SecureEmailManagement;
    preInstructionManagement: PreInstructionManagement;
    selfAdminManagement: SelfAdminManagement;
    signatureRequest: { type: string, challengeId: string, answer: string }
}

export type PreInstructionManagement = {
    creation: boolean;
    channels: NotificationChannel[];
}

export type SelfAdminManagement = {
    validation: boolean;
    activation: boolean;
    channels: NotificationChannel[];
}

export type SecureEmailManagement = {
    receiving: boolean;
    sending: boolean;
    channels: NotificationChannel[];
}

export enum NotificationChannel {
    PUSH = 'PUSH',
    EMAIL = 'EMAIL',
    SMS = 'SMS'
}

export const notificationApi = createApi({
    reducerPath: 'notificationApi',
    tagTypes: ['Preferences'],
    baseQuery: axiosBaseQuery({
        baseUrl: URL_API
    }),

    endpoints: (builder) => ({
        getPreferences: builder.query<NotificationsPreferences, void>({
            query: () => ({
                url: '/mbb/get',
                method: 'POST',
                data: {}
            }),
            providesTags: [{type: 'Preferences', id: 'SINGLE'}] /* SINGLE because preferences shared across all contracts, roots, etc. */
        }),
        savePreferences: builder.mutation<void, NotificationsPreferences>({
            query: data => ({
                url: '/mbb/save',
                method: 'POST',
                data
            }),
            invalidatesTags: [{type: 'Preferences', id: 'SINGLE'}] /* SINGLE because preferences shared across all contracts, roots, etc. */
        })
    })
});

export const {useGetPreferencesQuery, useSavePreferencesMutation} = notificationApi;
