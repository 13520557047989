import {PaymentConstraint} from '../../../redux/@types/SelfAdminTypes';
import {t} from 'i18next';

export const validatePaymentConstraint = (paymentConstraint: PaymentConstraint) => {
    const errors = [];
    const globalAmount = paymentConstraint?.maxAmount?.nb && paymentConstraint?.maxAmount.nb > 0 ? paymentConstraint?.maxAmount.nb : -1;

    const maxAmount = Math.max.apply(
        null,
        paymentConstraint?.signatureProfile
            .map((profile) => profile?.maxAmount?.nb ?? -1 as number)
            .flat()
    );
    if (globalAmount !== -1 && maxAmount > globalAmount) {
        errors.push(t('commons:modules.webpro.self-admin.payments-maximum-treshold-error-label'));
    }

    return errors;
};
