import { Box, Drawer, Link, Skeleton, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useMatch } from 'react-router-dom';
import { useAppBarHeight } from '../../../hooks/useAppBarHeight';
import { useMenuWidth } from '../../../hooks/useMenuWidth';
import PrimaryContracts from '../../menu/components/PrimaryContracts/PrimaryContracts';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { InnerPageBglSvgLoader, b64waves } from '../../../assets/Images';
import CitylineSvg from '../../../assets/images/commons/cityHigh.svg';
import { RootContext } from '../../../context/root-context';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { useCurrentPrimaryContract, useCurrentWebProContract } from '../../../hooks/useCurrentPrimaryContract';
import { useFooterHeight } from '../../../hooks/useFooterHeight';
import { PrimaryContract, WebProContract } from '../../../redux/@types/WebProContractTypes';
import { useGetFeaturesQuery } from '../../../redux/api/featureApiRtk';
import { useGetWebProContractsQuery } from '../../../redux/api/webProContractApiRtk';
import FeedbackDialog from '../../dashboard/FeedbackDialog';

const urlPattern = '/:webproContract/:primaryContract/*';

const DrawerContent = ({menuComponent}: { menuComponent: React.ReactNode }) => {

    const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
    const {t} = useTranslation(['commons']);
    const {pushAnalytics} = useAnalytics();

    const _openFeedbackDialog = () => {
        pushAnalytics({
            rule: 'start_feedback_tracking_rule',
            actionName: 'start_feedback',
            buttonName: 'start_feedback_button',
            sequenceName: 'feedback'
        });
        setOpenFeedbackDialog(true);
    };

    return (

        <Stack spacing={4} height={'100%'}>

            <PrimaryContracts/>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%'
            }}>
            {menuComponent}
            <Box sx={{width: '100%'}}>
                <FeedbackDialog opened={openFeedbackDialog} onClose={() => setOpenFeedbackDialog(false)}/>
                <Box sx={{
                    textAlign: 'center',
                    height: '100px',
                    backgroundImage: `url(${CitylineSvg})`,
                    backgroundSize: 'cover'
                }}>
                    <Box pt={'50px'}>
                    <Link variant={'button'} sx={{cursor: 'pointer', margin: 'auto'}}
                          onClick={() => _openFeedbackDialog()}>{t('commons:modules.webpro.rating.link')}</Link>
                    </Box>
                </Box>
            </Box>
            </Box>
        </Stack>
    );
};

const WebProConnectedLayout = ({menuComponent}: { menuComponent: React.ReactNode }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const matches = useMatch(urlPattern);
    const {t} = useTranslation(['commons']);

    const [ready, setReady] = useState(false);

    const appBarHeight = useAppBarHeight();
    const footerHeight = useFooterHeight();

    const appMenuWidth = useMenuWidth();

    const {drawerOpened, setDrawerOpened} = useContext(RootContext);
    const [ihub, setIhub] = useState(false);

    const handleDrawerToggle = () => {
        setDrawerOpened(!drawerOpened);
    };

    useEffect(() => {
        setIhub(location.pathname.endsWith('ihub'));
    }, [setIhub, location]);

    const [currentWebProContract, setCurrentWebProContract] = useCurrentWebProContract();
    const [currentPrimaryContract, setCurrentPrimaryContract] = useCurrentPrimaryContract();

    const {data: webProContracts} = useGetWebProContractsQuery();

    const {data: activeFeatures, isFetching: isFetchingFeatures} = useGetFeaturesQuery({
        webproContractRef: currentWebProContract?.reference,
        primaryContractRef: currentPrimaryContract?.primaryContractId
    }, {skip: !currentWebProContract || !currentPrimaryContract, refetchOnMountOrArgChange: true});

    const primaryContractList = currentWebProContract?.primaryContracts;

    /*useEffect(() => {
        setReady(false);
        if (webProContracts && currentWebProContract && currentPrimaryContract) {
            const webProContract = webProContracts?.find(c => c.reference === matches?.params['webproContract'] as string);
            setCurrentWebProContract(webProContract as WebProContract);
            const primaryContract = currentWebProContract?.primaryContracts?.find(p => p.primaryContractId === matches?.params['primaryContract'] as string);
            setCurrentPrimaryContract(primaryContract as PrimaryContract);
            const b = location.pathname.match(/^\/redirect\/dashboard/);
            if (b) {
                navigate(`/${currentWebProContract?.reference}/${currentPrimaryContract?.primaryContractId}/dashboard`, {replace: true});
            } else {
                setReady(true);
            }
        }
    }, [webProContracts]);*/


    useEffect(() => {
        setReady(false);
        if (webProContracts) {
            if (currentWebProContract && currentPrimaryContract) {
                if (activeFeatures && !isFetchingFeatures) {
                    const b = location.pathname.match(/^\/redirect\/dashboard/);
                    if (b) {
                        navigate(`/${currentWebProContract?.reference}/${currentPrimaryContract?.primaryContractId}/dashboard`, {replace: true});
                    } else {
                        setReady(true);
                    }
                }
            } else {
                if (matches && currentWebProContract?.reference !== matches.params['webproContract']) {
                    const webProContract = webProContracts?.find(c => c.reference === matches.params['webproContract'] as string);
                    setCurrentWebProContract(webProContract as WebProContract);
                }
                if (matches && currentPrimaryContract?.primaryContractId !== matches.params['primaryContract']) {
                    const primaryContract = currentWebProContract?.primaryContracts?.find(p => p.primaryContractId === matches.params['primaryContract'] as string);
                    setCurrentPrimaryContract(primaryContract as PrimaryContract);
                }

                if (activeFeatures && !isFetchingFeatures) {
                    setReady(true);
                }
            }
        }
    }, [currentPrimaryContract, primaryContractList, navigate, matches, location.pathname, setCurrentPrimaryContract, currentWebProContract, webProContracts, setCurrentWebProContract, activeFeatures, isFetchingFeatures]);


    return (
        ready ? <>
                <Box component="nav"
                     sx={{
                         width: {xs: appMenuWidth},
                         flexShrink: {xs: 0}
                     }}
                     aria-label="menu">
                    <Drawer
                        variant="temporary"
                        open={drawerOpened}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: {xs: 'block', md: 'none'},
                            '& .MuiDrawer-paper': {boxSizing: 'border-box', width: appMenuWidth},
                        }}
                    >

                        <DrawerContent menuComponent={menuComponent}/>
                    </Drawer>

                    <Drawer variant={'persistent'}
                            sx={{
                                display: {xs: 'none', md: 'block'},

                                '& .MuiDrawer-paper': {
                                    width: appMenuWidth,
                                    boxSizing: 'border-box',
                                    border: 'none',
                                    boxShadow: '3px 0px 15px #91C3B727',
                                    top: `${appBarHeight}px`, height: `calc(100% - ${appBarHeight}px)`
                                }
                            }}
                            open={!ihub}>

                        <DrawerContent menuComponent={menuComponent}/>
                    </Drawer>
                </Box>
                <Box
                    sx={{
                        ...!ihub && {
                            width: {xs: '100%', md: `calc(100% - ${appMenuWidth}px)`},
                            marginLeft: {md: `${appMenuWidth}px`}
                        },
                        flexGrow: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        minHeight: `calc(100vh - ${appBarHeight + footerHeight + 1}px)`,
                        background: 'transparent linear-gradient(310deg, #FDFFFD 0%, #F2F8F7 100%) 0% 0% no-repeat',
                        paddingBottom: '40px'
                    }}
                >
                    <Outlet/>
                </Box>
            </>
            :
            <>
                <Box component="nav"
                     sx={{
                         width: {xs: appMenuWidth},
                         flexShrink: {xs: 0}
                     }}
                     aria-label="menu">
                    <Drawer
                        variant="temporary"
                        open={drawerOpened}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: {xs: 'block', md: 'none'},
                            '& .MuiDrawer-paper': {boxSizing: 'border-box', width: appMenuWidth},
                        }}
                    >

                        <DrawerContent menuComponent={<MenuSkeleton/>}/>
                    </Drawer>

                    <Drawer variant={'persistent'}
                            sx={{
                                display: {xs: 'none', md: 'block'},

                                '& .MuiDrawer-paper': {
                                    width: appMenuWidth,
                                    boxSizing: 'border-box',
                                    border: 'none',
                                    boxShadow: '3px 0px 15px #91C3B727',
                                    top: `${appBarHeight}px`, height: `calc(100% - ${appBarHeight}px)`
                                }
                            }}
                            open={!ihub}>

                        <DrawerContent menuComponent={<MenuSkeleton/>}/>
                    </Drawer>
                </Box>
                <Box
                    sx={{
                        ...!ihub && {
                            width: {xs: '100%', md: `calc(100% - ${appMenuWidth}px)`},
                            marginLeft: {md: `${appMenuWidth}px`}
                        },
                        flexGrow: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        minHeight: `calc(100vh - ${appBarHeight + footerHeight + 1}px)`,
                        background: 'transparent linear-gradient(310deg, #FDFFFD 0%, #F2F8F7 100%) 0% 0% no-repeat',
                        paddingBottom: '40px'
                    }}
                >
                    <Stack width={'100%'}>
                        <Box sx={{
                            backgroundImage: `url(data:image/svg+xml;base64,${b64waves}), linear-gradient(262deg, #6FD67C 0%, #00915A 100%)`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            height: {
                                xs: '192px',
                                sm: '192px',
                                lg: '192px'
                            },
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            backgroundPosition: 'right'
                        }}>
                        </Box>

                        <InnerPageBglSvgLoader message={t('commons:labels.loading')}/>

                    </Stack>

                </Box>
            </>
    );
};

const MenuSkeleton = () => {
    return (
        <Stack spacing={6} pt={6}>
            {[1, 2, 3, 4, 5, 6].map((idx) =>
                <Stack key={idx} direction={'row'} width={'100%'} spacing={4} px={7} alignItems={'center'}>
                    <Skeleton variant={'circular'} height={30} width={30}></Skeleton>
                    <Skeleton variant={'text'} height={30} width={'80%'}></Skeleton>
                </Stack>
            )
            }
        </Stack>
    );
};

export default WebProConnectedLayout;
