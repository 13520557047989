import React, {Suspense} from 'react';
import {b64waves, FullPageBglSvgLoader, InnerPageBglSvgLoader} from '../assets/Images';
import {Box, Stack} from '@mui/material';
import {useTranslation} from 'react-i18next';

const FallbackInner = () => {
    const {t} = useTranslation(['commons']);

    return (
        <Stack width={'100%'}>
            <Box sx={{
                backgroundImage: `url(data:image/svg+xml;base64,${b64waves}), linear-gradient(262deg, #6FD67C 0%, #00915A 100%)`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                height: {
                    xs: '192px',
                    sm: '192px',
                    lg: '192px'
                },
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                backgroundPosition: 'right'
            }}>
            </Box>

            <InnerPageBglSvgLoader message={t('commons:labels.loading')}/>

        </Stack>
    );
};

const CodeSplit = ({children, inner = false}: React.PropsWithChildren<{ inner?: boolean }>): React.ReactNode =>
    <Suspense fallback={inner ? <FallbackInner/> : <FullPageBglSvgLoader/>}>{children}</Suspense>;

export default CodeSplit;
