import React from 'react';
import {Stack} from '@mui/system';
import DateField from '../../Form/DateField';


export type FieldRange = {
    type: 'date' | 'number',
    label: string,
    field: string,
    canSearchInFuture?: boolean
};

export type RangeFieldType = {
    type: string,
    field: string,
    label: string,
    fieldFrom: FieldRange,
    fieldTo: FieldRange
};

const DateRangeField = (
    {field, advancedKeys, checkbox, handleAdvancedKeys} :
    {field: RangeFieldType, advancedKeys: any, checkbox: React.ReactElement, handleAdvancedKeys: Function}
) => {
    const MINIMAL_YEAR = 1000;

    const _handleDateFieldChange = (id: string, value: Date | null) => {
        const name = id.replace('From', '').replace('To', '');
        const validValue = value !== null && !isNaN(value.getTime()) && value.getFullYear() > MINIMAL_YEAR;
        const fromDateIsSet = advancedKeys[name+'From'] && !isNaN(advancedKeys[name+'From'].getTime());
        const toDateIsSet = advancedKeys[name+'To'] && !isNaN(advancedKeys[name+'To'].getTime());

        switch (id) {
            case name+'From':
                if (toDateIsSet) {
                    handleAdvancedKeys({
                        ...advancedKeys,
                        [name+'Checked']: value !== null && validValue && value <= advancedKeys[name+'To'],
                        [name+'From']: value
                    });
                } else {
                    handleAdvancedKeys({
                        ...advancedKeys,
                        [name+'Checked']: value !== null && validValue,
                        [name+'From']: value
                    });
                }
                break;
            case name+'To':
                if (fromDateIsSet) {
                    handleAdvancedKeys({
                        ...advancedKeys,
                        [name+'Checked']: value !== null && validValue && value >= advancedKeys[name+'From'],
                        [name+'To']: value
                    });
                } else {
                    handleAdvancedKeys({
                        ...advancedKeys,
                        [name+'Checked']: value !== null && validValue,
                        [name+'To']: value
                    });
                }
                break;
            default:
        }
    };

    return (
        <Stack direction={'row'} alignItems={'center'} spacing={6} mb={4}>
            {field.fieldFrom.canSearchInFuture ?
                <DateField
                    key={field.fieldFrom.field}
                    label={field.fieldFrom.label}
                    value={(advancedKeys[field.fieldFrom.field])}
                    onChange={datePickerValue => {
                        _handleDateFieldChange(field.fieldFrom.field, datePickerValue);
                    }}
                /> :
                <DateField
                    key={field.fieldFrom.field}
                    label={field.fieldFrom.label}
                    value={(advancedKeys[field.fieldFrom.field])}
                    onChange={datePickerValue => {
                        _handleDateFieldChange(field.fieldFrom.field, datePickerValue);
                    }}
                    maxDate={(advancedKeys[field.fieldTo.field])}
                />
            }


            <DateField
                key={field.fieldTo.field}
                label={field.fieldTo.label}
                value={(advancedKeys[field.fieldTo.field])}
                onChange={datePickerValue => {
                    _handleDateFieldChange(field.fieldTo.field, datePickerValue);
                }}
                minDate={(advancedKeys[field.fieldFrom.field])}
            />

            {checkbox}
        </Stack>
    );
};

export default DateRangeField;
