import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from './api-utils';
import {
    AmountHistory,
    AmountHistoryRequest,
    AmountHistoryResponse,
    AssetHistoryDetailed,
    AssetHistoryDetailedResponse,
    ContractDetailsRequest,
    CreditCardMovement,
    CreditCardMovementList,
    CreditCardPeriod,
    CreditCardPeriodList,
    FamiliesRequest,
    Family,
    FamilyList,
    GetCreditCardMovementsRequest,
    MovementsRequest
} from '../@types/ContractInfoTypes';

const URL_API = '/business_webctrinfo/rest/v1';

export const getPrintRibUrl = (primaryContractId: string, contractReference: string, webProContractId: string) => `${URL_API}/synthesis/rib/print?primaryContractId=${primaryContractId}&contractReference=${contractReference}&webProContractId=${webProContractId}`;

export const contractInfoApi = createApi({
    reducerPath: 'contractInfoApi',
    baseQuery: axiosBaseQuery({
        baseUrl: URL_API
    }),
    endpoints: (builder) => ({
        getFamilies: builder.query<Array<Family>, FamiliesRequest>({
            query: (request) => ({
                url: '/synthesis/families/get',
                method: 'POST',
                data: request
            }),
            transformResponse: (response: FamilyList) => response.familyList
        }),
        getMovements: builder.query<AssetHistoryDetailed[], MovementsRequest>({
            query: (request) => ({
                url: '/asset-histories/movements/listWithPagination',
                method: 'POST',
                data: request
            }),
            transformResponse: (response: AssetHistoryDetailedResponse) => response.assetHistoryDetailedList
        }),
        getAmountHistory: builder.query<AmountHistory[], AmountHistoryRequest>({
            query: (request) => ({
                url: '/asset-histories/movements/amountHistoryList',
                method: 'POST',
                data: request
            }),
            transformResponse: (response: AmountHistoryResponse) => response.amountHistoryList
        }),
        details: builder.query<any, ContractDetailsRequest>({
            query: (request) => ({
                url: '/synthesis/details/' + request.urlEnd,
                method: 'POST',
                data: request.data
            })
        }),
        getCreditCardPeriods: builder.query<CreditCardPeriod[], { addAllPeriod: boolean, label: string }>({
            query: (request) => ({
                url: '/cards/periods/list',
                method: 'POST'
            }),
            keepUnusedDataFor: 60,
            transformResponse: (response: CreditCardPeriodList, meta, arg) => {
                const periods = response.creditCardPeriodList;
                const {addAllPeriod, label} = arg ?? {};
                if (addAllPeriod) {
                    periods.push({
                        endDate: response.creditCardPeriodList[0].endDate,
                        label: label,
                        startDate: response.creditCardPeriodList[response.creditCardPeriodList.length - 1].startDate,
                        withdrawalDate: ''
                    });
                }
                return periods;
            }
        }),
        getCreditCardMovements: builder.query<CreditCardMovement[], GetCreditCardMovementsRequest>({
            query: (request) => ({
                url: '/cards/movements/list',
                method: 'POST',
                data: request
            }),
            keepUnusedDataFor: 60,
            transformResponse: (response: CreditCardMovementList) => response.creditCardMovementList
        }),
        printAllCreditCardsMovements: builder.query<CreditCardMovement[], GetCreditCardMovementsRequest>({
            query: (request) => ({
                url: '/creditCards/synthesis/print',
                method: 'GET',
                data: request
            })
        })
    })
});

export const {
    useGetFamiliesQuery,
    useGetMovementsQuery,
    useGetAmountHistoryQuery,
    useDetailsQuery,
    useGetCreditCardPeriodsQuery,
    useGetCreditCardMovementsQuery,
} = contractInfoApi;
