import React from 'react';
import {Box, Paper, Stack, styled, Typography, useMediaQuery, useTheme} from '@mui/material';
import {PaperProps} from '@mui/material/Paper/Paper';
import GradientDivider from '../../_commons/components/Divider/GradientDivider';

const PaperStyled = styled(Paper)<PaperProps>(({theme}) => ({
    flexGrow: 0,
    boxShadow: '0px 5px 12px #A7C7B31C',
    border: '1px solid #E1F4EE',
    borderRadius: '10px',
}));

type ScreenWrapperProps = React.PropsWithChildren<{
    header: React.ReactNode,
    left?: React.ReactNode,
    headerLeft?: React.ReactNode,
    maxWidth?: number | string,
    marginTop?: number | string,
    stickyTop?: number | string,
    height?: Object,
    actions?: React.ReactNode
}>;

export const ScreenWrapperTitle = ({title}: { title: string }) => {
    return (
        <Typography
            variant="h2"
            sx={{
                textShadow: '0px 1px 1px #749A84',
                textTransform: 'uppercase',
                color: '#26B144',
                letterSpacing: '1.8px',
                fontWeight: {xs: 100},
                fontSize: {md: '1.95rem', xs: '1rem'}
            }}>
            {title}
        </Typography>
    );
};

const ScreenWrapper = ({
                           children,
                           header,
                           left,
                           headerLeft,
                           maxWidth,
                           height,
                           marginTop,
                           stickyTop,
                           actions
                       }: ScreenWrapperProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.only('xs'));

    return (
        <Stack justifyContent={'center'}
               sx={{marginTop: isSmall ? 0 : marginTop ?? '-65px', ...maxWidth && {maxWidth: maxWidth}}}alignItems={{xs: 'center', sm:'initial'}}
               direction={{xs: 'column', sm: 'row'}}
               gap={'2rem'} marginX={'auto'}>

            {left && <Stack width={'100%'} maxWidth={{xs: '100%', sm:250}} zIndex={{xs: 0, sm: 2}}>
                <PaperStyled sx={{
                    position: 'sticky',
                    zIndex: 1,
                    top: stickyTop ?? '192px',
                }}>
                    <Box py={4} px={{xs: 6, sm: 12}} sx={{
                        borderRadius: '10px 10px 0 0',
                        background: '#fff linear-gradient(90deg, #E9F6F0 0%, #F2FBF3 100%) 0% 0% no-repeat padding-box'
                    }}>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            {headerLeft}
                        </Stack>
                    </Box>

                    <GradientDivider orientation="horizontal" sx={{position: 'relative'}}/>

                    <Stack mt={0}>
                        {left}
                    </Stack>
                </PaperStyled>

            </Stack>}


            <Stack width={'100%'} position={'relative'}>
                <Box sx={{
                    position: 'sticky',
                    zIndex: 2,
                    top: stickyTop ?? '192px',
                    height: height ?? {sm: '62px', xs: '55px'},
                    boxShadow: '0px 5px 12px #A7C7B31C',
                }}>
                    <Box py={4} px={{xs: 6, sm: 12}} sx={{
                        borderRadius: '10px 10px 0 0',
                        height: height ?? {sm: '63px', xs: '55px'},
                        background: '#fff linear-gradient(90deg, #E9F6F0 0%, #F2FBF3 100%) 0% 0% no-repeat padding-box'
                    }} width={'100%'} display={'flex'} alignItems={'center'}>
                        {header}
                    </Box>

                    <GradientDivider orientation="horizontal" sx={{position: 'relative'}}/>

                </Box>

                <Stack mt={0}>
                    <PaperStyled sx={{
                        borderTopRightRadius: 0,
                        borderTopLeftRadius: 0
                    }}>

                        {children}
                    </PaperStyled>

                </Stack>
                <Box textAlign={'center'} position={'absolute'} width={'100%'} bottom={'-16px'}>
                    <Stack direction={'row'} justifyContent={'center'} spacing={12}>
                        {actions && actions}
                    </Stack>
                </Box>
            </Stack>


        </Stack>
    );
};

export default ScreenWrapper;
