import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from './api-utils';

const URL_API = '/business_publiccontact/rest';

export const publicContactApi = createApi({
    reducerPath: 'publicContactApi',
    baseQuery: axiosBaseQuery({
        baseUrl: URL_API
    }),

    endpoints: (builder) => ({
        getBusinessHolidays: builder.query<string[], void>({
            query: request => ({
                url: '/v1/agencies/businessHolidays',
                method: 'POST',
                data: request
            })
        })
    })
});

export const {useGetBusinessHolidaysQuery} = publicContactApi;
