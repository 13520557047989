import {
    PaymentConstraint,
} from '../../../redux/@types/SelfAdminTypes.ts';

export enum ActionKind {
    ACTION_ADD_PROFILE = 'ADD_PROFILE',
    ACTION_REMOVE_PROFILE = 'REMOVE_PROFILE',
    ACTION_EDIT_MAX_AMOUNT = 'EDIT_MAX_AMOUNT',
    ACTION_EDIT_AMOUNT = 'EDIT_AMOUNT',
    ACTION_EDIT_ALLOWED = 'EDIT_ALLOWED',
    ACTION_RESET_STATE = 'RESET_STATE'
};

type ActionAddProfile = {
    type: ActionKind.ACTION_ADD_PROFILE,
    payload: {
        profileList: string,
        amount?: number,
    }
};

type ActionRemoveProfile = {
    type: ActionKind.ACTION_REMOVE_PROFILE,
    payload: {
        profileList: string
    }
};

type ActionEditMaxAmount = {
    type: ActionKind.ACTION_EDIT_MAX_AMOUNT,
    payload: {
        amount: number | null
    }
};

type ActionEditAmount = {
    type: ActionKind.ACTION_EDIT_AMOUNT,
    payload: {
        index: number,
        amount: number | null
    }
};

type ActionEditAllowed = {
    type: ActionKind.ACTION_EDIT_ALLOWED
};

type ActionResetState = {
    type: ActionKind.ACTION_RESET_STATE,
    payload: {
        initialState: PaymentConstraint
    }
};

type Action = ActionAddProfile | ActionRemoveProfile | ActionEditMaxAmount | ActionEditAmount | ActionEditAllowed | ActionResetState;

export const reducer = (prevState: PaymentConstraint, action: Action): PaymentConstraint => {
    const prevSignatureProfile = prevState?.signatureProfile ? [...prevState.signatureProfile] : [];

    switch (action.type) {
        case ActionKind.ACTION_ADD_PROFILE: {
            return {...prevState, signatureProfile: [...prevState.signatureProfile, {
                    requiredTransactionProfiles: action.payload.profileList.split(','),
                    maxAmount: {nb: action.payload?.amount ?? null, currencyRef: 'EUR'}
            }]};
        }
        case ActionKind.ACTION_REMOVE_PROFILE: {
            const index = prevSignatureProfile.findIndex(profile => {
                return profile.requiredTransactionProfiles.length === action.payload.profileList.split(',').length &&
                    profile.requiredTransactionProfiles.every(
                        (el, tpIndex) => el === action.payload.profileList.split(',')[tpIndex]
                    );
            });

            if (index === -1) {
                return prevState;
            }

            prevSignatureProfile.splice(index, 1);

            return {...prevState, signatureProfile: [...prevSignatureProfile]};
        }
        case ActionKind.ACTION_EDIT_MAX_AMOUNT: {
            return {...prevState, maxAmount: {nb: action.payload?.amount ?? 0, currencyRef: 'EUR'}};
        }
        case ActionKind.ACTION_EDIT_AMOUNT: {
            prevSignatureProfile[action.payload.index] = {
                ...prevSignatureProfile[action.payload.index],
                maxAmount: {
                    nb: action.payload.amount && action.payload.amount > 0 ? action.payload.amount : null,
                    currencyRef: 'EUR'
                }
            };

            return {...prevState, signatureProfile: [...prevSignatureProfile]};
        }
        case ActionKind.ACTION_EDIT_ALLOWED: {
            return {...prevState, isAllowed: !prevState.isAllowed};
        }
        case ActionKind.ACTION_RESET_STATE: {
            return action.payload?.initialState ?? prevState;
        }
        default: {
            return prevState;
        }
    };
};
