import {Badge, Button, styled} from '@mui/material';
import {useMemo, useState} from 'react';
import NotificationsDialog from './NotificationsDialog.tsx';
import {useGetPreInstructionCountQuery, useGetSOPreInstructionCountQuery} from '../../redux/api/paymentProApiRtk.ts';
import {PreInstructionListRequest} from '../../redux/@types/PaymentProTypes.ts';
import {useGetContractUpdatesQuery} from '../../redux/api/selfAdminApiRtk.ts';
import {useGetUnreadImportantMailCountQuery} from '../../redux/api/messagingApiRtk.ts';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import {useHasOneOfPermission} from '../../hooks/useWebProPermissions.ts';
import {Permission} from '../../redux/@types/WebProContractTypes.ts';

const NotifButton = styled(Button, {
    shouldForwardProp: (propName) => propName !== 'active'
})<Pick<{ active: boolean }, 'active'>>(({theme, active}) => ({
    padding: '4px 20px',
    borderRadius: '23px',
    float: 'right',
    color: '#1B985D',
    border: '1px solid #C3EBDA',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 4px #A7C7B340',
    '&:hover': {
        background: '#FFFFFF 0% 0% no-repeat padding-box'
    },

    ...active && {
        background: 'transparent linear-gradient(242deg, #FFC479 0%, #FF7D41 100%) 0% 0% no-repeat padding-box',
        boxShadow: '0 3px 6px #FA475B58',
        color: '#FFFF',
        border: 'none',
        '&:hover': {
            background: 'transparent linear-gradient(242deg, #fbd19b 0%, #fb8955 100%) 0 0 no-repeat padding-box'
        },
    }
}));

const NotificationsButton = ({contractRef, primaryContract}: {
    contractRef: string | undefined,
    primaryContract: string | undefined
}) => {
    const [isOpenNotificationDialog, setIsOpenNotificationDialog] = useState(false);

    const buildDocumentQuery: PreInstructionListRequest = useMemo(() => {
        return  {
            limit: 50,
            page: 1,
            order: {
                orderBy: 'executionDate',
                ordering: 'ASCENDING'
            },
            contractReference: contractRef ?? '',
            primaryContracts: [primaryContract ?? ''],
            excludeCancelled: true,
            excludeValidated: true,
            couldSign: true
        };
    }, [contractRef, primaryContract]);

    const canSendRequest = useHasOneOfPermission([Permission.PAYMENT_PRE_INSTRUCTION_DISPLAY]);

    const {data: preInstructionCount} = useGetPreInstructionCountQuery(buildDocumentQuery, {skip: !contractRef || !canSendRequest});
    const {data : soPreInstructionCount} = useGetSOPreInstructionCountQuery(buildDocumentQuery, {skip: !contractRef  || !canSendRequest});
    const {data: contractUpdates} = useGetContractUpdatesQuery(void (0), {skip: !contractRef});
    const {data: nbImportantMails} = useGetUnreadImportantMailCountQuery({
        folder: 'Inbox',
        unreadOnly: true,
        searchRequest: ''
    }, {skip: !contractRef});

    const nbNotifications = useMemo(() => {
        let nb = 0;
        if (nbImportantMails?.totalCount ?? -1 > 0) {
            nb += 1;
        }
        if (contractUpdates?.updateNumber ?? -1 > 0) {
            nb += 1;
        }
        if (preInstructionCount?.count ?? -1 > 0) {
            nb += 1;
        }
        if (soPreInstructionCount?.count ?? -1 > 0) {
            nb += 1;
        }
        return nb;
    }, [nbImportantMails?.totalCount, contractUpdates?.updateNumber, preInstructionCount?.count, soPreInstructionCount?.count]);

    return (
        <>
            <Badge badgeContent={nbNotifications} color={'warning'} overlap={'circular'}
                   slotProps={{badge: {style: {top: '15%', right: '4%'}}}}>
                <NotifButton active={nbNotifications > 0} variant="contained" size="small"
                             onClick={() => setIsOpenNotificationDialog(true)}>
                    <NotificationsNoneIcon sx={{fontSize: 24}}/>
                </NotifButton>
            </Badge>

            <NotificationsDialog isOpen={isOpenNotificationDialog}
                                 nbNotifications={nbNotifications}
                                 rfExtCtr={contractRef}
                                 rfPmrCtr={primaryContract}
                                 nbMsg={nbImportantMails?.totalCount ?? 0}
                                 nbSelfAdmin={contractUpdates?.updateNumber ?? 0}
                                 nbToSign={preInstructionCount?.count ?? 0}
                                 nbSOToSign={soPreInstructionCount?.count ?? 0}
                                 handleClose={() => setIsOpenNotificationDialog(false)}/>
        </>
    );
};

export default NotificationsButton;
