import React, {useCallback} from 'react';
import {Stack} from '@mui/material';
import {OrelyFrameV2} from '../OrelyFrame.tsx';
import {ChallengeContext} from '../../../../../redux/@types/AuthentTypes.ts';
import {useGetSignatureChallengeQuery} from '../../../../../redux/api/customerAuthentApiRtk.ts';
import {isValidBase64} from "../../../../../utils/base64.ts";
import {BglLogger} from "@bgl/lib-ts-integration";

const OrelyValidation = ({children, challengeContext, callback, jsonStructure = false}: React.PropsWithChildren<{
    challengeContext: ChallengeContext,
    callback: (challengeId: string, samlResponse: string) => void,
    jsonStructure?: boolean
}>) => {
    const {data} = useGetSignatureChallengeQuery({
        challengeContext
    }, {skip: !challengeContext});

    const _receiveMessage = useCallback(async (event: MessageEvent) => {
        const logger = BglLogger.getInstance();
        const isDev = import.meta.env.DEV;
        if (!isDev && event.origin.indexOf('lu.fortis.bank') === -1 && event.origin.indexOf('bgl.lu') === -1) {
            /* Nothing to do */
        } else if (!event.data.source && data?.challengeId) {
            if (isValidBase64(event.data)) {
                const samlResponse = event.data;

                const answer = JSON.stringify({
                    tokens: [{
                        securityToken: samlResponse
                    }]
                });

                callback(data?.challengeId, jsonStructure ? answer : samlResponse);
            } else {
                logger.warn('event data format received not expected : ' + event.data);
            }
        }
    }, [callback, data, jsonStructure]);

    return (
        <Stack width={"100%"}>
            {children &&
                <>{children}</>
            }
            {data?.identityProviderURL &&

                <OrelyFrameV2 receiveMessage={_receiveMessage}
                              styling={{width: '100%', height: '500px', border: '0px'}}>
                    <form id={'samlForm'} name={'samlForm'} action={data.identityProviderURL}
                          method={'POST'}>
                        <input type={'hidden'} name="SAMLRequest" value={data.samlRequest}/>
                    </form>
                </OrelyFrameV2>

            }
        </Stack>
    );
};

export default OrelyValidation;
