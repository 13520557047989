import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import commons from './locales/fr/commons.json';
import authentication from './locales/fr/authentication.json';

import authenticationEn from './locales/en/authentication.json';
import commonsEn from './locales/en/commons.json';

import authenticationDe from './locales/de/authentication.json';
import commonsDe from './locales/de/commons.json';

export const defaultNS = 'commons';
export const resources = {
    fr: {
        commons, authentication
    },
    en: {
        commons: commonsEn,
        authentication: authenticationEn
    },
    de: {
        commons: commonsDe,
        authentication: authenticationDe
    }
} as const;

i18n
    .use(initReactI18next)
    /*.use(HttpApi)*/
    .init({
        defaultNS,
        debug: true,
        ns: ['commons', 'authentication'],
        fallbackLng: 'fr',
        load: 'languageOnly',
        interpolation: {
            escapeValue: false
        },
        resources
    });

export default i18n;
