import React from 'react';
import DashedPaper from '../../_commons/components/Paper/DashedPaper';
import {Box, List, ListItem, Paper, Stack, styled, Typography} from '@mui/material';
import {PaperProps} from '@mui/material/Paper/Paper';
import FormattedDateTime from '../../_commons/components/FormattedText/FormattedDateTime';
import {ContractUpdatedResult} from '../../../redux/@types/SelfAdminTypes';
import WarningIcon from '../../../assets/images/commons/warning.svg?react';
import {useTranslation} from 'react-i18next';

const PaperStyled = styled(Paper)<PaperProps>(({theme}) => ({
    flexGrow: 0,
    boxShadow: '0px 5px 12px #A7C7B31C',
    border: '1px solid #E1F4EE',
    borderRadius: '10px'
}));

const ModificationDetailedBanner = ({updates}: { updates?: ContractUpdatedResult }) => {
    const {t} = useTranslation(['commons']);

    return (
        updates ?
            <PaperStyled sx={{
                position: 'sticky',
                zIndex: 2,
                top: '192px',
                marginTop: '-65px',
            }}>
                {updates.updaterId && updates.updaterId !== 'BANK' && 0 === updates.errors.length && 0 === updates.warnings.length &&
                    <DashedPaper color={'green'}>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}
                               width={'100%'}>
                            <Stack>
                                <Typography variant={'h5'} color={'primary'} gutterBottom>
                                    {t('commons:modules.webpro.self-admin.updates-initiated-by')}
                                </Typography>
                                <Typography variant={'h4'} color={'primary'}>{updates.updaterName}</Typography>
                            </Stack>

                            <Stack justifyItems={'center'} alignItems={'center'}>
                                <Typography variant={'h5'} color={'primary'} gutterBottom>
                                    {t('commons:modules.webpro.self-admin.updates-date')}
                                </Typography>
                                <FormattedDateTime dateTime={updates.lastUpdateTime}
                                                   typographyProps={{variant: 'h4', color: 'primary'}}/>
                            </Stack>

                            <Stack alignItems={'flex-end'}>
                                <Typography variant={'h5'} color={'primary'} gutterBottom>
                                    {t('commons:modules.webpro.self-admin.updates-number')}
                                </Typography>
                                <Typography variant={'h4'} color={'primary'}>{updates.updateNumber}</Typography>
                            </Stack>
                        </Stack>
                    </DashedPaper>
                }
                {updates.updaterId && updates.updaterId !== 'BANK' && (updates.errors.length > 0 || updates.warnings.length > 0) &&
                    <>
                        <DashedPaper color={'orange'}>
                        <Stack direction={'row'} alignItems={'center'} width={'100%'} mb={4} spacing={8}>
                            <WarningIcon width={'55px'} height={'55px'} color={'warning'}/>

                                <Stack>
                                <Typography variant={'h3'} fontWeight={400} color={'text.warning'}>
                                        {t('commons:modules.webpro.self-admin.updates-error-incoherence')}
                                    </Typography>

                                    {(updates.errors.length > 0 || updates.warnings.length > 0) &&
                                        <List dense>
                                            {updates.errors.map(errorCode => (
                                                <ListItem disablePadding>
                                                    <Typography variant={'h6'} color={'text.warning'} gutterBottom>
                                                            {t(`commons:modules.webpro.self-admin.code.${errorCode}`)}
                                                        </Typography>
                                                </ListItem>
                                            ))}
                                            {updates.warnings.map(errorCode => (
                                                <ListItem disablePadding>
                                                    <Typography variant={'h6'} color={'text.warning'} gutterBottom>
                                                        {t(`commons:modules.webpro.self-admin.code.${errorCode}`)}
                                                    </Typography>
                                                </ListItem>
                                            ))}
                                        </List>
                                    }
                                </Stack>
                            </Stack>
                        </DashedPaper>

                        <Box position={'relative'}>
                            <Stack direction={'row'} p={2}
                                   spacing={12}
                                   justifyContent={'space-between'}
                                   pl={'125px'}
                                   pr={8}
                                   width={'100%'}
                                   bgcolor={'#FFA23620'}
                                   borderRadius={'0px 0px 10px 10px'}
                                   position={'absolute'}
                                   bottom={0}>
                                <Typography variant={'h6'} color={'text.warning'}>
                                {t('commons:modules.webpro.self-admin.updates-initiated-by')} : {updates.updaterName}
                        </Typography>

                        <Typography variant={'h6'} color={'text.warning'}>
                                    {t('commons:modules.webpro.self-admin.updates-date')} :&nbsp;
                            <FormattedDateTime dateTime={updates.lastUpdateTime}
                                               typographyProps={
                                                           {
                                                               variant: 'inherit',
                                                               color: 'text.warning',
                                                               display: 'contents'
                                                           }
                                               }/>
                        </Typography>
                    </Stack>
                        </Box>
                    </>
                }
            </PaperStyled>
            :
            <></>
    );
};

export default ModificationDetailedBanner;
