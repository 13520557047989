import {useTranslation} from 'react-i18next';
import {Box, Button, Checkbox, Stack, Typography} from '@mui/material';
import PaymentLimit from './PaymentLimit';
import {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import {PaymentTypeType} from '../../../redux/datastore/features/payment/storeSinglePayment';
import {
    PaymentConstraint,
    UpdatePaymentConstraintRequest,
    WebBankingBusinessPaymentConstraint
} from '../../../redux/@types/SelfAdminTypes';
import StandardPaymentSigningRules from './StandardPaymentSigningRules';
import {signaturesProfilesArray} from '../constants/signaturesProfiles';
import {useGetContractUpdatesQuery, useUpdatePaymentConstraintsMutation} from '../../../redux/api/selfAdminApiRtk';
import {useCurrentWebProContractReference} from '../../../hooks/useCurrentPrimaryContract';
import {ActionKind, reducer} from '../reducers/paymentConstraintStateReducer';
import SavedAsDraft from './SavedAsDraft';
import {GlobalToaster} from '../../_commons/components/GlobalToaster/GlobalToaster';
import {validatePaymentConstraint} from '../helper/paymentConstraintValidation';
import {Permission} from '../../../redux/@types/WebProContractTypes';
import RequirePermissions from '../../../components/RequirePermissions';
import {useHasOneOfPermission} from '../../../hooks/useWebProPermissions';

type SFPaymentType = {
    paymentType: PaymentTypeType.CLASSIC | PaymentTypeType.SALARY;
    webBankingBusinessPaymentConstraint: WebBankingBusinessPaymentConstraint,
    refetchPaymentConstraint: any
};

const PaymentConstraints = ({
                                paymentType,
                                webBankingBusinessPaymentConstraint,
                                refetchPaymentConstraint
                            }: SFPaymentType) => {
    const {t} = useTranslation(['commons', 'authentication']);
    const [isStandardPayment, setIsStandardPayment] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [hasTransactionProfile, setHasTransactionProfile] = useState(false);
    const [updateWebBankingPaymentConstraint] = useUpdatePaymentConstraintsMutation();
    const {refetch} = useGetContractUpdatesQuery();
    const contractReference = useCurrentWebProContractReference();
    const hasSelfAdminPermission = useHasOneOfPermission([Permission.SELF_ADMIN]);

    const [signaturesProfilesList] = useState(
        signaturesProfilesArray
    );

    const initialPaymentConstraint = PaymentTypeType.CLASSIC === paymentType ?
        webBankingBusinessPaymentConstraint.paymentConstraint :
        webBankingBusinessPaymentConstraint.salaryPaymentConstraint;

    const [paymentConstraint, dispatch] = useReducer(reducer, initialPaymentConstraint as PaymentConstraint);

    useMemo(() => {
        dispatch({
            type: ActionKind.ACTION_RESET_STATE,
            payload: {
                initialState: initialPaymentConstraint as PaymentConstraint
            }
        });
    }, [initialPaymentConstraint]);

    useMemo(() => {
        setIsStandardPayment(initialPaymentConstraint?.isAllowed ?? false);
    }, [initialPaymentConstraint]);

    useEffect(() => {
        setIsStandardPayment(paymentConstraint.isAllowed);
    }, [paymentConstraint]);

    const updateNewLimitPaymentAmount = (amount: number | null) => {
        dispatch({
            type: ActionKind.ACTION_EDIT_MAX_AMOUNT,
            payload: {
                amount: amount
            }
        });
    };

    const editPaymentConstraint = useCallback(async () => {
        setIsUpdating(true);
        let errorValidation = validatePaymentConstraint(paymentConstraint);

        if (errorValidation.length > 0) {
            GlobalToaster.error(
                t('commons:errors.generic'),
                errorValidation.length === 1 ? errorValidation[0] : errorValidation
            );
            setIsUpdating(false);
            return;
        }

        const updatePaymentConstraintRequest: UpdatePaymentConstraintRequest = {
            contractReference: contractReference,
            webBankingBusinessPaymentConstraint: [{
                paymentConstraint: PaymentTypeType.CLASSIC === paymentType ? paymentConstraint : webBankingBusinessPaymentConstraint.paymentConstraint,
                salaryPaymentConstraint: PaymentTypeType.SALARY === paymentType ? paymentConstraint : webBankingBusinessPaymentConstraint.salaryPaymentConstraint
            }]
        };

        await updateWebBankingPaymentConstraint(updatePaymentConstraintRequest)
            .unwrap()
            .then(() => {
                refetch();
                refetchPaymentConstraint();
                setIsUpdating(false);
                setIsSaved(true);
                setTimeout(() => {
                    setIsSaved(false);
                }, 2000);
            }).catch(() => {
                setIsUpdating(false);
            });
    }, [updateWebBankingPaymentConstraint, contractReference, paymentType, setIsSaved, paymentConstraint, refetch, refetchPaymentConstraint, webBankingBusinessPaymentConstraint, t]);

    useEffect(() => {
        setHasTransactionProfile(paymentConstraint?.signatureProfile?.length > 0);
    }, [paymentConstraint, setHasTransactionProfile]);
    return (
        <>
            {isSaved ?
                <SavedAsDraft/> :
                <Box
                    py={4}
                    sx={{
                        background: '#fff'
                    }}
                >
                    <Stack direction={'column'} spacing={6} alignItems={'center'}>
                        <Stack direction={'row'} alignItems={'center'}>
                            <Typography variant={'h2'}>
                                {t(`commons:modules.webpro.self-admin.${paymentType}-payments-label`)}
                            </Typography>

                            <Checkbox
                                checked={paymentConstraint.isAllowed}
                                onChange={() => dispatch({type: ActionKind.ACTION_EDIT_ALLOWED})}
                                disabled={!hasSelfAdminPermission}
                            />
                        </Stack>

                        {isStandardPayment &&
                            <>
                                <PaymentLimit
                                    maxAmount={paymentConstraint.maxAmount ?? null}
                                    paymentType={paymentType}
                                    hasSelfAdminPermission={hasSelfAdminPermission}
                                    onChange={updateNewLimitPaymentAmount}
                                />

                                <Stack direction={'column'} spacing={4} mt={2}>
                                    <Typography variant={'h2'} textAlign={'center'}>
                                        {t('commons:modules.webpro.self-admin.payments-signing-rules')}
                                    </Typography>

                                    <Box display={{md: 'grid'}} sx={
                                        {
                                            gridTemplateColumns: '2',
                                            columnGap: '4rem'
                                        }
                                    }>
                                        {signaturesProfilesList.map((profile, idx) => (
                                            <Box key={profile.id} sx={{
                                                gridRow: idx < Math.ceil(signaturesProfilesList.length / 2) ? `${idx + 1}` : `${idx + 1 - Math.ceil(signaturesProfilesList.length / 2)}`,
                                                gridColumn: idx < Math.ceil(signaturesProfilesList.length / 2) ? '1' : '2'
                                            }}>
                                                <StandardPaymentSigningRules

                                                    signaturesProfile={profile}
                                                    dispatch={dispatch}
                                                    paymentConstraint={paymentConstraint}
                                                    hasSelfAdminPermission={hasSelfAdminPermission}
                                                />
                                            </Box>
                                        ))}
                                    </Box>
                                </Stack>
                            </>
                        }
                        <RequirePermissions permissions={[Permission.SELF_ADMIN]}>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                size={'small'}
                                disabled={isUpdating || !hasTransactionProfile}
                                onClick={editPaymentConstraint}
                            >
                                {t('commons:buttons.edit')}
                            </Button>
                        </RequirePermissions>
                    </Stack>
                </Box>
            }
        </>
    );
};

export default PaymentConstraints;

