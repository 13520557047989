import React from 'react';
import GenericDialog from '../../ui/_commons/components/Dialog/GenericDialog';
import {Box, Button, Stack} from '@mui/material';
import {useTranslation} from 'react-i18next';

type ConfirmationDialogContextType = {
    openDialog: ({
                     title,
                     dialogContent,
                     callback
                 }: { title?: string, dialogContent?: React.ReactNode, callback?: (value: boolean | PromiseLike<boolean>) => void }) => void;
};

const ConfirmationDialogContext = React.createContext<ConfirmationDialogContextType | null>(null);

export const ConfirmationDialogProvider = ({children}: { children: React.ReactNode }) => {
    const {t} = useTranslation(['commons']);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogConfig, setDialogConfig] = React.useState<{ title?: string, dialogContent?: React.ReactNode, callback?: (value: boolean | PromiseLike<boolean>) => void }>({});

    const openDialog = ({title, dialogContent, callback}: typeof dialogConfig) => {
        setDialogOpen(true);
        setDialogConfig({title, dialogContent, callback});
    };

    const resetDialog = () => {
        setDialogOpen(false);
        setDialogConfig({});
    };

    const _onConfirm = () => {
        resetDialog();
        dialogConfig.callback && dialogConfig.callback(true);
    };

    const _onDismiss = () => {
        resetDialog();
        dialogConfig.callback && dialogConfig.callback(false);
    };

    return (
        <ConfirmationDialogContext.Provider value={{openDialog}}>
            <GenericDialog title={dialogConfig.title ?? ''}
                           isOpen={dialogOpen}
                           handleClose={_onDismiss}
                           actions={
                               <Stack direction={'row'} spacing={16}>
                                   <Button type={'submit'} variant="contained" color="primary" size={'small'}
                                           onClick={_onConfirm}>{t('commons:buttons.yes')}</Button>

                                   <Button type={'reset'} variant="contained" color="secondary" size={'small'}
                                           onClick={_onDismiss}>{t('commons:buttons.no')}</Button>
                               </Stack>
                           }>
                <Box p={4} pt={8}>
                    {dialogConfig?.dialogContent}
                </Box>
            </GenericDialog>
            {children}
        </ConfirmationDialogContext.Provider>
    );
};

const useConfirmationDialog = () => {
    const {openDialog} = React.useContext(ConfirmationDialogContext) ?? {};

    const getConfirmation = ({...options}) =>
        new Promise<boolean>(resolve => {
            openDialog && openDialog({callback: resolve, ...options});
        });

    return {getConfirmation};
};

export default useConfirmationDialog;
