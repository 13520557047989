import React, {lazy} from 'react';
import {HashRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import Layout from './ui/_commons/layouts/Layout';

import WebProConnectedLayout from './ui/_commons/layouts/WebProConnectedLayout';
import Menu from './ui/menu/Menu';
import ContractSelection from './ui/webProContracts/screens/ContractSelection';
import AuthLayout from './ui/authentication/layout/AuthLayout';

import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelfAdminRouter from './ui/self-admin/SelfAdminRouter';
import {useTranslation} from 'react-i18next';
import RequireAuth from './components/RequireAuth';
import CodeSplit from './components/CodeSplit';

const GclRouter = lazy(() => import('./ui/gcl/GclRouter'));
const AuthenticationRouter = lazy(() => import('./ui/authentication/AuthenticationRouter'));
const DashboardRouter = lazy(() => import('./ui/dashboard/DashboardRouter'));
const SynthesisRouter = lazy(() => import('./ui/synthesis/SynthesisRouter'));
const IhubRouter = lazy(() => import('./ui/ihub/IhubRouter'));
const EDocumentsRouter = lazy(() => import('./ui/e-docs/EDocumentsRouter'));
const MessagingRouter = lazy(() => import('./ui/secure-messaging/MessagingRouter'));
const PaymentRouter = lazy(() => import('./ui/payment/PaymentRouter'));
const SettingsRouter = lazy(() => import('./ui/settings/SettingsRouter'));

const AppRouter = () => {
    return (
        <>
            <ToastContainer
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
            />
            <Router>
                <AllRoutes/>
            </Router>
        </>
    );
};

const AllRoutes = () => {

    const {t} = useTranslation(['authentication']);

    return (
        <Routes>
            <Route index element={
                <Navigate to={'/contract-selection'}/>
            }/>
            <Route element={<Layout/>}>
                <Route path={'/auth/*'}
                       element={
                           <CodeSplit>
                               <AuthenticationRouter/>
                           </CodeSplit>}
                />

                <Route path={'/gcl/*'}
                       element={
                           <RequireAuth>
                               <CodeSplit>
                                   <GclRouter/>
                               </CodeSplit>
                           </RequireAuth>
                       }
                />

                <Route element={
                    <RequireAuth>
                        <CodeSplit>
                            <AuthLayout title={t('authentication:title')}/>
                        </CodeSplit>
                    </RequireAuth>
                }>
                    <Route path={'/contract-selection'}
                           element={<ContractSelection/>}/>
                </Route>

            </Route>

            <Route element={<Layout/>}>

                <Route element={<RequireAuth><WebProConnectedLayout menuComponent={<Menu/>}/></RequireAuth>}>
                    <Route path={'/redirect/dashboard/*'}
                           element={<CodeSplit inner><DashboardRouter/></CodeSplit>}/>

                    <Route path={'/:ctrPro/:primaryCtr/dashboard/*'}
                           element={<CodeSplit inner><DashboardRouter/></CodeSplit>}/>

                    <Route path={'/:ctrPro/:primaryCtr/synthesis/*'}
                           element={<CodeSplit inner><SynthesisRouter/></CodeSplit>}/>
                    <Route path={'/:ctrPro/:primaryCtr/ihub/*'}
                           element={<CodeSplit inner><IhubRouter/></CodeSplit>}/>
                    {import.meta.env.VITE_ENABLE_EDOCUMENTS === 'true' &&
                        <Route path={'/:ctrPro/:primaryCtr/e-documents/*'}
                               element={<CodeSplit inner><EDocumentsRouter/></CodeSplit>}/>
                    }
                    {import.meta.env.VITE_ENABLE_SECURE_MESSAGING === 'true' &&
                        <Route path={'/:ctrPro/:primaryCtr/secure-messaging/*'}
                               element={<CodeSplit inner><MessagingRouter/></CodeSplit>}/>
                    }

                    {import.meta.env.VITE_ENABLE_SELF_ADMIN === 'true' &&
                        <Route path={'/:ctrPro/:primaryCtr/self-admin/*'}
                               element={<CodeSplit inner><SelfAdminRouter/></CodeSplit>}/>
                    }

                    {import.meta.env.VITE_ENABLE_PAYMENT === 'true' &&
                        <Route path={'/:ctrPro/:primaryCtr/payment/*'}
                               element={<CodeSplit inner><PaymentRouter/></CodeSplit>}/>
                    }

                    {import.meta.env.VITE_ENABLE_SETTINGS === 'true' &&
                        <Route path={'/:ctrPro/:primaryCtr/settings/*'}
                               element={<CodeSplit inner><SettingsRouter/></CodeSplit>}/>
                    }
                </Route>
            </Route>
        </Routes>
    );
};


export default AppRouter;
