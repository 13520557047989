import {Box, Button, TextField, Typography} from '@mui/material';
import GenericDialog from '../_commons/components/Dialog/GenericDialog';
import Rating from '@mui/material/Rating';
import {ChangeEvent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSendFeedbackMutation} from '../../redux/api/messagingApiRtk';
import {useCurrentPrimaryContract} from '../../hooks/useCurrentPrimaryContract';
import {FeedbackRequest} from '../../redux/@types/MessagingTypes';
import {useValidation} from '../../hooks/useValidation';
import {useAnalytics} from '../../hooks/useAnalytics';

const rules = {
    value: ['required', 'numeric'],
    messageBodyField: ['required', 'max:2000']
};

const FeedbackDialog = ({opened, onClose}: { opened: boolean, onClose: () => void }) => {

    const [value, setValue] = useState(0);
    const [messageBodyField, setMessageBodyField] = useState('');
    const [currentPrimaryContract] = useCurrentPrimaryContract();
    const {pushAnalytics} = useAnalytics();

    const {t} = useTranslation(['commons']);

    const [sendFeedback, {isLoading}] = useSendFeedbackMutation();

    const {validate, hasError, setValidationErrors, validationErrors} = useValidation();

    const _handleChangeMessageBodyField = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setMessageBodyField(event.target.value);
    }, []);

    const _handleSendFeedback = useCallback(async () => {

        const validation = validate({
            value,
            messageBodyField
        }, rules);

        if (validation.fails()) {
            setValidationErrors(validation.errors.all());
        } else {
            const request: FeedbackRequest = {
                rate: value ?? 0,
                body: messageBodyField,
                primaryContract: currentPrimaryContract?.primaryContractId ?? ''
            };

            try {
                await sendFeedback(request);
                pushAnalytics({
                    rule: 'send_feedback_tracking_rule',
                    actionName: 'send_feedback',
                    buttonName: 'send_feedback_button',
                    sequenceName: 'feedback',
                    starsNumber: value
                });
                setMessageBodyField('');
                setValue(0);
                setValidationErrors([]);
                onClose();

            } catch (e) {
                /* nothing to do, error is catched by global error toaster */
            }
        }

    }, [value, messageBodyField, setValidationErrors, currentPrimaryContract?.primaryContractId, sendFeedback, onClose, pushAnalytics, validate]);

    return (
        <GenericDialog
            maxWidth={'sm'}
            title={t('commons:modules.webpro.rating.title')}
            isOpen={opened}
            actions={
                <Button type={'button'}
                        onClick={_handleSendFeedback}
                        variant="contained"
                        disabled={isLoading}
                        color="primary" size={'medium'}>{t('commons:buttons.send')}</Button>
            }
            handleClose={onClose}>
            <Box pt={8}>
                <Typography
                    variant="h5">{(t('commons:modules.webpro.rating.global-rating') as string).toUpperCase()}</Typography>
                <Box py={4}
                     display="flex"
                     justifyContent="center"
                     alignItems="center">
                    <Rating
                        value={value}
                        size="large"
                        onChange={(event, newValue) => {
                            setValue(newValue ?? 0);
                        }}
                    />
                </Box>
                <Typography variant="body2" py={6}>{t('commons:modules.webpro.rating.feedback-text')}</Typography>

                <TextField
                    label={t('commons:modules.webpro.rating.text-area-title')}
                    size={'medium'}
                    minRows={7}
                    onChange={_handleChangeMessageBodyField}
                    value={messageBodyField}
                    fullWidth
                    error={hasError('messageBodyField')}
                    helperText={hasError('messageBodyField') ? validationErrors['messageBodyField'] : ''}
                    multiline/>
            </Box>
        </GenericDialog>

    );
};

export default FeedbackDialog;
