import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from './api-utils';
import {DocumentRequestFilterTypes} from '../@types/DocumentRequestFilterTypes';
import {DocumentResponse, DocumentsDocsResponse} from '../@types/DocumentTypes';
import {DocumentSortedRequestTypes} from '../@types/DocumentSortedRequestTypes';

const URL_API = '/business_webdocacc/rest/v1/edocuments';

export type DocumentRequest = {
  primaryContractReferences ?: string[];
  filter ?: DocumentRequestFilterTypes;
  documentNb ?: number;
  page ?: number;
  sorter ?: DocumentSortedRequestTypes;
};

const GET_DOCUMENT = 'GET';

export const documentAccessApi = createApi({
  reducerPath: 'documentAccessApi',
  baseQuery: axiosBaseQuery({
    baseUrl: URL_API
  }),
  tagTypes: ['Document'],
  endpoints: builder => ({

    getDocuments: builder.query<DocumentResponse, DocumentRequest>({
      query: (documentRequest: DocumentRequest) => ({
        url: '/getDocuments',
        method: 'POST',
        data: {
          primaryContractReferences : documentRequest.primaryContractReferences,
          filter : documentRequest.filter,
          documentNb : documentRequest.documentNb,
          page : documentRequest.page,
          sortedRequest : documentRequest.sorter
        }
      }),
      providesTags: [{type: 'Document', id: GET_DOCUMENT}]
    }),

    getDocumentsCount: builder.query<DocumentsDocsResponse, DocumentRequest>({
      query: (documentRequest: DocumentRequest) => ({
        url: '/countDocuments',
        method: 'POST',
        data: {
          primaryContractReferences : documentRequest.primaryContractReferences,
          filter : documentRequest.filter
        }
      })
    })

  })
});

export const {useGetDocumentsQuery, useGetDocumentsCountQuery} = documentAccessApi;
