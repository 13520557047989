import React from 'react';
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {DisplayStyle} from '../../../redux/@types/SelfAdminTypes';

const UserSearchBar = ({
    filter,
    filterItems,
    filterChange,
    exportContent,
    exporting,
    exportType,
    searchKey,
    searchKeyChange
}: React.PropsWithoutRef<{
    filter: DisplayStyle,
    filterItems: DisplayStyle[],
    filterChange: (filter: DisplayStyle) => void,
    exportContent: () => void,
    exporting: boolean,
    exportType: 'USERS',
    searchKey: string,
    searchKeyChange: (searchKey: string) => void
}>) => {
    const {t} = useTranslation(['commons']);
    const TOP_MDUP = '214px';
    const TOP_SM = '257px';
    const TOP_XS = '249px';
    const PB_MDUP = '25px';
    const PB_XSUP = '65px';

    return (
        <Stack sx={{
            direction: 'row',
            width: '100%',
            position: 'sticky',
            zIndex: 3,
            top: {
                md: TOP_MDUP,
                sm: TOP_SM,
                xs: TOP_XS
            },
            marginTop: '-88px',
            paddingBottom: {
                md: PB_MDUP,
                xs: PB_XSUP
            }
        }}>
            <Box sx={{
                zIndex: 1,
                width:'100%',
                height: 'auto',
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 5,
                paddingRight: 5,
                textAlign: 'center',
                background: 'transparent linear-gradient(265deg, #F5FFF6 0%, #DEFFE3 100%) 0% 0% no-repeat padding-box',
                boxShadow: '0px 3px 12px #64D37D40',
                borderRadius: '15px',
                opacity: '1'
            }}>

            <Stack direction={{xs:'column', sm: 'row'}} spacing={6} width={'100%'}>
                <TextField
                sx={{
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    boxShadow: '0px 1px 3px #6580864E',
                    borderRadius: '5px',
                    opacity: '1',
                    width: {xs: '100%', sm: '65%'},
                }}
                value={searchKey}
                placeholder={t('commons:labels.search-placeholder')}
                label={t('commons:labels.search-title')}
                variant={'outlined'}
                onChange={(e: { target: { value: string; }; }) => {
                    searchKeyChange(e.target.value);
                }}/>

                <FormControl sx={{width: {xs: '100%', sm: '35%'}}}>
                <InputLabel>{t('commons:labels.select-display')}</InputLabel>
                <Select
                    sx={{
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        boxShadow: '0px 1px 3px #6580864E',
                        border: '1px solid #F8F8F8',
                        borderRadius: '5px',
                        opacity: '1'
                    }}
                    value={filter}
                    onChange={(e: SelectChangeEvent) => filterChange(e.target.value as DisplayStyle)}>

                    {filterItems.map(item =>
                        <MenuItem key={item} value={item} disableGutters>
                            <Typography
                                variant={'body1'}
                                marginRight={'32px'}
                                fontSize={'1.2rem'}
                                fontFamily={'BNPPSans'}
                                fontWeight={100}>{
                                    t(`commons:modules.webpro.self-admin.inputs.users.display-${item as DisplayStyle}`)
                                }
                            </Typography>
                        </MenuItem>
                    )}
                </Select>
                </FormControl>

                <Button
                    variant={'outlinedSecondary'}
                    size={'small'}
                    sx={{
                        width: {xs: '100%', sm: '250px'},
                        cursor: exporting ? 'default' : 'pointer'
                    }}
                    disableRipple={exporting}
                    onClick={() => exporting ? null : exportContent()}>
                    {exporting ?
                        <ButtonLoader text={t('commons:labels.loading-short')}/>
                        :
                        <Box>{exportType === 'USERS' ?
                            t('commons:modules.webpro.self-admin.buttons.export-users')
                            : t('commons:modules.webpro.payment.beneficiaries.buttons.export-beneficiaries')
                        }</Box>
                    }
                </Button>
                </Stack>
            </Box>
    </Stack>
    );
};

const ButtonLoader = ({text}: React.PropsWithoutRef<{text: string}>) => {
    return (
        <Stack direction={'row'} alignItems="center" spacing={4}>
            <CircularProgress size="25px"/>
            <Box marginTop="5px !important" textTransform={'none'}>{text}</Box>
        </Stack>
    );
};

export default UserSearchBar;
