import React from 'react';
import {ToggleButton, ToggleButtonGroup} from '@mui/material';

export type ExportType = 'XLS' | 'CSV' | 'PDF';

const TypeExportSelector = ({
                        handleExportTypeChange,
                        currentType,
                    }: { handleExportTypeChange: Function, currentType: ExportType}) => {

    return (
        <ToggleButtonGroup size={'large'} exclusive fullWidth={true} sx={{
            height: '3rem'
        }}>
            <ToggleButton value={'XLS'} onClick={() => handleExportTypeChange('XLS')}  sx={{
                ...currentType === 'XLS' && {
                    background: 'transparent linear-gradient(78deg, #00a767 0%, #2ab56f 100%) 0% 0% no-repeat padding-box',
                    color: '#fff'
                }
            }}>XLS</ToggleButton>
            <ToggleButton value={'CSV'} onClick={() => handleExportTypeChange('CSV')} sx={{
                ...currentType === 'CSV' && {
                    background: 'transparent linear-gradient(78deg, #00a767 0%, #2ab56f 100%) 0% 0% no-repeat padding-box',
                    color: '#fff'
                }
            }}>CSV</ToggleButton>
        </ToggleButtonGroup>
    );
};

export default TypeExportSelector;
