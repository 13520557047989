import React, {useContext} from 'react';
import {RootContext} from '../context/root-context';
import {useLocation} from 'react-router';
import {Navigate} from 'react-router-dom';
import {AppPrivateContextProvider} from '../context/AppPrivateContext';

const RequireAuth = ({children}: { children: JSX.Element }): JSX.Element => {
    const auth = useContext(RootContext);
    const location = useLocation();

    if (!auth.isAuthenticated) {
        return <Navigate to={'/auth'} state={{from: location}} replace/>;
    }

    return <AppPrivateContextProvider>{children}</AppPrivateContextProvider>;
};

export default RequireAuth;
