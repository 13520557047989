import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Slide,
    Stack,
    styled,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, {useCallback} from 'react';
import Dialog from '@mui/material/Dialog';
import {useTranslation} from 'react-i18next';
import Pen from '../../assets/images/commons/pen.svg'
import Message from '../../assets/images/commons/message.svg'
import Document from '../../assets/images/commons/document.svg'
import DashedDivider from '../_commons/components/Divider/DashedDivider.tsx'
import EastIcon from '@mui/icons-material/East';
import {TransitionProps} from '@mui/material/transitions';
import {useNavigate} from 'react-router';
import {Link as RouterLink} from "react-router-dom";

export type NotificationsDialogProps = {
    isOpen: boolean;
    rfExtCtr: string | undefined;
    rfPmrCtr: string | undefined;
    nbSelfAdmin: number;
    nbMsg: number;
    nbToSign: number;
    nbNotifications: number;
    handleClose: () => void;
    nbSOToSign: number;
};

const StyledCloseButton = styled(IconButton)({
    position: 'absolute',
    right: 0,
    background: 'transparent',
    backgroundColor: '#4FC476',
    boxShadow: '0 3px 6px #FA475B58',
    marginLeft: 'auto',
    marginRight: '24px',
    color: 'white',
    '&:hover': {
        background: 'transparent linear-gradient(256deg, #FA475B58 0, #FA475B58 100%) 0 0 no-repeat padding-box'
    }
});

const StyledNotificationBox = styled(Box)({
    border: '1px solid #FFF3E1',
    borderRadius: '15px',
    backgroundColor: '#FFFFFF',
    boxShadow: '5px 5px 10px #FFD1932F',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    cursor: 'pointer'
});

const StyledLineBox = styled(Box)({
    height: '100%',
    width: '8px',
    position: 'absolute',
    borderRadius: '15px 0 0 15px',
    background: 'transparent linear-gradient(180deg, #FF8245 0%, #FFBB72 100%) 0% 0% no-repeat padding-box'
});

const StyledDialogTitle = styled(DialogTitle)({
    background: 'transparent linear-gradient(252deg, #00A951 0%, #007265 100%) 0% 0% no-repeat',
    display: 'flex',
    fontFamily: 'BNPPSansCondensed',
    letterSpacing: '0.15rem',
    fontSize: '2.2rem',
    fontWeight: '300',
    color: 'white',
    borderRadius: '4px 4px 0px 0px',
    padding: '3rem'
});

const StyledArrowBox = styled(Box)({
    background: 'transparent linear-gradient(201deg, #FFFAF5 0%, #FFF2DE 100%) 0% 0% no-repeat padding-box',
    borderRadius: '0px 15px 15px 0px',
    height: '100%',
    marginLeft: 'auto',
    padding: '4rem 1.5rem'
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
      children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const NotificationsDialog = ({isOpen, handleClose, rfExtCtr, rfPmrCtr, nbNotifications, nbToSign, nbMsg, nbSelfAdmin, nbSOToSign}: NotificationsDialogProps) => {
    const {t} = useTranslation(['commons']);
    const navigate = useNavigate();

    const goTo = useCallback((url: string) => {
        handleClose();
        navigate(`/${rfExtCtr}/${rfPmrCtr}/${url}`, {replace: true});
    }, [rfExtCtr, rfPmrCtr, handleClose, navigate]);

    return (
        <Dialog
            TransitionComponent={Transition}
            scroll={'paper'}
            componentsProps={{
                backdrop: {
                    style: {
                        backdropFilter: 'blur(2px)'
                    }
                }
            }}
            PaperProps={{
                sx: {
                    overflowY: 'visible',
                    border: 'none',
                    margin: 0,
                    width: '100%',
                    height: '100%',
                    maxHeight: '100%',
                    position: 'absolute',
                    right: '0',
                    boxShadow: 'none'
                }
            }}
            open={isOpen}>

            <StyledDialogTitle textTransform={'uppercase'} position={'relative'}>
                {t('commons:modules.webpro.notifications.panel.title')}
                <StyledCloseButton onClick={handleClose}>
                    <CloseIcon/>
                </StyledCloseButton>
            </StyledDialogTitle>

            <DialogContent sx={{position: 'relative'}}>
                <Stack alignItems={'center'} spacing={{xs:4, md:10}} margin={{xs:'2rem 0', md:'4.5rem 3rem'}}>
                    {nbNotifications === 0 &&
                      <Typography variant={'h4'}>{t('commons:modules.webpro.notifications.panel.no-notif')}</Typography>
                    }
                    {nbToSign > 0 &&
                      <StyledNotificationBox onClick={() => goTo('payment/history/validations')}>
                          <StyledLineBox/>
                          <img src={Pen} width={55} alt={'Pen'} style={{margin: '2rem'}}/>
                          <Stack direction={'column'} sx={{marginRight: '2rem'}}>
                              <Typography variant={'h4'}>{t('commons:modules.webpro.payment.title')}</Typography>
                              <Typography variant={'h4'}><b>{nbToSign}</b> {t('commons:modules.webpro.notifications.panel.nb-sign')}</Typography>
                              <DashedDivider sx={{margin: '8px 0'}}/>
                              <Typography variant={'body2'} fontWeight={'300'}>{t('commons:modules.webpro.notifications.panel.nb-sign-detail')}</Typography>
                          </Stack>
                          <StyledArrowBox><EastIcon sx={{fontSize: 30}}/></StyledArrowBox>
                      </StyledNotificationBox>
                    }
                    {nbSOToSign > 0 &&
                        <StyledNotificationBox onClick={() => goTo('payment/standing-order/validations')}>
                            <StyledLineBox/>
                            <img src={Pen} width={55} alt={'Pen'} style={{margin: '2rem'}}/>
                            <Stack direction={'column'} sx={{marginRight: '2rem'}}>
                                <Typography variant={'h4'}>{t('commons:modules.webpro.payment.home.standing-orders')}</Typography>
                                <Typography variant={'h4'}><b>{nbSOToSign}</b> {t('commons:modules.webpro.notifications.panel.nb-sign')}</Typography>
                                <DashedDivider sx={{margin: '8px 0'}}/>
                                <Typography variant={'body2'} fontWeight={'300'}>{t('commons:modules.webpro.notifications.panel.nb-sign-detail')}</Typography>
                            </Stack>
                            <StyledArrowBox><EastIcon sx={{fontSize: 30}}/></StyledArrowBox>
                        </StyledNotificationBox>
                    }
                    {nbMsg > 0 &&
                      <StyledNotificationBox onClick={() => goTo('secure-messaging/inbox')}>
                          <StyledLineBox/>
                          <img src={Message} width={55} alt={'Message'} style={{margin: '2rem'}}/>
                          <Stack direction={'column'} sx={{marginRight: '2rem'}}>
                              <Typography variant={'h4'}><b>{nbMsg}</b> {t('commons:modules.webpro.notifications.panel.nb-msg')}</Typography>
                              <DashedDivider sx={{margin: '8px 0'}}/>
                              <Typography variant={'body2'} fontWeight={'300'}>{t('commons:modules.webpro.notifications.panel.nb-msg-detail')}</Typography>
                          </Stack>
                          <StyledArrowBox><EastIcon sx={{fontSize: 30}}/></StyledArrowBox>
                      </StyledNotificationBox>
                    }
                    {nbSelfAdmin > 0 &&
                      <StyledNotificationBox onClick={() => goTo('self-admin/validation')}>
                          <StyledLineBox/>
                          <img src={Document} width={55} alt={'Document'} style={{margin: '2rem'}}/>
                          <Stack direction={'column'} sx={{marginRight: '2rem'}}>
                              <Typography variant={'h4'}><b>{nbSelfAdmin}</b> {t('commons:modules.webpro.notifications.panel.nb-modif')}</Typography>
                              <DashedDivider sx={{margin: '8px 0'}}/>
                              <Typography variant={'body2'} fontWeight={'300'}>{t('commons:modules.webpro.notifications.panel.nb-modif-detail')}</Typography>
                          </Stack>
                          <StyledArrowBox><EastIcon sx={{fontSize: 30}}/></StyledArrowBox>
                      </StyledNotificationBox>
                    }
                </Stack>
            </DialogContent>
            <DialogActions sx={{backgroundColor: '#F7F7F7'}}>
                <Stack alignItems={'center'} spacing={4} margin='30px'>
                    <Typography variant={'body1'} fontWeight='bold'>{t('commons:modules.webpro.notifications.panel.notif-question')}</Typography>
                    <Typography variant={'h6'}>{t('commons:modules.webpro.notifications.panel.activate-notif')}</Typography>
                    <Button variant={'text'} component={RouterLink} onClick={() => handleClose()} to={`/${rfExtCtr}/${rfPmrCtr}/settings`} disableRipple>{t('commons:buttons.display')}</Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default NotificationsDialog;
