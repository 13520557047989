import React from 'react';
import {Language} from '../@types/ui.types';

export const RootContext = React.createContext({
    isAuthenticated: false,
    setIsAuthenticated: (value: boolean): void => {
        // This is intentional
    },

    drawerOpened: false,
    setDrawerOpened: (value: boolean): void => {
        // This is intentional
    },

    locale: 'fr-FR',
    language: 'fr' as Language,
    setLanguage: (value: Language) => {
        // This is intentional
    }
});
