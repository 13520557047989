import {
    Box,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    Stack,
    styled,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import { Trans, useTranslation } from 'react-i18next';
import { SxProps, Theme } from '@mui/system';

export type GenericDialogProps = {
    title: string;
    maxWidth?: DialogProps['maxWidth']
    isOpen: boolean;
    isSearch?: boolean;
    searchCountWarning?: boolean;
    searchText?: string;
    searchCount?: number;
    children: ReactNode;
    actions?: ReactNode;
    buttonHeader?: ReactNode;
    toolbar?: ReactNode;
    handleClose: (event: React.MouseEvent<HTMLElement>) => void;
    sxAction?: SxProps<Theme>
    noPadding?: boolean;
    hideCloseButton?: boolean;
};

const StyledDialog = styled(Dialog)({});

type DialogTitleProps = {
    title: string,
    size?: 'small' | 'medium' | 'large'
};

const StyledCloseButton = styled(IconButton)({
    position: 'absolute',
    right: 0,
    background: 'transparent',
    backgroundColor: '#4FC476',
    boxShadow: '0 3px 6px #FA475B58',
    marginLeft: 'auto',
    marginRight: '24px',
    color: 'white',
    '&:hover': {
        background: 'transparent linear-gradient(256deg, #FA475B58 0, #FA475B58 100%) 0 0 no-repeat padding-box'
    }
});

const StyledDialogTitle = styled(DialogTitle)<Pick<DialogTitleProps, 'size'>>(({ theme, size }) => ({
    background: 'transparent linear-gradient(252deg, #00A951 0%, #007265 100%) 0% 0% no-repeat',
    display: 'flex',
    fontFamily: 'BNPPSansCondensed',
    letterSpacing: '0.15rem',
    fontSize: '2rem',
    fontWeight: '300',
    color: 'white',
    borderRadius: '4px 4px 0px 0px',

    ...size === 'small' && {
        paddingX: theme.spacing(4)
    },
    ...size === 'medium' && {
        paddingX: theme.spacing(8)
    },
    ...size === 'large' && {
        paddingX: theme.spacing(10)
    }
}));

const GenericDialog = ({
    maxWidth,
    children,
    title,
    actions,
    buttonHeader,
    toolbar,
    isOpen,
    isSearch,
    searchCountWarning,
    searchText,
    searchCount,
    handleClose,
    sxAction = [],
    noPadding = false,
    hideCloseButton = false
}: GenericDialogProps) => {
    const theme = useTheme();
    const { t } = useTranslation(['commons']);
    const isLarge = useMediaQuery(theme.breakpoints.up('md'));
    const isSmall = useMediaQuery(theme.breakpoints.only('xs'));

    const maxWidthComputed = maxWidth ? maxWidth : isLarge ? 'sm' : false;

    return (
        <StyledDialog
            maxWidth={maxWidthComputed}
            fullWidth
            fullScreen={isSmall}
            scroll={'paper'}
            componentsProps={{
                backdrop: {
                    style: {
                        backdropFilter: 'blur(2px)'
                    }
                }
            }}
            PaperProps={{
                sx: {
                    overflowY: 'visible',
                    border: 'none',
                    boxShadow: 'none'
                }
            }}
            open={isOpen}>

            <StyledDialogTitle size="medium" textTransform={'uppercase'} position={'relative'}>
                {title}
                {buttonHeader &&
                    <Stack>{buttonHeader}</Stack>
                }
                {!hideCloseButton && <StyledCloseButton onClick={handleClose}>
                    <CloseIcon />
                </StyledCloseButton>}
                {toolbar &&
                    <Box position={'absolute'} bottom={'-15px'} left={0} zIndex={11} width={'100%'}>
                        <Stack direction={'row'} spacing={4} justifyContent={'center'}>
                            {toolbar}
                        </Stack>
                    </Box>
                }
            </StyledDialogTitle>

            <DialogContent sx={{ position: 'relative', px: noPadding ? 0 : 15 }}>
                <Box>
                    {children}
                </Box>
            </DialogContent>

            {actions && !isSearch &&
                <DialogActions sx={{ paddingBottom: 8, textAlign: 'center', ...sxAction }}>
                    {actions}
                </DialogActions>
            }

            {(isSearch && searchText) && <Box
                display="flex"
                flexDirection={'column'}
                height={80}
                sx={{
                    background: searchCountWarning ?
                        'transparent linear-gradient(262deg, #FFFCF5 0%, #FFF9ED 37%, #FFF9ED 37%, #FFF9EC 41%, #FFF5DE 100%) 0% 0% no-repeat padding-box;' :
                        'transparent linear-gradient(262deg, #F5FFF6 0%, #DEFFE3 100%) 0% 0% no-repeat padding-box',
                    borderRadius: '0px 0px 4px 4px'
                }}
                alignItems="center"
                justifyContent="center">
                <Typography sx={{
                    position: isSmall ? '' : 'absolute',
                    textTransform: 'uppercase',
                    letterSpacing: '0.5px',
                    fontFamily: 'BNPPSansCondensed',
                    fontSize: '1.5rem',
                    fontWeight: 100,
                    color: searchCountWarning ? '#FF9314' : '#2BAC6B'
                }}>

                    <Trans t={t}>
                        {
                            // @ts-ignore
                            t(searchText, { count: searchCount, defaultValue: '' })
                        }
                    </Trans>
                </Typography>

                {actions &&
                    <DialogActions sx={{ position: isSmall ? '' : 'absolute', bottom: '-25px' }}>
                        {actions}
                    </DialogActions>
                }
            </Box>}
        </StyledDialog>
    );
};

export default GenericDialog;
