import {ButtonBase, MenuItem, Select, SelectChangeEvent, Stack, styled, Typography} from '@mui/material';

import React, {useCallback} from 'react';
import {usePrincipalPrimaryContract} from '../../../hooks/useCurrentPrimaryContract';
import {PrimaryContract, WebProContract} from '../../../redux/@types/WebProContractTypes';
import Grid from '@mui/material/Grid2';
import {useGetWebProContractsQuery} from '../../../redux/api/webProContractApiRtk';
import SectionTitle from './SectionTitle';
import {useTranslation} from 'react-i18next';

const ButtonStyled = styled(ButtonBase)(({theme}) => ({
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 5px 12px #A7C7B31C',
    border: '1px solid #E1F4EE',
    borderRadius: '15px',
    padding: `${theme.spacing(4)} ${theme.spacing(4)}`,
    '&.selected': {
        background: 'transparent linear-gradient(244deg, #29AA6A 0%, #018B57 100%) 0% 0% no-repeat padding-box',
        color: '#fff'
    }
}));

const SingleWebProContract = ({
                                  contract,
                                  selected,
                                  onClick
                              }: { contract: WebProContract, selected: boolean, onClick: (webProContract: WebProContract) => void }) => {

    return (
        <ButtonStyled
            className={selected ? 'selected' : ''}
            onClick={() => {
                onClick(contract);
            }}>
            <Stack alignItems={'center'} justifyContent={'center'} width={120} height={60} alignContent={'stretch'}>
                <Typography variant={'h6'} fontWeight={400} textTransform={'uppercase'} lineHeight={'normal'}
                            fontSize={'1.1rem'}>{contract.companyName}</Typography>
            </Stack>
        </ButtonStyled>
    );
};

type SectionProContractsProps = {
    currentWebProContract?: WebProContract,
    currentPrimaryContract?: PrimaryContract,
    onChangeWebProContract: (webproContract: WebProContract) => void,
    onChangePrimaryContract: (primaryContract?: PrimaryContract) => void,
};

const SectionProContracts = ({
    currentWebProContract,
    currentPrimaryContract,
    onChangeWebProContract,
    onChangePrimaryContract
}: SectionProContractsProps) => {

    const {t} = useTranslation(['commons']);
    const {data: webProContracts} = useGetWebProContractsQuery();

    const principal = usePrincipalPrimaryContract();

    const _handleChangePrimaryContract = useCallback((event: SelectChangeEvent) => {
            const primaryContract = currentWebProContract?.primaryContracts.find(value => value.primaryContractId === event.target.value);
            onChangePrimaryContract(primaryContract);
        },
        [currentWebProContract?.primaryContracts, onChangePrimaryContract]);

    return (
        <Stack mt={8} spacing={8} justifyContent={'center'}>
            <Stack justifyContent={'center'} alignItems={'center'} py={8}>
                <SectionTitle title={t('commons:modules.webpro.contract-selection.available-contracts')}/>
                <Grid mt={4} container columnSpacing={4} rowSpacing={4} justifyContent={'center'}>
                    {webProContracts?.map((webProContract, idx) => (
                        <Grid key={idx} display={'flex'}>
                            <SingleWebProContract contract={webProContract} onClick={onChangeWebProContract}
                                                  selected={currentWebProContract?.reference === webProContract?.reference}/>
                        </Grid>
                    ))}
                </Grid>
            </Stack>

            {currentWebProContract &&
                <Stack p={4} justifyContent={'center'} alignItems={'center'} bgcolor={'#F8F8F8'} py={8}>
                    <SectionTitle title={t('commons:modules.webpro.contract-selection.available-roots')}/>

                    <Select

                        sx={{
                            background: '#fff',
                            minWidth: 150,
                            mt: 4
                        }}
                        value={currentPrimaryContract?.primaryContractId ?? ''}
                        onChange={_handleChangePrimaryContract}
                        label={''}
                        variant={'outlined'}>
                        {currentWebProContract.primaryContracts.map((primaryContract, index) => (
                            <MenuItem key={index} value={primaryContract.primaryContractId}>
                                <Typography variant={'body1'} fontSize={'1.3rem'} fontWeight={400}
                                            fontFamily={'BNPPSansCondensed'}
                                            textAlign={'center'}>{primaryContract.primaryContractId}</Typography>
                            </MenuItem>
                        ))}
                    </Select>


                    {!principal && <>Aucun contrat primaire</>}
                </Stack>
            }
        </Stack>
    );
};

export default SectionProContracts;
