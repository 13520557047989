import React from 'react';
import {Checkbox as MuiCheckbox, FormControlLabel, FormControlLabelProps, styled} from '@mui/material';
import CheckedIcon from './assets/svg/checked.svg?react';
import CheckedDisabledIcon from './assets/svg/checked-disabled.svg?react';
import UnCheckedIcon from './assets/svg/unchecked.svg?react';
import UnCheckedDisabledIcon from './assets/svg/unchecked-disabled.svg?react';

const StyledCheckbox = styled(MuiCheckbox)({});

const Checkbox = (props: Omit<FormControlLabelProps, 'control'>) => {
    return (
        <FormControlLabel {...props}
                          control={<StyledCheckbox id={props.id || ''}
                                                   disabled={props.disabled}
                                                   checkedIcon={props.disabled ?
                                                       <CheckedDisabledIcon width={'18px'} height={'18px'}/> :
                                                       <CheckedIcon width={'18px'} height={'18px'}/>}
                                                   icon={props.disabled ?
                                                       <UnCheckedDisabledIcon width={'18px'} height={'18px'}/> :
                                                       <UnCheckedIcon width={'18px'}
                                                                      height={'18px'}/>}/>}></FormControlLabel>
    );
};

export default Checkbox;
