import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { PickerValidDate } from '@mui/x-date-pickers/models';
import { de, enGB, fr } from 'date-fns/locale';
import { Language } from '../../../../@types/ui.types';
import { useLanguage } from '../../../../hooks/useLanguage';

type DateFieldProps<TDate extends PickerValidDate> = DatePickerProps<TDate>;

const LANGUAGE_MAPPING = {
    'fr': fr,
    'en': enGB,
    'de': de
};

const DateField = ({...props}: DateFieldProps<Date> & { fullWidth?: boolean, helperText?: any, error?: boolean}) => {
    const [language] = useLanguage();

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={LANGUAGE_MAPPING[language as Language]}>
            <DatePicker
                {...props}
                slotProps={{
                    textField: {fullWidth: true, sx: {width: '100%'}, helperText: props.helperText, error: props.error}
                }}
                views={['year', 'month', 'day']}
            />
        </LocalizationProvider>
    );

};

export default DateField;
