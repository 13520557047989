import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from './api-utils';

const URL_API = '/business_webprofile/rest/v1/iHub';

export type IHubUrlRequest = {
    primaryContract: string;
};

export type IHubUrlResponse = {
    iHubUrlWithSsoToken: string;
};

export const ihubApi = createApi({
    reducerPath: 'ihubApi',
    baseQuery: axiosBaseQuery({
        baseUrl: URL_API
    }),

    endpoints: (builder) => ({
        getIhubUrl: builder.query<IHubUrlResponse, IHubUrlRequest>({
            query: (request) => ({
                url: '/getUrl',
                method: 'POST',
                data: request
            })
        })
    })
});

export const {useGetIhubUrlQuery} = ihubApi;
