import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Typography
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import SortableHeader, { SortOrder } from './SortableHeader';
import AdvancedSearchBox, { ListFieldType } from './AdvancedSearchBox';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { GenericCircularIcon } from "../Icon/GenericCircularIcon";
import React, { useCallback, useState, useMemo } from "react";
import DownloadIcon from '../../../../assets/images/commons/download.svg?react';
import PrinterIcon from '../../../../assets/images/commons/printer.svg?react';
import GenericDialog from "../Dialog/GenericDialog";
import { differenceInMonths, format, sub } from "date-fns";
import DateField from "../Form/DateField";
import TypeExportSelector, { ExportType } from "../Form/TypeExportSelector";


export type TableHeaderSorting = {
    field: string;
    order?: SortOrder;
};

type Period = {
    startDate: Date,
    endDate: Date,
};

type PredefinedPeriod = {
    label: 'today' | 'yesterday' | 'week' | 'last_week' | 'month' | 'last_month' | 'three_last_months',
    value: string,
    beginDate: Date,
    endDate: Date
};

const getMonthDifference = (startDate: Date, endDate: Date) => {

    return differenceInMonths(new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDay()),
        new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDay()));

};

const ButtonStyled = styled(Button)({
    zIndex: 1,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 4px #A7C7B340',
    border: '1px solid #C3EBDA',
    borderRadius: '5px',
    opacity: '1',
    textAlign: 'center',
    font: 'normal normal bold 1.2rem/24px BNPPSansCondensed',
    letterSpacing: '1px',
    color: '#2BAC6B',
    textTransform: 'uppercase',
    marginRight: '60px',

    '&:hover': {
        background: '#FFFFFF 0% 0% no-repeat padding-box !important',
        boxShadow: '0px 5px 10px #27bf7030 !important'
    }
});

type TableHeaderType = {
    columns: Array<ListFieldType>,
    sorting: TableHeaderSorting,
    handleAdvancedKeys: Function,
    setSorting: Function,
    initialAdvancedKeys: any,
    titleSearchBox?: string | null,
    openAdvancedSearch: boolean,
    setOpenAdvancedSearch: Function,
    activeFilter: boolean,
    handleExport?: Function,
    handlePrint?: Function,
    smallExportButton?: boolean,
    smallPrintButton?: boolean,
    exportUsage?: string
    slindindPeriodeNbMonths?: number,
    exportTitle?: string
}


const TableHeader = ({
    columns,
    sorting,
    handleAdvancedKeys,
    setSorting,
    initialAdvancedKeys,
    titleSearchBox,
    openAdvancedSearch,
    setOpenAdvancedSearch,
    activeFilter,
    handleExport,
    handlePrint,
    smallExportButton,
    smallPrintButton,
    exportUsage = 'Payment',
    slindindPeriodeNbMonths = 3,
    exportTitle = 'Export'
}: TableHeaderType) => {
    const [openExportBox, setOpenExportBox] = useState(false);
    const { t } = useTranslation(['commons']);
    const [selectedPeriodValue, setSelectedPeriodValue] = useState('');
    const [selectedPeriod, setSelectedPeriod] = useState<Period>({ startDate: new Date(), endDate: new Date() });
    const DEFAULT_EXPORT_TYPE = 'CSV';
    const [type, setType] = useState<ExportType>(DEFAULT_EXPORT_TYPE);
    if (exportTitle === 'Export' && exportUsage === 'Payment') { exportTitle = t('commons:modules.webpro.payment.history.history-payment.export-dialog.title') }

    const periods = useMemo(() => [
        {
            label: 'today',
            value: 'TODAY',
            beginDate: new Date(),
            endDate: new Date()
        },
        {
            label: 'yesterday',
            value: 'YESTERDAY',
            beginDate: sub(new Date(), { days: 1 }),
            endDate: sub(new Date(), { days: 1 })
        },
        {
            label: 'week',
            value: 'WEEK',
            beginDate: sub(new Date(), { days: 7 }),
            endDate: new Date()
        },
        {
            label: 'last_week',
            value: 'LAST_WEEK',
            beginDate: sub(new Date(), { days: 14 }),
            endDate: sub(new Date(), { days: 7 })
        },
        {
            label: 'month',
            value: 'MONTH',
            beginDate: sub(new Date(), { months: 1 }),
            endDate: new Date()
        },
        {
            label: 'last_month',
            value: 'LAST_MONTH',
            beginDate: sub(new Date(), { months: 2 }),
            endDate: sub(new Date(), { months: 1 })
        },
        {
            label: 'three_last_months',
            value: 'THREE_LAST_MONTHS',
            beginDate: sub(new Date(), { months: 3 }),
            endDate: new Date()
        }
    ] as PredefinedPeriod[], []);

    const _handleDateFieldChange = useCallback((id: string, value: any) => {
        switch (id) {
            case 'startDate':
                setSelectedPeriod({ ...selectedPeriod, 'startDate': value });
                break;
            case 'endDate':
                setSelectedPeriod({ ...selectedPeriod, 'endDate': value });
                break;
            default: {
                let period = periods.find(p => p.value === value);
                if (period) {
                    setSelectedPeriod({
                        'startDate': period?.beginDate,
                        'endDate': period.endDate
                    });
                }
            }
        }
    }, [selectedPeriod, periods]);

    const _handleSelectChange = useCallback(
        (e: SelectChangeEvent) => {
            setSelectedPeriodValue(e.target.value);
            _handleDateFieldChange('export', e.target.value);
        },
        [_handleDateFieldChange]
    );

    const handleExportTypeChange = useCallback((newType: ExportType) => {
        setType(newType);
    }, []);

    const _handleClose = () => {
        setOpenAdvancedSearch(false);
    }

    const _handleAdvancedKeys = (advancedKeys: any) => {
        handleAdvancedKeys(advancedKeys);
        setOpenAdvancedSearch(false);
    }

    const _handleExportBox = (value: any) => {
        setOpenExportBox(value);
    }

    const date = new Date();

    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 2);
    const dateformat = (date: Date): string => format(date, 'dd/MM/yyyy');

    const _setMaxDate = useCallback((): Date => {
        if (selectedPeriod.startDate && getMonthDifference(selectedPeriod.startDate, new Date()) > slindindPeriodeNbMonths) {
            const currentDate = new Date(selectedPeriod.startDate);
            currentDate.setMonth(currentDate.getMonth() + slindindPeriodeNbMonths);
            return currentDate;
        } else {
            return new Date();
        }
    }, [selectedPeriod, slindindPeriodeNbMonths]);

    return (
        <>
            <Stack width={'100%'}>
                <Grid container rowSpacing={2} alignItems={'flex-start'}>
                    {
                        columns?.map(({ field, label, size, isSortable, justifyContent }, idx) => (
                            <Grid key={field} size={size}>
                                <SortableHeader
                                    sort={isSortable ? (sorting.field === field ? sorting?.order : null) : undefined}
                                    setSorting={setSorting} field={field} justifyContent={justifyContent}>{label}</SortableHeader>
                            </Grid>
                        ))
                    }
                </Grid>

            </Stack>
            <Stack direction={'row'} width={'100%'}
                sx={{ position: 'absolute', bottom: '-20px', left: '2.5rem' }}>
                {activeFilter &&
                    <>
                        <ButtonStyled
                            variant="contained"
                            size={'small'}
                            disabled={false}
                            onClick={() => {
                                setOpenAdvancedSearch(true)
                            }}>
                            {t('commons:buttons.search')}
                        </ButtonStyled>
                        {handleExport && !smallExportButton && <ButtonStyled
                            variant="contained"
                            size={'small'}
                            disabled={false}
                            onClick={_handleExportBox}>
                            {t('commons:buttons.export')}
                        </ButtonStyled>}
                        {handleExport && smallExportButton &&
                            <Box sx={{ zIndex: '1', marginLeft: '50rem', marginRight: '0rem' }}>
                                <GenericCircularIcon icon={<DownloadIcon />} size={35} callback={_handleExportBox} />
                            </Box>
                        }
                        {handlePrint && smallPrintButton &&
                            <Box sx={{ zIndex: '1', marginLeft: '2rem', marginRight: '20rem' }}>
                                <GenericCircularIcon icon={<PrinterIcon />} size={35} callback={handlePrint} />
                            </Box>
                        }
                        <AdvancedSearchBox
                            initialAdvancedKeys={initialAdvancedKeys}
                            isOpenAdvancedSearch={openAdvancedSearch}
                            handleClose={_handleClose}
                            handleAdvancedKeys={_handleAdvancedKeys}
                            title={titleSearchBox ?? t('commons:buttons.search')}
                            listFields={columns.filter(field => field.isFilter)}
                        />

                        <GenericDialog title={exportTitle} isOpen={openExportBox} handleClose={() => _handleExportBox(false)}
                            actions={<Button
                                type={'button'}
                                onClick={() => ((exportUsage === 'SimpleXlsCsv' && handleExport) || ((getMonthDifference(selectedPeriod.startDate, selectedPeriod?.endDate) <= slindindPeriodeNbMonths) && handleExport)) ? exportUsage !== 'SimpleXlsCsv' ?
                                    handleExport({
                                        documentType: type,
                                        startDate: dateformat(selectedPeriod?.startDate),
                                        endDate: dateformat(selectedPeriod?.endDate)
                                    }) : handleExport({
                                        documentType: type
                                    }) :
                                    ''}
                                variant="contained"
                                color="primary"
                                size={'medium'}>
                                {t('commons:modules.webpro.payment.history.history-payment.export-dialog.action-button')}
                            </Button>}
                        >

                            <Box mt={12} px={4}>
                                {exportUsage !== 'SimpleXlsCsv' &&
                                    <>
                                        <Box mb={10}>
                                            {exportUsage === 'Payment' &&
                                                <Typography mb={5}>
                                                    <Box sx={{ fontSize: '1rem' }}>
                                                        {t('commons:modules.webpro.payment.history.history-payment.export-dialog.period_available')}
                                                        <b>{dateformat(sub(new Date(), { years: 2 }))}</b> {t('commons:modules.webpro.payment.history.history-payment.export-dialog.at')}
                                                        <b>{dateformat(date)}</b>.
                                                    </Box>
                                                </Typography>
                                            }
                                            {exportUsage === 'Payment' &&
                                                <Typography mb={5}>
                                                    <Box sx={{ fontSize: '1rem' }}>
                                                        {t('commons:modules.webpro.synthesis.export_movements.export_rules_end')}
                                                    </Box>
                                                </Typography>
                                            }
                                            {(exportUsage === 'DirectDebit') &&
                                                <Typography mb={5}>
                                                    <Box sx={{ fontSize: '1rem' }}>
                                                        {t('commons:modules.webpro.payment.direct-debits.direct-debits-transactions.export.rules_end')}
                                                    </Box>
                                                </Typography>
                                            }
                                        </Box>

                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel
                                                id="export-period-label">{t('commons:modules.webpro.synthesis.export_movements.choose_period')}</InputLabel>
                                            <Select sx={{ paddingRight: '12px' }}
                                                id="export-period"
                                                label={'Export Period'}
                                                defaultValue={'Export'}
                                                value={selectedPeriodValue}
                                                onChange={_handleSelectChange}
                                            >
                                                {periods && periods.map((period, key) => (
                                                    <MenuItem key={key} value={period.value} selected={key === 0}>
                                                        <Typography variant={'body1'} fontSize={'1.5rem'} fontFamily={'BNPPSansCondensed'}
                                                            fontWeight={100}>{t(`commons:modules.webpro.synthesis.export_movements.${period.label}`)}</Typography>
                                                    </MenuItem>
                                                ))
                                                }
                                            </Select>
                                        </FormControl>

                                        <Typography variant={'body1'} my={'2rem'}>
                                            <Box sx={{ textAlign: 'center' }}>{t('commons:modules.webpro.synthesis.export_movements.or')}</Box>
                                        </Typography>

                                        <Stack direction={'row'} alignItems={'center'} spacing={2} mb={12}>
                                            <DateField key="startDate" label={t('commons:modules.webpro.synthesis.export_movements.begin_date')} value={selectedPeriod?.startDate}
                                                onChange={(datePickerValue: Date | null) => {
                                                    _handleDateFieldChange('startDate', datePickerValue);
                                                }}
                                                minDate={minDate}
                                                maxDate={new Date()} />
                                            <DateField key="endStartDate" label={t('commons:modules.webpro.synthesis.export_movements.end_date')}
                                                value={selectedPeriod?.endDate}
                                                onChange={(datePickerValue: Date | null) => {
                                                    _handleDateFieldChange('endDate', datePickerValue);
                                                }}
                                                minDate={selectedPeriod.startDate}
                                                maxDate={_setMaxDate()}
                                            />
                                        </Stack>
                                    </>}
                                <Stack sx={{ borderColor: '#ccc' }} ml={0}>
                                    <TypeExportSelector handleExportTypeChange={handleExportTypeChange} currentType={type} />
                                </Stack>

                            </Box>

                        </GenericDialog>
                    </>
                }
            </Stack>
        </>
    );
};

export default TableHeader;
