export type WebProContract = {
    reference: string;
    label: string;
    companyName: string;
    transactionalProfile: TransactionalProfile;
    primaryContracts: PrimaryContract[];
    menuProfiles: string;
};

export type TransactionalProfile = {
    code: string;
    label: string;
};

export type Role = {
    code: string;
    label: string;
};

export type PrimaryContract = {
    primaryContractId: string;
    mainPrimaryContract: boolean;
    cryptedPrimaryContractReference: string;
};

export type WebProContractResponse = {
    webProContracts: Array<WebProContract>;
};

export enum Permission {
    MANAGE_CARD = 'webpro.payment.manage-card',
    CONSULTATION_CARD = 'webpro.permissions.consultation-card',
    CONSULTATION_CREDIT = 'webpro.permissions.consultation-credit',
    CONSULTATION_DEPOTERM = 'webpro.permissions.consultation-depoterm',
    CONSULTATION_VIEW_ACCOUNT = 'webpro.permissions.consultation-view-account',
    CONSULTATION_SAVING = 'webpro.permissions.consultation-saving',
    CONSULTATION_E_DOCUMENT = 'webpro.permissions.consultation-edocuments',
    CONSULTATION_IHUB = 'webpro.ihub',

    SELF_ADMIN = 'webpro.self-admin',
    SELF_ADMIN_PAYMENT_SALARY = 'webpro.self-admin-payment-salary',
    SELF_ADMIN_CONSULTATION = 'webpro.self-admin-consultation',
    SELF_ADMIN_CANCELLATION = 'webpro.self-admin-cancellation',
    SELF_ADMIN_VALIDATION = 'webpro.self-admin-validation',

    PAYMENT_CREATE_SINGLE = 'webpro.payment.create-single',
    PAYMENT_UPLOAD_BULK = 'webpro.payment.upload-bulk',
    PAYMENT_HAS_ACCESS_BULK = 'webpro.payment.has-access-bulk',
    PAYMENT_MANAGE_SALARY = 'webpro.payment.manage-salary',
    PAYMENT_DISPLAY_HISTORY = 'webpro.payment.display-history',

    STANDING_ORDER_CREATE = 'webpro.payment.standing-order.create',
    STANDING_ORDER_DISPLAY = 'webpro.payment.standing-order.display',

    PAYMENT_PRE_INSTRUCTION_DISPLAY = 'webpro.payment.pre-instruction.display',
    PAYMENT_PRE_INSTRUCTION_REJECT = 'webpro.payment.pre-instruction.reject',
    PAYMENT_PRE_INSTRUCTION_VALIDATE = 'webpro.payment.pre-instruction.validate',

    PAYMENT_DIRECT_DEBIT_DISPLAY = 'webpro.payment.direct-debit.display',

    SELF_CARE_CARD_LOCK = 'webpro.payment.self-care-card.lock-card',

    BENEFICIARY_MANAGEMENT = 'webpro.beneficiary-management',

    EMASPHERE_DISPLAY = 'webpro.emasphere'
}
