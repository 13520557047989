import { useCallback } from 'react';
import { useLanguage } from './useLanguage';

export interface PushAnalyticsParams {
    rule: String,
    actionName: String,
    buttonName: String,
    pageName?: String,
    stepName?: String,
    cookieConsent?: String,
    sequenceName?: String,
    currencyName?: String,
    messageSubject?: String,
    starsNumber?: Number,
    sectionName?: String
  }

  let currentPage: String;

export const useAnalytics = () => {
    const language = useLanguage();
    const pushAnalytics = useCallback((pushAnalyticsParams: PushAnalyticsParams) => {
        if(pushAnalyticsParams.pageName) {
            currentPage = pushAnalyticsParams.pageName;
        }
        // @ts-ignore
        window?.pushAnalytics({
            trackingRule: pushAnalyticsParams.rule,
            userActionName: pushAnalyticsParams.actionName,
            buttonName: pushAnalyticsParams.buttonName,
            pageName: currentPage,
            country: language,
            stepName: pushAnalyticsParams.stepName,
            cookieConsent: pushAnalyticsParams.cookieConsent,
            sequenceName: pushAnalyticsParams.sequenceName,
            currencyName: pushAnalyticsParams.currencyName,
            messageSubject: pushAnalyticsParams.messageSubject,
            starsNumber: pushAnalyticsParams.starsNumber,
            sectionName: pushAnalyticsParams.sectionName,
        });
    }, [language]);

    return {
        pushAnalytics
    };
};

