import {Box, Button} from '@mui/material';
import GenericDialog from '../../_commons/components/Dialog/GenericDialog';
import SectionProContracts from '../components/SectionProContracts';
import React, {useCallback, useEffect, useState} from 'react';
import {PrimaryContract, WebProContract} from '../../../redux/@types/WebProContractTypes';
import {useCurrentPrimaryContract, useCurrentWebProContract} from '../../../hooks/useCurrentPrimaryContract';
import {useLocation, useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import {useAnalytics} from '../../../hooks/useAnalytics';

const ProContractsDialog = ({opened, onClose}: { opened: boolean, onClose: () => void }) => {
    const {t} = useTranslation(['commons']);
    const navigate = useNavigate();
    const location = useLocation();


    const [currentWebProContract, setCurrentWebProContract] = useCurrentWebProContract();
    const [currentPrimaryContract, setCurrentPrimaryContract] = useCurrentPrimaryContract();

    const [selectedWebProContract, setSelectedWebProContract] = useState<WebProContract | undefined>(currentWebProContract);
    const [selectedPrimaryContract, setSelectedPrimaryContract] = useState<PrimaryContract | undefined>(currentPrimaryContract);
    const {pushAnalytics} = useAnalytics();

    useEffect(() => {
        if (!selectedPrimaryContract) {
            const principal = selectedWebProContract?.primaryContracts?.find(value => value.mainPrimaryContract) ?? selectedWebProContract?.primaryContracts[0];
            setSelectedPrimaryContract(principal);
        }
    }, [currentPrimaryContract, /*prefetchFeatures,*/ selectedPrimaryContract, selectedWebProContract?.primaryContracts, selectedWebProContract?.reference, setCurrentPrimaryContract]);

    const _handleChangeWebProContract = useCallback((webProContract: WebProContract) => {
            setSelectedWebProContract(webProContract);
            setSelectedPrimaryContract(undefined);
        },
        []);

    const _handleChangePrimaryContract = useCallback((primaryContract?: PrimaryContract) => {
            setSelectedPrimaryContract(primaryContract);
        },
        []);

    const _handleClickValidate = useCallback(() => {
            if (selectedWebProContract && selectedPrimaryContract) {
                pushAnalytics({
                    rule: 'validate_contract_choice_tracking_rule',
                    actionName: 'validate_contract_choice',
                    buttonName: 'validate_contract_choice_button',
                    sequenceName: 'change contract'
                });
                if (selectedWebProContract === currentWebProContract && currentPrimaryContract !== selectedPrimaryContract && !location.pathname.includes('movements')) {
                    let pathName = location.pathname.split('/');
                    pathName[2] = selectedPrimaryContract.primaryContractId;
                    navigate(`${pathName.join('/')}`);
                } else {
                    if (currentWebProContract) {
                        navigate(`/${selectedWebProContract.reference}/${selectedPrimaryContract.primaryContractId}/dashboard`);
                        setCurrentPrimaryContract(undefined);
                        setCurrentWebProContract(undefined);
                    } else {
                        /* Nothing to do in useEffect. */
                    }
                }
                onClose();
                window.location.reload();
            }
        },
        [selectedWebProContract, selectedPrimaryContract, pushAnalytics, currentWebProContract, currentPrimaryContract, location.pathname, onClose, navigate, setCurrentPrimaryContract, setCurrentWebProContract]);

    return (
        <GenericDialog title={t('commons:modules.webpro.contract-selection.title-long')} isOpen={opened}
                       actions={<Button type={'submit'} variant="contained" color="primary" size={'medium'}
                                        onClick={_handleClickValidate}>{t('commons:buttons.validate')}</Button>}
                       handleClose={() => onClose()} noPadding>
            <Box>
                <SectionProContracts
                    currentWebProContract={selectedWebProContract}
                    currentPrimaryContract={selectedPrimaryContract}
                    onChangeWebProContract={_handleChangeWebProContract}
                    onChangePrimaryContract={_handleChangePrimaryContract}
                />
            </Box>
        </GenericDialog>
    );
};

export default ProContractsDialog;
