import {useTranslation} from 'react-i18next';
import {Button, ButtonGroup, Paper, styled, Typography} from '@mui/material';
import React from 'react';
import {SelfAdminUserDisplayType} from '../layout/SelfAdminLayout';
import {PaperProps} from '@mui/material/Paper/Paper';

const PaperStyled = styled(Paper)<PaperProps>(({theme}) => ({
    flexGrow: 0,
    padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
    boxShadow: '15px 15px 25px #A7C7B329',
    background: '#00000024 0 0 no-repeat padding-box',
    border: '1px solid #FFFFFF',
    borderRadius: '13px'
}));

const TypographyStyled = styled(Typography)({
    fontFamily: 'BNPPSansCondensed',
    color: '#FFFFFF',
    fontSize: '1.4rem',
    textTransform: 'uppercase',
    letterSpacing: '0.03rem'
});

const ButtonSynthesisDetails = ({
                                    handleChange,
                                    type
                                }: { handleChange: (type: SelfAdminUserDisplayType) => void, type: SelfAdminUserDisplayType }) => {
    const {t} = useTranslation(['commons']);
    return (
        <PaperStyled sx={{flexDirection: 'column'}}>
            <ButtonGroup variant="text" aria-label="text button group" sx={{padding: '6px'}}>
                <Button key={1}
                        sx={{
                            ...type === 'DETAILS' && {
                                padding: {xs: '0px 5px 0 5px', md: '0px 15px 0 15px'},
                                textDecoration: 'none'
                            },
                            ...type === 'SYNTHESIS' && {
                                padding: {xs: '0px 5px 0 5px', md: '0px 15px 0 15px'},
                                textDecoration: 'underline 3px',
                                textDecorationColor: '#FFFFFF',
                                '&.Mui-disabled': {
                                    background: 'transparent'
                                }
                            }
                        }}
                        onClick={(e) => handleChange('SYNTHESIS')} disabled={type === 'SYNTHESIS'}>
                    <TypographyStyled
                        sx={{
                            ...type === 'SYNTHESIS' ? {
                                fontWeight: 'bold'
                            } : {
                                fontWeight: '300'
                            }
                        }}>{t('commons:modules.webpro.self-admin.synthesis-view')}
                    </TypographyStyled>
                </Button>
                <Button key={2}
                        sx={{
                            ...type === 'DETAILS' && {
                                padding: {xs: '0px 5px 0 5px', md: '0px 15px 0 15px'},
                                textDecoration: 'underline 3px',
                                textDecorationColor: '#FFFFFF',
                                '&.Mui-disabled': {
                                    background: 'transparent'
                                }
                            },
                            ...type === 'SYNTHESIS' && {
                                padding: {xs: '0px 5px 0 5px', md: '0px 15px 0 15px'},
                                textDecoration: 'none'
                            }
                        }}
                        onClick={(e) => handleChange('DETAILS')} disabled={type === 'DETAILS'}>
                    <TypographyStyled
                        sx={{
                            ...type === 'DETAILS' ? {
                                fontWeight: 'bold'
                            } : {
                                fontWeight: '300'
                            }
                        }}>{t('commons:modules.webpro.self-admin.details-view')}</TypographyStyled>
                </Button>
            </ButtonGroup>
        </PaperStyled>
    );
};

export default ButtonSynthesisDetails;
