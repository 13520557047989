import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from './api-utils';
import {
    BulkResponse,
    PreInstructionCollectiveListRequest,
    PreInstructionCollectiveListResponse
} from '../@types/CollectivePaymentsTypes';

const URL_API = '/business_webpaymentpro/rest/collective';

export const collectivePaymentsApi = createApi({
    reducerPath: 'collectivePaymentsApi',
    baseQuery: axiosBaseQuery({
        baseUrl: URL_API
    }),
    tagTypes: ['CollectivePayments'],

    endpoints: builder => ({

        getCollectivePreInstructions:
            builder.query<PreInstructionCollectiveListResponse, PreInstructionCollectiveListRequest>({
                query: request => ({
                    url: '/pre-instruction/list',
                    method: 'POST',
                    data: request
                }),
                providesTags: [{ type: 'CollectivePayments', id: 'LIST' }]
            }),

        createCollectivePaymentFromFile:
            builder.mutation<BulkResponse, FormData>({
                query: formData => ({
                    url: '/payment/pain/upload',
                    method: 'POST',
                    formData: true,
                    data: formData
                }),
                invalidatesTags: (result, error) => (error || (result?.warnings && result?.warnings.length > 0)) ? [] : ['CollectivePayments']
            }),

        createCollectivePaymentFromCsvFile:
            builder.mutation<BulkResponse, FormData>({
                query: formData => ({
                    url: '/payment/csv/upload',
                    method: 'POST',
                    formData: true,
                    data: formData
                }),
                invalidatesTags: (result, error) => (error || (result?.warnings && result?.warnings.length > 0)) ? [] : ['CollectivePayments']
            })

    })
});

export const {
    useGetCollectivePreInstructionsQuery,
    useCreateCollectivePaymentFromFileMutation,
    useCreateCollectivePaymentFromCsvFileMutation
} = collectivePaymentsApi;
