import {useMemo} from 'react';
import {useGetFeaturesQuery} from '../redux/api/featureApiRtk';
import {Permission} from '../redux/@types/WebProContractTypes';
import {useCurrentPrimaryContractReference, useCurrentWebProContractReference} from './useCurrentPrimaryContract';

export const useWebProPermissions = () => {
    const currentWebProContract = useCurrentWebProContractReference();
    const primaryContract = useCurrentPrimaryContractReference();
    const {data, isFetching} = useGetFeaturesQuery({
        webproContractRef: currentWebProContract,
        primaryContractRef: primaryContract
    }, {skip: !currentWebProContract});

    const permissions = useMemo(() => data && Object.values(data)
            .map(category => category.filter(value => (value.startsWith('webpro.'))))
            .reduce((previousValue, currentValue) => [...previousValue, ...currentValue])
        , [data]);

    return {permissions, isLoading: isFetching};
};

export const useHasOneOfPermission = (permissions: Permission[]) => {
    const {permissions: allPermissions} = useWebProPermissions();
    return useMemo(() => {
        if (allPermissions) {
            return permissions.some(value => allPermissions.includes(value));
        } else {
            return false;
        }
    }, [allPermissions, permissions]);
};

export const useHasOnlyThisPermissions = (permissions: Permission[]) => {
    const {permissions: allPermissions} = useWebProPermissions();

    return useMemo(() => {
        if (allPermissions && allPermissions.length === permissions.length) {
            return permissions.forEach(value => allPermissions.includes(value));
        } else {
            return false;
        }
    }, [allPermissions, permissions]);
};
