import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { AccountType, Beneficiary, BeneficiaryAddress } from '../../../@types/BeneficiaryTypes';

export type BeneficiaryState = {
    formData: Beneficiary;
};

const initialState: BeneficiaryState = {
    formData: {
        id: null,
        contractReference: '',
        beneficiaryBankName: '',
        beneficiaryTownBankName: '',
        beneficiaryAccountType: AccountType.IBAN,
        beneficiaryAccountCountryCode: 'LU',
        accountReference: 'LU',
        bankCodeType: undefined,
        bankCodeReference: '',
        beneficiaryName: '',
        foreignNationalReference: null,

        beneficiaryAddresses: [
            {
                id: null,
                streetName: '',
                buildingNumber: '',
                postBox: '',
                postCode: '',
                townName: '',
                countryCode: 'LU'
            }
        ],

        beneficiaryCommunicationArea: '',
        shareBeneficiary: false
    } as Beneficiary
};

const beneficiarySlice = createSlice({
    initialState,
    name: 'beneficiary',
    reducers: {
        setGlobalFormData(state, action: PayloadAction<Beneficiary>) {
            state.formData = action.payload;
        },
        setFormData(state, action: PayloadAction<{ field: keyof Beneficiary; value: any }>) {
            _.set(state.formData, action.payload.field, action.payload.value);
            //state.formData[action.payload.field as string] = action.payload.value;
        },
        setBeneficiaryAddressData(state, action: PayloadAction<{
            field: keyof BeneficiaryAddress;
            value: any
        }>) {
            _.set(state.formData, 'beneficiaryAddresses[0].' + action.payload.field, action.payload.value);
        },
        reset: () => initialState
    }
});

export const { setGlobalFormData, setFormData, setBeneficiaryAddressData, reset } = beneficiarySlice.actions;
export const reducer = beneficiarySlice.reducer;
