import React from 'react';
import {FormControl, InputLabel, MenuItem, Select, styled} from '@mui/material';
import {BaseSelectProps} from '@mui/material/Select/Select';

interface SelectFieldProps<T> extends BaseSelectProps {
    label: React.ReactNode,
    renderItem: (item: T) => React.ReactNode,
    items: T[],
    extraItems?: React.ReactNode
}

export const MenuItemStyled = styled(MenuItem)({
    marginLeft: '15px',
    padding: '0',
    marginBottom: '10px',
    marginRight: '15px',
    paddingBottom: '5px',
    '&.Mui-selected': {
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white'
        }
    }
});

const SelectField = <T extends unknown>({
        label,
        renderItem,
        items,
        extraItems,
        value,
        disabled,
        onChange,
        fullWidth,
        name
    }: SelectFieldProps<T>
) => {

    return (
        <FormControl fullWidth={fullWidth}>
            <InputLabel id="menuContact">{label}</InputLabel>
            <Select
                name={name}
                labelId={'menuContact'}
                label={label}
                MenuProps={{
                    PaperProps: {
                        style: {
                            minWidth: '400px',
                            marginTop: '9px',
                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                            boxShadow: '0px 5px 28px #A7C7B3A8',
                            border: '1px solid #E1F4EE',
                            opacity: '1',
                            borderRadius: '15px',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            maxHeight: '455px'
                        }
                    }
                }}
                disabled={disabled}
                value={value ?? ''}
                onChange={onChange}>

                {items.map(item => renderItem(item))}

                {extraItems && (extraItems as React.ReactNode)}

            </Select>
        </FormControl>
    );
};

export default SelectField;
