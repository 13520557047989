import React from 'react';
import {Box, Button, Paper, Stack, styled, Typography} from '@mui/material';
import {PaperProps} from '@mui/material/Paper/Paper';
import {useTranslation} from 'react-i18next';
import {useGetContractUpdatesQuery} from '../../../redux/api/selfAdminApiRtk';
import {useCurrentWebProContractReference} from '../../../hooks/useCurrentPrimaryContract';
import DashedPaper from '../../_commons/components/Paper/DashedPaper';
import {Link as RouterLink} from 'react-router-dom';

const PaperStyled = styled(Paper)<PaperProps>(({theme}) => ({
    flexGrow: 0,
    boxShadow: '0px 5px 12px #A7C7B31C',
    border: '1px solid #E1F4EE',
    borderRadius: '10px'
}));

const NumberCircle = styled(Box)({
    height: '60px',
    width: '60px',
    background: '#fffbf8 0% 0% no-repeat padding-box',
    borderRadius: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    font: 'normal normal 700 3rem BNPPSansCondensed',
    color: '#FF8831',
    textShadow: '0px 3px 6px #FF7F004D'
});

const searchButtonStyle = {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 4px #FF7E1F33',
    border: '1px solid #FDD5B8',
    borderRadius: '5px',
    textAlign: 'center',
    font: 'normal normal 400 1.2rem BNPPSansCondensed',
    letterSpacing: '1px',
    color: '#FF7E1F',
    textTransform: 'uppercase',
    '&:hover': {
        background: '#FFFFFF 0% 0% no-repeat padding-box !important',
        boxShadow: '0px 5px 10px #FF7E1F33 !important'
    }
} as React.CSSProperties;

const ModificationBanner = () => {
    const {t} = useTranslation(['commons']);

    const currentWebProContractRef = useCurrentWebProContractReference();
    const {data} = useGetContractUpdatesQuery(void (0), {skip: !currentWebProContractRef});
    const marginTop = data?.updaterId ? data?.updaterId !== 'BANK' ? '-55px' : '-38px' : '-38px';

    return (
        data ?
            <PaperStyled sx={{
                position: 'sticky',
                zIndex: 3,
                top: '192px',
                marginTop: marginTop,
            }}>
                {data.updaterId && data.updaterId !== 'BANK' && data.updateNumber > 0 &&
                    <DashedPaper color={'orange'}>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}
                               width={'100%'}>

                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                <NumberCircle>{data.updateNumber}</NumberCircle>
                                <Typography color={'#FF8E2E'} marginLeft={'15px'}
                                            variant={'h4'}>{t('commons:modules.webpro.self-admin.updates-waiting-validation', {count: data.updateNumber})}</Typography>
                            </Box>

                            <Button
                                variant="contained"
                                size={'small'}
                                style={searchButtonStyle}
                                component={RouterLink}
                                to={'./validation'}
                            >
                                {t('commons:buttons.display')}
                            </Button>
                        </Stack>
                    </DashedPaper>
                }

                {data.updaterId === 'BANK' &&
                    <DashedPaper color={'orange'}>
                        <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} width={'100%'}
                        >
                            <Typography color={'#FF8E2E'} variant={'h3'} fontSize={'1.5rem'} fontWeight={100}>
                                {t('commons:modules.webpro.self-admin.updates-not-possible')}
                            </Typography>
                        </Stack>
                    </DashedPaper>
                }

                {(!data.updaterId || (data.updateNumber === 0 && data.updaterId !== 'BANK')) &&
                    <DashedPaper color={'green'}>
                        <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} width={'100%'}
                        >
                            <Typography color={'primary'} variant={'h3'} fontSize={'1.5rem'} fontWeight={100}>
                                {t('commons:modules.webpro.self-admin.updates-waiting-validation', {count: 0})}
                            </Typography>
                        </Stack>
                    </DashedPaper>
                }
            </PaperStyled>
            :
            <></>
    );
};

export default ModificationBanner;
