import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export type FormData = {
    phoneNumber: string | null,
    email: string | null,
    activationCode: string | null,
};

export type GclState = {
    formData: FormData,
    orderNumber?: string
};

const initialState: GclState = {
    formData: {
        phoneNumber: null,
        email: null,
        activationCode: null
    }
};

const gclSlice = createSlice({
    name: 'gcl',
    initialState,
    reducers: {
        setFormData(state, action: PayloadAction<{ field: keyof FormData, value: string }>) {
            state.formData[action.payload.field] = action.payload.value;
        },
        setOrderNumber(state, action: PayloadAction<string>) {
            state.orderNumber = action.payload;
        }
    }
});

export const {setFormData, setOrderNumber} = gclSlice.actions;
export const reducer = gclSlice.reducer;
