import {useTranslation} from 'react-i18next';
import React, {useCallback, useMemo, useState} from 'react';
import {useListPartyProfileQuery} from '../../../redux/api/selfAdminApiRtk';
import {Box, Button, Stack, Typography, useMediaQuery, useTheme} from '@mui/material';
import UserSummary, {UserSummarySkeleton} from './UserSummary';
import {WebBankingBusinessPartyProfile, WebBankingBusinessRoles} from '../../../redux/@types/SelfAdminTypes';
import GenericDialog from '../../_commons/components/Dialog/GenericDialog';
import TextField from '@mui/material/TextField';


const ProfileImportModal = ({isOpen, handleClose, partyProfile}: {isOpen: boolean, handleClose: Function, partyProfile: WebBankingBusinessPartyProfile}) => {
    const {t} = useTranslation(['commons']);

    const {data: partyProfiles, isFetching} = useListPartyProfileQuery(void(0),{refetchOnMountOrArgChange: true});
    const [searchValue, setSearchValue] = useState<string>('');
    const [selectedProfile, setSelectedProfile] = useState<string>('');

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.only('xs'));

    const handleSubmit = useCallback(() => {
        handleClose(partyProfiles?.find(profile=> profile.id === selectedProfile));
        setSelectedProfile('');
    },[partyProfiles,selectedProfile, handleClose]);

    const _isAdmin = useCallback((pProfile: WebBankingBusinessPartyProfile) =>
        pProfile?.permissions?.findIndex(p => p.code === WebBankingBusinessRoles.SADM) !== -1
    , []);

    const _isDecisionMaker = useCallback((pProfile: WebBankingBusinessPartyProfile) =>
            pProfile?.permissions?.findIndex(p => p.code === WebBankingBusinessRoles.DECI) !== -1
        , []);

    // Filter on firstname/lastname(label) and ssn. Remove also the current profile.
    const filteredParty = useMemo(()=> partyProfiles?.filter(partyProfileItem =>
        (partyProfileItem.party.label.toLowerCase().includes(searchValue.toLowerCase()) ||
        partyProfileItem.ssn?.findIndex(ssn => ssn.code?.includes(searchValue))>=0)
         && partyProfileItem.id !== partyProfile.id)
         ,[partyProfiles,searchValue,partyProfile]);

    return (
      <GenericDialog
        title={t('commons:modules.webpro.self-admin.import.title')}
        handleClose={e => {
          setSelectedProfile('');
          handleClose(false);}
        }
        actions={
          <Stack direction={'row'} spacing={16} sx={{width:'100%', justifyContent:'center'}}
          >
            <Button
              type={'reset'}
              variant='contained'
              color='secondary'
              size={'small'}
              disabled={!selectedProfile}
              onClick={() => {
                setSelectedProfile('');
                handleClose(null);
              }}
            >
              {t('commons:buttons.no')}
            </Button>

            <Button
              type={'submit'}
              variant='contained'
              color='primary'
              size={'small'}
              onClick={handleSubmit}
              disabled={!selectedProfile}
            >
              {t('commons:buttons.yes')}
            </Button>
          </Stack>
        }
        sxAction={{
            position: isSmall ? '' : 'absolute',
            bottom: '-17px',
            width:'100%',
            paddingBottom: 0
        }}
        noPadding={true}
        isOpen={isOpen}
      >
        <Stack
          alignItems='center'
          maxHeight={isSmall?'100vh':'75vh'}
        >
          <Box
            sx={{
              backgroundColor: '#EEFFF0',
              color: '#2BAC6B',
              padding: '40px 15%',
              fontSize: '1rem',
              fontFamily: 'BNPPSans',
              fontWeight: 400
            }}
          >
            {t('commons:modules.webpro.self-admin.import.info')}
          </Box>
          <TextField
            size={'small'}
            fullWidth
            name={'searchKey'}
            label={t('commons:buttons.make-search')}
            value={searchValue}
            onChange={ e => setSearchValue(e.target.value)}
            variant={'outlined'}
            className={'group-text-field-with-button'}
            sx={{
              boxShadow: '0px 5px 10px #27bf7233',
              borderRadius: '5px 0 0 5px',
              backgroundColor: '#fff',
              marginTop: '-20px',
              width: {
                sx: '200px',
                lg: '400px'
              },
              maxWidth: '100%'
            }}
          />

        <Stack
            py={6}
            px={'15%'}
            width={'100%'}
            overflow={'auto'}
            flexShrink={1}
            flexGrow={1}
            spacing={5}
        >
          {isFetching || !partyProfiles ? (
            <UserSummarySkeleton></UserSummarySkeleton>
          ) : filteredParty?.length ? (
            filteredParty.map(partyProfileItem => (
                <UserSummary
                  key={partyProfileItem?.id}
                  admin={_isAdmin(partyProfileItem)}
                  decision_maker={_isDecisionMaker(partyProfileItem)}
                  isLink={false}
                  handleClick={setSelectedProfile}
                  selected={selectedProfile===partyProfileItem?.id}
                  partyProfile={partyProfileItem}
                />
            ))
          ) : (
            <Typography fontSize={'1.2rem'} color={'error'} textAlign={'center'} mt={6} sx={{
                minHeight:'5vh'
            }}>
              {t('commons:modules.webpro.self-admin.import.no-result')}
            </Typography>
          )}
        </Stack>

        <Typography
            variant={'h5'}
            fontWeight={400}
            fontSize={'1.75rem'}
            color={'#48424E'}
            borderTop='1px solid #E5E5E5'
            p={6}
          >
            {t('commons:modules.webpro.self-admin.import.confirm')}
          </Typography>
        </Stack>
      </GenericDialog>
    );
};

export default ProfileImportModal;
