import {
    WebBankingBusinessPartyProfile,
    WebBankingBusinessProfilePermission
} from '../../../redux/@types/SelfAdminTypes';

export type UserManagementState = {
    partyProfile: Partial<WebBankingBusinessPartyProfile>
};

export enum ActionKind {
    ACTION_IMPORT_PROFILE = 'ACTION_IMPORT_PROFILE',
    ACTION_RESET_PARTY_PERMISSION = 'RESET_PARTY_PERMISSION',
    ACTION_ADD_PARTY_PERMISSION = 'ADD_PARTY_PERMISSION',
    ACTION_REMOVE_PARTY_PERMISSION = 'REMOVE_PARTY_PERMISSION'
}

export type Action = {
    type: ActionKind,
    payload: {
        permissionToAdd?: {
            value: WebBankingBusinessProfilePermission | WebBankingBusinessProfilePermission[],
            replace?: boolean
        },
        permissionToRemove?: WebBankingBusinessProfilePermission,
        partyProfile?: WebBankingBusinessPartyProfile
    }
};

export const initialState: UserManagementState = {
    partyProfile: {
        permissions: []
    }
};

export const reducer = (prevState: UserManagementState, action: Action): UserManagementState => {
    const prevPermissions = prevState?.partyProfile?.permissions ?? [];

    switch (action.type) {
        case ActionKind.ACTION_IMPORT_PROFILE:
            return {
                ...prevState,
                partyProfile: {
                    ...prevState.partyProfile,
                    permissions: action.payload.partyProfile?.permissions,
                    paymentEncodingAllowed: action.payload.partyProfile?.paymentEncodingAllowed,
                    transactionProfileEnum: action.payload.partyProfile?.transactionProfileEnum,
                }
            };
        case ActionKind.ACTION_RESET_PARTY_PERMISSION:
            return {
                ...prevState,
                partyProfile: {
                    ...prevState.partyProfile,
                    permissions: []
                }
            };
        case ActionKind.ACTION_ADD_PARTY_PERMISSION:
            const permissionToAdd = action?.payload?.permissionToAdd?.value;
            const replace = action?.payload?.permissionToAdd?.replace ?? false;

            if (permissionToAdd) {
                const permissionsToAddArray = Array.isArray(permissionToAdd) ? [...permissionToAdd] : [permissionToAdd];

                return {
                    ...prevState,
                    partyProfile: {
                        ...prevState.partyProfile,
                        permissions: [...replace ? [] : [...prevPermissions], ...permissionsToAddArray]
                    }
                };
            } else {
                return prevState;
            }
        case ActionKind.ACTION_REMOVE_PARTY_PERMISSION:
            const permissionToRemove = action?.payload?.permissionToRemove;
            const idxPermissionToRemove = prevPermissions.findIndex((p: WebBankingBusinessProfilePermission) => p.code === permissionToRemove?.code);

            if (idxPermissionToRemove === -1) {
                return prevState;
            } else {
                prevPermissions.splice(idxPermissionToRemove, 1);
                return {
                    ...prevState,
                    partyProfile: {
                        ...prevState.partyProfile,
                        permissions: [...prevPermissions]
                    }
                };
            }
        default:
            /* Nothing to do*/
            return prevState;
    }
};
