import { Box, ButtonBase, Grid, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from '../../../../hooks/useAnalytics';
import {
    useCurrentPrimaryContractReference,
    useCurrentWebProContract
} from '../../../../hooks/useCurrentPrimaryContract';
import { useGetWebProContractsQuery } from '../../../../redux/api/webProContractApiRtk';
import ProContractsDialog from '../../../webProContracts/screens/ProContractsDialog';

export const KeyMenuStyled = styled(Typography)({
    fontFamily: 'BNPPSansCondensed',
    fontWeight: 100,
    fontSize: '1rem',
    textTransform: 'uppercase',
    letterSpacing: '0.1rem'
});

export const ValueMenuStyled = styled(Typography)({
    fontFamily: 'BNPPSansCondensed',
    fontWeight: 100,
    fontSize: '1.2rem',
    textTransform: 'uppercase',
    letterSpacing: '0.1rem'
});

const PrimaryContracts = () => {

    const [openProContractsChange, setOpenContractsChange] = useState(false);
    const [switchAvailable, setSwitchAvailable] = useState(false);

    const [currentWebProContract] = useCurrentWebProContract();
    const currentPrimaryContract = useCurrentPrimaryContractReference();
    const {t} = useTranslation(['commons']);
    const {data: webProContracts} = useGetWebProContractsQuery();
    const {pushAnalytics} = useAnalytics();

    useEffect(() => {
        // user can access to button to select web pro contract or primary contract if :
        // he has more than 1 web pro contract OR
        // more than one primary contracts linked to the selected web pro contract
        if (webProContracts && currentWebProContract) {
            setSwitchAvailable(webProContracts.length > 1 || currentWebProContract.primaryContracts.length > 1);
        } else {
            setSwitchAvailable(false);
        }
    }, [switchAvailable, webProContracts, currentWebProContract]);

    const _openProContracstDialog = () => {
        setOpenContractsChange(true);
        pushAnalytics({rule: 'change_contract_tracking_rule', actionName: 'change_contract', buttonName: 'change_contract_button', pageName: 'card operations advanced search', sequenceName: 'change contract'});
    };

    return (
        <Box sx={{
            px: 8,
            py: 4,
            background: '#186C54 0% 0% no-repeat padding-box',
            flexShrink :0
        }} height={{lg: 192, md: 192, xs: 192}}>
            <Typography variant={'h4'} sx={{
                fontFamily: 'BNPPSansCondensed',
                fontWeight: 400,
                fontSize: '1.8rem',
                letterSpacing: '0.05rem',
                color: '#FFFFFF',
                textTransform: 'uppercase',
                opacity: 1
            }}>{t('commons:modules.webpro.menu.activeRoot.selection')}</Typography>

            <KeyMenuStyled variant={'h4'} sx={{color: 'rgba(255,255,255,.5)'}}>{t('commons:modules.webpro.menu.activeRoot.contract')}</KeyMenuStyled>
            <Grid container justifyItems={'center'} alignItems={'center'}>
                <Grid item xs={12}>
                    <ValueMenuStyled variant={'h4'}
                                     color={'white'}>{currentWebProContract?.companyName}</ValueMenuStyled>
                </Grid>
            </Grid>
            <KeyMenuStyled variant={'h4'} sx={{color: 'rgba(255,255,255,.5)'}}>{t('commons:modules.webpro.menu.activeRoot.accountRoot')}</KeyMenuStyled>
            <Grid container justifyItems={'center'} alignItems={'center'}>
                <Grid item xs={12} md={6}>
                    <ValueMenuStyled variant={'h4'}
                                     color={'white'}>{currentPrimaryContract}</ValueMenuStyled>
                </Grid>

            </Grid>
            {switchAvailable &&
                <Grid justifyItems={'center'} alignItems={'center'} mt={2}>
                    <ButtonBase sx={{
                        background: '#FFFFFF',
                        fontFamily: 'BNPPSansCondensed',
                        fontSize: '1.2rem',
                        letterSpacing: '0.08rem',
                        boxShadow: '0px 3px 4px #A7C7B340',
                        border: '1px solid #C3EBDA !important',
                        borderRadius: '5px',
                        py: 2,
                        textTransform: 'uppercase',
                        textDecoration: 'none',
                        color: '#1B985D',
                        fontWeight: 700,
                        width: '100%'
                    }} onClick={() => _openProContracstDialog()}
                                disabled={!switchAvailable}>{t('commons:buttons.other-contracts', {count: webProContracts?.length ?? 0})}
                    </ButtonBase>
                    {openProContractsChange &&
                        <ProContractsDialog opened={openProContractsChange}
                                            onClose={() => setOpenContractsChange(false)}/>
                    }
                </Grid>}
        </Box>
    );
};

export default PrimaryContracts;


