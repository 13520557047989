import React, {ReactNode} from 'react';
import {ButtonBase, styled, Typography, TypographyProps} from '@mui/material';
import {SxProps} from '@mui/system/styleFunctionSx';
import {Theme} from '@mui/material/styles';

export type ChipProps = {
    title: string,
    color: 'green' | 'orange' | 'red' | 'grey' | 'white' | 'gradient-orange',
    icon?: ReactNode,
    onClick?: (event: React.MouseEvent<HTMLElement>) => void
    typographyProps?: TypographyProps,
    sx?: SxProps<Theme>
};

const ChipStyled = styled(ButtonBase)<Pick<ChipProps, 'color'>>(({theme, color}) => ({
    padding: `${theme.spacing(0.2)} ${theme.spacing(0.5)}`,
    borderRadius: '23px',
    display: 'inline-flex',
    alignItems: 'center',

    ' svg': {
        width: 15,
        height: 15
    },
    ' .chip-title': {
        padding: '0.1rem 0.2rem 0 0.2rem',
        fontSize: '0.8rem',
        fontFamily: 'BNPPSans'
    },

    ...color === 'white' && {
        backgroundColor: '#fff',
        boxShadow: '0 3px 4px #2BAC6B1F',
        border: '1px solid #00915A',
        '.chip-title': {
            color: '#00915A'
        }
    },

    ...color === 'green' && {
        backgroundColor: '#DAF5EB',
        boxShadow: '0 3px 4px #2BAC6B1F',
        border: '1px solid #00915A',
        '.chip-title': {
            color: '#00915A'
        }
    },
    ...color === 'orange' && {
        backgroundColor: '#FFF5E5',
        boxShadow: '0 3px 4px #AC6B2B1F',
        border: '1px solid #FF9314',
        '.chip-title': {
            color: '#FF9314'
        }
    },
    ...color === 'red' && {
        backgroundColor: '#FFEDED',
        boxShadow: '0 3px 4px #AC2B2B1F',
        border: '1px solid #FF4343',
        '.chip-title': {
            color: '#FF4343'
        }
    },
    ...color === 'grey' && {
        backgroundColor: '#F4F5F5',
        boxShadow: '0 3px 4px #6E6E6E1F',
        border: '1px solid #9C93A2',
        '.chip-title': {
            color: '#9C93A2'
        }
    },
    ...color === 'gradient-orange' && {
        background: 'transparent linear-gradient(253deg, #FDD49F 0%, #FFA67C 100%) 0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 10px #FAA74767',
        border: 'none',
        '.chip-title': {
            color: '#fff'
        }
    }
}));

const Chip = ({title, color, icon, onClick, typographyProps, sx}: ChipProps) => {

    return (
        <ChipStyled color={color} onClick={onClick} disabled={!onClick} sx={sx}>
            {icon}
            <Typography {...typographyProps} noWrap className="chip-title">{title}</Typography>
        </ChipStyled>
    );
};

export default Chip;
