import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from './api-utils';
import {CustomerInformationForNotification} from "../@types/CustomerProfileTypes.ts";
import {SignatureRequest} from "../@types/CommonTypes.ts";

const URL_API = '/business_webprofile/rest/v1/customerinformation';

export type CustomerInformation = {
    lastName: string;
    firstName: string;
    title: string;
    age: number;
    employee: boolean;
    phoneNumber: string;
    phonePrefix: string;
    emailAddress: string;
    nationality: string;
    birthDate: string;
    medalliaIdentifier: number;
    agencyCode: string;
    ihub: boolean;
};

export const customerApi = createApi({
    reducerPath: 'customerApi',
    baseQuery: axiosBaseQuery({
        baseUrl: URL_API
    }),
    tagTypes: ['NotificationInformation'],

    endpoints: (builder) => ({
        getCustomerInformation: builder.query<CustomerInformation, void>({
            query: () => ({
                url: '/get',
                method: 'POST',
                data: {}
            }),
            keepUnusedDataFor: 5
        }),

        getNotificationCustomerInformation: builder.query<CustomerInformationForNotification, void>({
            query: () => ({
                url: '/notifications/get',
                method: 'POST',
                data: {}
            }),
            providesTags: [{type: 'NotificationInformation', id: 'SINGLE'}] /* SINGLE because informations shared across all contracts, roots, etc. */
        }),

        saveNotificationCustomerInformation: builder.mutation<void, CustomerInformationForNotification & {signatureRequest: SignatureRequest}>({
            query: ({email, prefix, phoneNumber, signatureRequest}) => ({
                url: '/notifications/put',
                method: 'POST',
                data: {
                    phonePrefix: prefix,
                    phoneNumber,
                    emailAddress: email,
                    signatureRequest
                }
            }),
            invalidatesTags: [{type: 'NotificationInformation', id: 'SINGLE'}]
        }),

    })
});

export const {
    useGetCustomerInformationQuery,
    useGetNotificationCustomerInformationQuery,
    useSaveNotificationCustomerInformationMutation
} = customerApi;
