import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from './api-utils';

const URL_API = '/business_webmsg/rest/v1';

export const pingApi = createApi({
    reducerPath: 'pingApi',
    baseQuery: axiosBaseQuery({
        baseUrl: URL_API
    }),
    endpoints: (builder) => ({
        ping: builder.query<boolean, void>({
            query: () => ({
                url: '/ping',
                method: 'GET',
                data: {}
            })
        })
    })
});

export const {usePingQuery} = pingApi;
