import { createApi } from '@reduxjs/toolkit/query/react';
import {
    ActivateECommerceCardRequest,
    ActivateECommerceResponse,
    Active3DSecureRequest,
    Active3DSecureResponse,
    CancelCardContractRequest,
    CancelCardContractResponse,
    DetailsCardRequest,
    DynamicSecureEligibleRequest,
    DynamicSecureEligibleResponse,
    ECommerceStatusCardRequest,
    ECommerceStatusResponse,
    LockCardEligibleResponse,
    LockCardRequest,
    LockCardResponse,
    UnlockCardRequest,
    UpdateLimitCardContracResponse,
    UpdateLimitCardContractRequest
} from '../@types/cardTypes';
import { axiosBaseQuery } from './api-utils';

const URL_API = '/business_webpaymentpro/rest/card';

export const selfCareCardApi = createApi({
    reducerPath: 'selfCareCardApi',
    baseQuery: axiosBaseQuery({
        baseUrl: URL_API
    }),
    endpoints: builder => ({
        detailsCard: builder.query<any, DetailsCardRequest>({
            query: request => ({
                url: '/details',
                method: 'POST',
                data: request
            })
        }),
        cancelCard: builder.mutation<CancelCardContractResponse, CancelCardContractRequest>({
            query: request => ({
                url: '/cancel',
                method: 'POST',
                data: request
            })
        }),
        isLockEligibleCard: builder.query<LockCardEligibleResponse, LockCardRequest>({
            query: request => ({
                url: '/is-lock-eligible',
                method: 'POST',
                data: request
            })
        }),
        lockCard: builder.mutation<LockCardResponse, LockCardRequest>({
            query: request => ({
                url: '/lock',
                method: 'POST',
                data: request
            })
        }),
        unlockCard: builder.mutation<LockCardResponse, UnlockCardRequest>({
            query: request => ({
                url: '/unlock',
                method: 'POST',
                data: request
            })
        }),
        activateECommerce: builder.mutation<ActivateECommerceResponse, ActivateECommerceCardRequest>({
            query: request => ({
                url: '/activate-e-commerce',
                method: 'POST',
                data: request
            })
        }),
        eCommerceStatus: builder.query<ECommerceStatusResponse, ECommerceStatusCardRequest>({
            query: request => ({
                url: '/is-e-commerce-activated',
                method: 'POST',
                data: request
            })
        }),
        updateLimitCard: builder.mutation<UpdateLimitCardContracResponse, UpdateLimitCardContractRequest>({
            query: request => ({
                url: '/change-limit',
                method: 'POST',
                data: request
            })
        }),
        getAllSSNForCardHolder: builder.query<DynamicSecureEligibleResponse, DynamicSecureEligibleRequest>({
            query: request => ({
                url: '/3ds-details',
                method: 'POST',
                data: request
            })
        }),
        activate3DSCard: builder.mutation<Active3DSecureResponse, Active3DSecureRequest>({
            query: request => ({
                url: '/activate-3ds',
                method: 'POST',
                data: request
            })
        })
    })
});

export const {
    useDetailsCardQuery,
    useCancelCardMutation,
    useIsLockEligibleCardQuery,
    useLockCardMutation,
    useUnlockCardMutation,
    useActivateECommerceMutation,
    useECommerceStatusQuery,
    useUpdateLimitCardMutation,
    useGetAllSSNForCardHolderQuery,
    useActivate3DSCardMutation
} = selfCareCardApi;
