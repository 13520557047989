import { Box, FormControl, MenuItem, Paper, Select, SelectChangeEvent, SxProps, Typography, keyframes, styled } from '@mui/material';
import React, { useState } from 'react';
import DashedDivider from '../Divider/DashedDivider';

const DASHED_NO_MARGIN = '0 !important';
const spinArrowDownToUp = keyframes`
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(270deg);
  }
`;
const spinArrowUpToDown = keyframes`
  0% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(90deg);
  }
`;

const Arrow = styled(Paper)({
  backgroundImage: 'linear-gradient(91deg, #8DE541 0%, #2AAB44 100%)',
  clipPath: 'polygon(0 0, 100% 50%, 0 100%)',
  cursor: 'pointer',
  zIndex: '-1!important'
});
const ArrowUp = styled(Arrow)({
  animation: `${spinArrowDownToUp} 0.2s linear`,
  transform: 'rotate(270deg)'
});

const ArrowDown = styled(Arrow)({
  animation: `${spinArrowUpToDown} 0.2s linear`,
  transform: 'rotate(90deg)'
});

const MenuItemStyled = styled(MenuItem)({
  fontFamily: 'BNPPSansCondensed',
  fontWeight: 'bold',
  fontSize: '1.4rem',
  textTransform: 'uppercase',
  letterSpacing: '0.03rem',
  minWidth: '90px'
});

const TypographyStyled = styled(Typography)({
  fontFamily: 'BNPPSansCondensed',
  fontSize: '1.3rem',
  color: '#1B985D',
  textTransform: 'uppercase',
  letterSpacing: '0.03rem'
});

const TypographyStyledDashed = styled(Typography)({
  fontFamily: 'BNPPSans',
  fontSize: '1rem',
  color: '#1B985D',
  textTransform: 'uppercase',
  letterSpacing: '0.03rem',
  textDecoration: 'dashed underline 0.05rem',
  textUnderlineOffset: '0.2rem'
});

const ActionSelect = ({
  currentValue,
  listItems,
  valueChanged,
  arrowStyle,
  selectStyle = {maxWidth: '70px !important'},
  noBorder = false,
  textStyle = false
}: React.PropsWithoutRef<{
  currentValue: string,
  listItems: {value: string, display: string, style?: SxProps}[],
  valueChanged: (e: SelectChangeEvent) => void,
  arrowStyle: SxProps,
  selectStyle?: SxProps,
  noBorder?: boolean,
  textStyle?: boolean
}>) => {
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const _handleChange = async (value: SelectChangeEvent) => {
    setDropDownOpen(false);
    valueChanged(value);
  };

  return (
  <FormControl sx={selectStyle}>
    <Select
        IconComponent={() => (
            dropDownOpen ?
            <ArrowUp sx={arrowStyle}/>
            : <ArrowDown sx={arrowStyle}/>
        )}
        onChange={e => _handleChange(e)}
        variant={textStyle ? 'standard' : undefined}
        value={currentValue}
        renderValue={val =>
            <Box>
                {textStyle ?
                  <TypographyStyledDashed variant={'body1'}>{val}</TypographyStyledDashed>
                  :
                  <>
                  <TypographyStyled variant={'body1'}>{val}</TypographyStyled>
                  <div style={{
                    backgroundImage: 'linear-gradient(to right, #8DE541 0%, white 100%)',
                    backgroundSize: '4px 10px',
                    backgroundPosition: 'top',
                    backgroundRepeat: 'repeat-x',
                    height: '1.5px',
                    width: '35px',
                    marginTop: '2px'
                  }}/>
                  </>
                }
            </Box>
        }
        displayEmpty
        sx={{
            height: '32px',
            fontSize: '1.3rem',
            lineHeight: '1.3rem',
            borderRadius: '30px',
            boxShadow: noBorder ? '' : '0px 3px 4px #A7C7B340',
            border: noBorder ? '' : '1px solid #C3EBDA',
            '&::before,&::after,':{
              border: noBorder ? '0 !important': ''
            }
        }}
        MenuProps={{
            PaperProps: {
                sx: {
                    borderRadius: '15px',
                    display: 'flex',
                    justifyContent: 'center',
                    '& ul': {
                      paddingY: noBorder ? '0px' : ''
                    },
                    '& .MuiMenuItem-root': {
                        padding: 2,
                        display: 'flex',
                        justifyContent: textStyle ? 'left' : 'center',
                        marginLeft: textStyle ? '' : '-25px',
                        fontWeight: textStyle ? '300' : ''
                    }
                }
            }, sx: {maxHeight: '28rem', top: '-5px'}
        }}
        open={dropDownOpen}
        onOpen={
            () => setDropDownOpen(true)
        }
        onClose={
            () => setDropDownOpen(false)
        }>
          {/* Can't use map becaue of dashed divider and Select component rules */}
          {listItems[0] && <MenuItemStyled key={listItems[0].value} value={listItems[0].value} sx={listItems[0].style}>{listItems[0].display}</MenuItemStyled>}
          {listItems[1] && <DashedDivider sx={{margin: noBorder? DASHED_NO_MARGIN:''}} variant={'middle'}/>}
          {listItems[1] && <MenuItemStyled key={listItems[1].value} value={listItems[1].value} sx={listItems[1].style}>{listItems[1].display}</MenuItemStyled>}
          {listItems[2] && <DashedDivider sx={{margin: noBorder? DASHED_NO_MARGIN:''}} variant={'middle'}/>}
          {listItems[2] && <MenuItemStyled key={listItems[2].value} value={listItems[2].value} sx={listItems[2].style}>{listItems[2].display}</MenuItemStyled>}
          {listItems[3] && <DashedDivider sx={{margin: noBorder? DASHED_NO_MARGIN:''}} variant={'middle'}/>}
          {listItems[3] && <MenuItemStyled key={listItems[3].value} value={listItems[3].value} sx={listItems[3].style}>{listItems[3].display}</MenuItemStyled>}
          {listItems[4] && <DashedDivider sx={{margin: noBorder? DASHED_NO_MARGIN:''}} variant={'middle'}/>}
          {listItems[4] && <MenuItemStyled key={listItems[3].value} value={listItems[3].value} sx={listItems[4].style}>{listItems[3].display}</MenuItemStyled>}

    </Select>
  </FormControl>
  );
};

export default ActionSelect;
