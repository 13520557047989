import React, {useCallback, useContext} from 'react';
import {Outlet} from 'react-router-dom';
import {
    AppBar,
    Box,
    Button,
    IconButton,
    Slide,
    Stack,
    Toolbar,
    Typography
} from '@mui/material';
import Logo from '../../../assets/images/logo/bgl-logo.svg';
import {useTranslation} from 'react-i18next';
import {RootContext} from '../../../context/root-context';
import {useMenuWidth} from '../../../hooks/useMenuWidth';
import MenuIcon from '@mui/icons-material/Menu';
import useWidth from '../../../hooks/useMuiWidth';
import Footer from '../components/Footer/Footer';
import {useLocation, useNavigate} from 'react-router';
import LogoutUserButton from '../../authentication/components/LogoutUserButton';
import NotificationsButton from '../../notifications/NotificationsButton';

export type AppBarWidth = 'full' | 'partial';

const Layout = ({width = 'full'}: { width?: AppBarWidth }) => {
    const menuWidth = useMenuWidth();
    const {isAuthenticated} = useContext(RootContext);
    const {t} = useTranslation(['commons']);
    const {drawerOpened, setDrawerOpened} = useContext(RootContext);
    const widthBreakpoint = useWidth();
    const location = useLocation();
    const navigate = useNavigate();

    const isContractSelectionRoute = location.pathname === '/contract-selection';
    const isGclRoute = location.pathname.startsWith('/gcl');
    const isIhub = location.pathname.endsWith('ihub');
    const isAuthPage = isContractSelectionRoute || location.pathname.indexOf('/auth') >= 0;

    const handleDrawerToggle = () => {
        setDrawerOpened(!drawerOpened);
    };

    const rfExtCtr = window.location.hash.match(/^#\/(\w{16})\/\w{6}\/(.*)$/)?.at(1);
    const rfPmrCtr = window.location.hash.match(/^#\/(\w{16})\/(\w{6})\/(.*)$/)?.at(2);

    const _handleBtnBackClick = useCallback(() => {
        navigate(`/${rfExtCtr}/${rfPmrCtr}/dashboard`, {replace: true});
    }, [navigate, rfExtCtr, rfPmrCtr]);

    return (
        <>
            {!isAuthPage &&
                <AppBar position={width === 'partial' ? 'fixed' : 'sticky'} variant={'elevation'} elevation={1}
                        color={'inherit'} sx={{
                    ...width === 'partial' && {
                        width: {md: `calc(100% - ${menuWidth}px)`},
                        marginLeft: {md: `${menuWidth}px`}
                    }
                }}>
                    <Toolbar sx={{justifyContent: 'space-between'}}>
                        <Box sx={{display: {md: 'none'}}}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{px: 4, display: {md: 'none'}}}
                            >
                                <MenuIcon/>
                            </IconButton>
                        </Box>
                        <Stack sx={{display: {xs: 'none', sm: 'flex'}}} direction={'row'} flex={0.8} alignItems={'center'}
                               pl={'18px'} spacing={12}>
                            {isIhub &&
                                <Slide direction={'right'} in={isIhub} appear={isIhub}>
                                    <Button variant={'contained'} onClick={_handleBtnBackClick}>
                                        {t('commons:modules.webpro.ihub.exit')}
                                    </Button>
                                </Slide>
                            }
                            <img alt={'logo'} src={Logo} style={{width: '160px', maxWidth: '70%'}}/>
                            <Typography fontWeight={400}
                                        fontSize={'1.142857142857143rem'}>{t('commons:slogan')}</Typography>
                        </Stack>

                        <Stack sx={{display: {xs: 'flex', sm: 'none'}}} direction={'row'} flex={1} alignItems={'center'}>
                            <img alt={'logo'} src={Logo} style={{height: '35px'}}/>
                        </Stack>
                        <Stack pr={'18px'} direction={'row'} spacing={12}>
                            <NotificationsButton contractRef={rfExtCtr} primaryContract={rfPmrCtr}/>
                            <LogoutUserButton/>
                        </Stack>
                        {import.meta.env.DEV &&
                            <Box position={'absolute'} width={50} height={20} top={5} left={5}>
                                {widthBreakpoint}
                            </Box>
                        }
                    </Toolbar>
                </AppBar>
            }

            <Outlet/>

            {isAuthenticated && !isGclRoute && !isContractSelectionRoute && !isIhub &&
                <Footer width={`calc(100% - ${menuWidth}px)`} marginLeft={`${menuWidth}px`}/>
            }
            {isIhub &&
                <Footer width={'100%'} marginLeft={'0'}/>
            }
        </>
    );
};

export default Layout;
