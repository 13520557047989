import React, {useCallback, useMemo, useState} from 'react';
import Grid from '@mui/material/Grid2';
import {Box} from '@mui/material';
import axios from 'axios';
import UserSummary, {UserSummarySkeleton} from '../components/UserSummary';
import UserSearchBar from '../components/UserSearchBar';
import {useListPartyProfileQuery} from '../../../redux/api/selfAdminApiRtk';
import {
    DisplayStyle,
    WebBankingBusinessPartyProfile,
    WebBankingBusinessRoles
} from '../../../redux/@types/SelfAdminTypes';
import {useLanguage} from '../../../hooks/useLanguage';
import {useCurrentWebProContractReference} from '../../../hooks/useCurrentPrimaryContract';

const ContractUsers = () => {
    const {data: partyProfiles, isFetching} = useListPartyProfileQuery(void(0),{refetchOnMountOrArgChange: true});
    const [isExporting, setIsExporting] = useState(false);
    const [language] = useLanguage();
    const webProContractId = useCurrentWebProContractReference();
    const [filter, setFilter] = useState<DisplayStyle>('ALL');
    const [searchKey, setSearchKey] = useState('');

    const _isAdmin = useCallback((partyProfile: WebBankingBusinessPartyProfile) => partyProfile?.permissions?.findIndex(p => p.code === WebBankingBusinessRoles.SADM) !== -1, []);
    const _isDecisionMaker = useCallback((partyProfile: WebBankingBusinessPartyProfile) => partyProfile?.permissions?.findIndex(p => p.code === WebBankingBusinessRoles.DECI) !== -1, []);

    const _getCurrentProfiles = () => searchKey && searchKey.length > 0 ? searchFilteredPartyProfiles : filteredPartyProfiles;

    const _setFilter = useCallback((value: DisplayStyle) => {
        setFilter(value as DisplayStyle);
    }, [setFilter]);

    const _handleSearchChange = useCallback((value: string, currentList: WebBankingBusinessPartyProfile[]) => {
        if(!value || value.length === 0){
            return [];
        }

        return currentList.filter(profile => {
            if(profile.party.label.toLocaleLowerCase().includes(value.toLocaleLowerCase())){
                return true;
            }
            for (const ssn of profile.ssn) {
                if(ssn.code?.includes(value)){
                    return true;
                }
            }
            return false;
        });
    }, []);

    const _handleFilterChange = useCallback((value: DisplayStyle) => {
        if(partyProfiles === undefined || partyProfiles.length === 0){
            return [];
        }

        const copy = [...partyProfiles];

        let filteredList: WebBankingBusinessPartyProfile[] = [];
        switch (value) {
            case 'ALL':
                filteredList = [...copy];
                break;
            case 'ACTIVE':
                filteredList = copy.filter(profile => !profile.accessBlocked);
                break;
            case 'SUSPENDED':
                filteredList = copy.filter(profile => profile.accessBlocked);
                break;
            case 'ADMIN':
                filteredList = copy.filter(profile => _isAdmin(profile));
                break;
            case 'NOADMIN':
                filteredList = copy.filter(profile => !_isAdmin(profile));
                break;
            default:
                //nothing
                break;
        }
        //Makes sure both types of filters are applied together if needs be
        _handleSearchChange(searchKey, filteredList);
        return filteredList;
    }, [partyProfiles, _handleSearchChange, _isAdmin, searchKey]);

    const filteredPartyProfiles: WebBankingBusinessPartyProfile[] = useMemo(() => _handleFilterChange(filter), [filter, _handleFilterChange]);

    const searchFilteredPartyProfiles: WebBankingBusinessPartyProfile[] = useMemo(() => _handleSearchChange(searchKey, filteredPartyProfiles), [searchKey, filteredPartyProfiles, _handleSearchChange]);

    const _handleExport = () => {
        setIsExporting(true);

        axios({
            url: `/business_webselfadm/rest/party-profile/export?rfCtr=${webProContractId}&lg=${language}`,
            responseType: 'blob'
        }).then(response => {
            setIsExporting(false);
            const href = URL.createObjectURL(response.data);
            const filename = response.headers['content-disposition'].split('filename=')[1];
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', filename.replaceAll('"', ''));
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }).catch(e => {
            setIsExporting(false);
        });
    };

    return (
        <Box mt={12}>
            <UserSearchBar
            filter={filter}
            filterItems={['ALL', 'ADMIN', 'NOADMIN', 'ACTIVE', 'SUSPENDED']}
            filterChange={_setFilter}
            exportContent={_handleExport}
            exporting={isExporting}
            exportType={'USERS'}
            searchKey={searchKey}
            searchKeyChange={setSearchKey}/>

            {isFetching ?
                <UserListSkeleton/>
                :
                _getCurrentProfiles() &&
                <Grid container spacing={8}>
                    {
                        _getCurrentProfiles().map(partyProfile =>
                            <Grid key={partyProfile.id} size={{ xs: 12, md: 6 }}>
                                <UserSummary
                                    key={partyProfile.id}
                                    admin={_isAdmin(partyProfile)}
                                    decision_maker={_isDecisionMaker(partyProfile)}
                                    partyProfile={partyProfile}/>
                            </Grid>
                        )
                    }
                </Grid>
            }
        </Box>
    );
};

const UserListSkeleton = () => {
    return (
        <Grid container spacing={8}>
            {
                Array(6).fill({}).map((nope, idx) =>
                    <Grid key={idx} size={{ xs: 12, md: 6 }}>
                        <UserSummarySkeleton/>
                    </Grid>
                )
            }
        </Grid>
    );
};

export default ContractUsers;
