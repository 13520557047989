import React from 'react';
import ImgMobile from './images/luxtrust/mobile.png';
import ImgScan from './images/luxtrust/scan.png';
import ImgSmartCard from './images/luxtrust/smartcard.png';
import ImgCardCode from './images/first-connection/cardcode.png';
import ImgLuxtrust from './images/first-connection/luxtrust.png';
import wavesRaw from './images/commons/waves.svg?raw';
import {Box, Stack, SxProps, Typography} from '@mui/material';

import loader from './images/bgl-loader.svg';
import {Theme} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';

const BOX_SX = {
    'small': {
        minWidth: {xs: '35px', sm: '50px'},
        maxWidth: {xs: '35px', sm: '50px'}
    } as SxProps<Theme>,
    'medium': {
        minWidth: {xs: '65px', sm: '80px'},
        maxWidth: {xs: '65px', sm: '80px'}
    } as SxProps<Theme>,
    'large': {
        minWidth: {xs: '95px', sm: '110px'},
        maxWidth: {xs: '95px', sm: '110px'}
    } as SxProps<Theme>
};

export const ImgLuxtrustMobile = ({size = 'medium'}: { size?: 'small' | 'medium' | 'large' }) => (
    <Box sx={BOX_SX[size]}>
        <img src={ImgMobile} alt={'Luxtrust Mobile'} style={{width: '100%'}}/>
    </Box>
);
export const ImgLuxtrustScan = ({size = 'medium'}: { size?: 'small' | 'medium' | 'large' }) => (
    <Box sx={BOX_SX[size]}>
        <img src={ImgScan} alt={'Luxtrust Scan'} style={{width: '100%'}}/>
    </Box>
);
export const ImgLuxtrustSmartCard = ({size = 'medium'}: { size?: 'small' | 'medium' | 'large' }) => (
    <Box sx={BOX_SX[size]}>
        <img src={ImgSmartCard} alt={'Luxtrust Smartcard'} style={{width: '100%'}}/>
    </Box>
);

export const ImgSmidCardCode = ({size = 'medium'}: { size?: 'small' | 'medium' | 'large' }) => (
    <Box sx={BOX_SX[size]}>
        <img src={ImgCardCode} alt={'SMID / Card Code'} style={{width: '100%'}}/>
    </Box>
);

export const ImgLuxtrustDevice = ({size = 'medium'}: { size?: 'small' | 'medium' | 'large' }) => (
    <Box sx={{...BOX_SX[size], minHeight: {xs: '65px', sm: '80px'}, display: 'flex', alignItems: 'center'}}>
        <img src={ImgLuxtrust} alt={'Luxtrust'} style={{width: '100%'}}/>
    </Box>
);

const LOADER_SIZE = '120px';

export const BglSvgLoader = ({size = 'medium'}: { size?: 'small' | 'medium' | 'large' }) => (
  <Box sx={BOX_SX[size]} margin={'auto'}>
    <img src={loader} width={LOADER_SIZE} alt={'loader'} style={{width: '100%'}}/>
  </Box>
);

export const FullPageBglSvgLoader = () => {
  const {t} = useTranslation(['commons']);

  return (
      <Box
          style={{display: 'grid', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh'}}>
          <Stack alignItems={'center'}>
              <img src={loader} width={LOADER_SIZE} height={LOADER_SIZE} alt={'loader'}/>
              <Typography variant={'h6'} fontWeight={400}>{t('commons:labels.loading')}</Typography>
          </Stack>
      </Box>
  );
};

export const InnerPageBglSvgLoader = ({message}: React.PropsWithoutRef<{ message?: string }>) => {
    const {t} = useTranslation(['commons']);

    return (
        <Box
            style={{display: 'grid', justifyContent: 'center', alignItems: 'center', width: '100%', height: '50vh'}}>
            <Stack alignItems={'center'}>
                <img src={loader} width={LOADER_SIZE} height={LOADER_SIZE} alt={'loader'}/>
                <Typography variant={'h6'}
                            fontWeight={400}>{message ? message : t('commons:labels.loading')}</Typography>
            </Stack>
        </Box>
    );
};

export const b64waves = btoa(wavesRaw);
