import { Box, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import RequirePermissions from '../../../../components/RequirePermissions';
import { ParentUpdatedData } from '../../../../redux/@types/SelfAdminTypes';
import { Permission } from '../../../../redux/@types/WebProContractTypes';
import { useGetPermissionsQuery } from '../../../../redux/api/selfAdminApiRtk';
import { ChipProps } from '../../../_commons/components/Chip/Chip';
import LeftAndRightValueTextField from '../../../_commons/components/Form/LeftAndRightValueTextField';
import GenericPaper from '../../../_commons/components/Paper/GenericPaper';
import UserStatusIcon from '../UserStatusIcon';

const MulitpleLineValidation = ({boxKey, update, label, url}:
                                    { boxKey: number, update: ParentUpdatedData, label: string , url: string }) => {
    const {data: allPermissions} = useGetPermissionsQuery();
    const {t} = useTranslation(['commons']);
    const navigate = useNavigate();

    const oldValues: { label: string, value: string }[] = [];
    const newValues: { label: string, value: string }[] = [];

    update.children.forEach((child) => {

        const childLabel = allPermissions?.find((permission) => permission.code === child.type)?.label ?? child.label ??
            // @ts-ignore
            t(`commons:modules.webpro.self-admin.code.${child.type}`) as string
        ;
        const oldValue = child.oldValue ?
            child.oldValue.replace('true', t('commons:buttons.yes'))
                .replace('false', t('commons:buttons.no'))
                .replace('PDD', t(
                    'commons:modules.webpro.self-admin.payments-no-signature-profile'
                )): ''
        ;
        const newValue = child.newValue ?
            child.newValue.replace('true', t('commons:buttons.yes'))
                .replace('false', t('commons:buttons.no'))
                .replace('PDD', t(
                    'commons:modules.webpro.self-admin.payments-no-signature-profile'
                )): ''
        ;

        oldValues.push({label: childLabel, value: oldValue});
        newValues.push({label: childLabel, value: newValue});
    });


    return (
        <Box mt={12} key={boxKey}>
            <Box mt={12}>
                <GenericPaper sxPaper={{position: 'relative', padding: 0}} sxBox={{paddingBottom: 0}}>
                    <Typography variant={'h3'} color={'primary'} fontWeight={400} padding={10}>
                        {label}
                    </Typography>
                    {update.infoUser &&
                        <Stack direction={'row'} alignItems={'center'} spacing={8} padding={6} pt={0} pb={12}>
                            <UserStatusIcon admin={false} disabled={false} width={50} height={50}/>
                            <Stack direction={'column'} alignItems={'flex-start'}>
                                <Typography variant={'h4'} fontWeight={400} mb={4}>
                                    {update.infoUser.firstName}, {update.infoUser.lastName}
                                </Typography>
                                {
                                    update.infoUser.ssns.map(ssn =>
                                        <Typography key={ssn} variant={'h6'} fontWeight={100} lineHeight={1}>
                                            SSN LUXTRUST: {ssn}
                                        </Typography>
                                    )
                                }
                            </Stack>
                        </Stack>
                    }
                    <Stack direction={'row'} alignItems={'center'} width={'100%'}>
                        <LeftAndRightValueTextField
                            leftValues={oldValues}
                            rightValues={newValues}
                            chipLeft={{
                                title: t('commons:modules.webpro.self-admin.old-value'),
                                color: 'grey'
                            } as ChipProps}
                            chipRight={{
                                title: t('commons:modules.webpro.self-admin.new-value'),
                                color: 'green'
                            } as ChipProps}
                        />
                    </Stack>
                    <RequirePermissions permissions={[Permission.SELF_ADMIN]}>
                        <Stack direction={'row'} alignItems={'center'} width={'100%'} justifyContent={'center'}
                               sx={{position: 'absolute', bottom: '-18px'}}>
                            <Button variant={'contained'} color={'primary'} size={'small'}
                                    onClick={() => navigate(url)}>
                                {t('commons:buttons.edit')}
                            </Button>
                        </Stack>
                    </RequirePermissions>
                </GenericPaper>
            </Box>
        </Box>
    );
};

export default MulitpleLineValidation;
