import React, {useMemo, useReducer} from 'react';
import {PrimaryContract, WebProContract} from '../redux/@types/WebProContractTypes';
import {useRedirectRules} from '../hooks/useRedirectRules';

export type AppPrivateState = {
    currentPrimaryContract?: PrimaryContract;
    currentWebProContract?: WebProContract;
    dashboardBanner?: {
        loaded: boolean,
        html?: string
    };
};

const initialState: AppPrivateState = {
    currentPrimaryContract: undefined,
    currentWebProContract: undefined,
    dashboardBanner: {
        loaded: false,
        html: undefined
    }
};

enum ActionKind {
    ACTION_SET_CURRENT_PRIMARY_CONTRACT = 'SET_CURRENT_PRIMARY_CONTRACT',
    ACTION_SET_CURRENT_WEBPRO_CONTRACT = 'SET_CURRENT_WEBPRO_CONTRACT',
    ACTION_SET_DASHBOARD_BANNER = 'SET_DASHBOARD_BANNER'
}

type Action = {
    type: ActionKind,
    payload: AppPrivateState
};

type AppPrivateContextInterface = {
    setCurrentPrimaryContract?: (primaryContract: PrimaryContract | undefined) => void,
    setCurrentWebProContract?: (webProContract: WebProContract | undefined) => void,
    setDashboardBanner?: ({loaded, html}: { loaded: boolean, html?: string }) => void
};

const AppPrivateContext = React.createContext<AppPrivateState & AppPrivateContextInterface>(initialState);

const reducer = (prevState: AppPrivateState, action: Action) => {
    switch (action.type) {
        case ActionKind.ACTION_SET_CURRENT_PRIMARY_CONTRACT:
            return {
                ...prevState,
                currentPrimaryContract: action?.payload?.currentPrimaryContract
            };
        case ActionKind.ACTION_SET_CURRENT_WEBPRO_CONTRACT:
            return {
                ...prevState,
                currentWebProContract: action?.payload?.currentWebProContract
            };
        case ActionKind.ACTION_SET_DASHBOARD_BANNER:
            return {
                ...prevState,
                dashboardBanner: action?.payload?.dashboardBanner
            };
        default:
            /* Nothing to do*/
            return prevState;
    }
};

const AppPrivateContextProvider = ({children}: { children: JSX.Element | Array<JSX.Element> }): JSX.Element => {
    const [state, dispatch] = useReducer(reducer, initialState);
    useRedirectRules(state);

    const value = useMemo(
        () => ({
            setCurrentPrimaryContract: (currentPrimaryContract: PrimaryContract | undefined) => {
                dispatch({
                    type: ActionKind.ACTION_SET_CURRENT_PRIMARY_CONTRACT,
                    payload: {...state, currentPrimaryContract}
                });
            },
            currentPrimaryContract: state.currentPrimaryContract,

            setCurrentWebProContract: (currentWebProContract: WebProContract | undefined) => {
                dispatch({
                    type: ActionKind.ACTION_SET_CURRENT_WEBPRO_CONTRACT,
                    payload: {...state, currentWebProContract}
                });
            },
            currentWebProContract: state.currentWebProContract,

            setDashboardBanner: ({loaded, html} : {loaded: boolean, html?: string}) => {
                dispatch({
                    type: ActionKind.ACTION_SET_DASHBOARD_BANNER,
                    payload: {...state, dashboardBanner: {loaded, html}}
                });
            },
            dashboardBanner: state.dashboardBanner
        }),
        [state]
    );

    return <AppPrivateContext.Provider value={value}>{children}</AppPrivateContext.Provider>;
};


export {AppPrivateContextProvider, AppPrivateContext};
