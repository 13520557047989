export const SELFADMIN_CODES = {
    CT_PERS: 'CT_PERS',
    CONTRACT_LB: 'CONTRACT_LB',
    RMV_USER: 'RMV_USER',
    LOG_USR: 'LOG_USR',
    CONTRACT_LIM_STD: 'CONTRACT_LIM_STD',
    CONTRACT_PFL_STD: 'CONTRACT_PFL_STD',
    CONTRACT_LIM_SLR: 'CONTRACT_LIM_SLR',
    CONTRACT_PFL_SLR: 'CONTRACT_PFL_SLR',
    LOG_CTR_MT_LIM_STD: 'LOG_CTR_MT_LIM_STD',
    LOG_CTR_PFL_STD: 'LOG_CTR_PFL_STD',
    LOG_CTR_PAY_STD: 'LOG_CTR_PAY_STD',
    LOG_CTR_PAY_STD_1: 'LOG_CTR_PAY_STD_1',
    LOG_CTR_PAY_STD_0: 'LOG_CTR_PAY_STD_0',
    LOG_CTR_MT_LIM_SLR: 'LOG_CTR_MT_LIM_SLR',
    LOG_CTR_PFL_SLR: 'LOG_CTR_PFL_SLR',
    LOG_CTR_PAY_SLR: 'LOG_CTR_PAY_SLR',
    LOG_CTR_PAY_SLR_1: 'LOG_CTR_PAY_SLR_1',
    LOG_CTR_PAY_SLR_0: 'LOG_CTR_PAY_SLR_0'
};
