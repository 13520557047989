import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from './api-utils';

import {
  AccountsRoot,
  CancelCollectiveRequest,
  CancelResultResponse,
  CancelTransferRequest,
  CollectiveDetailsReponse,
  CollectiveDetailsRequest,
  CollectiveHistoryListResponse,
  Country,
  CountryCodeReporting,
  CreditableRoots,
  CurrencyResponse,
  FeesResponse,
  FeesTypeEnum,
  IbanCountryRules,
  PaymentHistoryDetailsRequest,
  PaymentHistoryDetailsResponse,
  PaymentHistoryListRequest,
  PaymentHistoryListResponse,
  PaymentTypesResponse,
  PreInstructionCountResponse,
  PreInstructionListRequest,
  PreInstructionListResponse,
  PreInstructionRequest,
  PreInstructionResponse,
  ServerDate,
  SignPreInstructionRequest,
  SignStandingOrderPreInstructionRequest,
  StandingOrderDetailsResponse,
  StandingOrderLightHistoryListRequest,
  StandingOrderLightListResponse,
  StandingOrderPreInstructionListResponse,
  StandingOrderPreInstructionResponse,
  StandingOrderRequest,
  TransactionsBulkRequest,
  TransactionsBulkResponse,
  TransferCancelResponse,
  TransferEstimationFees,
  TransferPreValidationResponse
} from '../@types/PaymentProTypes.ts';
import {
  CreateAdvancedTransferData,
  FeesRequest,
  PaymentTypeRequest,
  PaymentTypeType,
  TransferResultResponse
} from '../datastore/features/payment/storeSinglePayment';
import {AdvancedFilterKeysType} from '../../ui/payment/components/DirectDebitsList.tsx';
import {
  AdvancedFilterKeysType as TransactionAdvancedFilterKeysType
} from '../../ui/payment/components/DirectDebitTransactionsList.tsx';
import {TableHeaderSorting} from '../../ui/_commons/components/Table/TableHeader.tsx';
import {Language} from '../../@types/ui.types.ts';
import {CreateStandingOrder, StandingOrderResult} from '../datastore/features/payment/storeStandingOrder.ts';

const URL_API = '/business_webpaymentpro/rest';

export const getPrintDirectDebitDetailsUrl = (primaryContractId: string,
                                              webProContractId: string,
                                              directDebitKind: string,
                                              directDebitId: string,
                                              directDebitKey: string,
                                              documentType: string,
                                              language: Language) => {
  return buildUrlToPrintDetailsOfDirectDebit(primaryContractId, webProContractId, directDebitKind, directDebitId, directDebitKey, documentType, language);
};

export const getPrintDirectDebitListUrl = (primaryContractId: string,
                                           webProContractId: string,
                                           directDebitKind: string,
                                           advancedFilterKeysType: AdvancedFilterKeysType,
                                           tableHeaderSorting: TableHeaderSorting,
                                           documentType: string,
                                           language: Language) => {
  return buildUrlToPrintListOfDirectDebits(primaryContractId, webProContractId, directDebitKind, advancedFilterKeysType, tableHeaderSorting, documentType, language);
};

export const getPrintListOfDirectDebitTransactionsUrl = (primaryContractId: string,
                                                         webProContractId: string,
                                                         directDebitKind: string,
                                                         iban: string,
                                                         type: string,
                                                         mandateId: string,
                                                         advancedFilterKeys: TransactionAdvancedFilterKeysType,
                                                         tableHeaderSorting: TableHeaderSorting,
                                                         documentType: string,
                                                         language: Language) => {
  return buildUrlToPrintListOfDirectDebitTransactions(primaryContractId, webProContractId, directDebitKind, iban, type, mandateId, advancedFilterKeys, tableHeaderSorting, documentType, language);
};

type Parameter = {
  code: string;
  value: string;
};

const addParameters = (parameters: Array<Parameter>): string => {
  let parametersAdded: string = '';
  parameters.forEach(parameter => {
    parametersAdded = parametersAdded.concat('&').concat(parameter.code).concat('=').concat(parameter.value);
  });

  return parametersAdded;
};

const buildUrlToPrintListOfDirectDebitTransactions = (primaryContractId: string,
                                                      webProContractId: string,
                                                      directDebitKind: string,
                                                      iban: string,
                                                      type: string,
                                                      mandateId: string,
                                                      advancedFilterKeys: TransactionAdvancedFilterKeysType,
                                                      tableHeaderSorting: TableHeaderSorting,
                                                      documentType: string,
                                                      language: Language): string => {

  return URL_API
    .concat('/directdebit/print/transaction/list?')
    .concat('webProContractId=')
    .concat(webProContractId)
    .concat('&primaryContractId=')
    .concat(primaryContractId)
    .concat('&iban=')
    .concat(iban)
    .concat('&type=')
    .concat(type)
    .concat('&mandateId=')
    .concat(mandateId)
    .concat('&directDebitKind=')
    .concat(directDebitKind)
    .concat(addTransactionAdvanceFilterKeys(advancedFilterKeys))
    .concat('&documentType=').concat(documentType)
    .concat('&language=').concat(language)
    .concat(addSortingParameters(tableHeaderSorting));
};

const buildUrlToPrintListOfDirectDebits = (primaryContractId: string,
                                           webProContractId: string,
                                           directDebitKind: string,
                                           advancedFilterKeysType: AdvancedFilterKeysType,
                                           tableHeaderSorting: TableHeaderSorting,
                                           documentType: string,
                                           language: Language): string => {

  return URL_API
    .concat('/directdebit/print/list?')
    .concat('webProContractId=')
    .concat(webProContractId)
    .concat('&primaryContractId=')
    .concat(primaryContractId)
    .concat('&directDebitKind=')
    .concat(directDebitKind)
    .concat(addAdvanceFilterKeys(advancedFilterKeysType))
    .concat('&documentType=').concat(documentType)
    .concat('&language=').concat(language)
    .concat(addSortingParameters(tableHeaderSorting));
};

const buildUrlToPrintDetailsOfDirectDebit = (primaryContractId: string,
                                             webProContractId: string,
                                             directDebitKind: string,
                                             directDebitId: string,
                                             directDebitKey: string,
                                             documentType: string,
                                             language: Language): string => {

  return URL_API
    .concat('/directdebit/print/details?')
    .concat('webProContractId=')
    .concat(webProContractId)
    .concat('&primaryContractId=')
    .concat(primaryContractId)
    .concat('&directDebitKind=')
    .concat(directDebitKind)
    .concat('&directDebitKey=')
    .concat(directDebitKey)
    .concat('&directDebitId=')
    .concat(directDebitId)
    .concat('&language=').concat(language)
    .concat('&documentType=').concat(documentType);
};

const addAdvanceFilterKeys = (advancedFilterKeysType: AdvancedFilterKeysType): string => {

  let filtersParameters: Array<Parameter> = [];

  if (advancedFilterKeysType.typeChecked) {
    filtersParameters.push({code: 'type', value: advancedFilterKeysType.type} as Parameter);
  }

  if (advancedFilterKeysType.statusChecked) {
    filtersParameters.push({code: 'status', value: advancedFilterKeysType.status} as Parameter);
  }

  if (advancedFilterKeysType.amountChecked) {
    filtersParameters.push({code: 'minimumAmount', value: advancedFilterKeysType.amountFrom} as Parameter);
    filtersParameters.push({code: 'maximumAmount', value: advancedFilterKeysType.amountTo} as Parameter);
  }

  if (advancedFilterKeysType.debitorAccountChecked) {
    filtersParameters.push({code: 'debitorIban', value: advancedFilterKeysType.debitorAccount} as Parameter);
  }

  if (advancedFilterKeysType.beneficiaryAccountChecked) {
    filtersParameters.push({code: 'creditorIban', value: advancedFilterKeysType.beneficiaryAccount} as Parameter);
  }

  if (advancedFilterKeysType.executionDateChecked) {
    filtersParameters.push({
      code: 'lastExecutionDateFrom',
      value: (advancedFilterKeysType.executionDateFrom as Date).toLocaleDateString('fr-FR')
    } as Parameter);
    filtersParameters.push({
      code: 'lastExecutionDateTo',
      value: (advancedFilterKeysType.executionDateTo as Date).toLocaleDateString('fr-FR')
    } as Parameter);
  }

  return addParameters(filtersParameters);
};

const addTransactionAdvanceFilterKeys = (advancedFilterKeysType: TransactionAdvancedFilterKeysType): string => {

  let filtersParameters: Array<Parameter> = [];

  if (advancedFilterKeysType.reason) {
    filtersParameters.push({code: 'reason', value: advancedFilterKeysType.reason} as Parameter);
  }

  if (advancedFilterKeysType.amountChecked) {
    filtersParameters.push({code: 'minimumAmount', value: advancedFilterKeysType.amountFrom} as Parameter);
    filtersParameters.push({code: 'maximumAmount', value: advancedFilterKeysType.amountTo} as Parameter);
  }

  if (advancedFilterKeysType.bglReferenceChecked) {
    filtersParameters.push({code: 'bglReference', value: advancedFilterKeysType.bglReference} as Parameter);
  }

  if (advancedFilterKeysType.luxeseReferenceChecked) {
    filtersParameters.push({code: 'luxeseReference', value: advancedFilterKeysType.luxeseReference} as Parameter);
  }

  if (advancedFilterKeysType.referenceChecked) {
    filtersParameters.push({code: 'reference', value: advancedFilterKeysType.reference} as Parameter);
  }

  if (advancedFilterKeysType.dateChecked) {
    filtersParameters.push({
      code: 'dateFrom',
      value: (advancedFilterKeysType.dateFrom as Date).toLocaleDateString('fr-FR')
    } as Parameter);
    filtersParameters.push({
      code: 'dateTo',
      value: (advancedFilterKeysType.dateTo as Date).toLocaleDateString('fr-FR')
    } as Parameter);
  }

  return addParameters(filtersParameters);
};

const addSortingParameters = (tableHeaderSorting: TableHeaderSorting): string => {

  let addedParameters: string = '&orderBy='.concat(tableHeaderSorting.field);

  if (tableHeaderSorting.order) {
    addedParameters.concat('&ordering=')
      .concat(tableHeaderSorting.order);
  }

  return addedParameters;
};

export const paymentProApi = createApi({
  reducerPath: 'paymentProApi',
  baseQuery: axiosBaseQuery({
    baseUrl: URL_API
  }),
  tagTypes: ['Country', 'PreValidation', 'PaymentDetails', 'StandingOrderDetails', 'CollectiveDetails'],

  endpoints: builder => ({

    getServerDate: builder.query<ServerDate, void>({
      query: () => ({
        url: '/context/server/date',
        method: 'POST'
      })
    }),

    getCountries: builder.query<Country[], void>({
      query: () => ({
        url: '/context/countries/list',
        method: 'POST'
      }),
      providesTags: (result, messages, request) => [
        {type: 'Country', id: 'LIST'}
      ]
    }),

    getIbanRules: builder.query<{
      [p: string]: IbanCountryRules
    }, void>({
      query: () => ({
        url: '/context/rules/iban/get',
        method: 'POST'
      })
    }),

    getCurrencyList: builder.query<string[], void>({
      query: () => ({
        url: '/transfer/characteristic/currencies/get',
        method: 'POST'
      }),
      transformResponse: (response: CurrencyResponse) => response.currencies,
      keepUnusedDataFor: 60
    }),

    getFeesList: builder.query<FeesTypeEnum[], FeesRequest>({
      query: request => ({
        url: '/transfer/characteristic/fees/get',
        method: 'POST',
        data: request
      }),
      transformResponse: (response: FeesResponse) => response.types
    }),

    getPaymentTypesList: builder.query<PaymentTypeType[], PaymentTypeRequest>({
      query: request => ({
        url: '/transfer/characteristic/transfer/types/get',
        method: 'POST',
        data: request
      }),
      transformResponse: (response: PaymentTypesResponse) => response.types
    }),

    getCountriesCodeReporting: builder.query<CountryCodeReporting, void>({
      query: () => ({
        url: '/transfer/characteristic/countries/reporting/get',
        method: 'POST'
      })
    }),

    getDebitableAccounts: builder.query<AccountsRoot[], {
      contractReference: string,
      onlyPayAccount?: boolean
    }>({
      query: request => ({
        url: '/transfer/accounts/debitable/get',
        method: 'POST',
        data: request
      }),
      keepUnusedDataFor: 5
    }),

    getCreditableAccounts: builder.query<CreditableRoots, {
      contractReference: string,
      debitableAccount: string
    }>({
      query: request => ({
        url: '/transfer/accounts/creditable/get',
        method: 'POST',
        data: request
      }),
      keepUnusedDataFor: 5
    }),

    createTransfer: builder.mutation<TransferResultResponse, CreateAdvancedTransferData>({
      query: formData => ({
        url: '/transfer/create',
        method: 'POST',
        data: formData
      }),
      invalidatesTags: (result, error, request) => [
        {type: 'PreValidation', id: 'SINGLE'}, /* Clean to be ready for the next transfer */
        {type: 'PreValidation', id: 'COUNT'}
      ]
    }),

    getPreInstructionList: builder.query<PreInstructionListResponse, PreInstructionListRequest>({
      query: request => ({
        url: '/transfer/pre-instruction/list',
        method: 'POST',
        data: request
      })
    }),

    getPreInstructionCount: builder.query<PreInstructionCountResponse, PreInstructionListRequest>({
      query: request => ({
        url: '/transfer/pre-instruction/count',
        method: 'POST',
        data: request
      }),
      providesTags: [{type: 'PreValidation', id: 'COUNT'}]
    }),

    getSOPreInstructionCount: builder.query<PreInstructionCountResponse, PreInstructionListRequest>({
      query: request => ({
        url: '/standing-order/pre-instruction/count',
        method: 'POST',
        data: request
      }),
      providesTags: [{type: 'PreValidation', id: 'COUNT'}]
    }),

    getPreInstruction: builder.query<PreInstructionResponse, PreInstructionRequest>({
      query: request => ({
        url: '/transfer/pre-instruction/get',
        method: 'POST',
        data: request
      })
    }),

    cancelPreInstruction: builder.mutation<void, PreInstructionRequest>({
      query: request => ({
        url: '/transfer/pre-instruction/cancel',
        method: 'POST',
        data: request
      }),
      invalidatesTags: [{type: 'PreValidation', id: 'COUNT'}]
    }),

    signPreInstruction: builder.mutation<PreInstructionResponse, SignPreInstructionRequest>({
      query: request => ({
        url: '/transfer/pre-instruction/sign',
        method: 'POST',
        data: request
      }),
      invalidatesTags: [{type: 'PreValidation', id: 'COUNT'}]
    }),

    isTransferSignatureRequired: builder.query<{
      signatureRequired: boolean
    }, CreateAdvancedTransferData>({
      query: request => ({
        url: '/transfer/signature/is-required',
        method: 'POST',
        data: request
      })
    }),

    getEstimatedTransferFees: builder.query<TransferEstimationFees, CreateAdvancedTransferData>({
      query: request => ({
        url: '/transfer-fees/estimate',
        method: 'POST',
        data: request
      })
    }),

    getPaymentHistoryList: builder.query<PaymentHistoryListResponse, PaymentHistoryListRequest>({
      query: request => ({
        url: '/transfer/history',
        method: 'POST',
        data: request
      })
    }),

    getCollectiveHistoryList: builder.query<CollectiveHistoryListResponse, PaymentHistoryListRequest>({
      query: request => ({
        url: '/collective/payment/history',
        method: 'POST',
        data: request
      })
    }),

    getPreInstructionTransactions: builder.query<TransactionsBulkResponse[], TransactionsBulkRequest>({
      query: request => ({
        url: '/collective/pre-instruction/transactions',
        method: 'POST',
        data: request
      })
    }),

    getPaymentDetails: builder.query<PaymentHistoryDetailsResponse, PaymentHistoryDetailsRequest>({
      query: request => ({
        url: '/transfer/details/get',
        method: 'POST',
        data: request
      }),
      providesTags: [{type: 'PaymentDetails', id: 'SINGLE'}] /* One transfer done at a time, no need to keep multiple tags */
    }),

    getCollectiveDetails: builder.query<CollectiveDetailsReponse, CollectiveDetailsRequest>({
      query: request => ({
        url: '/collective/payment/details/get',
        method: 'POST',
        data: request
      }),
      providesTags: [{type: 'CollectiveDetails', id: 'SINGLE'}] /* One transfer done at a time, no need to keep multiple tags */
    }),

    preValidateTransfer: builder.query<TransferPreValidationResponse, CreateAdvancedTransferData>({
      query: request => ({
        url: '/transfer/pre-validation/get',
        method: 'POST',
        data: request
      }),
      providesTags: [{type: 'PreValidation', id: 'SINGLE'}] /* One transfer done at a time, no need to keep multiple tags */
    }),

    cancelTransfer: builder.mutation<TransferCancelResponse, CancelTransferRequest>({
      query: request => ({
        url: '/transfer/cancel',
        method: 'POST',
        data: request
      }),
      invalidatesTags: (result, error, request) => [
        {type: 'PaymentDetails', id: 'SINGLE'} /* Clean to be ready for the next transfer */
      ]
    }),

    cancelCollective: builder.mutation<CancelResultResponse, CancelCollectiveRequest>({
      query: request => ({
        url: '/collective/payment/cancel',
        method: 'POST',
        data: request
      }),
      invalidatesTags: (result, error, request) => [
        {type: 'CollectiveDetails', id: 'SINGLE'} /* Clean to be ready for the next transfer */
      ]
    }),

    getStandingOrderHistoryList: builder.query<StandingOrderLightListResponse, StandingOrderLightHistoryListRequest>({
        query: request => ({
            url: '/standing-order/contracts',
            method: 'POST',
            data: request
        })
    }),

    getStandingOrderDetails: builder.query<StandingOrderDetailsResponse, StandingOrderRequest>({
      query: request => ({
        url: '/standing-order/details',
        method: 'POST',
        data: request
      }),
      providesTags: [{type: 'StandingOrderDetails', id: 'SINGLE'}]
    }),

    cancelStandingOrder: builder.mutation<StandingOrderResult, StandingOrderRequest>({
      query: request => ({
        url: '/standing-order/delete',
        method: 'POST',
        data: request
      }),
      invalidatesTags: (result, error, request) => [
        {type: 'StandingOrderDetails', id: 'SINGLE'} /* Clean to be ready for the next transfer */
      ]
    }),

    getStandingOrderPreInstructionList: builder.query<StandingOrderPreInstructionListResponse, PreInstructionListRequest>({
      query: request => ({
        url: '/standing-order/pre-instruction/list',
        method: 'POST',
        data: request
      })
    }),

    getStandingOrderPreInstruction: builder.query<StandingOrderPreInstructionResponse, PreInstructionRequest>({
      query: request => ({
        url: '/standing-order/pre-instruction/get',
        method: 'POST',
        data: request
      })
    }),

    cancelStandingOrderPreInstruction: builder.mutation<void, PreInstructionRequest>({
      query: request => ({
        url: '/standing-order/pre-instruction/cancel',
        method: 'POST',
        data: request
      }),
      invalidatesTags: [{type: 'PreValidation', id: 'COUNT'}]
    }),

    signStandingOrderPreInstruction: builder.mutation<StandingOrderResult, SignStandingOrderPreInstructionRequest>({
      query: request => ({
        url: '/standing-order/pre-instruction/sign',
        method: 'POST',
        data: request
      }),
      invalidatesTags: [{type: 'PreValidation', id: 'COUNT'}]
    }),

    preValidateStandingOrder: builder.query<TransferPreValidationResponse, CreateStandingOrder>({
      query: request => ({
        url: '/standing-order/pre-validation/get',
        method: 'POST',
        data: request
      }),
      providesTags: [{type: 'PreValidation', id: 'SINGLE'}] /* One transfer done at a time, no need to keep multiple tags */
    }),

    createStandingOrder: builder.mutation<StandingOrderResult, CreateStandingOrder>({
        query: request => ({
            url: '/standing-order/create',
            method: 'POST',
            data: request
        })
    }),

    getStandingOrderFirstDate: builder.query<{firstExecutionDate: string}, void>({
      query: request => ({
        url: '/standing-order/first-date',
        method: 'POST',
        data: request
      })
    }),

    getEstimatedStandingOrderFees: builder.query<TransferEstimationFees, CreateStandingOrder>({
      query: request => ({
        url: '/standing-order/fees/estimate',
        method: 'POST',
        data: request
      })
    })

  })
});

export const {
  useGetServerDateQuery,
  useGetDebitableAccountsQuery,
  useGetCreditableAccountsQuery,
  useGetCountriesQuery,
  useGetIbanRulesQuery,
  useGetCurrencyListQuery,
  useGetFeesListQuery,
  useGetPaymentTypesListQuery,
  useGetCountriesCodeReportingQuery,
  useGetPreInstructionListQuery,
  useGetPreInstructionCountQuery,
  useGetPreInstructionQuery,
  useCancelPreInstructionMutation,
  useSignPreInstructionMutation,
  useCreateTransferMutation,
  useIsTransferSignatureRequiredQuery,
  useGetEstimatedTransferFeesQuery,
  useGetPaymentHistoryListQuery,
  useGetCollectiveHistoryListQuery,
  useGetPreInstructionTransactionsQuery,
  useGetPaymentDetailsQuery,
  useGetCollectiveDetailsQuery,
  usePreValidateTransferQuery,
  useCancelTransferMutation,
  useCancelCollectiveMutation,
  useGetStandingOrderHistoryListQuery,
  useGetStandingOrderDetailsQuery,
  useCancelStandingOrderMutation,
  useGetStandingOrderPreInstructionListQuery,
  useGetStandingOrderPreInstructionQuery,
  useCancelStandingOrderPreInstructionMutation,
  useSignStandingOrderPreInstructionMutation,
  usePreValidateStandingOrderQuery,
  useCreateStandingOrderMutation,
  useGetStandingOrderFirstDateQuery,
  useGetEstimatedStandingOrderFeesQuery,
  useGetSOPreInstructionCountQuery
} = paymentProApi;
