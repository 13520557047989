import {Avatar, styled} from '@mui/material';


export const GenericCircularIcon = ({
                                        icon,
                                        size,
                                        color,
                                        callback
                                    }: { icon: JSX.Element, size: number, color?: 'GREEN' | 'RED', borderColor?: string, callback?: Function }) => {

    const StyledAvatar = styled(Avatar)({
        backgroundColor: '#fff',
        ...color === 'GREEN' && {
            boxShadow: '0px 3px 4px #A7C7B340',
            border: '1px solid #C3EBDA',
            color: '#00915A'
        },
        ...color === 'RED' && {
            boxShadow: '0px 3px 4px #FFAFB840',
            border: '1px solid #FFCDC4',
            color: '#FE6145'
        },
        ...color === undefined && {
            boxShadow: '0px 3px 4px #A7C7B340',
            border: '1px solid #C3EBDA'
        },
    });

    return (
        <StyledAvatar onClick={e => callback ? callback(e) : {}}
                      style={{width: size, height: size, cursor: callback ? 'pointer' : ''}}>
            {icon}
        </StyledAvatar>
    );
};
