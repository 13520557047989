import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from './api-utils';
import {WebProContract, WebProContractResponse} from '../@types/WebProContractTypes';

const URL_API = '/business_webctrinfo/rest/v1';

export const webProContractApi = createApi({

    reducerPath: 'webProContractApi',
    baseQuery: axiosBaseQuery({
        baseUrl: URL_API
    }),
    tagTypes: ['WebProContracts'],
    endpoints: (builder) => ({
        getWebProContracts: builder.query<Array<WebProContract>, void>({
            query: () => ({
                url: '/webProContract/list',
                method: 'POST',
                data: {}
            }),
            keepUnusedDataFor: 5,
            providesTags: [{type: 'WebProContracts', id: 'LIST'}],
            transformResponse: (response: WebProContractResponse) => response.webProContracts
        })
    })
});

export const {useGetWebProContractsQuery} = webProContractApi;
