import { Box, Divider, Radio, Stack, Typography } from '@mui/material';
import { CheckWhiteActionIcon, UnCheckWhiteActionIcon } from '../../../../assets/images/icons/actions/ActionsIcons';

export const DetailsRadioBox = ({
                                    children,
                                    name,
                                    title,
                                    desc,
                                    selection,
                                    updateSelection
                                }: { children?: JSX.Element, name: string, title?: string, desc?: string, selection: string, updateSelection: Function }) => {

    return (
        <Box sx={{
            padding: 0,
            border: '1px solid #CBCBCB',
            ...selection === name && {
                border: '1px solid #C3EBDA',
                boxShadow: '0px 3px 15px #B2FFCF80'
            },
            borderRadius: '10px'
        }} onClick={() => updateSelection(name)}>
            <Stack spacing={2} direction={'row'}>
                <Stack direction={'row'} sx={{
                    background: 'transparent linear-gradient(39deg, #F2F3F4 0%, #F7F7F7 100%) 0% 0% no-repeat padding-box',
                    borderBottomLeftRadius: '10px',
                    borderTopLeftRadius: '10px',
                    ...selection === name && {background: 'transparent linear-gradient(46deg, #00915A 0%, #6FD67C 100%) 0% 0% no-repeat padding-box'}
                }
                } paddingX={2}>
                    <Radio checked={selection === name} checkedIcon={<CheckWhiteActionIcon/>}
                           icon={<UnCheckWhiteActionIcon/>}></Radio>
                </Stack>
                <Stack direction={'column'} spacing={4} padding={6}>
                    { title && <><Typography textAlign={'left'}
                                variant={'h4'} sx={{
                        ...selection === name && {color: '#2BAC6B', fontWeight: '400'}
                    }}>{title}</Typography>
                    <Divider/></>
                    }
                    {desc &&
                        <Typography textAlign={'left'}
                                variant={'body2'}>{desc}</Typography>
                    }
                    
                    {children}
                </Stack>
            </Stack>
        </Box>
    );
};
