import React, {DetailedHTMLProps, useEffect, useLayoutEffect, useState} from 'react';
import {createPortal} from 'react-dom';

export const OrelyFrame = (
    {children, styling = {width: '96%', height: '350px', border: '0px'}, ...props}
    :
    React.PropsWithChildren<{styling?: React.CSSProperties}>
) => {
    const [contentRef, setContentRef] = useState(null as DetailedHTMLProps<any, any>);
    const [formPosted, setFormPosted] = useState(false);
    const mountNode = !formPosted && contentRef && contentRef.contentWindow.document.body;

    useLayoutEffect(() => {
        if (mountNode && mountNode.querySelector('#samlForm') && !formPosted) {
            const formNode = mountNode.querySelector('#samlForm');
            setFormPosted(true);
            formNode.submit();
        }
    }, [mountNode, formPosted]);

    return (
        <iframe style={styling} {...props} ref={setContentRef} id={'orely-iframe'} title={'luxtrust'}>
            {mountNode && !formPosted &&
                createPortal(
                    React.Children.only(children),
                    mountNode
                )}
        </iframe>
    );
};

export const OrelyFrameV2 = ({children, receiveMessage, styling = {width: '495px', height: '460px', border: '0px'}}: React.PropsWithChildren<{
    receiveMessage: (this: Window, ev: MessageEvent<any>) => any,
    styling?: React.CSSProperties
}>) => {

    const [contentRef, setContentRef] = useState(null as DetailedHTMLProps<any, any>);
    const [formPosted, setFormPosted] = useState(false);
    const mountNode = !formPosted && contentRef && contentRef.contentWindow.document.body;

    useEffect(() => {
        window.addEventListener('message', receiveMessage, false);

        return () => {
            window.removeEventListener('message', receiveMessage, false);
        };
    }, [receiveMessage]);

    useLayoutEffect(() => {
        if (mountNode && mountNode.querySelector('#samlForm') && !formPosted) {
            const formNode = mountNode.querySelector('#samlForm');
            setFormPosted(true);
            formNode.submit();
        }
    }, [mountNode, formPosted]);

    return (
        <iframe ref={setContentRef} id={'orely-iframe'} style={styling} title={'luxtrust'}>
            {mountNode && !formPosted &&
                createPortal(
                    React.Children.only(children),
                    mountNode
                )}
        </iframe>
    );
};
