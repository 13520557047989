import React, {PropsWithChildren, SyntheticEvent} from 'react';
import {ButtonBase, SxProps} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {Link as RouterLink} from 'react-router-dom';

export enum PressableTheme {
    SUCCESS,
    WARNING,
    ERROR
}

const Pressable = ({children, sx, to, onClick, theme = PressableTheme.SUCCESS}: PropsWithChildren<{
    to?: string,
    onClick?: (e: SyntheticEvent) => void,
    sx?: SxProps<Theme>,
    theme?: PressableTheme
}>) => {
    const themeStyle = theme === PressableTheme.WARNING ?
    {boxShadow: '15px 15px 25px #A7C7B330', border: '1px solid #FFE3C3'}
    : theme === PressableTheme.ERROR ?
    {boxShadow: '15px 15px 25px #A7C7B330', border: '1px solid #E8A4A4'}
    : {boxShadow: '0px 5px 12px #A7C7B31C', border: '1px solid #E1F4EE'};

    return (
        <ButtonBase sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '15px',
            ...themeStyle,
            ...sx
        }} component={RouterLink} to={to ?? ''} onClick={onClick}>
            {children}
        </ButtonBase>
    )
};

export default Pressable;

// box-shadow: 15px 15px 25px #A7C7B330;
// border: 1px solid #FFE3C3;
// border-radius: 15px;