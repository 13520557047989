export type BeneficiaryList = {
    start: number;
    size: number;
    total: number;
    totalPages: number;
    beneficiaries: Beneficiary[];
};

export type BeneficiaryListRequest = {
    contractReference: string;
    page: number;
    size: number;
    beneficiaryListRequestFilter?: {
        text?: string
        shareBeneficiaryType?: DisplayType;
    }
};

export type DisplayType = 'ALL' | 'PART' | 'IND';

export enum AccountType {
    IBAN = 'IBAN',
    AUTR = 'AUTR',
    MPAIN = 'MPAIN',
    MCSV = "MCSV"
};

export enum BankCodeType {
    BIC = 'BIC',
    AUTR = 'AUTR'
};

export type CreateOrUpdateBeneficiary = Beneficiary & {
    signatureRequest: {
        challengeId: string,
        answer: string
    }
};

export type CreateBeneficiariesFromFile = BeneficiariesFile & {
    signatureRequest: {
        challengeId: string,
        answer: string
    }
};

export type BeneficiariesFile = {
    contractReference: string;
    file: File;
}

export type Beneficiary = {
    id: string | null;
    contractReference: string;
    beneficiaryBankName: string | null;
    beneficiaryTownBankName: string | null;
    beneficiaryAccountType: AccountType;
    beneficiaryAccountCountryCode: string | null;
    foreignNationalReference: string | null;
    accountReference: string | null;
    bankCodeType?: BankCodeType;
    bankCodeReference: string | null;
    beneficiaryName: string | null;
    beneficiaryAlias: string | null;
    beneficiaryCommunicationArea: string | null;
    shareBeneficiary: boolean;
    beneficiaryAddresses: BeneficiaryAddress[];
    creator: boolean;
    primaryContract: string | null;
};

export type BeneficiaryAccount = {
    primaryContract: string | null;
    nationalBankIdentifier: string | null;
    reference: string;
    name: string;
    address: string;
    countryCode: string;
    bankTypeCode: string;
    bankReference: string;
    bankCountryCode: string;
    bankAddress: string;
    bankName: string;

};

export type BeneficiaryAddress = {
    id: string | null;
    streetName: string;
    buildingNumber: string;
    postBox: string;
    postCode: string;
    townName: string;
    countryCode: string;
};

export type DeleteBeneficiaryRequest = {
    contractReference: string;
    id: string;
};

export enum BeneficiaryImportFileErrors {
    INVALID_IBAN = 'INVALID_IBAN',
    INVALID_BANK_CODE = 'INVALID_BANK_CODE',
    INVALID_BANK_NAME = 'INVALID_BANK_NAME',
    INVALID_TOWN_BANK_NAME = 'INVALID_TOWN_BANK_NAME',
    INVALID_BEN_NAME = 'INVALID_BEN_NAME',
    INVALID_ADDRESS = 'INVALID_ADDRESS',
    INVALID_CITY_NAME = 'INVALID_CITY_NAME'
}
