import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './api-utils';
import { DirectDebitsDetailsRequest, DirectDebitsListRequest, DirectDebitBlockRequest, DirectDebitTransactionListResponse, DirectDebitsListResponse, DirectDebitDetailsResponse, DirectDebitBlockListResponse, DirectDebitsBlockResponse, DirectDebitTransactionsRequest, DirectDebitsBlockListRequest } from "../@types/DirectDebitsTypes";

const URL_API = '/business_webpaymentpro/rest/directdebit';

export const domiciliationApi = createApi({
    reducerPath: 'domiciliationApi',
    baseQuery: axiosBaseQuery({
        baseUrl: URL_API
    }),
    tagTypes: ['Domiciliations', 'Domiciliation'],
    keepUnusedDataFor: 60,

    endpoints: builder => ({
        getTransactionDirectDebitsList: builder.query<DirectDebitTransactionListResponse[], DirectDebitTransactionsRequest>({
            query: request => ({
                url: '/transaction/list',
                method: 'POST',
                data: request
            })
        }),
        getDirectDebitsList: builder.query<DirectDebitsListResponse[], DirectDebitsListRequest>({
            query: request => ({
                url: '/list',
                method: 'POST',
                data: request
            }),
            providesTags: [{ type: 'Domiciliations', id: 'LIST' }]
        }),
        getDetailsDirectDebit: builder.query<DirectDebitDetailsResponse, DirectDebitsDetailsRequest>({
            query: request => ({
                url: '/details',
                method: 'POST',
                data: request
            }),
            providesTags: (result, messages, request) => [{ type: 'Domiciliation', id: request.domId }]
        }),
        getBlockDirectDebitList: builder.query<DirectDebitBlockListResponse, DirectDebitsBlockListRequest>({
            query: request => ({
                url: '/blocking/list',
                method: 'POST',
                data: request
            })
        }),
        updateBlockDirectDebit: builder.mutation<DirectDebitsBlockResponse, DirectDebitBlockRequest>({
            query: request => ({
                url: '/block',
                method: 'POST',
                data: request
            }),
            invalidatesTags: (result, error, request) => [
                { type: 'Domiciliations', id: 'LIST' },
                { type: 'Domiciliation', id: request.mandateRef },

            ]
        }),
    })
});

export const {
    useGetDirectDebitsListQuery,
    useGetTransactionDirectDebitsListQuery,
    useGetDetailsDirectDebitQuery,
    useGetBlockDirectDebitListQuery,
    useUpdateBlockDirectDebitMutation
} = domiciliationApi;