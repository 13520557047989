import React, { useEffect, useState } from 'react';
import { useWebProPermissions } from '../hooks/useWebProPermissions';
import { Permission } from '../redux/@types/WebProContractTypes';

const RequirePermissions = ({
                                permissions,
                                oneOf = false,
                                children
                            }: {
    permissions: Array<Permission>,
    oneOf?: boolean,
    children: React.ReactNode | Array<React.ReactNode>
}) => {

    const [authorizedToRender, setAuthorizedToRender] = useState(false);
    const {permissions: allPermissions, isLoading} = useWebProPermissions();

    useEffect(() => {
        if (!isLoading) {
            if (oneOf) {
                setAuthorizedToRender(permissions.some(value => allPermissions?.includes(value)));
            } else {
                setAuthorizedToRender(permissions.every(value => allPermissions?.includes(value)));
            }
        }
    }, [allPermissions, isLoading, oneOf, permissions]);

    return (
        authorizedToRender ? <>{children}</> : <></>
    );
};

export default RequirePermissions;
