import {ChildrenUpdatedData, ParentUpdatedData} from '../../../../redux/@types/SelfAdminTypes';
import MulitpleLineValidation from './MulitpleLineValidation';
import {useTranslation} from 'react-i18next';

const SignatureRulesValidation = ({boxKey, update, label, url}:
                                      { boxKey: number, update: ParentUpdatedData, label: string , url: string }
) => {

    const updateClone = {...update};
    const {t} = useTranslation(['commons']);

    updateClone.children = updateClone.children.map((children: ChildrenUpdatedData) => {
        const newValue = children.newValue ? children.newValue.replaceAll(' ', '')
            .replaceAll('€', '')
            .replaceAll('(', '-')
            .replaceAll(')', '')
            .replaceAll('UNLIMITED', t(
                'commons:modules.webpro.self-admin.limit-payement-unlimited'
            ))
            .replaceAll('DEACTIVATED', t(
                'commons:modules.webpro.self-admin.limit-payement-deactivated'
            ))
            .split('-') : [];
        const oldValue = children.oldValue ? children.oldValue.replaceAll(' ', '')
            .replaceAll('€', '')
            .replaceAll('(', '-')
            .replaceAll(')', '')
            .replaceAll('UNLIMITED', t(
                'commons:modules.webpro.self-admin.limit-payement-unlimited'
            ))
            .replaceAll('DEACTIVATED', t(
                'commons:modules.webpro.self-admin.limit-payement-deactivated'
            ))
            .split('-') : [];

        const newAmount = newValue.pop();
        const oldAmount = oldValue.pop();

        const newChildren = {...children};

        newChildren.newValue = newAmount ?? null;
        newChildren.oldValue = oldAmount ?? null;
        if (newValue.length > 0) {
            newChildren.label = t('commons:modules.webpro.self-admin.profile') + ' ' + newValue.join('+').replaceAll('_', ' ');
        } else if (oldValue.length > 0) {
            newChildren.label = t('commons:modules.webpro.self-admin.profile') + ' ' +oldValue.join('+').replaceAll('_', ' ');
        }

        return newChildren;
    });

    return (
        <MulitpleLineValidation boxKey={boxKey} update={updateClone} label={label} url={url} />
    )
};

export default SignatureRulesValidation;
