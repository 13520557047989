import React, {useContext, useEffect} from 'react';
import {RootContext} from '../../../context/root-context';
import Cookies from 'js-cookie';
import {Language} from '../../../@types/ui.types';
import {useLanguage} from '../../../hooks/useLanguage';
import {useTranslation} from 'react-i18next';
import {BglWebAndMobileConfig} from '../../../config';
import {useLocation, useNavigate} from 'react-router';
import ActionSelect from '../../_commons/components/Select/ActionSelect';
import {SelectChangeEvent} from '@mui/material';

const COOKIE_WBK_LANGUAGE = 'wbklanguage';

const SelectLanguageButton = () => {
    const {isAuthenticated} = useContext(RootContext);
    const {i18n} = useTranslation(['commons']);
    const [language, setLanguage] = useLanguage();
    const location = useLocation();
    const navigate = useNavigate();

    const _handleChangeLanguage = async (e: SelectChangeEvent | string) => {
        const value = typeof e === 'string' ? e : e.target.value;
        if(!['fr', 'en', 'de'].includes(value.toLowerCase())){
            return;
        }

        await BglWebAndMobileConfig.load({
            appLanguage: value
        });

        // @ts-ignore
        setLanguage(value as Language);
        await i18n.changeLanguage(value);
        // If we are in Orely Iframe, changing language blocks authent, need to reload iframe
        if(location.pathname === '/auth/luxtrust'){
            navigate(-1);
        }
    };

    useEffect(() => {
        const savedLanguage = Cookies.get(COOKIE_WBK_LANGUAGE) as Language;
        if(savedLanguage && savedLanguage.length > 0 && savedLanguage !== language && ['fr', 'en', 'de'].includes(savedLanguage)){
            _handleChangeLanguage(savedLanguage);
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    //Avoids infinite loop, array left empty for a reason

    return (
        <>
            {!isAuthenticated &&
                <ActionSelect
                currentValue={language as string}
                listItems={[{value: 'fr', display: 'FR'},{value: 'en', display: 'EN'},{value: 'de', display: 'DE'}]}
                valueChanged={_handleChangeLanguage}
                arrowStyle={{zIndex: 1,width: '12px',height: '14px',position: 'absolute',left:'40px',bottom:'10px'}}/>
            }
        </>
    );
};

export default SelectLanguageButton;
