import GenericDialog from '../../_commons/components/Dialog/GenericDialog';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    SelectChangeEvent,
    Skeleton,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { format } from 'date-fns';
import { useGetFamiliesQuery } from '../../../redux/api/contractInfoApiRtk';
import DateField from '../../_commons/components/Form/DateField';
import CheckBox from '../../_commons/components/Form/Checkbox/CheckBox';
import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Account } from '../../../redux/@types/ContractInfoTypes';
import { useGetDocumentsCountQuery } from '../../../redux/api/documentAccessApiRtk';
import { WebProContract } from '../../../redux/@types/WebProContractTypes';
import { DocumentRequestFilterTypes } from '../../../redux/@types/DocumentRequestFilterTypes';

export type AdvancedFilterKeysType = {
    startDate: any,
    dateChecked: boolean,
    endDate: any,
    account: string,
    root: string,
    accountChecked: boolean,
    docType: string[],
    docLabel: string,
    docTypeChecked: boolean,
    reference: string,
    referenceChecked: boolean
    status: 'READ' | 'UNREAD' | 'ANY',
    statusChecked: boolean
};

//A voir si pas mieux de recuperer les différentes listes en faisant des calls ici
export const AdvancedEdocsSearchBox = ({
    isOpenAdvancedSearch,
    initialAdvancedKeys,
    isAdvancedSearchActive,
    roots,
    handleAdvancedKeys,
    handleClose
}: {
    isOpenAdvancedSearch: boolean,
    initialAdvancedKeys: AdvancedFilterKeysType,
    isAdvancedSearchActive: boolean,
    roots: WebProContract,
    handleAdvancedKeys: Function,
    handleClose: Function
}) => {

    const TODAY = new Date();
    const MINIMAL_YEAR = 1000;

    const { t } = useTranslation(['commons']);
    const [advancedKeys, setAdvancedKeys] = useState(initialAdvancedKeys);
    const [openAdvancedSearch, setOpenAdvancedSearch] = useState(false);
    const [accounts, setAccounts] = useState<Account[]>();
    const previousAdvancedKeys = { ...initialAdvancedKeys };

    const checkRootValue = () => advancedKeys.root === 'allRoot' ? roots.primaryContracts.map(p => p.primaryContractId) : [advancedKeys.root];
    const checkDocTypeValue = () => advancedKeys.docLabel === 'allDocs' ? [] : advancedKeys.docType;

    const { data: documentTypesList } = useGetDocumentsCountQuery({
        primaryContractReferences: advancedKeys.accountChecked ? checkRootValue() : roots.primaryContracts.map(p => p.primaryContractId),
        //Turn to array, filter, turn to object
        filter: Object.fromEntries(Object.entries({
            dateFrom: advancedKeys.dateChecked && advancedKeys.startDate ? format(advancedKeys.startDate, 'yyyy-MM-dd') : '',
            dateTo: advancedKeys.dateChecked && advancedKeys.endDate ? format(advancedKeys.endDate, 'yyyy-MM-dd') : '',
            readStatus: advancedKeys.statusChecked ? advancedKeys.status : 'ANY'
        }).filter(([key, value]) => value !== '' && value !== null)) as unknown as DocumentRequestFilterTypes
    }, { skip: !roots });

    const documentMapByLabel = useMemo(() => {
        const map = new Map<string, { code: string, label: string }[]>();
        documentTypesList?.documentTypes?.forEach(docType => {
            if (docType.label) {
                map.set(docType.label, [...map.get(docType.label) ?? [], docType]);
            }
        });
        return map;
    }, [documentTypesList]);

    const { data: filterResults, isFetching: isFetchingCount } = useGetDocumentsCountQuery(
        {
            primaryContractReferences:
                advancedKeys.accountChecked ? checkRootValue() : roots.primaryContracts.map(p => p.primaryContractId),
            //Turn to array, filter, turn to object
            filter: Object.fromEntries(Object.entries({
                dateFrom: advancedKeys.dateChecked && advancedKeys.startDate ? format(advancedKeys.startDate, 'yyyy-MM-dd') : '',
                dateTo: advancedKeys.dateChecked && advancedKeys.endDate ? format(advancedKeys.endDate, 'yyyy-MM-dd') : '',
                readStatus: advancedKeys.statusChecked ? advancedKeys.status : 'ANY',
                documentType: advancedKeys.docTypeChecked ? checkDocTypeValue() : '',
                accountId: (advancedKeys.accountChecked && advancedKeys.account && advancedKeys.account.length > 0) ? advancedKeys.account : '',
                reference: advancedKeys.referenceChecked ? advancedKeys.reference : ''
            }).filter(([key, value]) => value !== '' && value !== null)) as unknown as DocumentRequestFilterTypes
        }, { skip: !roots }
    );

    const { data: families = [], isFetching } = useGetFamiliesQuery(
        {
            primaryContractId: advancedKeys.root,
            families: ['ViewAccount']
        }, { skip: !advancedKeys.root || advancedKeys.root.length === 0 || advancedKeys.root === 'allRoot' }
    );

    const checkDocumentCountError = !filterResults?.numberOfDocuments;

    useEffect(() => {
        if (families.length === 0) {
            return;
        }
        const allAccounts = families.flatMap(family => family.accountList);
        if (!isFetching && allAccounts.length > 0) {
            setAccounts(allAccounts);
        }
    }, [families, setAccounts, isFetching]);

    useEffect(() => {
        if (!isAdvancedSearchActive) {
            setAdvancedKeys(initialAdvancedKeys);
        }
        setOpenAdvancedSearch(isOpenAdvancedSearch);
    }, [isOpenAdvancedSearch, isAdvancedSearchActive, initialAdvancedKeys]);

    const _handleDateFieldChange = (id: string, value: Date | null) => {
        const validValue = value !== null && !isNaN(value.getTime()) && value.getFullYear() > MINIMAL_YEAR;
        const endDateIsSet = advancedKeys.endDate && !isNaN(advancedKeys.endDate.getTime());
        const startDateIsSet = advancedKeys.startDate && !isNaN(advancedKeys.startDate.getTime());

        switch (id) {
            case 'startDate':
                if (endDateIsSet) {
                    setAdvancedKeys({
                        ...advancedKeys,
                        'dateChecked': value !== null && validValue && value <= TODAY && value <= advancedKeys.endDate,
                        'startDate': value
                    });
                } else {
                    setAdvancedKeys({
                        ...advancedKeys,
                        'dateChecked': value !== null && validValue && value <= TODAY,
                        'startDate': value
                    });
                }
                break;
            case 'endDate':
                if (startDateIsSet) {
                    setAdvancedKeys({
                        ...advancedKeys,
                        'dateChecked': value !== null && validValue && value <= TODAY && value >= advancedKeys.startDate,
                        'endDate': value
                    });
                } else {
                    setAdvancedKeys({
                        ...advancedKeys,
                        'dateChecked': value !== null && validValue && value <= TODAY,
                        'endDate': value
                    });
                }
                break;
            default: /* Nothing to do */
        }
    };

    const _handleTextFieldChange = (id: string, value: string) => {
        //Avoid doc types list emptying and leaving an empty space
        switch (id) {
            case 'docType': {
                setAdvancedKeys({
                    ...advancedKeys,
                    'docTypeChecked': !!(value && value.length > 0),
                    'docType': value === 'allDocs' ? [''] : [...documentMapByLabel?.get(value)!]?.map(docType => docType.code),
                    'docLabel': value
                });
                break;
            }
            case 'reference': {
                setAdvancedKeys({
                    ...advancedKeys,
                    'referenceChecked': !!(value && value.length > 0),
                    'reference': value
                });
                break;
            }
            case 'account': {
                setAdvancedKeys({ ...advancedKeys, 'accountChecked': true, 'account': value });
                break;
            }
            case 'root': {
                setAdvancedKeys({ ...advancedKeys, 'accountChecked': true, 'root': value, 'account': '' });
                break;
            }
            default: /* Nothing to do */
        }
    };

    const _handleStatusChange = (value: 'READ' | 'UNREAD' | 'ANY') => {
        setAdvancedKeys({ ...advancedKeys, 'statusChecked': true, 'status': value });
    };

    const _handleClose = () => {
        setAdvancedKeys({ ...previousAdvancedKeys });
        handleClose();
    };

    const _checkValue = (e: SyntheticEvent, key: string) => {
        if ((e.target as HTMLInputElement).checked) {
            (e.target as HTMLInputElement).checked = false;
        }
        const name = (e.target as HTMLInputElement).id;
        if (key === 'date' && !(e.target as HTMLInputElement).checked) {
            setAdvancedKeys({
                ...advancedKeys,
                [name]: (e.target as HTMLInputElement).checked,
                startDate: null,
                endDate: null
            });
        } else if (key === 'account' && !(e.target as HTMLInputElement).checked) {
            setAdvancedKeys({ ...advancedKeys, [name]: (e.target as HTMLInputElement).checked, account: '', root: '' });
        } else {
            setAdvancedKeys({ ...advancedKeys, [name]: (e.target as HTMLInputElement).checked, [key]: '' });
        }
    };

    return (
        <GenericDialog
            title={t('commons:modules.webpro.synthesis.movements.search_document')}
            handleClose={() => _handleClose()}
            isOpen={openAdvancedSearch}
            actions={
                <Button
                    type={'submit'}
                    onClick={() => handleAdvancedKeys(advancedKeys)}
                    variant="contained"
                    color="primary"
                    disabled={checkDocumentCountError}
                    size={'medium'}>{t('commons:buttons.search')}
                </Button>
            }
            isSearch={true}
            searchCount={filterResults?.numberOfDocuments}
            searchCountWarning={checkDocumentCountError}
            searchText={checkDocumentCountError ? 'commons:modules.webpro.e-docs.advanced-search.search-result_error' : 'commons:modules.webpro.e-docs.advanced-search.search-result'}>

            <Box pt={4}>
                <Typography
                    variant={'h5'}
                    marginLeft={0}
                    marginTop={'1.5rem'}
                    textTransform={'uppercase'}
                    marginBottom={'0.5rem'}>
                    {t('commons:modules.webpro.e-docs.advanced-search.references')}
                </Typography>
                <Stack direction={'row'} alignItems={'center'} spacing={6} mb={2}>
                    <TextField
                        value={(advancedKeys.reference != null ? advancedKeys.reference : '')}
                        fullWidth={true}
                        label={t('commons:modules.webpro.e-docs.advanced-search.reference')}
                        variant={'outlined'}
                        onChange={(e: { target: { value: string; }; }) => {
                            _handleTextFieldChange('reference', e.target.value);
                        }} />

                    <CheckBox
                        id="referenceChecked"
                        checked={advancedKeys.referenceChecked}
                        onChange={e => {
                            _checkValue(e, 'reference');
                        }}
                        label={''} />
                </Stack>

                <Typography
                    variant={'h5'}
                    marginLeft={0}
                    marginTop={'1.5rem'}
                    marginBottom={'0.5rem'}
                    textTransform={'uppercase'}>
                    {t('commons:modules.webpro.e-docs.advanced-search.accounts')}
                </Typography>
                <Stack direction={'row'} alignItems={'center'} spacing={6} mb={2}>
                    <FormControl sx={{ width: '50%' }}>
                        <InputLabel>{t('commons:modules.webpro.e-docs.advanced-search.root')}</InputLabel>
                        <Select
                            value={advancedKeys.root ?? advancedKeys.root ?? ''}
                            label={t('commons:modules.webpro.e-docs.advanced-search.root')}
                            fullWidth
                            onChange={(e: SelectChangeEvent) => _handleTextFieldChange('root', e.target.value)}>

                            <MenuItem key={'allRoot'} value={'allRoot'} disableGutters>
                                <Typography
                                    variant={'body1'}
                                    fontSize={'1.5rem'}
                                    fontFamily={'BNPPSansCondensed'}
                                    fontWeight={100}>{t('commons:modules.webpro.e-docs.advanced-search.all-roots')}
                                </Typography>
                            </MenuItem>
                            {roots?.primaryContracts.map(root =>
                                <MenuItem key={root.primaryContractId} value={root.primaryContractId} disableGutters>
                                    <BasicMenuItem text={root.primaryContractId} />
                                </MenuItem>)
                            }
                        </Select>
                    </FormControl>

                    <FormControl sx={{ width: '50%' }}>
                        <InputLabel>{t('commons:modules.webpro.e-docs.advanced-search.account')}</InputLabel>
                        <Select
                            value={advancedKeys.account ?? advancedKeys.account ?? ''}
                            label={t('commons:modules.webpro.e-docs.advanced-search.account')}
                            disabled={!advancedKeys.root || advancedKeys.root.length === 0}
                            fullWidth
                            onChange={(e: SelectChangeEvent) => _handleTextFieldChange('account', e.target.value)}>

                            {accounts?.map(account =>
                                <MenuItem key={account.ibanAccount} value={account.ibanAccount} disableGutters
                                    sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <BasicMenuItem text={account.label} />
                                    <Typography variant={'body1'}>{account.ibanAccount}</Typography>
                                </MenuItem>)
                            }
                        </Select>
                    </FormControl>

                    <CheckBox
                        id="accountChecked"
                        checked={advancedKeys.accountChecked}
                        onChange={e => {
                            _checkValue(e, 'account');
                        }}
                        label={''} />
                </Stack>

                <Typography
                    variant={'h5'}
                    marginLeft={0}
                    marginTop={'1.5rem'}
                    marginBottom={'0.5rem'}
                    textTransform={'uppercase'}>
                    {t('commons:modules.webpro.e-docs.advanced-search.dates')}
                </Typography>
                <Stack direction={'row'} alignItems={'center'} spacing={6} mb={2}>
                    <DateField
                        key="startDate"
                        label={t('commons:modules.webpro.e-docs.advanced-search.date-start')}
                        value={(advancedKeys.startDate)}
                        onChange={datePickerValue => {
                            _handleDateFieldChange('startDate', datePickerValue);
                        }}
                        maxDate={TODAY} />
                    <DateField
                        key="endStartDate"
                        label={t('commons:modules.webpro.e-docs.advanced-search.date-end')}
                        value={advancedKeys.endDate}
                        onChange={datePickerValue => {
                            _handleDateFieldChange('endDate', datePickerValue);
                        }}
                        minDate={advancedKeys.startDate}
                        maxDate={TODAY} />
                    <CheckBox
                        id="dateChecked"
                        checked={advancedKeys.dateChecked}
                        onChange={e => {
                            _checkValue(e, 'date');
                        }}
                        label={''} />
                </Stack>

                <Typography
                    variant={'h5'}
                    marginLeft={0}
                    marginTop={'1.5rem'}
                    marginBottom={'0.5rem'}
                    textTransform={'uppercase'}>
                    {t('commons:modules.webpro.e-docs.advanced-search.document-types')}
                </Typography>
                <Stack direction={'row'} alignItems={'center'} spacing={6} mb={2}>
                    <FormControl sx={{ flexGrow: 1 }}>
                        <InputLabel>{t('commons:modules.webpro.e-docs.advanced-search.document-type')}</InputLabel>
                        <Select
                            value={advancedKeys.docLabel ?? advancedKeys.docLabel ?? ''}
                            label={t('commons:modules.webpro.e-docs.advanced-search.document-type')}
                            fullWidth
                            onChange={(e: SelectChangeEvent) => _handleTextFieldChange('docType', e.target.value)}>

                            <MenuItem key={'allDocs'} value={'allDocs'} disableGutters>
                                <Typography
                                    variant={'body1'}
                                    fontSize={'1.5rem'}
                                    fontFamily={'BNPPSansCondensed'}
                                    fontWeight={100}>{t('commons:modules.webpro.e-docs.advanced-search.all-document-types')}
                                </Typography>
                            </MenuItem>
                            {documentMapByLabel
                                && [...documentMapByLabel?.keys()].map(docTypeLabel =>
                                    <MenuItem key={docTypeLabel} value={docTypeLabel} disableGutters>
                                        <BasicMenuItem text={docTypeLabel} />
                                    </MenuItem>)
                            }

                        </Select>
                    </FormControl>

                    <CheckBox
                        id="docTypeChecked"
                        checked={advancedKeys.docTypeChecked}
                        onChange={e => {
                            _checkValue(e, 'docType');
                        }}
                        label={''} />
                </Stack>

                <Typography
                    variant={'h5'}
                    marginLeft={0}
                    marginTop={'1.5rem'}
                    marginBottom={'0.5rem'}
                    textTransform={'uppercase'}>
                    {t('commons:modules.webpro.e-docs.advanced-search.statuses')}
                </Typography>
                <Stack direction={'row'} alignItems={'center'} spacing={6} mb={2}>
                    <FormControl sx={{ flexGrow: 1 }}>
                        <InputLabel>{t('commons:modules.webpro.e-docs.advanced-search.status')}</InputLabel>
                        <Select
                            value={advancedKeys.status ?? advancedKeys.status ?? ''}
                            label={t('commons:modules.webpro.e-docs.advanced-search.status')}
                            fullWidth
                            onChange={(e: SelectChangeEvent) => _handleStatusChange(e.target.value as 'READ' | 'UNREAD' | 'ANY')}>

                            <MenuItem key={'any-download'} value={'ANY'} disableGutters>
                                <BasicMenuItem text={t('commons:modules.webpro.e-docs.advanced-search.all')} />
                            </MenuItem>
                            <MenuItem key={'not-downloaded'} value={'UNREAD'} disableGutters>
                                <BasicMenuItem
                                    text={t('commons:modules.webpro.e-docs.advanced-search.not-downloaded')} />
                            </MenuItem>
                            <MenuItem key={'downloaded'} value={'READ'} disableGutters>
                                <BasicMenuItem text={t('commons:modules.webpro.e-docs.advanced-search.downloaded')} />
                            </MenuItem>
                        </Select>
                    </FormControl>

                    <CheckBox
                        id="statusChecked"
                        checked={advancedKeys.statusChecked}
                        onChange={e => {
                            _checkValue(e, 'status');
                        }}
                        label={''} />
                </Stack>
            </Box>

            {(isFetching || isFetchingCount) &&
                <LinearProgress sx={{ width: '100%', position: 'absolute', top: 'calc(100% - 4px)', left: 0 }} />
            }
        </GenericDialog>
    );

};

export const SingleItemSearchSkeleton = () => {
    return (
        <Box pt={4}>
            <Skeleton animation="wave" height={'33px'} width={'120px'} />
            <Stack width="100%" direction={'row'} alignItems={'center'} spacing={6} mb={2}>
                <Skeleton animation="wave" height={'51.11px'} width={'100%'} />
                <Skeleton animation="wave" height={'20px'} width={'20px'} variant={'rectangular'} />
            </Stack>
        </Box>
    );
};

export const BasicMenuItem = ({ text }: { text: string }) => {
    return (
        <Typography
            variant={'body1'}
            fontSize={'1.5rem'}
            fontFamily={'BNPPSansCondensed'}
            fontWeight={100}>
            {text}
        </Typography>
    );
};
