import {Dispatch, SetStateAction, SyntheticEvent, useCallback, useEffect, useMemo, useReducer, useState,} from 'react';
import _ from 'lodash';
import ScreenWrapper, {ScreenWrapperTitle} from '../components/ScreenWrapper';
import {Box, Button, FormControl, SelectChangeEvent, Stack, Typography} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import CheckBox from '../../_commons/components/Form/Checkbox/CheckBox';
import Grid from '@mui/material/Grid2';
import {
    useGetContractUpdatesQuery,
    useGetPartyProfileQuery,
    useGetPermissionsQuery,
    useUpdatePartyProfileMutation,
} from '../../../redux/api/selfAdminApiRtk';
import {useParams} from 'react-router-dom';
import {
    MaxAmount,
    TransactionProfileEnum,
    WebBankingBusinessPartyProfile,
    WebBankingBusinessProfilePermission,
    WebBankingBusinessRoles,
} from '../../../redux/@types/SelfAdminTypes';
import {ActionKind, initialState, reducer,} from '../reducers/userManagementStateReducer';
import DashedDivider from '../../_commons/components/Divider/DashedDivider';
import SavedAsDraft from '../components/SavedAsDraft';
import {Permission} from '../../../redux/@types/WebProContractTypes';
import RequirePermissions from '../../../components/RequirePermissions';
import {useHasOneOfPermission} from '../../../hooks/useWebProPermissions';
import ProfileImportModal from '../components/ProfileImportModal';
import {useNavigate} from 'react-router';
import UserBox, {UserBoxSkeleton,} from '../components/user-management/UserBox';
import SelectField, {MenuItemStyled,} from '../../_commons/components/Form/SelectField/SelectField.tsx';
import {useCurrentWebProContractReference} from '../../../hooks/useCurrentPrimaryContract.ts';
import {useGetAllPaymentConstraintsQuery} from '../../../redux/api/selfAdminApiRtk.ts';

const PERMISSIONS_ACCOUNTS_AND_PRODUCTS = [
    WebBankingBusinessRoles.CONS,
    WebBankingBusinessRoles.CDMA,
    WebBankingBusinessRoles.CAMA,
    WebBankingBusinessRoles.EDMA,
    WebBankingBusinessRoles.DECI,
    WebBankingBusinessRoles.PTDC,
];

const PERMISSIONS_PAIEMENT = [
    WebBankingBusinessRoles.BEMA,
    WebBankingBusinessRoles.SLMA,
    WebBankingBusinessRoles.SDMA,
];

const FLEX_START = 'flex-start';

const TitleBox = ({
    onImportBtnClick,
}: {
    onImportBtnClick: Dispatch<SetStateAction<boolean>>;
}) => {
    const { t } = useTranslation(['commons']);

    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            width={'100%'}
        >
            <ScreenWrapperTitle
                title={t('commons:modules.webpro.self-admin.rights-and-permissions')}
            />
            <RequirePermissions permissions={[Permission.SELF_ADMIN]}>
                <Button
                    type={'submit'}
                    onClick={() => onImportBtnClick(true)}
                    variant={'outlinedSecondary'}
                    color='primary'
                    size={'small'}
                >
                    {t('commons:modules.webpro.self-admin.buttons.import-profile')}
                </Button>
            </RequirePermissions>
        </Stack>
    );
};

type PermissionsGroupProps = {
    title: string;
    permissions: WebBankingBusinessProfilePermission[];
    isCheckedFct: (p: Partial<WebBankingBusinessProfilePermission>) => boolean;
    handleChange: (e: SyntheticEvent<Element, Event>, checked: boolean) => void;
};

const PermissionsGroup = ({
    title,
    permissions,
    isCheckedFct,
    handleChange,
}: PermissionsGroupProps) => {
    const hasSelfAdminPermission = useHasOneOfPermission([Permission.SELF_ADMIN]);

    return (
        <Box width={'100%'}>
            <Typography variant={'h4'} fontWeight={400} textAlign={'left'}>
                {title}
            </Typography>

            <DashedDivider sx={{ width: '90%', my: 4 }} />

            {permissions && (
                <Grid container justifyItems={FLEX_START} width={'100%'}>
                    {permissions?.map((permission) => (
                        <Grid key={permission.code} size={{ xs: 12, sm: 6 }} textAlign={'left'}>
                            <CheckBox
                                id={permission.code}
                                name={permission.code}
                                checked={isCheckedFct(permission)}
                                onChange={handleChange}
                                disabled={
                                    !hasSelfAdminPermission ||
                                    permission.code === WebBankingBusinessRoles.SADM
                                }
                                label={
                                    <Typography
                                        variant={'body1'}
                                        sx={{
                                            padding: '2px 6px 1px 6px',
                                            ...(isCheckedFct(permission) && {
                                                background: '#e8f9f0',
                                                borderRadius: '10px',
                                            }),
                                        }}
                                        color={isCheckedFct(permission) ? 'primary' : 'black'}
                                        fontWeight={isCheckedFct(permission) ? 400 : 100}
                                    >
                                        {permission.label}
                                    </Typography>
                                }
                            />
                        </Grid>
                    ))}
                </Grid>
            )}
        </Box>
    );
};

const SigningRulesGroup = ({
    title,
    titleSelect,
    profileValue,
    isAllowed,
    handleChange,
    retrieveAmount,
    retrieveSalaryAmount,
    setHasStandardProfile,
    setHasSalaryProfile
}: {
    title: string;
    titleSelect: string;
    profileValue: string;
    isAllowed: boolean;
    handleChange: (profile: string, isAllowed: boolean) => void;
    retrieveAmount: (maxAmount: MaxAmount) => void;
    retrieveSalaryAmount: (maxAmount: MaxAmount) => void;
    setHasStandardProfile: (arg0: boolean) => void;
    setHasSalaryProfile: (arg0: boolean) => void;
}) => {
    const { t } = useTranslation(['commons']);
    let selectedProfilesList: string[] = [];
    let finalProfilesList: { key: string; value: string | undefined }[];
    const initialProfilesList: { key: string; value: string | undefined }[] = [
        {
            key: 'NO_PROFILE',
            value: t(
                'commons:modules.webpro.self-admin.payments-no-signature-profile'
            ),
        },
    ];


    // Retrieve data to get user profiles
    const { data: webBankingBusinessPaymentConstraint } =
        useGetAllPaymentConstraintsQuery({
            contractReference: useCurrentWebProContractReference(),
        }, { refetchOnMountOrArgChange: true });

    if (webBankingBusinessPaymentConstraint) {
        const { paymentConstraint, salaryPaymentConstraint } = webBankingBusinessPaymentConstraint[0];

        if (paymentConstraint) {
            selectedProfilesList = paymentConstraint.signatureProfile
                .map((signature) => signature.requiredTransactionProfiles)
                .flat();
        }
        if (salaryPaymentConstraint) {
            selectedProfilesList = selectedProfilesList.concat(
                salaryPaymentConstraint.signatureProfile
                    .map((signature) => signature.requiredTransactionProfiles)
                    .flat()
            );
        }

        if (selectedProfilesList.indexOf(profileValue) === -1) {
            handleChange('NO_PROFILE', isAllowed);
        }
        // Filter list to remove duplicate keys and sort it
        selectedProfilesList = Array.from(new Set(selectedProfilesList));
        selectedProfilesList.sort((a, b) => a.localeCompare(b));

        finalProfilesList = initialProfilesList.concat(
            selectedProfilesList.map((profile) => {
                return {
                    key: profile,
                    value: profile.split('_').pop(),
                };
            })
        );
    } else {
        finalProfilesList = initialProfilesList;
    }

    const retrieveAmountOfProfile = (profile: string) => {
        if (webBankingBusinessPaymentConstraint && webBankingBusinessPaymentConstraint[0].paymentConstraint) {
            const { maxAmount, signatureProfile } = webBankingBusinessPaymentConstraint[0].paymentConstraint;

            const matchingSignature = signatureProfile.find((signature) => {
                return (
                    signature.requiredTransactionProfiles.length === 1 && profile === signature.requiredTransactionProfiles[0]
                )
            });

            if (matchingSignature) {
                // If no MaxAmount for the selected profile, use the global one
                const amountToRetrieve = matchingSignature.maxAmount || maxAmount;
                retrieveAmount(amountToRetrieve);
                setHasStandardProfile(true);
            } else {
                setHasStandardProfile(false);
            }

        }

        if (webBankingBusinessPaymentConstraint && webBankingBusinessPaymentConstraint[0].salaryPaymentConstraint) {
            const {
                maxAmount: salaryMaxAmount,
                signatureProfile: salarySignatureProfile
            } = webBankingBusinessPaymentConstraint[0].salaryPaymentConstraint;

            const matchingSalarySignature = salarySignatureProfile.find((signature) => {
                return (
                    signature.requiredTransactionProfiles.length === 1 && profile === signature.requiredTransactionProfiles[0]
                )
            });

            if (matchingSalarySignature) {
                // If no MaxAmount for the selected profile, use the global one
                const amountToRetrieve = matchingSalarySignature.maxAmount || salaryMaxAmount;
                retrieveSalaryAmount(amountToRetrieve);
                setHasSalaryProfile(true);
            } else {
                setHasSalaryProfile(false);
            }
        }


    }

    retrieveAmountOfProfile(profileValue);

    const handleChangeProfile = (profile: string) => {
        retrieveAmountOfProfile(profile);

        const profileName = finalProfilesList.find((p) => p.key === profile);
        if (profileName && profileName.value) {
            handleChange(profileName.key, isAllowed);
        }
    };

    return (
        <Box width={'100%'}>
            <Typography variant={'h4'} fontWeight={400} textAlign={'left'}>
                {title}
            </Typography>
            <DashedDivider sx={{ width: '90%', my: 4 }} />
            <Grid container justifyContent={'space-between'} width={'100%'}>
                <FormControl sx={{ m: 1, width: 350 }}>
                    <SelectField
                        label={titleSelect}
                        items={finalProfilesList}
                        renderItem={(item) => (
                            <MenuItemStyled key={item.key} value={item.key} divider>
                                <Typography variant={'h5'}>{item.value} </Typography>
                            </MenuItemStyled>
                        )}
                        onChange={(event: SelectChangeEvent<unknown>) =>
                            handleChangeProfile(event.target.value as string)
                        }
                        value={profileValue}
                    ></SelectField>
                </FormControl>
                <CheckBox
                    id={'ENCD'}
                    name={t('commons:modules.webpro.self-admin.code.LOG_USR_ENCO')}
                    checked={isAllowed}
                    onChange={(e: SyntheticEvent<Element, Event>, checked: boolean) =>
                        handleChange(profileValue, checked)
                    }
                    disabled={false}
                    label={
                        <Typography
                            variant={'body1'}
                            sx={{
                                padding: '2px 6px 1px 6px',
                                ...(isAllowed && {
                                    background: '#e8f9f0',
                                    borderRadius: '10px',
                                }),
                            }}
                            color={isAllowed ? 'primary' : 'black'}
                            fontWeight={isAllowed ? 400 : 100}
                        >
                            {t('commons:modules.webpro.self-admin.code.LOG_USR_ENCO')}
                        </Typography>
                    }
                />
            </Grid>
        </Box>
    );
};

const UserDetails = () => {
    const { t } = useTranslation(['commons']);
    const navigate = useNavigate();

    const { id = '' } = useParams();
    const [openImportProfile, setOpenImportProfile] = useState(false);
    const { data: allPermissions } = useGetPermissionsQuery();
    const hasSelfAdminPaymentSalaryPermission = useHasOneOfPermission([Permission.SELF_ADMIN_PAYMENT_SALARY]);

    const groupAccounts = useMemo(
        () =>
            allPermissions?.filter(
                (p) =>
                    PERMISSIONS_ACCOUNTS_AND_PRODUCTS.findIndex((p1) => p1 === p.code) !==
                    -1
            ),
        [allPermissions]
    );
    const groupPayment = useMemo(
        () =>
            allPermissions?.filter(
                (p) => {
                    if ((p.code === WebBankingBusinessRoles.SLMA && !hasSelfAdminPaymentSalaryPermission)) {
                        return false;
                    }

                    return PERMISSIONS_PAIEMENT.findIndex((p1) => p1 === p.code) !== -1;
                }
            ),
        [allPermissions, hasSelfAdminPaymentSalaryPermission]
    );
    const groupMisc = useMemo(
        () =>
            allPermissions?.filter(
                (p) =>
                    PERMISSIONS_ACCOUNTS_AND_PRODUCTS.findIndex((p1) => p1 === p.code) ===
                    -1 && PERMISSIONS_PAIEMENT.findIndex((p1) => p1 === p.code) === -1
            ),
        [allPermissions]
    );

    const { data: partyProfile, refetch: refetchPartyProfile, isFetching: loadingPartyProfile } = useGetPartyProfileQuery(
        {
            partyId: id,
        },
        { skip: !allPermissions, refetchOnMountOrArgChange: true }
    );

    const [amountOfSelectedProfile, setAmountOfSelectedProfile] = useState<MaxAmount>({
        nb: 0,
        currencyRef: '',
    });
    const [salaryAmountOfSelectedProfile, setSalaryAmountOfSelectedProfile] = useState<MaxAmount>({
        nb: 0,
        currencyRef: '',
    });

    const [currentTransactionProfile, setCurrentTransactionProfile] = useState('');
    const [currentIsPaymentEncoder, setCurrentIsPaymentEncoder] = useState(false);
    const [waitingUpdate, setWaitingUpdate] = useState(false);

    const [draftSaved, setDraftSaved] = useState(false);

    const [userManagementState, dispatch] = useReducer(reducer, initialState);
    const [hasStandardProfile, setHasStandardProfile] = useState(false);
    const [hasSalaryProfile, setHasSalaryProfile] = useState(false);

    const isDirty = useCallback(() => {
        if (
            partyProfile?.permissions &&
            userManagementState?.partyProfile?.permissions
        ) {
            /* Check checked permissions equals to partyprofile permissions */
            const serverPermissions = partyProfile?.permissions
                .map((p) => p.code)
                .sort();
            const uiPermissions = userManagementState?.partyProfile?.permissions
                .map((p) => p.code)
                .sort();
            return !_.isEqual(serverPermissions, uiPermissions);
        } else {
            return false;
        }
    }, [
        partyProfile?.permissions,
        userManagementState?.partyProfile?.permissions,
    ]);

    const isAllPermissionRemoved = useCallback(() => {
        const uiPermissions = userManagementState?.partyProfile?.permissions;
        return _.size(uiPermissions) === 0;
    }, [userManagementState?.partyProfile?.permissions]);

    const isSigningRulesChanged = useCallback(() => {
        return partyProfile?.transactionProfileEnum !== currentTransactionProfile || partyProfile?.paymentEncodingAllowed !== currentIsPaymentEncoder;
    }, [partyProfile, currentTransactionProfile, currentIsPaymentEncoder]);

    const handleSelection = useCallback((from: WebBankingBusinessPartyProfile) => {
        if (from?.permissions) {
            let nextPermissions = from.permissions.map((p) => ({
                code: p.code as WebBankingBusinessRoles,
                label: p.label,
            }));
            const adminInFrom =
                from.permissions.findIndex(
                    (p) => p.code === WebBankingBusinessRoles.SADM
                ) !== -1;
            const wasAdmin =
                partyProfile?.permissions?.findIndex(
                    (p) => p.code === WebBankingBusinessRoles.SADM
                ) !== -1;

            if (wasAdmin && !adminInFrom) {
                /* Add */
                nextPermissions = [
                    ...nextPermissions,
                    { code: WebBankingBusinessRoles.SADM, label: '' },
                ];
            } else if (!wasAdmin && adminInFrom) {
                /* Remove */
                nextPermissions.splice(
                    from.permissions.findIndex(
                        (p) => p.code === WebBankingBusinessRoles.SADM
                    ),
                    1
                );
            }

            const fromNext = { ...from };
            fromNext.permissions = nextPermissions;

            dispatch({
                type: ActionKind.ACTION_IMPORT_PROFILE,
                payload: {
                    partyProfile: fromNext
                },
            });

            setCurrentIsPaymentEncoder(from.paymentEncodingAllowed);
            setCurrentTransactionProfile(from.transactionProfileEnum);
        }

        setOpenImportProfile(false);
    },
        [partyProfile?.permissions]
    );

    useEffect(() => {
        if (partyProfile?.id && partyProfile?.permissions) {
            dispatch({
                type: ActionKind.ACTION_ADD_PARTY_PERMISSION,
                payload: {
                    permissionToAdd: {
                        value: partyProfile.permissions.map((p) => ({
                            code: p.code,
                            label: p.label,
                        })),
                        replace: true,
                    },
                },
            });
        }

        setCurrentTransactionProfile(partyProfile?.transactionProfileEnum ?? '');
        setCurrentIsPaymentEncoder(partyProfile?.paymentEncodingAllowed ?? false);
    }, [partyProfile]);

    const _hasPermission = useCallback(
        (permission: Partial<WebBankingBusinessProfilePermission>) => {
            const permissions = userManagementState?.partyProfile?.permissions;
            if (permissions) {
                return (
                    permissions.findIndex(
                        (p: Partial<WebBankingBusinessProfilePermission>) =>
                            p.code === permission.code
                    ) !== -1
                );
            } else {
                return false;
            }
        },
        [userManagementState?.partyProfile?.permissions]
    );

    const _isAdmin = useCallback(
        (profile: WebBankingBusinessPartyProfile) =>
            profile?.permissions?.findIndex(
                (p) => p.code === WebBankingBusinessRoles.SADM
            ) !== -1,
        []
    );

    const _handleChangeCheckPermission = useCallback(
        (e: SyntheticEvent<Element, Event>, checked: boolean) => {
            const permission = allPermissions?.find(
                (currentPermission) =>
                    currentPermission.code === (e.currentTarget as HTMLInputElement).name
            );

            if (checked) {
                dispatch({
                    type: ActionKind.ACTION_ADD_PARTY_PERMISSION,
                    payload: {
                        permissionToAdd: {
                            value: permission ?? ({} as WebBankingBusinessProfilePermission),
                        },
                    },
                });
            } else {
                dispatch({
                    type: ActionKind.ACTION_REMOVE_PARTY_PERMISSION,
                    payload: {
                        permissionToRemove:
                            permission ?? ({} as WebBankingBusinessProfilePermission),
                    },
                });
            }
        },
        [allPermissions]
    );

    const _handleCancelBtnClick = useCallback(() => {
        if (partyProfile?.permissions) {
            dispatch({
                type: ActionKind.ACTION_ADD_PARTY_PERMISSION,
                payload: {
                    permissionToAdd: {
                        value: partyProfile.permissions.map((p) => ({
                            code: p.code,
                            label: p.label,
                        })),
                        replace: true,
                    },
                },
            });

            setCurrentTransactionProfile(partyProfile.transactionProfileEnum);
            setCurrentIsPaymentEncoder(partyProfile.paymentEncodingAllowed);
        }
    }, [partyProfile]);

    const [updatePartyProfile, { isLoading: isUpdateInProgress }] = useUpdatePartyProfileMutation();

    const refetchProfile = useCallback(() => {
        setWaitingUpdate(true);
        //waiting on backend to be up to date
        setTimeout(() => {
            setWaitingUpdate(false)
            refetchPartyProfile();
        }, 5000);
    }, [refetchPartyProfile, setWaitingUpdate]);

    const _handleValidateBtnClick = useCallback(async () => {
        if (partyProfile) {
            try {
                const updatedData = await updatePartyProfile({
                    id: partyProfile?.id,
                    paymentEncoder: currentIsPaymentEncoder,
                    transactionProfile:
                        currentTransactionProfile as TransactionProfileEnum,
                    activatedPermissions:
                        userManagementState?.partyProfile?.permissions?.map(
                            (p) => p.code as WebBankingBusinessRoles
                        ) ?? [],
                }).unwrap();

                dispatch({
                    type: ActionKind.ACTION_RESET_PARTY_PERMISSION,
                    payload: {},
                });

                setDraftSaved(true);

                if (updatedData.id !== partyProfile?.id) {
                    navigate('../users/user-detail/' + updatedData.id, { replace: true });
                }
            } catch (e) {
            }
        }
    }, [
        navigate,
        partyProfile,
        updatePartyProfile,
        currentTransactionProfile,
        currentIsPaymentEncoder,
        userManagementState?.partyProfile?.permissions,
    ]);

    const { refetch } = useGetContractUpdatesQuery();

    const _handleBackClick = useCallback(() => {
        dispatch({
            type: ActionKind.ACTION_RESET_PARTY_PERMISSION,
            payload: {},
        });

        refetch();
        navigate('../');
    }, [navigate, refetch, dispatch]);

    const onChangeSelectProfile = (profile: string, isAllowed: boolean) => {
        setCurrentTransactionProfile(profile);
        setCurrentIsPaymentEncoder(isAllowed);
    };

    const retrieveAmount = (maxAmount: MaxAmount) => {
        if (maxAmount) {
            setAmountOfSelectedProfile(maxAmount);
        }
    };

    const retrieveSalaryAmount = (maxAmount: MaxAmount) => {
        if (maxAmount) {
            setSalaryAmountOfSelectedProfile(maxAmount);
        }
    };

    const hasNoProfile = (profile: string) => {
        return ['PROFILE_A', 'PROFILE_B', 'PROFILE_C'].indexOf(profile) === -1;
    };

    return partyProfile && !waitingUpdate && !loadingPartyProfile ? (
        <ScreenWrapper
            header={<TitleBox onImportBtnClick={setOpenImportProfile} />}
            headerLeft={
                <ScreenWrapperTitle
                    title={(t('commons:labels.user') as string).toUpperCase()}
                />
            }
            left={
                <UserBox
                    partyId={partyProfile.id}
                    label={partyProfile.party.label}
                    ssn={partyProfile.ssn}
                    admin={_isAdmin(partyProfile)}
                    blocked={partyProfile.accessBlocked}
                    refetchPartyProfile={refetchProfile}
                />
            }
        >
            {draftSaved ? (
                <>
                    <SavedAsDraft />
                    <Box
                        textAlign={'center'}
                        position={'absolute'}
                        width={'100%'}
                        bottom={'-16px'}
                    >
                        <Button
                            type={'submit'}
                            onClick={_handleBackClick}
                            variant='contained'
                            color='primary'
                            size={'medium'}
                        >
                            {t('commons:buttons.back')}
                        </Button>
                    </Box>
                </>
            ) : (
                <>
                    <Stack
                        mt={8}
                        direction={'column'}
                        spacing={12}
                        justifyContent={FLEX_START}
                        justifyItems={FLEX_START}
                        alignItems={FLEX_START}
                        textAlign={'center'}
                        px={12}
                        mb={20}
                    >
                        <PermissionsGroup
                            title={t(
                                'commons:modules.webpro.self-admin.accounts-and-products-management'
                            )}
                            permissions={groupAccounts ?? []}
                            isCheckedFct={_hasPermission}
                            handleChange={_handleChangeCheckPermission}
                        />

                        {groupPayment && groupPayment.length > 0 && (
                            <PermissionsGroup
                                title={t(
                                    'commons:modules.webpro.self-admin.payments-management'
                                )}
                                permissions={groupPayment ?? []}
                                isCheckedFct={_hasPermission}
                                handleChange={_handleChangeCheckPermission}
                            />
                        )}

                        <PermissionsGroup
                            title={t('commons:modules.webpro.self-admin.misc-management')}
                            permissions={groupMisc ?? []}
                            isCheckedFct={_hasPermission}
                            handleChange={_handleChangeCheckPermission}
                        />

                        <RequirePermissions permissions={[Permission.SELF_ADMIN]}>
                            <SigningRulesGroup
                                title={t(
                                    'commons:modules.webpro.self-admin.payments-signing-rules'
                                ).toUpperCase()}
                                titleSelect={t(
                                    'commons:modules.webpro.self-admin.payments-signature-profile'
                                )}
                                profileValue={currentTransactionProfile}
                                isAllowed={currentIsPaymentEncoder}
                                handleChange={onChangeSelectProfile}
                                retrieveAmount={retrieveAmount}
                                retrieveSalaryAmount={retrieveSalaryAmount}
                                setHasStandardProfile={setHasStandardProfile}
                                setHasSalaryProfile={setHasSalaryProfile}
                            />
                            <>
                                {currentTransactionProfile && !hasNoProfile(currentTransactionProfile) && (
                                    <Box
                                        sx={{
                                            width: '100%',
                                            color: '#00915A',
                                            backgroundColor: '#EEFFF0',
                                        }}
                                    >
                                        <Typography
                                            variant={'h5'}
                                            fontWeight={500}
                                            align='left'
                                            ml={5}
                                            color={'primary'}
                                        >
                                            {t(
                                                'commons:modules.webpro.self-admin.payments-signing-rules-title'
                                            )}
                                        </Typography>
                                        {hasStandardProfile &&
                                        <Typography
                                            variant={'h6'}
                                            fontWeight={300}
                                            align='left'
                                            ml={5}
                                            color={'primary'}
                                        >
                                            <Trans t={t}>
                                                {t('commons:modules.webpro.payment.payment-view-type.classic-transfer-title')} : { }
                                                {amountOfSelectedProfile?.nb && amountOfSelectedProfile.nb > 0 ?
                                                    t('commons:modules.webpro.self-admin.payments-signing-rules-info',
                                                        {
                                                            profile: currentTransactionProfile.split('_').pop() ?? '',
                                                            amount: amountOfSelectedProfile.nb ?? '',
                                                            currency: amountOfSelectedProfile.currencyRef,
                                                        }) :
                                                    t('commons:modules.webpro.self-admin.payments-signing-rules-info-unlimited',
                                                        {
                                                            profile: currentTransactionProfile.split('_').pop() ?? '',
                                                        })
                                                }
                                            </Trans>
                                        </Typography>
                                        }
                                        {hasSalaryProfile &&
                                        <Typography
                                            variant={'h6'}
                                            fontWeight={300}
                                            align='left'
                                            ml={5}
                                            color={'primary'}
                                        >
                                            <Trans t={t}>
                                                {t('commons:modules.webpro.payment.payment-view-type.salary-transfer-title')} : { }
                                                {salaryAmountOfSelectedProfile?.nb && salaryAmountOfSelectedProfile.nb > 0 ?
                                                    t('commons:modules.webpro.self-admin.payments-signing-rules-info',
                                                        {
                                                            profile: currentTransactionProfile.split('_').pop() ?? '',
                                                            amount: salaryAmountOfSelectedProfile.nb ?? '',
                                                            currency: salaryAmountOfSelectedProfile.currencyRef,
                                                        }) :
                                                    t('commons:modules.webpro.self-admin.payments-signing-rules-info-unlimited',
                                                        {
                                                            profile: currentTransactionProfile.split('_').pop() ?? '',
                                                        })
                                                }
                                            </Trans>
                                        </Typography>
                                        }
                                    </Box>
                                )}
                            </>
                        </RequirePermissions>
                    </Stack>
                    <RequirePermissions permissions={[Permission.SELF_ADMIN]}>
                        <Box position={'absolute'} width={'100%'}>
                            <Stack
                                direction={'row'}
                                spacing={8}
                                justifyContent={'center'}
                                textAlign={'center'}
                                alignItems={'center'}
                                mt={-5}
                            >
                                <Button
                                    variant={'contained'}
                                    disabled={
                                        (!isDirty() && !isSigningRulesChanged()) ||
                                        isUpdateInProgress
                                    }
                                    color={'secondary'}
                                    size={'small'}
                                    onClick={_handleCancelBtnClick}
                                >
                                    {t('commons:buttons.cancel')}
                                </Button>

                                <Button
                                    variant={'contained'}
                                    color={'primary'}
                                    size={'small'}
                                    disabled={
                                        (!isDirty() && !isSigningRulesChanged()) ||
                                        isUpdateInProgress ||
                                        isAllPermissionRemoved()
                                    }
                                    onClick={_handleValidateBtnClick}
                                >
                                    {t('commons:buttons.validate-updates')}
                                </Button>
                            </Stack>
                        </Box>
                    </RequirePermissions>
                </>
            )}
            <ProfileImportModal
                isOpen={openImportProfile}
                handleClose={handleSelection}
                partyProfile={partyProfile}
            ></ProfileImportModal>
        </ScreenWrapper>
    ) : (
        <UserDetailsSkeleton />
    );
};

const UserDetailsSkeleton = () => {
    const { t } = useTranslation(['commons']);
    return (
        <ScreenWrapper
            header={<TitleBox onImportBtnClick={() => { }}></TitleBox>}
            headerLeft={
                <ScreenWrapperTitle
                    title={(t('commons:labels.user') as string).toUpperCase()}
                />
            }
            left={<UserBoxSkeleton />}
        ></ScreenWrapper>
    );
};

export default UserDetails;
