import {useContext} from 'react';
import {AppPrivateContext} from '../context/AppPrivateContext';
import {PrimaryContract, WebProContract} from '../redux/@types/WebProContractTypes';

/**
 * Custom hooks based on AppPrivateContextProvider witch returns a tuple to get the currentPrimaryContract with his setter function.
 */
export const useCurrentPrimaryContract = (): [PrimaryContract | undefined, (primaryContract: PrimaryContract | undefined) => void] => {
    const {
        currentPrimaryContract, setCurrentPrimaryContract = () => {/* default impl */
        }
    } = useContext(AppPrivateContext);
    return [currentPrimaryContract, setCurrentPrimaryContract];
};

export const useCurrentPrimaryContractReference = (): string => useCurrentPrimaryContract()[0]?.primaryContractId ?? '';
export const useCurrentCryptedPrimaryContractReference = (): string => useCurrentPrimaryContract()[0]?.cryptedPrimaryContractReference ?? '';

export const usePrincipalPrimaryContract = () => {
    const currentWebproContract = useCurrentWebProContract()[0];

    let principal = currentWebproContract?.primaryContracts?.find(primaryContract => primaryContract.mainPrimaryContract);
    if (!principal) {
        principal = currentWebproContract?.primaryContracts?.at(0);
    }

    return principal;
};

/**
 * Custom hooks based on AppPrivateContextProvider witch returns a tuple to get the currentWebProContract with his setter function.
 */
export const useCurrentWebProContract = (): [WebProContract | undefined, (webProContract: WebProContract | undefined) => void] => {
    const {
        currentWebProContract, setCurrentWebProContract = () => {/* default impl */
        }
    } = useContext(AppPrivateContext);
    return [currentWebProContract, setCurrentWebProContract];
};

export const useCurrentWebProContractReference = (): string => useCurrentWebProContract()[0]?.reference ?? '';
