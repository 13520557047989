import React from 'react';
import {Box, Stack} from '@mui/material';
import {styled} from '@mui/system';


export const FilterArrow = styled(Box, {
    shouldForwardProp: (propName: PropertyKey) => propName !== 'direction' && propName !== 'disabled'
})<{ direction: 'up' | 'down', disabled: boolean }>(({theme, direction, disabled}) => ({
    width: '8px',
    height: '8px',
    clipPath: 'polygon(0 0, 100% 50%, 0 100%)',
    cursor: 'pointer',

    backgroundImage: !disabled ? 'linear-gradient(91deg, #8DE541 0%, #2AAB44 100%)' : 'linear-gradient(91deg, #d0e1c6 0%, #a2b9ae 100%)',

    ...direction === 'up' && {
        transform: 'rotate(270deg)',
    },

    ...direction === 'down' && {
        transform: 'rotate(90deg)',
    }
}));

const TableFilterArrow = ({sort}: { sort?: 'asc' | 'desc' | null }) => {
    return (
        <Stack direction={'column'} spacing={1} justifyContent={'center'} alignItems={'center'} ml={2}>
            <FilterArrow direction={'up'} disabled={sort !== 'asc'}/>
            <FilterArrow direction={'down'} disabled={sort !== 'desc'}/>
        </Stack>
    );
};

export default TableFilterArrow;
