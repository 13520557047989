import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Stack, Typography} from '@mui/material';
import AppRouter from './AppRouter';
import {usePingQuery} from './redux/api/pingApiRtk';
import {RootContext} from './context/root-context';
import {useCookies} from 'react-cookie';
import {useIdleTimer} from 'react-idle-timer';
import {Language, Locale} from './@types/ui.types';
import Validator from 'validatorjs';
import {FullPageBglSvgLoader} from './assets/Images';
import {usePrefetchFeatures} from './redux/api/featureApiRtk';
import {useTranslation} from 'react-i18next';
import GenericDialog from './ui/_commons/components/Dialog/GenericDialog';
import {useLogoutMutation} from './redux/api/customerAuthentApiRtk.ts';
import eventbus, {EventName} from './eventbus/eventbus.ts';
import ExitSvg from './assets/images/commons/exit.svg?react';

export const COOKIE_WBK_LANGUAGE = 'wbklanguage';
const IDLE_TIME_IN_MILLIS = import.meta.env.DEV ? 43_200_000 /* 12h in dev */ : 600_000 /* 9min elsewhere */;
const PING_POOLING_IN_MILLIS = import.meta.env.DEV ? 43_200_000 /* 12h in dev */ : 540_000 /* 9min elsewhere */;

const App = ({language: defaultLanguage}: { language: Language }) => {
    Validator.useLang(defaultLanguage);

    const {t} = useTranslation(['commons']);

    const [, setCookie] = useCookies([COOKIE_WBK_LANGUAGE]);
    const [isReady, setIsReady] = useState(false);

    const [_isAuthenticated, _setIsAuthenticated] = useState(false);
    const [drawerOpened, setDrawerOpened] = useState(false);
    const [language, setLanguage] = useState(defaultLanguage);
    const [logout] = useLogoutMutation();
    const [isOpenTimeOutDialog, setIsOpenTimeOutDialog] = useState(false);

    const prefetchFeatures = usePrefetchFeatures('getPublicFeatures');

    const locale = useMemo((): Locale => {
        switch (language) {
            case 'fr':
                return 'fr-FR';
            case 'en':
                return 'en-GB';
            case 'de':
                return 'de-DE';
            default:
                return 'fr-FR';
        }
    }, [language]);

    const messages = useMemo(
        () => ({
            required: t('commons:errors.required'),
            required_if: t('commons:errors.required_if'),
            required_without: t('commons:errors.required_without_nomenclature_bic'),
            email: t('commons:errors.email'),
            digits: t('commons:errors.max.digits'),
            size: t('commons:errors.size'),
            min: {
                string: t('commons:errors.min.string'),
                numeric: t('commons:errors.min.numeric')
            },
            max: {
                string: t('commons:errors.max.string'),
                numeric: t('commons:errors.max.numeric')
            },
            accepted: t('commons:errors.accepted'),
            regex: t('commons:errors.regex_no_special'),
            in: t('commons:errors.required'),
            before_or_equal: t('commons:errors.date'),
            after_or_equal: t('commons:errors.date'),
            numeric: t('commons:errors.numeric'),
            digits_between: t('commons:errors.digits_between'),


            attributes: {}
        }),
        [t]
    );

    Validator.setMessages(defaultLanguage, messages);

    const {
        data,
        isSuccess,
        isFetching,
        isUninitialized
    } = usePingQuery(void (0), {pollingInterval: PING_POOLING_IN_MILLIS});

    const rootContextValue = useMemo(
        () => ({
            setIsAuthenticated: (value: boolean) => {
                _setIsAuthenticated(value);
            },
            setDrawerOpened: (value: boolean) => {
                setDrawerOpened(value);
            },
            drawerOpened,
            isAuthenticated: _isAuthenticated,
            locale,
            language,
            setLanguage: (value: Language) => {
                setLanguage(value);
            }
        }),
        [_isAuthenticated, _setIsAuthenticated, locale, language, drawerOpened, setDrawerOpened]
    );

    useEffect(() => {
        setCookie(COOKIE_WBK_LANGUAGE, language, {path: '/'});
    }, [setCookie, language]);

    useEffect(() => {
        if (!isFetching) {
            _setIsAuthenticated(isSuccess);
            setIsReady(true);
        }
    }, [isSuccess, isFetching, _setIsAuthenticated, isUninitialized, data, prefetchFeatures]);

    const handleTimeoutError = useCallback(async () => {
        await logout().unwrap();
        _setIsAuthenticated(false);
        setIsOpenTimeOutDialog(true);
    }, [logout]);

    const iIdleTimer = useIdleTimer({
        timeout: IDLE_TIME_IN_MILLIS,
        onIdle: handleTimeoutError,
        startManually: true,
        startOnMount: false
    });

    useEffect(() => {
        if (_isAuthenticated) {
            iIdleTimer.reset();
            iIdleTimer.start();
        } else {
            iIdleTimer.pause();
        }
    }, [_isAuthenticated, iIdleTimer]);

    useEffect(() => {
        const subscribed = eventbus.on(EventName.DISCONNECTED, (data: any) => {
            _setIsAuthenticated(false);
            if (data?.popup) {
                setIsOpenTimeOutDialog(true);
            }
        });

        return () => {
            subscribed.unsubscribe();
        };
    }, []);


    return (
        <RootContext.Provider value={rootContextValue}>
            {
                isReady ? <AppRouter/> : <FullPageBglSvgLoader/>
            }

            <GenericDialog
                isOpen={isOpenTimeOutDialog}
                title={t('commons:buttons.logout')}
                handleClose={() => setIsOpenTimeOutDialog(false)}
                hideCloseButton={true}
                noPadding={true}
                actions={
                    <Button
                        type={'submit'}
                        variant="contained"
                        color="primary"
                        size={'medium'}
                        onClick={() => setIsOpenTimeOutDialog(false)}>
                        {t('commons:buttons.validate')}
                    </Button>
                }>
                <Stack alignItems={'center'} justifyContent={'center'} spacing={4} py={8} mt={8}>
                    <ExitSvg height={'auto'} width={'350px'}/>
                    <Typography variant={'h4'} align={'center'}>
                        {t('commons:errors.disconnected')}
                    </Typography>
                </Stack>
            </GenericDialog>
        </RootContext.Provider>
    );
};

export default App;

