import React from 'react';
import {Divider, DividerProps, styled} from '@mui/material';

const DividerStyled = styled(Divider)<DividerProps & { height: number | string }>(({theme, height}) => ({
    background: 'transparent linear-gradient(270deg, #8DE541 0%, #018B57 100%) 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
    height: typeof height === 'number' ? height + 'px' : height,
    opacity: 1,
    marginTop: '0 ! important',
    marginBottom: '0 ! important',
}));

const DEFAULT_HEIGHT = 3;

const GradientDivider = ({height = DEFAULT_HEIGHT, ...dividerProps}: DividerProps & { height?: number | string }) => {
    return (
        <DividerStyled {...dividerProps} height={height}/>
    );
};

export default GradientDivider;
