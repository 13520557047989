import React from 'react';
import {Divider, DividerProps, styled} from '@mui/material';
import {dividerClasses} from '@mui/material/Divider';

const DividerStyled = styled(Divider, {shouldForwardProp: (propertyName) => propertyName !== 'inverse'})<{ inverse: boolean }>(({
                                                                                                                                    theme,
                                                                                                                                    inverse
                                                                                                                                }) => ({
    [`&.${dividerClasses['root']}:empty`]: {
        backgroundImage: inverse ? 'linear-gradient(to right, white 20%, rgba(255, 255, 255, 0) 0%)' : 'linear-gradient(to right, black 20%, rgba(255, 255, 255, 0) 0%)',
        backgroundSize: '5px 1px',
        borderColor: 'transparent'
    },

    [`&.${dividerClasses['withChildren']}`]: {
        '&:before, &:after': {
            backgroundImage: inverse ? 'linear-gradient(to right, white 20%, rgba(255, 255, 255, 0) 0%)' : 'linear-gradient(to right, black 20%, rgba(255, 255, 255, 0) 0%)',
            backgroundSize: '5px 1px',
            margin: 'auto 0',
            height: '1px',
            borderColor: 'transparent',
            transform: 'none'
        }
    }
}));

const DashedDivider = ({inverse = false, ...props}: DividerProps & { inverse?: boolean }) => {
    return (
        <DividerStyled inverse={!!inverse} {...props}>{props.children}</DividerStyled>
    );
};

export default DashedDivider;
