import React, {SyntheticEvent, useCallback, useContext} from 'react';
import {Box, Button, FormControl, Skeleton, Stack, Typography} from '@mui/material';
import UserIcon from '../../../assets/images/commons/picto/user-standard.svg?react';
import {useTranslation} from 'react-i18next';
import Pressable from '../../_commons/components/Pressable/Pressable';
import {
    WebBankingBusinessPartyProfile,
} from '../../../redux/@types/SelfAdminTypes';
import UserStatusIcon from './UserStatusIcon';
import {SelfAdminContext} from '../context/SelfAdminContext';
import CheckBox from '../../_commons/components/Form/Checkbox/CheckBox';
import SelectField, {MenuItemStyled} from '../../_commons/components/Form/SelectField/SelectField';
import DashedDivider from '../../_commons/components/Divider/DashedDivider';

const BORDER = '1px solid #E1F4EE';
const FLEX_START = 'flex-start';

const UserSummary = ({handleClick, isLink = true, selected = false, admin = false, decision_maker = false, partyProfile}: React.PropsWithoutRef<{
    admin: boolean,
    decision_maker: boolean,
    isLink?: boolean,
    selected?: boolean,
    handleClick?: Function,
    partyProfile: WebBankingBusinessPartyProfile
}>) => {
    const {t} = useTranslation(['commons']);
    const {typeView} = useContext(SelfAdminContext);

    const handleClickCard = useCallback((e: SyntheticEvent)=> {
        if (handleClick){
            e.preventDefault();
            handleClick(partyProfile.id);
        }
    },[handleClick,partyProfile.id]);

    return (
        <Pressable to={isLink?`./user-detail/${partyProfile.id}`:''} sx={selected ?{
            border: '1px solid #186C54',
            boxShadow: '0px 5px 12px #186C54'
        }:{}}>
            <Stack direction={'column'} width={'100%'} onClick={handleClickCard}>
                <Stack direction={'row'} alignItems={'center'} spacing={8} padding={6}>

                    <UserStatusIcon admin={admin} decision_maker={decision_maker} disabled={partyProfile.accessBlocked}/>

                    <Stack direction={'column'} alignItems={FLEX_START}>
                        <Typography variant={'h4'} fontWeight={400} mb={4}>{partyProfile.party.label}</Typography>
                        {
                            partyProfile.ssn.map(s =>
                                <Typography key={s.code} variant={'h6'} fontWeight={100} lineHeight={1}>
                                    SSN LUXTRUST: {s.code}
                                </Typography>
                            )
                        }
                    </Stack>
                </Stack>

                {typeView === 'DETAILS' &&
                    <>
                        <Stack direction={'row'} spacing={8} padding={6} justifyContent={'space-between'} onClick={() => {}}>
                            <Stack direction={'column'}>
                                {partyProfile.permissions?.slice(0, Math.ceil(partyProfile.permissions?.length / 2)).map((permission) => (
                                    <CheckBox
                                        id={permission.code}
                                        name={permission.code}
                                        checked={true}
                                        disabled={true} label={
                                        <Typography textTransform={'uppercase'} color={'#464D54'}>{permission.label}</Typography>
                                    }/>
                                ))}
                            </Stack>
                            <Stack direction={'column'}>
                                {partyProfile.permissions?.slice(Math.ceil(partyProfile.permissions?.length / 2), Math.ceil(partyProfile.permissions?.length / 2)*2).map((permission) => (
                                    <CheckBox
                                        id={permission.code}
                                        name={permission.code}
                                        checked={true}
                                        disabled={true} label={
                                        <Typography textTransform={'uppercase'} color={'#464D54'}>{permission.label}</Typography>
                                    }/>
                                ))}
                            </Stack>
                        </Stack>
                        <DashedDivider sx={{width: '90%', my: 4, ml: '5%'}}/>
                        <Stack direction={'row'} spacing={8} padding={6} justifyContent={'space-between'}>
                            <FormControl sx={{m: 1, width: 350}}>
                                <SelectField
                                    label={t(
                                        'commons:modules.webpro.self-admin.payments-signature-profile'
                                    )}
                                    items={[{
                                        key: partyProfile.transactionProfileEnum,
                                        value: partyProfile.transactionProfileEnum === 'NO_PROFILE' ? t(
                                            'commons:modules.webpro.self-admin.payments-no-signature-profile'
                                        ) : partyProfile.transactionProfileEnum.split('_').pop()
                                    }]}
                                    renderItem={(item) => (
                                        <MenuItemStyled key={item.key} value={item.key} divider>
                                            <Typography variant={'h5'}>{item.value} </Typography>
                                        </MenuItemStyled>
                                    )}
                                    disabled={true}
                                    value={partyProfile.transactionProfileEnum}
                                ></SelectField>
                            </FormControl>
                            <CheckBox
                                id={'ENCD'}
                                name={t('commons:modules.webpro.self-admin.code.LOG_USR_ENCO')}
                                checked={partyProfile.paymentEncodingAllowed}
                                disabled={true}
                                label={
                                    <Typography
                                        variant={'body1'}
                                        sx={{
                                            padding: '2px 6px 1px 6px',
                                        }}
                                        color={'black'}
                                        fontWeight={partyProfile.paymentEncodingAllowed ? 400 : 100}
                                    >
                                        {t('commons:modules.webpro.self-admin.code.LOG_USR_ENCO')}
                                    </Typography>
                                }
                            />
                        </Stack>
                    </>
                }
                {isLink &&
                (
                    <Box sx={{
                        background: '#F4FFF8',
                        border: BORDER,
                        paddingY: 4,
                        textAlign: 'center',
                        borderBottomRightRadius: '15px',
                        borderBottomLeftRadius: '15px'
                    }}>
                        <Button type={'submit'} onClick={() => void (0)} variant="text"
                                color="primary" size={'medium'}>
                            {t('commons:modules.webpro.self-admin.buttons.manage-user')}
                        </Button>
                    </Box>
                )}
            </Stack>
        </Pressable>
    );
};

export const UserSummarySkeleton = () => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: FLEX_START,
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            boxShadow: '0px 5px 12px #A7C7B31C',
            border: BORDER,
            borderRadius: '15px'
        }}>
            <Stack direction={'column'} width={'100%'}>
                <Stack direction={'row'} alignItems={'center'} spacing={8} padding={8}>
                    <Skeleton variant={'circular'}>
                        <UserIcon width={70} height={65}></UserIcon>
                    </Skeleton>
                    <Stack direction={'column'} alignItems={FLEX_START} width={'100%'}>
                        <Typography variant={'h4'} fontWeight={400} width={'100%'}>
                            <Skeleton variant={'text'}/>
                        </Typography>

                        <Typography variant={'h6'} fontWeight={100} width={'100%'}>
                            <Skeleton variant={'text'}/>
                        </Typography>

                    </Stack>
                </Stack>

                <Box sx={{
                    background: '#F4FFF8',
                    border: BORDER,
                    padding: 4,
                    display: 'flex',
                    justifyContent: 'center',
                    borderBottomRightRadius: '15px',
                    borderBottomLeftRadius: '15px'
                }}>
                    <Skeleton variant={'text'}><Typography textAlign={'center'}>... JUST TO FIX SKELETON SIZE
                        ...</Typography></Skeleton>
                </Box>
            </Stack>
        </Box>
    );
};

export default UserSummary;
