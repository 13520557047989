export interface SignaturesArray {
  id: number;
  label: string;
  value: string[];
}

export const signaturesProfilesArray: SignaturesArray[] = [
    { id: 1, label:'A', value: ['PROFILE_A']},
    { id: 2, label: 'B', value: ['PROFILE_B']},
    { id: 3, label: 'C', value: ['PROFILE_C']},
    {
      id: 4,
      label: 'A+A',
      value: ['PROFILE_A','PROFILE_A']
    },
    {
      id: 5,
      label: 'A+B',
      value: ['PROFILE_A','PROFILE_B']
    },
    {
      id: 6,
      label: 'A+C',
      value: ['PROFILE_A','PROFILE_C']
    },
    {
      id: 7,
      label: 'B+B',
      value: ['PROFILE_B','PROFILE_B']
    },
    {
      id: 8,
      label: 'B+C',
      value: ['PROFILE_B','PROFILE_C']
    },
    {
      id: 9,
      label: 'C+C',
      value: ['PROFILE_C','PROFILE_C']
    }
]
